import React from 'react'

import { Footer } from '../components/Footer'
import { Main } from '../components/Main'
import { StudentMedalDetails, withStudentMedalDetailsData } from '../components/StudentMedal'

const StudentMedalDetailsView = ({ studentMedalData }) => (
    <Main>
        {/*<Header>Student Medal</Header>*/}
        <StudentMedalDetails {...studentMedalData} />
        <Footer />
    </Main>
)

export default withStudentMedalDetailsData()(StudentMedalDetailsView)
