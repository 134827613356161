import React from 'react'

import { fetchSessionDraft, fetchSessionDrafts, updateSessionDraft } from '../actions/sessionDraftActions'

import SessionEditView from './SessionEditView'

const SessionDraftEditView = props => (
    <SessionEditView
        {...props}
        fetchMethod={fetchSessionDraft}
        formProps={{
            fetchMethod: fetchSessionDrafts,
            isDraft: true,
            updateMethod: updateSessionDraft,
        }}
        title="Draft Session"
    />
)

export default SessionDraftEditView
