import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { filterCompletedCourses } from '../../utils/courses'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'
import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { Logo } from '../Logo'
import { SessionProgress } from '../Session'
import { SimpleList } from '../SimpleList'
import { UserProgress } from '../User'

class SessionCard extends React.PureComponent {
    static propTypes = {
        isDraft: PropTypes.bool,
        isFull: PropTypes.bool,
        overrideClass: PropTypes.string,
        session: PropTypes.object.isRequired,
    }

    static defaultProps = {
        isDraft: false,
        isFull: false,
        overrideClass: '',
    }

    render() {
        const {
            isDraft,
            isFull,
            overrideClass,
            session,
            session: { courses, students, reports },
        } = this.props

        const completedCourses = filterCompletedCourses(courses)
        const listItems = [
            { label: 'Session', value: session.name },
            {
                label: 'Steps',
                value: isDraft ? courses.length : `${completedCourses.length}/${courses.length}`,
            },
            { label: 'Starts', value: session.startDate.display },
            { label: 'Ends', value: session.endDate.display },
        ]
        const cardClass = classNames('session-card', 'u-card', 'u-margin-y', overrideClass)
        const editRouteId = isDraft ? 'session-draft-edit' : 'session-edit'
        const listRoute = isDraft ? 'session-draft-list' : 'session-list'

        return (
            <article className={cardClass}>
                <CardHeader>
                    <Logo /> {session.curriculum.name}
                </CardHeader>
                <div className="u-padding-tiny-y u-padding-small-x">
                    <Layout overrideClass="-reverse -middle">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="u-text-center">
                                <SessionProgress
                                    courses={courses}
                                    isDraft={isDraft}
                                    overrideClass={classNames('-margin', {
                                        '-full': isFull,
                                    })}
                                />
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <SimpleList items={listItems} />
                            {isFull && <SessionCard.Body reports={reports} students={students} />}
                        </LayoutItem>
                    </Layout>
                    {!isFull && <SessionCard.Body reports={reports} students={students} />}
                </div>
                <CardFooter>
                    <div className="u-inline-block u-align-middle">
                        <Button
                            route={{
                                id: editRouteId,
                                bind: 'sessionId',
                                param: session.id,
                            }}
                            overrideClass="card-footer_cta"
                        >
                            View Session
                        </Button>
                    </div>
                    {!isFull && (
                        <div className="u-inline-block u-align-middle">
                            <Link route={listRoute}>View all sessions</Link>
                        </div>
                    )}
                </CardFooter>
            </article>
        )
    }
}

SessionCard.Body = ({ reports, students }) => (
    <div className="session-card_main">
        <div className="u-padding-tiny-y">
            <Layout>
                <LayoutItem overrideClass="u-1/2">Students</LayoutItem>
                <LayoutItem overrideClass="u-1/2">
                    <div className="u-text-right">
                        <p className="u-blue u-margin-none">{reports.length || students.length}</p>
                    </div>
                </LayoutItem>
            </Layout>
        </div>
        <div className="session-card_users_list">
            <Layout>
                {reports.length > 0
                    ? reports.map((report, index) => (
                          <LayoutItem overrideClass="u-1/5" key={index}>
                              <UserProgress
                                  user={report.student}
                                  value={report.progression}
                                  color={report.merit < -1 ? 'red' : report.merit < 0 ? 'yellow' : 'green'}
                              />
                          </LayoutItem>
                      ))
                    : students.map((student, index) => (
                          <LayoutItem overrideClass="u-1/5" key={index}>
                              <UserProgress user={student} value={0} />
                          </LayoutItem>
                      ))}
            </Layout>
        </div>
    </div>
)

SessionCard.Placeholder = ({ isFull }) => (
    <div className="session-card u-card u-margin-y">
        {isFull ? (
            <ContentLoader height={420} width={780} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                <circle cx="591" cy="208" r="102" />
                <circle cx="44" cy="300" r="22" />
                <circle cx="116" cy="300" r="22" />
                <circle cx="186" cy="300" r="22" />
                <circle cx="257" cy="300" r="22" />
                <circle cx="328" cy="300" r="22" />
                <rect x="21" y="375" rx="5" ry="5" width="131" height="30" />
                <rect x="1" y="358" rx="0" ry="0" width="811" height="2" />
                <rect x="-15" y="57" rx="0" ry="0" width="811" height="2" />
                <rect x="45" y="23" rx="0" ry="0" width="229" height="22" />
                <rect x="20" y="23" rx="0" ry="0" width="16" height="22" />
                <rect x="20" y="143" rx="3" ry="3" width="330" height="2" />
                <rect x="20" y="182" rx="3" ry="3" width="330" height="2" />
                <rect x="20" y="221" rx="3" ry="3" width="330" height="2" />
                <rect x="20" y="104" rx="3" ry="3" width="330" height="2" />
            </ContentLoader>
        ) : (
            <ContentLoader height={553} width={485} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                <rect x="35" y="30" width="17" height="21" />
                <rect x="66" y="30" width="145" height="21" />
                <rect x="0" y="78" width="485" height="1" />
                <rect x="35" y="124" width="56" height="13" />
                <rect x="35" y="171" width="42" height="13" />
                <rect x="35" y="153" width="208" height="1" />
                <rect x="35" y="200" width="208" height="1" />
                <rect x="182" y="124" width="61" height="13" />
                <rect x="207" y="171" width="36" height="13" />
                <rect x="35" y="218" width="63" height="13" />
                <rect x="35" y="247" width="208" height="1" />
                <rect x="151" y="218" width="92" height="13" />
                <rect x="35" y="265" width="38" height="13" />
                <rect x="35" y="329" width="75" height="13" />
                <rect
                    transform="translate(243.000000, 304.500000) scale(1, -1) translate(-243.000000, -304.500000) "
                    x="35"
                    y="304"
                    width="416"
                    height="1"
                />
                <rect
                    transform="translate(242.500000, 466.500000) scale(1, -1) translate(-242.500000, -466.500000) "
                    x="0"
                    y="466"
                    width="485"
                    height="1"
                />
                <rect x="35" y="484" width="168" height="51" rx="5" />
                <rect x="152" y="265" width="91" height="13" />
                <rect x="423" y="329" width="28" height="13" />
                <circle cx="381.5" cy="200.5" r="68.5" />
                <circle cx="77" cy="408" r="24" />
                <circle cx="160" cy="408" r="24" />
                <circle cx="243" cy="408" r="24" />
                <circle cx="326" cy="408" r="24" />
                <circle cx="409" cy="408" r="24" />
                <rect x="235" y="501" width="131" height="20" />
            </ContentLoader>
        )}
    </div>
)

export default SessionCard
