import React from 'react'

import axios from 'axios'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { connect } from 'react-redux'

import { fetchCertificate, fetchPublicCertificate } from '../../actions/certificateActions'
import {
    galleryStates,
    loadGallery,
    loadMessages,
    loadReportStickers,
    loadTasks,
    messagesStates,
    stickersStates,
    tasksStates,
} from '../../helpers/ReportHelpers'

const withCertificateDetailsData = (isPublic = false) => WrappedComponent => {
    class EnhancedComponent extends React.Component {
        signal = axios.CancelToken.source()

        state = {
            error: null,
            certificate: {},
            isLoadingCertificate: true,
            ...galleryStates,
            ...messagesStates,
            ...stickersStates,
            ...tasksStates,
        }

        loadPublicCertificate(certificateId) {
            this.setState(
                {
                    isLoadingCertificate: true,
                    isLoadingGallery: true,
                    isLoadingMessages: true,
                    isLoadingReportStickers: true,
                    isLoadingTasks: true,
                },
                () => {
                    fetchPublicCertificate(certificateId, {
                        cancelToken: this.signal.token,
                    })
                        .then(({ data }) => {
                            this.setState({
                                certificate: data.certificate,
                                gallery: data.gallery,
                                messages: data.messages,
                                reportStickers: data.reportStickers,
                                tasks: data.tasks,
                            })
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                console.log(error)
                                this.setState({
                                    error: error,
                                })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoadingCertificate: false,
                                isLoadingGallery: false,
                                isLoadingMessages: false,
                                isLoadingReportStickers: false,
                                isLoadingTasks: false,
                            })
                        })
                }
            )
        }

        componentDidMount() {
            const {
                match: {
                    params: { certificateId },
                },
            } = this.props

            if (isPublic === true) {
                this.loadPublicCertificate(certificateId)
            } else {
                this.setState({ isLoadingCertificate: true }, () => {
                    fetchCertificate(certificateId, {
                        cancelToken: this.signal.token,
                    })
                        .then(response => {
                            this.setState(
                                {
                                    certificate: response.data,
                                    isLoadingCertificate: false,
                                },
                                () => {
                                    Promise.all([
                                        loadTasks.call(this, response.data.reportId),
                                        loadGallery.call(this, response.data.reportId),
                                        loadReportStickers.call(this, response.data.reportId),
                                        loadMessages.call(this, response.data.reportId),
                                    ])
                                }
                            )
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                this.setState({
                                    error: error.response.data,
                                    isLoadingCertificate: false,
                                })
                            }
                        })
                })
            }
        }

        componentWillUnmount() {
            this.signal.cancel('API call is being canceled.')
        }

        render() {
            return <WrappedComponent certificateData={{ ...this.state, organization: this.props.organization }} />
        }
    }

    const mapStateToProps = state => ({
        organization: state.organization.data,
    })

    hoistNonReactStatics(EnhancedComponent, WrappedComponent)
    return connect(mapStateToProps)(EnhancedComponent)
}

export default withCertificateDetailsData
