import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { Dropzone } from '../Dropzone'
import { Label } from '../Label'

const ProfileImage = ({ form, initialValue, isRequired }) => (
    <div className="o-input_wrapper">
        <Label isRequired={isRequired}>Photo</Label>
        <Dropzone
            form={form}
            initialFiles={initialValue}
            initialValue={initialValue}
            inputName="profileImage"
            isRounded={true}
            overrideClass="-user"
            recommendedSize="256&times;256px"
        />
    </div>
)

ProfileImage.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
}

ProfileImage.defaultProps = {
    initialValue: '',
    isRequired: false,
}

export default ProfileImage
