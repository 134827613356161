import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'memberships'

/**
 * Add some report cards to a membership.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const addReportCards = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/self/report-cards`, formData, config)
}
