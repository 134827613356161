import React from 'react'

import axios from 'axios'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { connect } from 'react-redux'

import { fetchStudentMedal, fetchPublicStudentMedal } from '../../actions/studentMedalActions'

const withStudentMedalDetailsData = (isPublic = false) => WrappedComponent => {
    class EnhancedComponent extends React.Component {
        signal = axios.CancelToken.source()

        state = {
            error: null,
            isLoading: true,
            studentMedal: {},
        }

        componentDidMount() {
            const {
                match: {
                    params: { medalId },
                },
            } = this.props

            const action = isPublic ? fetchPublicStudentMedal : fetchStudentMedal
            action(medalId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        studentMedal: response.data,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        }

        componentWillUnmount() {
            this.signal.cancel('API call is being canceled.')
        }

        render() {
            return <WrappedComponent studentMedalData={{ ...this.state, organization: this.props.organization }} />
        }
    }

    const mapStateToProps = state => ({
        organization: state.organization.data,
    })

    hoistNonReactStatics(EnhancedComponent, WrappedComponent)
    return connect(mapStateToProps)(EnhancedComponent)
}

export default withStudentMedalDetailsData
