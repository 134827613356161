import axios from 'axios'
import { mapKeys } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'

import { fetchCurriculums } from '../actions/curriculumActions'
import { renderRoute } from '../utils/routes'
import { FilteredList, FilteredListItem } from '../components/FilteredList'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

const HEADERS = [
    { id: 'name', label: 'Name' },
    { id: 'lastModified', label: 'Last update' },
]

class CurriculumListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchCurriculums({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { isLoading, items } = this.props

        return (
            <Main>
                <Header>View Curriculums</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <FilteredList
                        hasFooter={true}
                        hasSorting={true}
                        isFull={true}
                        isLoading={isLoading}
                        itemComponent={FilteredListItem}
                        listHeaders={HEADERS}
                        listItems={items}
                        title={`${items.length} Curriculums`}
                        buttonData={{ label: 'Create a curriculum', route: 'curriculum-create' }}
                    />
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    const headers = mapKeys(HEADERS, 'id')
    return {
        isLoading: state.requestStates.FETCH_CURRICULUM_LIST === true,
        items: state.curriculums.entities.map(item => {
            return {
                id: item.id,
                data: item,
                url: renderRoute({
                    id: 'curriculum-edit',
                    bind: 'curriculumId',
                    param: item.id,
                }),
                columns: {
                    ...headers,
                    name: item.name,
                    lastModified: `${item.lastModified.date} - ${item.lastModified.time}`,
                },
            }
        }),
    }
}

export default connect(mapStateToProps)(CurriculumListView)
