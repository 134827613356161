import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'

import { fetchCurriculum } from '../../actions/curriculumActions'

import { CurriculumCard } from '../Curriculum'
import { Fade } from '../Fade'

class CurriculumCardContainer extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        curriculum: {},
        error: null,
        isLoading: true,
    }

    loadCurriculumData(curriculumId) {
        const { fetchMethod } = this.props
        this.setState({ isLoading: true }, () => {
            fetchMethod(curriculumId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        curriculum: response.data,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        const { curriculum } = this.props

        if (typeof curriculum.isPlaceholder === 'undefined') {
            this.loadCurriculumData(curriculum.id)
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.curriculum.isPlaceholder !== this.props.curriculum.isPlaceholder ||
            prevProps.curriculum.id !== this.props.curriculum.id
        ) {
            this.loadCurriculumData(this.props.curriculum.id)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { cardProps } = this.props
        const { curriculum, error, isLoading } = this.state

        return (
            <SwitchTransition>
                <Fade timeout={250} key={isLoading}>
                    {isLoading ? (
                        <CurriculumCard.Placeholder />
                    ) : error !== null ? (
                        <section className="u-relative">{error.message}</section>
                    ) : (
                        <CurriculumCard curriculum={curriculum} {...cardProps} />
                    )}
                </Fade>
            </SwitchTransition>
        )
    }
}

CurriculumCardContainer.propTypes = {
    cardProps: PropTypes.object,
    curriculum: PropTypes.object.isRequired,
    fetchMethod: PropTypes.func,
}

CurriculumCardContainer.defaultProps = {
    cardProps: {},
    fetchMethod: fetchCurriculum,
}

export default CurriculumCardContainer
