import React from 'react'

import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Layout, LayoutItem } from '../Layout'

const MessagesItem = ({ canDelete, handleDeleteItem, message: { id, date, message, user }, readOnly }) => (
    <div className="messages-item">
        <div className="messages-item_inner">
            <Layout overrideClass="-top -gutter-small">
                <LayoutItem overrideClass="u-1/3@from-small">
                    <div className="u-inline-block">
                        <figure className="messages-item_picture">
                            <img src={user.profileImage.url} alt={`${user.firstName} ${user.lastName}`} />
                        </figure>
                        <p className="messages-item_name">
                            {user.firstName} {user.lastName}
                        </p>
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-2/3@from-small">
                    <div className="messages-item_text">{message}</div>
                    <time className="messages-item_date">{date.display}</time>
                    {canDelete && readOnly !== true && (
                        <button type="button" className="link -red" onClick={handleDeleteItem} data-id={id}>
                            Delete message
                        </button>
                    )}
                </LayoutItem>
            </Layout>
        </div>
    </div>
)

MessagesItem.propTypes = {
    canDelete: PropTypes.bool,
    handleDeleteItem: PropTypes.func,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.object.isRequired,
        message: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
    }).isRequired,
    readOnly: PropTypes.bool,
}

MessagesItem.defaultProps = {
    canDelete: false,
    readOnly: false,
    handleDeleteItem: () => {},
}

MessagesItem.Placeholder = () => (
    <div className="messages-item">
        <ContentLoader height={195} width={868} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="304" y="163" width="74" height="15" />
            <rect x="304" y="18" width="546" height="20" />
            <rect x="304" y="43" width="533" height="20" />
            <rect x="304" y="69" width="519" height="20" />
            <rect x="304" y="95" width="486" height="20" />
            <rect x="304" y="120" width="313" height="20" />
            <circle cx="55" cy="55" r="41" />
            <rect x="14" y="104" width="85" height="15" />
        </ContentLoader>
    </div>
)

export default MessagesItem
