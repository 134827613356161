import axios from 'axios'
import { first, isEmpty, reverse, slice, sortBy } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'

import { fetchInstructors } from '../../actions/instructorActions'
import { fetchReports } from '../../actions/reportActions'
import { fetchStudents } from '../../actions/studentActions'
import { fetchCertificates } from '../../actions/certificateActions'
import { fetchStudentMedals } from '../../actions/studentMedalActions'

import { DashboardCardLoader, DashboardInfos } from '../Dashboard'
import { InstructorCard } from '../InstructorCard'
import { Layout, LayoutItem } from '../Layout'
import { List, ListCertificateItem, ListMedalItem /*ListCertificateItem*/ } from '../List'
import { ReportCardList } from '../Report'
import { TrackVisibility } from '../TrackVisibility'
import { Fade } from '../Fade'
import { SwitchTransition } from 'react-transition-group'

class DashboardParent extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchInstructors({ cancelToken: this.signal.token }))
        dispatch(fetchReports({ cancelToken: this.signal.token }))
        dispatch(fetchStudents({ cancelToken: this.signal.token }))
        dispatch(fetchCertificates({ cancelToken: this.signal.token }))
        dispatch(fetchStudentMedals({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            certificates,
            infoItems,
            instructor,
            isLoadingAchievements,
            isLoadingInstructor,
            isLoadingReports,
            medals,
            reports,
        } = this.props

        return (
            <React.Fragment>
                <DashboardInfos items={infoItems} />
                <Layout overrideClass="-gutter-small">
                    {isLoadingReports ? (
                        <DashboardCardLoader overrideClass="u-1/2@from-small" />
                    ) : (
                        !isEmpty(reports) && (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-1" key="reports">
                                    <ReportCardList items={reports} />
                                </TrackVisibility>
                            </LayoutItem>
                        )
                    )}
                    {isLoadingInstructor ? (
                        <DashboardCardLoader overrideClass="u-1/2@from-small" />
                    ) : (
                        !isEmpty(instructor) && (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <InstructorCard instructor={instructor} />
                                </TrackVisibility>
                            </LayoutItem>
                        )
                    )}
                </Layout>
                <TrackVisibility overrideClass="u-anim-scroll -delay-3">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingAchievements}>
                            {isLoadingAchievements ? (
                                <List.Placeholder />
                            ) : (
                                !!(medals.length || certificates.length) && (
                                    <List
                                        button={{
                                            route: 'achievement-list',
                                            label: 'View achievements',
                                        }}
                                        title="My Kid's Achievements"
                                    >
                                        {medals.map(studentMedal => (
                                            <ListMedalItem key={studentMedal.id} studentMedal={studentMedal} />
                                        ))}
                                        {certificates.map(certificate => (
                                            <ListCertificateItem
                                                key={certificate.id}
                                                studentCertificate={certificate}
                                            />
                                        ))}
                                    </List>
                                )
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    let certificates = reverse(sortBy(state.certificates.entities, ['date.timestamp']))
    let studentMedals = reverse(sortBy(state.studentMedals.entities, ['date.timestamp']))
    studentMedals = slice(studentMedals, 0, 2)
    certificates = slice(certificates, 0, 3 - studentMedals.length)

    return {
        infoItems: [
            { label: 'Report Cards', value: state.reports.entities.length },
            { label: 'Kids', value: state.students.entities.length },
        ],
        instructor: first(state.instructors.entities),
        isLoadingInstructor: state.requestStates.FETCH_INSTRUCTOR_LIST === true,
        reports: state.reports.entities.slice(0, 2),
        isLoadingReports: state.requestStates.FETCH_REPORT_LIST === true,
        certificates: certificates,
        medals: studentMedals,
        isLoadingAchievements:
            state.requestStates.FETCH_CERTIFICATE_LIST === true || state.requestStates.FETCH_MEDAL_LIST === true,
    }
}

export default connect(mapStateToProps)(DashboardParent)
