import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TweenMax from 'gsap'

import { refreshAccessToken } from '../../actions/userActions'
import { renderRoute } from '../../utils/routes'

class NavSports extends React.Component {
    state = {
        isOpen: false,
    }

    accordionBody = React.createRef()
    accordionBodyInner = React.createRef()
    signal = axios.CancelToken.source()

    componentDidUpdate() {
        if (this.props.sports.length > 1) {
            if (this.state.isOpen) {
                this.open()
            } else {
                this.close()
            }
        }
    }

    open() {
        const bound = this.accordionBodyInner.current.getBoundingClientRect()
        TweenMax.to(this.accordionBody.current, 0.35, {
            height: Math.round(bound.height),
        })
    }

    close() {
        TweenMax.to(this.accordionBody.current, 0.35, { height: 0 })
    }

    handleSportClick = event => {
        const { onSportClick } = this.props

        event.preventDefault()

        onSportClick({ sportId: event.target.getAttribute('data-id') }, { cancelToken: this.signal.token }).then(() => {
            window.location = renderRoute('organization')
        })
    }

    handleToggleAccordion = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { currentSport, sports } = this.props
        const { isOpen } = this.state

        const otherSports = sports.filter(sport => {
            return sport.id !== currentSport.id
        })

        return sports.length > 1 ? (
            <li className="nav_group_item nav-sports -accordion">
                <div
                    className={classNames('nav-accordion_item', {
                        '-open': isOpen,
                    })}
                >
                    <span className="nav_group_item_label" onClick={this.handleToggleAccordion}>
                        {currentSport.name} <span className="nav-accordion_item_icon"></span>
                    </span>
                    <div className="nav-accordion_body" ref={this.accordionBody}>
                        <div className="nav-accordion_item_inner" ref={this.accordionBodyInner}>
                            <ul className="nav-sports_list">
                                {otherSports.map((sport, index) => (
                                    <li key={index} className="nav-sports_list_item">
                                        <button
                                            className="nav-sports_button"
                                            data-id={sport.id}
                                            onClick={this.handleSportClick}
                                            type="button"
                                        >
                                            {sport.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        ) : (
            <li className="nav_group_item nav-sports">
                <span className="nav_group_item_label">{currentSport.name}</span>
            </li>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onSportClick: refreshAccessToken,
        },
        dispatch
    )

export default connect(null, mapDispatchToProps)(NavSports)
