import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'

import { fetchCurriculums } from '../actions/curriculumActions'
import { fetchInstructors } from '../actions/instructorActions'
import { fetchSessions, updateSession } from '../actions/sessionActions'
import { fetchStudents } from '../actions/studentActions'
import { isUserAdministrator } from '../helpers/auth'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { SessionForm } from '../components/Session'
import { TrackVisibility } from '../components/TrackVisibility'

class SessionCreateView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch, user } = this.props

        const config = { cancelToken: this.signal.token }
        dispatch(fetchCurriculums(config))
        dispatch(fetchStudents(config))
        if (isUserAdministrator(user)) {
            dispatch(fetchInstructors(config))
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        return (
            <Main>
                <Header>
                    Create <br /> a Session
                </Header>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <SessionForm fetchMethod={fetchSessions} isDraft={true} updateMethod={updateSession} />
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(SessionCreateView)
