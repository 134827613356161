import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'

import { fetchCurriculums } from '../../actions/curriculumActions'
import { createMedal, updateMedal } from '../../actions/medalActions'
import { apiClient } from '../../helpers/ApiClient'
import { getHistory } from '../../store'
import { formatFormMessages } from '../../utils/forms'
import { renderRoute } from '../../utils/routes'

import { Dropzone } from '../Dropzone'
import { FormMessages } from '../FormMessages'
import { ProgramLogo, ProgramName } from '../FormFields'
import { ItemManager, ItemManagerCurriculumItem } from '../ItemManager'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'
import { Spinner } from '../Spinner'
import { TrackVisibility } from '../TrackVisibility'

class MedalForm extends React.Component {
    state = {
        errors: [],
        initialValues: Immutable.Map({}),
        isFormSubmitted: false,
        isSavingMedal: false,
        selectedCurriculumIds: [],
    }

    signal = axios.CancelToken.source()

    handleClickCancel = event => {
        getHistory().goBack()
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            medal,
        } = this.props
        const { isSavingMedal, selectedCurriculumIds } = this.state

        if (isSavingMedal) {
            return false
        }

        const hasMedal = medal !== null
        const action = hasMedal ? updateMedal : createMedal
        const firstParam = hasMedal ? [medal.id] : []

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingMedal: true,
                })
                const actionParams = [
                    ...firstParam,
                    {
                        ...formValues,
                        curriculumIds: selectedCurriculumIds,
                    },
                    { cancelToken: this.signal.token },
                ]
                action(...actionParams)
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingMedal: false,
                        })
                        apiClient.clearCache('medals')
                        if (hasMedal) {
                            setFieldsValue({
                                image: response.data.image.path,
                                programLogo: response.data.programLogo.path,
                            })
                        } else {
                            dispatch(
                                push(
                                    renderRoute({
                                        id: 'medal-edit',
                                        bind: 'medalId',
                                        param: response.data.id,
                                    })
                                )
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingMedal: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    handleItemManagerAddItem = newItem => {
        this.setState(({ selectedCurriculumIds }) => ({
            selectedCurriculumIds: [...selectedCurriculumIds, newItem],
        }))
    }

    handleItemManagerRemoveItem = itemIdToRemove => {
        this.setState(({ selectedCurriculumIds }) => ({
            selectedCurriculumIds: selectedCurriculumIds.filter(itemId => itemId !== itemIdToRemove),
        }))
    }

    componentDidMount() {
        const { dispatch, medal } = this.props

        dispatch(fetchCurriculums({ cancelToken: this.signal.token }))

        if (medal !== null) {
            this.setState({
                initialValues: Immutable.Map({
                    ...medal,
                    image: medal.image.path,
                    programLogo: medal.programLogo.path,
                }),
                selectedCurriculumIds: medal.curriculumIds,
            })
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            curriculums,
            form,
            form: { getFieldDecorator, getFieldError },
            medal,
        } = this.props
        const { errors, initialValues, isFormSubmitted, isSavingMedal, selectedCurriculumIds } = this.state
        const hasMedal = medal !== null
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? [{ message: 'Medal has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <div
                className={classNames('o-form', {
                    'is-loading': isSavingMedal,
                })}
            >
                <form onSubmit={this.handleSubmit}>
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isFormSubmitted} items={notifications} />
                        </div>
                    )}
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <ProgramName
                                form={form}
                                initialValue={initialValues.get('programName') || ''}
                                isRequired={true}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label inputId="name" isRequired={true}>
                                    Medal Name
                                </Label>
                                {getFieldDecorator('name', {
                                    initialValue: initialValues.get('name') || '',
                                    validate: [
                                        {
                                            trigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter a medal name',
                                                },
                                            ],
                                        },
                                    ],
                                })(<input className="o-input" type="text" id="name" />)}
                                <div className="o-input_error">{getFieldError('name')}</div>
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <ProgramLogo
                                form={form}
                                initialValue={initialValues.get('programLogo') || ''}
                                isRequired={true}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label>Medal Image</Label>
                                <Dropzone
                                    form={form}
                                    initialFiles={initialValues.get('image') || ''}
                                    initialValue={initialValues.get('image') || ''}
                                    inputName="image"
                                    recommendedSize="350&times;200px"
                                />
                            </div>
                        </LayoutItem>
                    </Layout>

                    <TrackVisibility overrideClass="u-anim-scroll">
                        <ItemManager
                            addLabel="Add a curriculum"
                            handleAddItem={this.handleItemManagerAddItem}
                            handleRemoveItem={this.handleItemManagerRemoveItem}
                            header="Curriculums"
                            listHeaders={['Certification Code', 'Curriculum']}
                            selectedItemIds={selectedCurriculumIds}
                            itemComponent={ItemManagerCurriculumItem}
                            itemList={curriculums}
                        />
                    </TrackVisibility>

                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button className="o-button -huge" onClick={this.handleClickCancel} type="button">
                                    Cancel
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button className="o-button -huge -green" type="submit">
                                    {hasMedal ? 'Save Medal' : 'Create Medal'}
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                    </Layout>
                </form>
                <div className="o-form_overlay">
                    <div className="o-form_spinner u-text-center">
                        <Spinner />
                    </div>
                </div>
            </div>
        )
    }
}

MedalForm.Placeholder = () => (
    <ContentLoader height={978} width={1000} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="27" rx="5" ry="5" width="1000" height="48" />
        {/*<rect x="775" y="27" rx="5" ry="5" width="224" height="48" />*/}
        <rect x="0" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="517" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="0" y="219" rx="5" ry="5" width="1000" height="48" />
        <rect x="0" y="316" rx="5" ry="5" width="1000" height="114" />
        <rect x="34" y="723" rx="5" ry="5" width="210" height="46" />
        <rect x="277" y="723" rx="5" ry="5" width="206" height="49" />
        <rect x="516" y="723" rx="5" ry="5" width="209" height="47" />
        <circle cx="785" cy="740" r="30" />
        <rect x="0" y="802" rx="0" ry="0" width="1000" height="53" />
        <rect x="0" y="6" rx="0" ry="0" width="112" height="13" />
        {/*<rect x="775" y="6" rx="0" ry="0" width="112" height="13" />*/}
        <rect x="0" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="517" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="0" y="196" rx="0" ry="0" width="112" height="13" />
        <rect x="1" y="290" rx="0" ry="0" width="112" height="13" />
        <rect x="34" y="545" rx="0" ry="0" width="115" height="29" />
        <rect x="0" y="516" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="595" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="998" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="34" y="635" rx="0" ry="0" width="106" height="23" />
        <rect x="34" y="701" rx="0" ry="0" width="132" height="13" />
        <rect x="277" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="517" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="275" y="875" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="875" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="875" rx="0" ry="0" width="128" height="21" />
        <rect x="27" y="867" rx="0" ry="0" width="34" height="34" />
        <rect x="0" y="913" rx="0" ry="0" width="1000" height="2" />
        <rect x="27" y="928" rx="0" ry="0" width="34" height="34" />
        <rect x="275" y="934" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="934" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="934" rx="0" ry="0" width="128" height="21" />
    </ContentLoader>
)

MedalForm.propTypes = {
    medal: PropTypes.object,
}

MedalForm.defaultProps = {
    medal: null,
}

const mapStateToProps = state => ({
    curriculums: state.curriculums.entities,
})

export default connect(mapStateToProps)(createForm()(MedalForm))
