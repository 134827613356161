import React from 'react'

import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Layout, LayoutItem } from '../Layout'
import { SimpleList } from '../SimpleList'
import { TrackVisibility } from '../TrackVisibility'
import { UserImage } from './index'

const UserHeader = ({ children, listItems, user }) => (
    <div className="user-header u-card u-anim">
        <div className="u-padding-small-x">
            <TrackVisibility>
                <UserImage user={user} overrideClass="user-header_picture" />
            </TrackVisibility>
            <h2 className="user-header_name u-anim -delay-1">{user.name}</h2>
            {listItems.length > 0 && (
                <Layout overrideClass="-gutter-small">
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <SimpleList items={listItems[0]} />
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <SimpleList items={listItems[1]} />
                    </LayoutItem>
                </Layout>
            )}
        </div>
        {children}
    </div>
)

UserHeader.propTypes = {
    listItems: PropTypes.array,
    user: PropTypes.object.isRequired,
}

UserHeader.defaultProps = {
    listItems: [],
}

UserHeader.Placeholder = () => (
    <div className="user-header u-card u-anim -delay-1">
        <TrackVisibility>
            <div className="user-header_picture">
                <ContentLoader height={160} width={160} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" />
            </div>
        </TrackVisibility>
        <ContentLoader height={212} width={903} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="311" y="21" rx="0" ry="0" width="284" height="36" />
            <rect x="16" y="108" rx="0" ry="0" width="82" height="13" />
            <rect x="466" y="108" rx="0" ry="0" width="82" height="13" />
            <rect x="16" y="145" rx="0" ry="0" width="105" height="13" />
            <rect x="466" y="145" rx="0" ry="0" width="48" height="13" />
            <rect x="16" y="185" rx="0" ry="0" width="52" height="13" />
            <rect x="466" y="185" rx="0" ry="0" width="67" height="13" />
            <rect x="415" y="108" rx="0" ry="0" width="22" height="13" />
            <rect x="355" y="145" rx="0" ry="0" width="82" height="13" />
            <rect x="325" y="185" rx="0" ry="0" width="112" height="13" />
            <rect x="865" y="108" rx="0" ry="0" width="22" height="13" />
            <rect x="865" y="145" rx="0" ry="0" width="22" height="13" />
            <rect x="791" y="185" rx="0" ry="0" width="95" height="13" />
            <rect x="16" y="130" rx="0" ry="0" width="420" height="2" />
            <rect x="16" y="170" rx="0" ry="0" width="420" height="2" />
            <rect x="466" y="130" rx="0" ry="0" width="420" height="2" />
            <rect x="466" y="170" rx="0" ry="0" width="420" height="2" />
        </ContentLoader>
    </div>
)

export default UserHeader
