import { filter, find } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '../Button'
import { CardHeader } from '../Card'
import { FilterableSelect } from '../FilterableSelect'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'

class ItemManager extends React.Component {
    state = {
        currentItemId: null,
        filterableSelectInputValue: '',
    }

    handleClickAddItem = event => {
        event.preventDefault()

        const { handleAddItem } = this.props
        const { currentItemId } = this.state

        handleAddItem(currentItemId)
        this.setState({
            currentItemId: null,
            filterableSelectInputValue: '',
        })
    }

    handleClickRemoveItem = event => {
        const { handleRemoveItem } = this.props
        const itemId = event.currentTarget.getAttribute('data-id')
        handleRemoveItem(itemId)
    }

    handleFilterableSelectInputChange = event => {
        this.setState({
            filterableSelectInputValue: event.target.value,
        })
    }

    handleFilterableSelectItemSelection = id => {
        const { itemList } = this.props
        const selectedCurriculum = find(itemList, item => item.id === id)
        this.setState({
            currentItemId: id,
            filterableSelectInputValue: selectedCurriculum.name,
        })
    }

    handleCreatePopup = event => {
        const { handleCreateItem } = this.props

        handleCreateItem()
    }

    render() {
        const {
            addLabel,
            createButton,
            createButtonPopup,
            header,
            itemComponent: ItemComponent,
            itemList,
            listHeaders,
            selectedItemIds,
        } = this.props
        const { currentItemId, filterableSelectInputValue } = this.state

        const unselectedItemList = filter(itemList, item => {
            return !selectedItemIds.includes(item.id)
        }).map(item => {
            return {
                id: item.id,
                label: item.name,
            }
        })

        const selectedItemList = filter(itemList, item => {
            return selectedItemIds.includes(item.id)
        })

        return (
            <div className="item-manager u-card u-margin-y">
                <CardHeader>
                    <Layout overrideClass="-gutter-small -middle">
                        <LayoutItem overrideClass="u-1/2">
                            <p className="o-h2 u-margin-none">{header}</p>
                        </LayoutItem>
                        {createButton && (
                            <LayoutItem overrideClass="u-1/2 u-text-right@from-small">
                                <Button route={createButton.route}>{createButton.label}</Button>
                            </LayoutItem>
                        )}
                        {createButtonPopup && (
                            <LayoutItem overrideClass="u-1/2 u-text-right@from-small">
                                <Button onClick={this.handleCreatePopup}>{createButtonPopup.label}</Button>
                            </LayoutItem>
                        )}
                    </Layout>
                </CardHeader>
                <div className="u-padding-small">
                    <Layout overrideClass="-gutter-small -bottom">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <label className="o-label">{addLabel}</label>
                            <FilterableSelect
                                handleInputChange={this.handleFilterableSelectInputChange}
                                handleItemSelection={this.handleFilterableSelectItemSelection}
                                inputValue={filterableSelectInputValue}
                                items={unselectedItemList}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-small">
                            {currentItemId !== null && (
                                <button
                                    type="button"
                                    className="icon-button u-margin-tiny-y"
                                    onClick={this.handleClickAddItem}
                                >
                                    <Icon name="plus" />
                                </button>
                            )}
                        </LayoutItem>
                    </Layout>
                </div>
                <div className="item-manager_wrapper">
                    <div className="item-manager_header u-padding-small-x">
                        <div className="u-none@to-small">
                            <Layout overrideClass="-gutter-small">
                                {listHeaders.map((header, index) => (
                                    <LayoutItem key={index} overrideClass="u-1/2@from-small">
                                        {header}
                                    </LayoutItem>
                                ))}
                            </Layout>
                        </div>
                        <div className="u-none@from-small">
                            {listHeaders.map((header, index) => (
                                <React.Fragment key={index}>
                                    {header}
                                    {index === 0 && <span>, </span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    {selectedItemList.length > 0 ? (
                        <ul className="item-manager_list">
                            {selectedItemList.map(item => (
                                <li key={item.id} className="item-manager_list_item u-padding-small-x">
                                    <ItemComponent item={item} />
                                    <button
                                        type="button"
                                        className="item-manager_remove"
                                        onClick={this.handleClickRemoveItem}
                                        data-id={item.id}
                                    >
                                        <Icon name="close" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="item-manager_empty">No {header} have been added</p>
                    )}
                </div>
            </div>
        )
    }
}

ItemManager.propTypes = {
    addLabel: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    itemComponent: PropTypes.elementType.isRequired,
    listHeaders: PropTypes.array.isRequired,
    createButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    }),
    handleAddItem: PropTypes.func,
    handleCreateItem: PropTypes.func,
    handleRemoveItem: PropTypes.func,
    itemList: PropTypes.array.isRequired,
    selectedItemIds: PropTypes.array.isRequired,
}

ItemManager.defaultProps = {
    createButton: null,
    handleAddItem: () => {},
    handleCreateItem: () => {},
    handleRemoveItem: () => {},
}

export default ItemManager
