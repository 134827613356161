import React from 'react'

import PropTypes from 'prop-types'

const Layout = ({ children, overrideClass }) => <div className={`layout ${overrideClass}`}>{children}</div>

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    overrideClass: PropTypes.string,
}

Layout.defaultProps = {
    overrideClass: '',
}

export default Layout
