import React from 'react'
import axios from 'axios'
import { fetchBillingInformation } from '../../actions/BillingInformationAction'

const withBillingInformation = WrappedComponent => {
    class EnhancedComponent extends React.Component {
        signal = axios.CancelToken.source()

        state = {
            hasLoaded: false,
            isLoading: true,
            data: {},
        }

        componentDidMount() {
            const { hasLoaded } = this.state

            if (!hasLoaded)
                this.setState({ isLoading: true }, () => {
                    fetchBillingInformation({
                        cancelToken: this.signal.token,
                    })
                        .then(response => {
                            this.setState({
                                data: response.data,
                                isLoading: false,
                                hasLoaded: true,
                            })
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                this.setState({
                                    error: error.response.data,
                                    isLoading: false,
                                })
                            }
                        })
                })
        }

        componentWillUnmount() {
            this.signal.cancel('API call is being canceled.')
        }

        render() {
            return <WrappedComponent {...this.props} billingInformation={{ ...this.state }} />
        }
    }

    return EnhancedComponent
}

export default withBillingInformation
