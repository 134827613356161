import { apiClient } from '../helpers/ApiClient'

/**
 * Fetch a list of all active curriculum presets for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_CURRICULUM_PRESET_LIST_REQUEST = 'FETCH_CURRICULUM_PRESET_LIST_REQUEST'
export const FETCH_CURRICULUM_PRESET_LIST_SUCCESS = 'FETCH_CURRICULUM_PRESET_LIST_SUCCESS'
export const FETCH_CURRICULUM_PRESET_LIST_FAILURE = 'FETCH_CURRICULUM_PRESET_LIST_FAILURE'
export const FETCH_CURRICULUM_PRESET_LIST_PENDING = 'FETCH_CURRICULUM_PRESET_LIST_PENDING'
export const fetchCurriculumPresets = (config = {}) => {
    return {
        promise: client => client.get('curriculum-presets', config),
        types: [
            FETCH_CURRICULUM_PRESET_LIST_REQUEST,
            FETCH_CURRICULUM_PRESET_LIST_SUCCESS,
            FETCH_CURRICULUM_PRESET_LIST_FAILURE,
            FETCH_CURRICULUM_PRESET_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a curriculum.
 *
 * @param  {String} curriculumPresetId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchCurriculumPreset = (curriculumPresetId, config = {}) => {
    return apiClient.get(`curriculum-presets/${curriculumPresetId}`, config)
}
