import React from 'react'

import { connect } from 'react-redux'

const LoginBackground = ({ background, logo }) => (
    <div className="login-background">
        <figure className="login-background_image" style={{ backgroundImage: `url('${background}')` }}></figure>
        <figure className="login-form_organization">
            <img src={logo} alt="" />
        </figure>
    </div>
)

const mapStateToProps = state => {
    return {
        background: state.organization.data.backgroundImage.url,
        logo: state.organization.data.logoImage.url,
    }
}

export default connect(mapStateToProps)(LoginBackground)
