import React from 'react'

import axios from 'axios'
import { SwitchTransition } from 'react-transition-group'

import { fetchMedals } from '../actions/medalActions'

import { Fade } from '../components/Fade'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { List, ListEditMedalItem } from '../components/List'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

class MedalListView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        isLoading: true,
        medals: [],
    }

    componentDidMount() {
        fetchMedals({ cancelToken: this.signal.token })
            .then(response => {
                this.setState({
                    isLoading: false,
                    medals: response.data,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        isLoading: false,
                        error: error.response.data,
                    })
                }
            })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { isLoading, medals } = this.state
        return (
            <Main>
                <Header>Program Medals</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoading}>
                            {isLoading ? (
                                <List.Placeholder />
                            ) : (
                                <List
                                    title={`${medals.length} Medals`}
                                    button={{ label: 'Create a medal', route: 'medal-create' }}
                                >
                                    {medals.map(medal => (
                                        <ListEditMedalItem key={medal.id} medal={medal} />
                                    ))}
                                </List>
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

export default MedalListView
