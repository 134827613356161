import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Layout, LayoutItem } from '../Layout'
import { UserImage } from '../User'

const Header = ({ children, logo }) => (
    <header className="header">
        {/*<span className="header_current-section">Dashboard</span>*/}
        <Layout overrideClass="-middle -gutter-small -reverse">
            <LayoutItem overrideClass="u-1/2@from-small">
                <div className="header_logo u-text-right@from-small">
                    {logo && <UserImage overrideClass="user-image header_logo_image" url={logo} />}
                </div>
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <h1>{children}</h1>
            </LayoutItem>
        </Layout>
    </header>
)

Header.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

const mapStateToProps = ({ organization }) => ({
    logo: organization.id !== null ? organization.data.logoImage.url : null,
})

export default connect(mapStateToProps)(Header)
