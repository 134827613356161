import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const LastName = ({ form, initialValue }) => (
    <div className="o-input_wrapper">
        <label htmlFor="lastName" className="o-label -required">
            Last Name
        </label>
        {form.getFieldDecorator('lastName', {
            initialValue: initialValue,
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'Please enter a last name',
                        },
                    ],
                },
            ],
        })(<input type="text" id="lastName" placeholder="Last Name" className="o-input" />)}
        <div className="o-input_error">{form.getFieldError('lastName')}</div>
    </div>
)

LastName.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
}

LastName.defaultProps = {
    initialValue: '',
}

export default LastName
