import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'subscription'

/**
 * Fetch the subscription for the current organisation.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSubscription = (config = {}) => {
    return apiClient.get(baseUrl, config)
}

/**
 * Update a Subscription.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateSubscription = (formData, config = {}) => {
    return apiClient.patch(baseUrl, formData, config)
}
