import React from 'react'
import propTypes from 'prop-types'

import { Layout, LayoutItem } from '../Layout'

const SelectableList = ({ items, defaultValue, onChange, name }) => (
    <div className="selectable-list u-card">
        {items.map(({ label, content, value }, index) => (
            <div className="selectable-list_item" key={index}>
                <input
                    type="radio"
                    value={value}
                    name={name}
                    id={`${name}-${index}`}
                    checked={value === defaultValue}
                    className="selectable-list_item_radio"
                    onChange={e => onChange(e, items[index])}
                />
                <label htmlFor={`${name}-${index}`} className="selectable-list_item_radio_label">
                    label
                </label>
                <Layout overrideClass="-middle selectable-list_item_grid">
                    <LayoutItem overrideClass="u-1/2">
                        <p className="selectable-list_item_label">{label}</p>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2 u-text-right">
                        <p className="selectable-list_item_value">{content}</p>
                    </LayoutItem>
                </Layout>
            </div>
        ))}
    </div>
)

SelectableList.propTypes = {
    name: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    items: propTypes.array.isRequired,
}

export default SelectableList
