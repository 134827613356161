import classNames from 'classnames'
import { filter, reverse, sortBy } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { isValidElementType } from 'react-is'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { Logo } from '../Logo'
import { Spinner } from '../Spinner'
import FlipMove from 'react-flip-move'
import { Container } from '../Container'

const DEFAULTVALUE = 'default'

class FilteredList extends React.Component {
    state = {
        inputValue: '',
        sortedValue: DEFAULTVALUE,
        reverseOrder: false,
    }

    handleOnSortChange = ({ target: { value } }) => {
        this.setState({
            sortedValue: value.toString(),
        })
    }

    handleOnSearchChange = ({ target: { value } }) => {
        this.setState({
            inputValue: value.toLowerCase(),
        })
    }

    handleOnOrderChange = ({ target: { checked } }) => {
        this.setState({
            reverseOrder: checked,
        })
    }

    prepareListItems = items => {
        const { inputValue, sortedValue, reverseOrder } = this.state

        // Search through items
        items = filter(items, item => {
            return item.columns.name.toLowerCase().indexOf(inputValue) !== -1
        })

        // Sort the remaining items
        if (sortedValue !== DEFAULTVALUE) {
            items = sortBy(items, [item => item.columns[sortedValue]])
        }

        if (reverseOrder) {
            items = reverse(items)
        }

        return items
    }

    render() {
        const { sortedValue } = this.state
        const {
            buttonData,
            emptyLabel,
            hasFooter,
            hasLogo,
            hasSorting,
            isFull,
            isLoading,
            isSmall,
            itemComponent: ItemComponent,
            itemOptions,
            listHeaders,
            listItems,
            overrideClass,
            title,
        } = this.props

        const headerCount = listHeaders.length
        const classes = classNames(
            'filtered-list',
            'u-card',
            'u-margin-y',
            { 'is-loading': isLoading },
            { '-small': isSmall },
            overrideClass
        )
        const items = this.prepareListItems(listItems)

        return (
            <div className={classes}>
                <CardHeader>
                    <Layout overrideClass="-gutter-small -middle">
                        <LayoutItem overrideClass={isFull ? 'u-1/4@from-small' : 'u-3/4@from-small'}>
                            <h1 className="o-h2 c-card-header_title">
                                {hasLogo && <Logo />}
                                {title}
                            </h1>
                        </LayoutItem>
                        {isFull && (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="u-relative">
                                    <input
                                        type="text"
                                        className="o-input"
                                        placeholder="Search"
                                        onChange={this.handleOnSearchChange}
                                    />
                                    <span className="o-input_icon">
                                        <Icon name="search" />
                                    </span>
                                </div>
                            </LayoutItem>
                        )}
                        <LayoutItem overrideClass="u-1/4@from-small u-text-right@from-small">
                            {hasSorting && (
                                <Layout overrideClass="-flex -stretch">
                                    <LayoutItem overrideClass="u-3/4@from-small u-text-right@from-small">
                                        <div className="filtered-list_filter o-select_wrapper">
                                            <select
                                                className="o-select filtered-list_order_select"
                                                value={sortedValue}
                                                onChange={this.handleOnSortChange}
                                            >
                                                <option value={DEFAULTVALUE}>
                                                    {sortedValue === DEFAULTVALUE ? 'Sort by' : 'No sorting'}
                                                </option>
                                                {listHeaders.map((header, index) => (
                                                    <option key={index} value={header.id}>
                                                        {header.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-width-auto u-1/4@from-small u-text-right@from-small">
                                        <div className="filtered-list_order_checkbox_wrapper">
                                            <input
                                                className="filtered-list_order_checkbox"
                                                type="checkbox"
                                                name={'test'}
                                                id={title + '-filter'}
                                                disabled={sortedValue === DEFAULTVALUE}
                                                onChange={this.handleOnOrderChange}
                                            />
                                            <label
                                                htmlFor={title + '-filter'}
                                                className="filtered-list_order_checkbox_label"
                                            />
                                            <Icon name="order" overrideClass="filtered-list_order_checkbox_icon" />
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            )}
                        </LayoutItem>
                    </Layout>
                </CardHeader>
                <div className="filtered-list_main">
                    <Layout overrideClass="filtered-list_main_header u-padding-small-x">
                        {listHeaders.map((header, index) => (
                            <LayoutItem overrideClass={`u-1/${headerCount}@from-small`} key={index}>
                                {header.label}
                            </LayoutItem>
                        ))}
                    </Layout>
                    <div className="filtered-list_main_data">
                        {isLoading ? (
                            <div className="filtered-list_spinner u-padding-tiny-y">
                                <Spinner isSmall={true} />
                            </div>
                        ) : items.length > 0 ? (
                            <div className="u-relative">
                                <FlipMove
                                    typeName={null}
                                    easing="cubic-bezier(.5, 0, .5, 1)"
                                    staggerDurationBy={15}
                                    staggerDelayBy={20}
                                    maintainContainerHeight={true}
                                >
                                    {items.map((item, index) => (
                                        <Container key={item.id || index}>
                                            <ItemComponent
                                                headerCount={headerCount}
                                                item={item}
                                                options={itemOptions}
                                            />
                                        </Container>
                                    ))}
                                </FlipMove>
                            </div>
                        ) : (
                            <p className="filtered-list_empty">{emptyLabel}</p>
                        )}
                    </div>
                </div>
                {hasFooter && (
                    <CardFooter>
                        {buttonData !== null && (
                            <Button route={buttonData.route} overrideClass="card-footer_cta">
                                {buttonData.label}
                            </Button>
                        )}
                    </CardFooter>
                )}
            </div>
        )
    }
}

FilteredList.propTypes = {
    buttonData: PropTypes.object,
    emptyLabel: PropTypes.string,
    hasFooter: PropTypes.bool,
    hasLogo: PropTypes.bool,
    hasSorting: PropTypes.bool,
    isFull: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSmall: PropTypes.bool,
    itemComponent: (props, propName) => {
        if (props[propName] || isValidElementType(props[propName])) {
        } else {
            return new Error(
                `Invalid prop 'itemComponent' supplied to 'FilterList': the prop is not a valid React component`
            )
        }
    },
    itemOption: PropTypes.object,
    listHeaders: PropTypes.array.isRequired,
    listItems: PropTypes.array,
    overrideClass: PropTypes.string,
    title: PropTypes.string.isRequired,
}

FilteredList.defaultProps = {
    buttonData: null,
    emptyLabel: 'List is empty',
    hasLogo: false,
    hasFooter: true,
    hasSorting: false,
    isLoading: false,
    isFull: false,
    isSmall: false,
    itemOption: {},
    listItems: [],
    overrideClass: '',
}

export default FilteredList
