import React from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Main } from '../components/Main'

const LandingView = () => (
    <Main isPublic={true}>
        <Header>Public Landing Page TODO</Header>
        <Footer />
    </Main>
)

export default LandingView
