import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import {
    fetchStudent,
    fetchStudentCertificates,
    fetchStudentMedals,
    fetchStudentReports,
} from '../actions/studentActions'
import { userCanDo } from '../helpers/auth'
import mapFetchToState from '../helpers/mapFetchToState'
import { renderRoute } from '../utils/routes'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { List, ListCertificateItem, ListMedalItem } from '../components/List'
import { Main } from '../components/Main'
import { ReportCard } from '../components/Report'
import { TrackVisibility } from '../components/TrackVisibility'
import { UserHeader } from '../components/User'
import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../components/Fade'

class StudentDetailsView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        student: null,
        reports: [],
        studentMedals: [],
        certificates: [],
        isLoading: true,
    }

    loadStudentDetails(studentId) {
        this.setState({ isLoading: true }, () => {
            const config = { cancelToken: this.signal.token }
            mapFetchToState({
                student: fetchStudent(studentId, config),
                reports: fetchStudentReports(studentId, config),
                studentMedals: fetchStudentMedals(studentId, config),
                certificates: fetchStudentCertificates(studentId, config),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        this.loadStudentDetails(this.props.match.params.studentId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.studentId !== this.props.match.params.studentId) {
            this.loadInstructor(this.props.match.params.studentId)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    listItems() {
        const { student, reports, studentMedals, certificates } = this.state
        const { organization } = this.props

        if (!student || !organization) {
            return []
        }

        const parent = () => {
            let value = student.parents.map((parent, index) => {
                return (
                    <React.Fragment key={parent.id}>
                        {index > 0 && <span>, </span>}
                        <RouterLink
                            to={renderRoute({
                                id: 'parent-details',
                                bind: 'parentId',
                                param: parent.id,
                            })}
                        >
                            {parent.name}
                        </RouterLink>
                    </React.Fragment>
                )
            })

            return {
                label: 'Parents',
                value: value,
            }
        }

        return [
            [{ label: 'Report Cards', value: reports.length }, parent()],
            [
                { label: 'Certificates', value: certificates.length },
                { label: 'Medals', value: studentMedals.length },
            ],
        ]
    }

    render() {
        const { error, student, reports, certificates, studentMedals, isLoading } = this.state
        const { user } = this.props

        const listItems = this.listItems()

        return (
            <Main>
                <Header>
                    Student <br />
                    Profile
                </Header>
                <SwitchTransition>
                    <Fade timeout={150} key={isLoading}>
                        {isLoading ? (
                            <UserHeader.Placeholder />
                        ) : error !== null ? (
                            <section className="u-relative">{error.message}</section>
                        ) : (
                            <React.Fragment>
                                <TrackVisibility overrideClass="u-anim-scroll">
                                    <UserHeader listItems={listItems} user={student} />
                                </TrackVisibility>
                                {reports.map(report => (
                                    <TrackVisibility key={report.id} overrideClass="u-anim-scroll -delay-1">
                                        <ReportCard report={report} isFull />
                                    </TrackVisibility>
                                ))}
                                {(certificates.length > 0 || studentMedals.length > 0) && (
                                    <TrackVisibility overrideClass="u-anim-scroll">
                                        <List
                                            hasFooter="true"
                                            NOTSUREIFSHOULDDISPLAYbutton={{
                                                label: 'View achievements',
                                                route: 'achievement-list',
                                            }}
                                            title={`${certificates.length} Certificates, ${studentMedals.length} medals`}
                                        >
                                            {studentMedals.map(studentMedal => (
                                                <ListMedalItem
                                                    key={studentMedal.id}
                                                    studentMedal={studentMedal}
                                                    hideStudent
                                                />
                                            ))}
                                            {certificates.map(certificate => (
                                                <ListCertificateItem
                                                    key={certificate.id}
                                                    studentCertificate={certificate}
                                                    hideStudent
                                                />
                                            ))}
                                        </List>
                                    </TrackVisibility>
                                )}
                                {userCanDo(user, 'report-cards/object/student.update') && (
                                    <Layout>
                                        <LayoutItem>
                                            <TrackVisibility overrideClass="u-anim-scroll">
                                                <RouterLink
                                                    className="o-button -huge -green u-margin-small-y"
                                                    to={renderRoute({
                                                        id: 'student-edit',
                                                        bind: 'studentId',
                                                        param: student.id,
                                                    })}
                                                >
                                                    Edit Student
                                                </RouterLink>
                                            </TrackVisibility>
                                        </LayoutItem>
                                    </Layout>
                                )}
                            </React.Fragment>
                        )}
                    </Fade>
                </SwitchTransition>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    organization: state.organization,
    user: state.auth.user,
})

export default connect(mapStateToProps)(StudentDetailsView)
