import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'instructors'

/**
 * Fetch a list of all active instructors for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_INSTRUCTOR_LIST_REQUEST = 'FETCH_INSTRUCTOR_LIST_REQUEST'
export const FETCH_INSTRUCTOR_LIST_SUCCESS = 'FETCH_INSTRUCTOR_LIST_SUCCESS'
export const FETCH_INSTRUCTOR_LIST_FAILURE = 'FETCH_INSTRUCTOR_LIST_FAILURE'
export const FETCH_INSTRUCTOR_LIST_PENDING = 'FETCH_INSTRUCTOR_LIST_PENDING'
export const fetchInstructors = (config = {}) => {
    return {
        types: [
            FETCH_INSTRUCTOR_LIST_REQUEST,
            FETCH_INSTRUCTOR_LIST_SUCCESS,
            FETCH_INSTRUCTOR_LIST_FAILURE,
            FETCH_INSTRUCTOR_LIST_PENDING,
        ],
        promise: client => client.get(baseUrl, config),
    }
}

/**
 * Fetch an instructor.
 *
 * @param  {String} instructorId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchInstructor = (instructorId, config = {}) => {
    return apiClient.get(`${baseUrl}/${instructorId}`, config)
}

/**
 * Create a new instructor.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createInstructor = (formData, config = {}) => {
    return apiClient.post(baseUrl, formData, config)
}

/**
 * Update an instructor.
 *
 * @param  {String} instructorId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateInstructor = (instructorId, formData, config = {}) => {
    return apiClient.patch(`${baseUrl}/${instructorId}`, formData, config)
}

/**
 * Fetch an instructor's students.
 *
 * @param  {String} instructorId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchInstructorStudents = (instructorId, config = {}) => {
    return apiClient.get(`${baseUrl}/${instructorId}/students`, config)
}

/**
 * Fetch an instructor's sessions.
 *
 * @param  {String} instructorId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchInstructorSessions = (instructorId, config = {}) => {
    return apiClient.get(`${baseUrl}/${instructorId}/sessions`, config)
}

/**
 * Fetch an instructor's upcoming session according to course dates.
 *
 * @param  {String} instructorId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchInstructorUpcomingSession = (instructorId, config = {}) => {
    return apiClient.get(`${baseUrl}/${instructorId}/upcoming-session`, config)
}
