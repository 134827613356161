import React from 'react'

import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { parseCourseList } from '../../utils/courses'

import { Layout, LayoutItem } from '../Layout'
import { Progress } from '../Progress'
import { Sharer } from '../Sharer'
import { SimpleList } from '../SimpleList'
import { StickersList } from '../Stickers'

class ReportIntro extends React.Component {
    completedTasks() {
        return this.props.tasks.filter(task => task.isCompleted)
    }

    progress() {
        return Math.ceil((this.completedTasks().length / this.props.tasks.length) * 100)
    }

    render() {
        const {
            report,
            report: { session },
            stickers,
        } = this.props
        const lastCourse = parseCourseList(this, session.courses).last
        const nextCourse = parseCourseList(this, session.courses).next

        const listItems = [
            { label: 'Session', value: session.name },
            { label: 'Last course', value: lastCourse !== null ? lastCourse.startDate.display : '-' },
            { label: 'Report update', value: report.lastModified.display },
            { label: 'Next course', value: nextCourse !== null ? nextCourse.startDate.display : '-' },
        ]

        return (
            <div className="u-padding-tiny-y u-padding-small-x">
                <Layout overrideClass="-reverse -middle">
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <div className="u-text-center">
                            <Progress value={this.progress()} overrideClass="-margin -full" />
                        </div>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <SimpleList items={listItems} />
                        <Layout overrideClass="-middle -gutter-small u-padding-tiny-y">
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <button
                                    className="o-button -full"
                                    type="button"
                                    onClick={() => {
                                        window.print()
                                    }}
                                >
                                    Print report
                                </button>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <Sharer
                                    text="Check out my Report Card!"
                                    url={report.shareUrl}
                                >
                                    Share report
                                </Sharer>
                            </LayoutItem>
                        </Layout>
                    </LayoutItem>
                </Layout>
                <StickersList reportStickers={stickers} />
            </div>
        )
    }
}

ReportIntro.propTypes = {
    report: PropTypes.object.isRequired,
    stickers: PropTypes.array.isRequired,
    tasks: PropTypes.array.isRequired,
}

ReportIntro.Placeholder = () => (
    <ContentLoader height={284} width={898} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="12" y="26" rx="0" ry="0" width="52" height="14" />
        <rect x="12" y="65" rx="0" ry="0" width="72" height="14" />
        <rect x="12" y="102" rx="0" ry="0" width="76" height="14" />
        <rect x="12" y="140" rx="0" ry="0" width="65" height="14" />
        <rect x="385" y="26" rx="0" ry="0" width="67" height="14" />
        <rect x="351" y="65" rx="0" ry="0" width="100" height="14" />
        <rect x="341" y="102" rx="0" ry="0" width="110" height="14" />
        <rect x="353" y="140" rx="0" ry="0" width="99" height="14" />
        <circle cx="667" cy="142" r="124" />
        <rect x="12" y="193" rx="5" ry="5" width="208" height="48" />
        <rect x="243" y="193" rx="5" ry="5" width="208" height="48" />
        <rect x="12" y="52" rx="0" ry="0" width="440" height="2" />
        <rect x="12" y="90" rx="0" ry="0" width="440" height="2" />
        <rect x="12" y="127" rx="0" ry="0" width="440" height="2" />
    </ContentLoader>
)

export default ReportIntro
