import React from 'react'

import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Button } from '../Button'
import { Layout, LayoutItem } from '../Layout'
import { Sharer } from '../Sharer'
import { SimpleList } from '../SimpleList'
import { UserImage } from '../User'

const AchievementIntro = ({
    button,
    handleClickDownload,
    image,
    listItems,
    programLogo,
    sharerProps,
    student,
    title,
}) => (
    <div className="achievement-intro">
        <div className="achievement-intro_heading u-card -overflow">
            <Layout>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <figure className="achievement-intro_image">
                        <img src={image} alt="" />
                        <p className="achievement-intro_image_title">{title}</p>
                    </figure>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <figure>
                        <img src={programLogo} alt="" />
                    </figure>
                </LayoutItem>
            </Layout>
        </div>

        <div className="u-margin-small-y">
            <Layout overrideClass="-gutter-small -top">
                <LayoutItem overrideClass="u-1/2@from-small">
                    <span>Awarded to</span>
                    <div>
                        <div className="u-inline-block u-align-middle">
                            <UserImage user={student} overrideClass={'user-image'} />
                        </div>
                        <p className="o-h2 u-inline-block u-align-middle u-margin-none u-margin-small-x">
                            {student.firstName} <br />
                            {student.lastName}
                        </p>
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <SimpleList items={listItems} />
                </LayoutItem>
            </Layout>
            <hr />
            <Layout overrideClass="-gutter-small u-print-none">
                {/*<LayoutItem overrideClass="u-1/2@from-small">*/}
                {/*<Layout overrideClass="-gutter-small">*/}
                <LayoutItem overrideClass="u-1/2@from-small u-1/4@from-large u-padding-small-y">
                    <button className="o-button -full" onClick={handleClickDownload}>
                        Download PDF
                    </button>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small u-1/4@from-large u-padding-small-y">
                    <button className="o-button -full" onClick={() => window.print()}>
                        Print
                    </button>
                </LayoutItem>
                {/*</Layout>*/}
                {/*</LayoutItem>*/}
                {/*<LayoutItem overrideClass="u-1/2@from-small">*/}
                {/*<hr />*/}
                {/*<Layout overrideClass="-gutter-small">*/}
                <LayoutItem overrideClass="u-1/2@from-small u-1/4@from-large u-padding-small-y">
                    <Sharer {...sharerProps}>{sharerProps.label}</Sharer>
                </LayoutItem>
                {button !== null && (
                    <LayoutItem overrideClass="u-1/2@from-small u-1/4@from-large u-padding-small-y">
                        <Button overrideClass="o-button -full" link={button.url} isExternalLink={true}>
                            {button.label}
                        </Button>
                    </LayoutItem>
                )}
                {/*</Layout>*/}
                {/*</LayoutItem>*/}
            </Layout>
        </div>
    </div>
)

AchievementIntro.propTypes = {
    button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    handleClickDownload: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    listItems: PropTypes.array,
    programLogo: PropTypes.string.isRequired,
    sharerProps: PropTypes.shape({
        label: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    student: PropTypes.object.isRequired,
    title: PropTypes.string,
}

AchievementIntro.defaultProps = {
    button: null,
    listItems: [],
    title: '',
}

AchievementIntro.Placeholder = () => (
    <ContentLoader height={669} width={888} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="1" y="585" width="195" height="51" rx="5" />
        <rect x="231" y="585" width="195" height="51" rx="5" />
        <rect x="462" y="585" width="195" height="51" rx="5" />
        <rect x="692" y="585" width="195" height="51" rx="5" />
        <rect x="125" y="400" width="78" height="24" />
        <rect x="125" y="431" width="149" height="24" />
        <circle cx="46" cy="427" r="46" />
        <rect x="461" y="377" width="427" height="2" />
        <rect x="461" y="423" width="427" height="2" />
        <rect x="461" y="469" width="427" height="2" />
        <rect x="461" y="532" width="427" height="2" />
        <rect x="0" y="0" width="888" height="296" rx="5" />
        <rect x="0" y="335" width="97" height="19" />
        <rect x="461" y="344" width="97" height="19" />
        <rect x="461" y="393" width="133" height="19" />
        <rect x="790" y="344" width="98" height="19" />
        <rect x="811" y="393" width="78" height="19" />
        <rect x="461" y="439" width="90" height="19" />
        <rect x="760" y="439" width="128" height="19" />
        <rect x="461" y="488" width="157" height="19" />
        <rect x="781" y="488" width="107" height="19" />
        <rect x="1" y="532" width="427" height="2" />
    </ContentLoader>
)

export default AchievementIntro
