import React from 'react'

import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'

import { FlexibleListItem, FlexibleListItemCheckbox } from './'

const FlexibleListRows = SortableContainer(
    ({ handleCheckboxChange, handleDelete, isEditable, isDisabled, isProgress, items }) => (
        <ul className="flexible-list_rows">
            {items.map((item, index) =>
                isProgress ? (
                    <FlexibleListItemCheckbox
                        key={index}
                        handleCheckboxChange={handleCheckboxChange}
                        index={index}
                        inputId={`flexible-list-checkbox-${index}`}
                        isDisabled={isDisabled}
                        item={item}
                        number={index}
                    />
                ) : (
                    <FlexibleListItem
                        key={index}
                        handleDeleteItem={handleDelete}
                        index={index}
                        isEditable={isEditable}
                        item={item}
                        number={index}
                    />
                )
            )}
        </ul>
    )
)

FlexibleListRows.propTypes = {
    handleCheckboxChange: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isProgress: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
}

export default FlexibleListRows
