import { filter } from 'lodash-es'
import moment from 'moment'

import { datetimeAsString } from './datetimes'

export function filterCompletedCourses(courses) {
    const currentTimestamp = moment().valueOf()
    return filter(courses, course => {
        const startDateMoment = moment(datetimeAsString(course.startDate))
        return currentTimestamp > startDateMoment.valueOf()
    })
}

export function completedCourses(courses) {
    const now = new Date()
    return courses.filter(course => new Date(course.startDate.datetime) < now)
}

export function upcomingCourses(courses) {
    const now = new Date()
    return courses.filter(course => new Date(course.startDate.datetime) >= now)
}

export function getRecentCourse(courses) {
    const completedCourse = completedCourses([...courses]).pop()
    return typeof completedCourse !== 'undefined' ? completedCourse : null
}

export function getNextCourse(courses) {
    const upcomingCourse = upcomingCourses([...courses]).shift()
    return typeof upcomingCourse !== 'undefined' ? upcomingCourse : null
}

export function parseCourseList(ref, courses) {
    if (ref.parsedCourseList) {
        return ref.parsedCourseList
    }

    return (ref.parsedCourseList = {
        last: getRecentCourse(courses),
        next: getNextCourse(courses),
    })
}
