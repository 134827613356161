import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { Label } from '../Label'

const ProgramName = ({ form, initialValue, isRequired }) => (
    <div className="o-input_wrapper">
        <Label inputId="programName" isRequired={isRequired}>
            Program Name
        </Label>
        {form.getFieldDecorator('programName', {
            initialValue: initialValue,
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'Please enter a program name',
                        },
                    ],
                },
            ],
        })(<input className="o-input" type="text" id="programName" />)}
        <div className="o-input_error">{form.getFieldError('programName')}</div>
    </div>
)

ProgramName.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
}

ProgramName.defaultProps = {
    initialValue: '',
    isRequired: false,
}

export default ProgramName
