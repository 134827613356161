import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import React from 'react'
import PropTypes from 'prop-types'

import { CardHeader } from '../Card'
import { FlexibleListRows } from './'
import { IconButton } from '../IconButton'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'

class FlexibleList extends React.Component {
    state = {
        isMoving: false,
        descriptionValue: '',
    }

    onSortStart = () => {
        this.setState({
            isMoving: true,
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { handleItemSort } = this.props
        this.setState({
            isMoving: false,
        })
        handleItemSort(oldIndex, newIndex)
    }

    onDescriptionChange = event => {
        this.setState({ descriptionValue: event.target.value })
    }

    handleSubmit = event => {
        event.preventDefault()

        const { handleItemCreate } = this.props
        const { descriptionValue } = this.state

        const value = descriptionValue.replace(/^\s+|\s+$/g, '')

        if (!isEmpty(value)) {
            this.setState({
                descriptionValue: '',
            })
            handleItemCreate({
                description: value,
            })
        }
    }

    onItemDelete = event => {
        const { handleItemDelete } = this.props
        const itemIndex = parseInt(event.currentTarget.getAttribute('data-index'))
        handleItemDelete(itemIndex)
    }

    handleCheckboxChange = event => {
        const { handleItemUpdate } = this.props
        const itemIndex = parseInt(event.currentTarget.getAttribute('data-index'))
        handleItemUpdate(itemIndex, {
            isCompleted: event.currentTarget.checked,
        })
    }

    render() {
        const { headers, isCard, isEditable, isDisabled, isProgress, items, title } = this.props
        const { descriptionValue, isMoving } = this.state
        const listClasses = classNames('flexible-list', 'u-margin-y', { 'is-moving': isMoving }, { 'u-card': isCard })

        return (
            <div className={listClasses}>
                {isCard ? <CardHeader>{title}</CardHeader> : <h1 className="o-h2 u-margin-small">{title}</h1>}
                {isEditable && (
                    <div className="flexible-list_form">
                        <div className="u-padding-small">
                            <Layout overrideClass="-gutter-small -bottom">
                                <LayoutItem overrideClass="u-3/4@from-small u-margin-tiny-y">
                                    <Label inputId="description">Add a row</Label>
                                    <textarea
                                        value={descriptionValue}
                                        onChange={this.onDescriptionChange}
                                        id="description"
                                        className="o-textarea"
                                        placeholder="Description"
                                    />
                                </LayoutItem>
                                <LayoutItem overrideClass="u-1/4@from-small u-margin-tiny-y">
                                    <IconButton handleClick={this.handleSubmit} name="plus" />
                                </LayoutItem>
                            </Layout>
                        </div>
                    </div>
                )}
                <div className="flexible-list_wrapper">
                    <div className="flexible-list_header u-padding-small-x">
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/5@from-small">{headers[0]}</LayoutItem>
                            <LayoutItem overrideClass="u-4/5@from-small">{headers[1]}</LayoutItem>
                        </Layout>
                    </div>
                    {items.length > 0 ? (
                        <FlexibleListRows
                            handleCheckboxChange={this.handleCheckboxChange}
                            handleDelete={this.onItemDelete}
                            isEditable={isEditable}
                            isDisabled={isDisabled}
                            isProgress={isProgress}
                            items={items}
                            lockAxis="y"
                            onSortEnd={this.onSortEnd}
                            onSortStart={this.onSortStart}
                            useDragHandle={true}
                        />
                    ) : (
                        <p className="flexible-list_empty">List is empty</p>
                    )}
                </div>
            </div>
        )
    }
}

FlexibleList.propTypes = {
    handleItemCreate: PropTypes.func,
    handleItemDelete: PropTypes.func,
    handleItemUpdate: PropTypes.func,
    handleItemSort: PropTypes.func,
    headers: PropTypes.array.isRequired,
    isCard: PropTypes.bool,
    isEditable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isProgress: PropTypes.bool,
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
}

FlexibleList.defaultProps = {
    handleItemCreate: () => {},
    handleItemDelete: () => {},
    handleItemUpdate: () => {},
    handleItemSort: () => {},
    isCard: false,
    isEditable: false,
    isDisabled: false,
    isProgress: false,
}

export default FlexibleList
