import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { createInstructor, fetchInstructors } from '../actions/instructorActions'
import { apiClient } from '../helpers/ApiClient'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { Footer } from '../components/Footer'
import { ConfirmEmail, Email, FirstName, LastName, ProfileImage } from '../components/FormFields'
import { FormMessages } from '../components/FormMessages'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class InstructorCreateView extends React.Component {
    state = {
        errors: [],
        isFormSubmitted: false,
        isSavingInstructor: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const {
            dispatch,
            form: { validateFields },
        } = this.props
        const { isFormSubmitted, isSavingInstructor } = this.state

        if (isFormSubmitted || isSavingInstructor) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingInstructor: true,
                })
                createInstructor(formValues, { cancelToken: this.signal.token })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingInstructor: false,
                        })
                        /** Update the instructor list in store through `fetchInstructors`. Empty cache first. */
                        apiClient.clearCache('instructors')
                        dispatch(fetchInstructors())
                        dispatch(
                            push(
                                renderRoute({
                                    id: 'instructor-details',
                                    bind: 'instructorId',
                                    param: response.data.id,
                                })
                            )
                        )
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingInstructor: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form } = this.props
        const { errors, isSavingInstructor } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main>
                <Header>
                    Create <br /> an Instructor
                </Header>
                <form
                    onSubmit={this.handleSubmit}
                    className={classNames('o-form', {
                        'is-loading': isSavingInstructor,
                    })}
                >
                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        {hasErrors && (
                            <div className="o-input_wrapper">
                                <FormMessages hasErrors={true} items={notifications} />
                            </div>
                        )}
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <FirstName form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <LastName form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Email form={form} />
                                <ConfirmEmail form={form} />
                            </LayoutItem>
                        </Layout>
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <RouterLink
                                        className="o-button -huge u-margin-small-y"
                                        to={renderRoute('instructor-list')}
                                    >
                                        Cancel
                                    </RouterLink>
                                </TrackVisibility>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <button className="o-button -huge -green u-margin-small-y" type="submit">
                                        Create Instructor
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                        </Layout>
                    </TrackVisibility>
                    <div className="o-form_overlay">
                        <div className="o-form_spinner u-text-center">
                            <Spinner />
                        </div>
                    </div>
                </form>
                <Footer />
            </Main>
        )
    }
}

export default connect()(createForm()(InstructorCreateView))
