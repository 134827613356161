import moment from 'moment'

import {
    FETCH_INSTRUCTOR_LIST_REQUEST,
    FETCH_INSTRUCTOR_LIST_SUCCESS,
    FETCH_INSTRUCTOR_LIST_FAILURE,
} from '../actions/instructorActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function instructors(state = initialState, action) {
    switch (action.type) {
        case FETCH_INSTRUCTOR_LIST_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_INSTRUCTOR_LIST_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_INSTRUCTOR_LIST_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
