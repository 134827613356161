import React from 'react'

import PropTypes from 'prop-types'

import { Layout, LayoutItem } from '../Layout'

const SimpleList = ({ items }) => (
    <div className="simple-list">
        {items.map(({ label, value }, index) => (
            <Layout overrideClass="simple-list_line" key={index}>
                <LayoutItem overrideClass="u-1/2">
                    <p className="simple-list_label">{label}</p>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2 u-text-right">
                    <p className="simple-list_value">{value}</p>
                </LayoutItem>
            </Layout>
        ))}
    </div>
)

SimpleList.propTypes = {
    items: PropTypes.array.isRequired,
}

export default SimpleList
