import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'

import { fetchInstructors } from '../actions/instructorActions'
import { isUserParent, isUserStudent } from '../helpers/auth'

import UserListView from './UserListView'

class InstructorListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchInstructors({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { user, ...rest } = this.props
        const title = isUserParent(user) || isUserStudent(user) ? 'My Instructors' : 'Instructors'
        return (
            <UserListView
                {...rest}
                detailsRoute={{
                    id: 'instructor-details',
                    bind: 'instructorId',
                }}
                listTitle="Instructors"
                title={title}
            />
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.requestStates.FETCH_INSTRUCTOR_LIST === true,
    items: state.instructors.entities,
    user: state.auth.user,
})

export default connect(mapStateToProps)(InstructorListView)
