import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchCurriculums } from '../actions/curriculumActions'
import { fetchInstructors } from '../actions/instructorActions'
import { fetchSession, fetchSessions, updateSession } from '../actions/sessionActions'
import { isUserAdministrator } from '../helpers/auth'

import { Fade } from '../components/Fade'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { SessionForm } from '../components/Session'
import { TrackVisibility } from '../components/TrackVisibility'

class SessionEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        session: null,
        isLoading: true,
    }

    loadSession(sessionId) {
        const { fetchMethod } = this.props
        this.setState({ isLoading: true }, () => {
            fetchMethod(sessionId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        session: response.data,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        const { dispatch, user } = this.props

        this.loadSession(this.props.match.params.sessionId)

        const config = { cancelToken: this.signal.token }
        dispatch(fetchCurriculums(config))
        if (isUserAdministrator(user)) {
            dispatch(fetchInstructors(config))
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.sessionId !== this.props.match.params.sessionId) {
            this.loadSession(this.props.match.params.sessionId)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { formProps, title } = this.props
        const { error, isLoading, session } = this.state

        return (
            <Main>
                <Header>
                    Edit <br /> {title}
                </Header>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <SwitchTransition>
                        <Fade timeout={250} key={isLoading}>
                            {isLoading ? (
                                <SessionForm.Placeholder />
                            ) : error !== null ? (
                                <section className="u-relative">{error.message}</section>
                            ) : (
                                <SessionForm {...formProps} session={session} />
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

SessionEditView.propTypes = {
    fetchMethod: PropTypes.func,
    formProps: PropTypes.object,
    title: PropTypes.string,
}

SessionEditView.defaultProps = {
    fetchMethod: fetchSession,
    formProps: {
        fetchMethod: fetchSessions,
        isDraft: false,
        updateMethod: updateSession,
    },
    title: 'Active Session',
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(SessionEditView)
