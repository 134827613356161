import axios from 'axios'
import { reverse, sortBy } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchCertificates } from '../actions/certificateActions'
import { fetchStudentMedals } from '../actions/studentMedalActions'
import { isUserStudent } from '../helpers/auth'
import { Fade } from '../components/Fade'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { List, ListCertificateItem, ListMedalItem } from '../components/List'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

class AchievementListView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        student: null,
    }

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchCertificates({ cancelToken: this.signal.token }))
        dispatch(fetchStudentMedals({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { user, certificates, studentMedals, isLoadingAchievements, sport } = this.props

        return (
            <Main>
                {isUserStudent(user) ? (
                    <Header>
                        My <br /> Achievements
                    </Header>
                ) : (
                    <Header>
                        {sport.name} <br /> Achievements
                    </Header>
                )}

                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingAchievements}>
                            {isLoadingAchievements ? (
                                <List.Placeholder />
                            ) : (
                                <List title={`${certificates.length} Certificates, ${studentMedals.length} medals`}>
                                    {studentMedals.map(studentMedal => (
                                        <ListMedalItem key={studentMedal.id} studentMedal={studentMedal} />
                                    ))}
                                    {certificates.map(certificate => (
                                        <ListCertificateItem key={certificate.id} studentCertificate={certificate} />
                                    ))}
                                </List>
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

AchievementListView.header = role => {
    switch (role) {
        case 'student':
            return (
                <React.Fragment>
                    My <br /> Achievements
                </React.Fragment>
            )
        case 'parent':
            return (
                <React.Fragment>
                    Swimming <br /> Achievements
                </React.Fragment>
            )
        default:
            return (
                <React.Fragment>
                    Swimming <br /> Achievements
                </React.Fragment>
            )
    }
}

const mapStateToProps = state => {
    const certificates = reverse(sortBy(state.certificates.entities, ['date.timestamp']))
    const studentMedals = reverse(sortBy(state.studentMedals.entities, ['date.timestamp']))

    return {
        user: state.auth.user,
        sport: state.auth.user.sport,
        certificates: certificates,
        studentMedals: studentMedals,
        isLoadingAchievements:
            state.requestStates.FETCH_CERTIFICATE_LIST === true || state.requestStates.FETCH_MEDAL_LIST === true,
    }
}

export default connect(mapStateToProps)(AchievementListView)
