import React from 'react'

import { connect } from 'react-redux'

import { fetchInstructors } from '../../actions/instructorActions'
import { fetchParents } from '../../actions/parentActions'
import { fetchSessions } from '../../actions/sessionActions'
import { fetchStudents } from '../../actions/studentActions'

import { DashboardAdministratorUserList, DashboardInfos } from '../Dashboard'
import { SessionListContainer } from '../Session'
import { TrackVisibility } from '../TrackVisibility'

const DashboardAdministrator = ({ infoItems }) => (
    <React.Fragment>
        <DashboardInfos items={infoItems} />
        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
            <SessionListContainer
                fetchMethod={fetchSessions}
                isSmall
                listProps={{
                    buttonData: {
                        label: 'View Sessions',
                        route: 'session-list',
                    },
                    emptyLabel: 'No active sessions',
                    title: 'Active Sessions',
                }}
                requestState="FETCH_SESSION_LIST"
                storeKey="sessions"
            />
        </TrackVisibility>
        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
            <DashboardAdministratorUserList
                buttonData={{
                    label: 'All Instructors',
                    route: 'instructor-list',
                }}
                fetchMethod={fetchInstructors}
                requestState="FETCH_INSTRUCTOR_LIST"
                storeKey="instructors"
                title="Instructors"
                userRoute={{ id: 'instructor-details', bind: 'instructorId' }}
            />
        </TrackVisibility>
        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
            <DashboardAdministratorUserList
                buttonData={{ label: 'All Students', route: 'student-list' }}
                fetchMethod={fetchStudents}
                requestState="FETCH_STUDENT_LIST"
                storeKey="students"
                title="Students"
                userRoute={{ id: 'student-details', bind: 'studentId' }}
            />
        </TrackVisibility>
        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
            <DashboardAdministratorUserList
                buttonData={{ label: 'All Parents', route: 'parent-list' }}
                fetchMethod={fetchParents}
                requestState="FETCH_PARENT_LIST"
                storeKey="parents"
                title="Parents"
                userRoute={{ id: 'parent-details', bind: 'parentId' }}
            />
        </TrackVisibility>
    </React.Fragment>
)

const mapStateToProps = state => {
    return {
        infoItems: [
            { label: 'Students', value: state.students.entities.length },
            { label: 'Instructors', value: state.instructors.entities.length },
        ],
    }
}

export default connect(mapStateToProps)(DashboardAdministrator)
