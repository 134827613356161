import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'sessions'

/**
 * Fetch a list of all active sessions for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_SESSION_LIST_REQUEST = 'FETCH_SESSION_LIST_REQUEST'
export const FETCH_SESSION_LIST_SUCCESS = 'FETCH_SESSION_LIST_SUCCESS'
export const FETCH_SESSION_LIST_FAILURE = 'FETCH_SESSION_LIST_FAILURE'
export const FETCH_SESSION_LIST_PENDING = 'FETCH_SESSION_LIST_PENDING'
export const fetchSessions = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_SESSION_LIST_REQUEST,
            FETCH_SESSION_LIST_SUCCESS,
            FETCH_SESSION_LIST_FAILURE,
            FETCH_SESSION_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a session.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSession = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}`, config)
}

/**
 * Create a new session.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createSession = (formData, config = {}) => {
    return apiClient.post(baseUrl, formData, config)
}

/**
 * Update a session.
 *
 * @param  {String} sessionId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateSession = (sessionId, formData, config = {}) => {
    return apiClient.patch(`${baseUrl}/${sessionId}`, formData, config)
}

/**
 * Update a session and send emails to students.
 *
 * @param  {String} sessionId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateAndPushSession = (sessionId, formData, config = {}) => {
    formData.isNotify = true
    return apiClient.patch(`${baseUrl}/${sessionId}`, formData, config)
}

/**
 * Fetch a list of a session's courses.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionCourses = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}/courses`, config)
}

/**
 * Fetch a list of a session's students.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionStudents = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}/students`, config)
}

/**
 * Fetch a list of a session's reports.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionReports = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}/reports`, config)
}
