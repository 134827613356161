import React from 'react'

import { logout } from '../../actions/userActions'
import { apiClient } from '../../helpers/ApiClient'
import { connect } from 'react-redux'

const Logout = ({ logout }) => (
    <div className="sidebar_logout">
        <button className="link sidebar_logout_button" onClick={logout} type="button">
            Logout
        </button>
    </div>
)

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout())
            apiClient.clearCache()
        },
    }
}

export default connect(null, mapDispatchToProps)(Logout)
