import React from 'react'

import PropTypes from 'prop-types'

const ConfirmEmail = ({ form }) => (
    <div className="o-input_wrapper">
        <label htmlFor="emailConfirmation" className="o-label -required">
            Confirm Email
        </label>
        {form.getFieldDecorator('emailConfirmation', {
            initialValue: '',
            validateFirst: true,
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            type: 'email',
                            required: true,
                            message: 'Please validate the email',
                        },
                        {
                            validator: (rule, value, callback) => {
                                value !== form.getFieldValue('email') ? callback(false) : callback()
                            },
                            message: 'Both email fields must be identical',
                        },
                    ],
                },
            ],
        })(<input type="email" id="emailConfirmation" placeholder="example@example.com" className="o-input" />)}
        <div className="o-input_error">{form.getFieldError('emailConfirmation')}</div>
    </div>
)

ConfirmEmail.propTypes = {
    initialValue: PropTypes.string,
}

ConfirmEmail.defaultProps = {
    initialValue: '',
}

export default ConfirmEmail
