const baseUrl = 'stickers'

/**
 * Fetch a list of all stickers for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_STICKER_LIST_REQUEST = 'FETCH_STICKER_LIST_REQUEST'
export const FETCH_STICKER_LIST_SUCCESS = 'FETCH_STICKER_LIST_SUCCESS'
export const FETCH_STICKER_LIST_FAILURE = 'FETCH_STICKER_LIST_FAILURE'
export const FETCH_STICKER_LIST_PENDING = 'FETCH_STICKER_LIST_PENDING'
export const fetchStickers = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_STICKER_LIST_REQUEST,
            FETCH_STICKER_LIST_SUCCESS,
            FETCH_STICKER_LIST_FAILURE,
            FETCH_STICKER_LIST_PENDING,
        ],
    }
}
