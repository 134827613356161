import { includes, isEmpty } from 'lodash-es'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import { renderRoute } from '../utils/routes'

import NotFoundView from '../views/NotFoundView'

const locationHelper = locationHelperBuilder({})

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: state => state.auth.user !== null,
    redirectPath: state => renderRoute('account-login'),
    wrapperDisplayName: 'UserIsAuthenticated',
})

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => state.auth.user === null,
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || renderRoute('organization'),
    wrapperDisplayName: 'UserIsNotAuthenticated',
})

export const userCanViewRoute = action => {
    return connectedAuthWrapper({
        authenticatedSelector: state => userCanDo(state.auth.user, action),
        FailureComponent: NotFoundView,
    })
}

/**
 * Determine if a user can perform a certain action.
 *
 * @param {object} user
 * @param {string} action
 * @return {boolean}
 */
export const userCanDo = (user, action) => {
    if (isEmpty(user)) {
        return false
    }

    const canDo = includes(user.role.rules, `allow.${action}`) || user.role.isSuperuser

    /** Check for denied rules */
    if (canDo && user.role.isSuperuser) {
        if (includes(user.role.rules, `deny.${action}`)) {
            return false
        }
    }

    return canDo
}

export const isUserAdministrator = user => {
    return user.role.id === 'administrator'
}

export const isUserInstructor = user => {
    return user.role.id === 'instructor'
}

export const isUserStudent = user => {
    return user.role.id === 'student'
}

export const isUserParent = user => {
    return user.role.id === 'parent'
}

export const isCurrentUser = (user, person) => {
    return user.pairedObjectId === person.id
}
