import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { fetchSelf, login } from '../actions/userActions'
import { formatFormMessages } from '../utils/forms'
import { FormMessages } from '../components/FormMessages'
import { Layout, LayoutItem } from '../components/Layout'
import { Link } from '../components/Link'
import { LoginBackground } from '../components/Login'
import { Logo } from '../components/Logo'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class AccountLoginView extends React.Component {
    state = {
        errors: [],
        isAuthenticated: false,
        isLoading: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const {
            dispatch,
            form: { validateFields },
        } = this.props
        const { isAuthenticated, isLoading } = this.state

        if (isLoading || isAuthenticated) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isLoading: true,
                })
                dispatch(login(formValues, { cancelToken: this.signal.token }))
                    .then(response => {
                        this.setState({
                            errors: [],
                            // Leave loading state true to simulate redirection...
                            // isLoading: false,
                            isAuthenticated: true,
                        })
                        /** Load the authenticated user's details. */
                        dispatch(fetchSelf())
                            .then(() => {
                                /** Redirection on success is managed through "redux-auth-wrapper". */
                            })
                            .catch(() => {
                                console.warn('Something terrible went wrong for this to happen!')
                            })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isLoading: false,
                        })
                    })
            }
        })

        return false
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { errors, isAuthenticated, isLoading } = this.state
        const {
            form: { getFieldDecorator, getFieldError },
            organizationId,
        } = this.props
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isAuthenticated
            ? [
                  {
                      message: 'You have logged in successfully. You will be redirected shortly.',
                  },
              ]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <Main isSimple={true}>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <div className="login-form_wrapper">
                        <form
                            className={classNames('login-form', 'u-card', 'o-form', { 'is-loading': isLoading })}
                            onSubmit={this.handleSubmit}
                        >
                            <div className="login-form_inner-tiny">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Logo /> Report.Cards
                                    </LayoutItem>
                                </Layout>
                            </div>
                            <div className="login-form_inner">
                                <h1 className="o-h1 u-text-big">Login</h1>
                                {hasNotifications && (
                                    <div className="o-input_wrapper">
                                        <FormMessages hasErrors={hasErrors} items={notifications} />
                                    </div>
                                )}
                                <div className="o-input_wrapper">
                                    <label className="o-label" htmlFor="email">
                                        Email
                                    </label>
                                    {getFieldDecorator('email', {
                                        initialValue: '',
                                        validate: [
                                            {
                                                trigger: 'onBlur',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter your email',
                                                    },
                                                ],
                                            },
                                        ],
                                    })(<input type="email" className="o-input" id="email" />)}
                                    <div className="o-input_error">{getFieldError('email')}</div>
                                </div>
                                <div className="o-input_wrapper">
                                    <label className="o-label" htmlFor="password">
                                        Password
                                    </label>
                                    {getFieldDecorator('password', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter your password',
                                            },
                                        ],
                                    })(<input type="password" className="o-input" id="password" />)}
                                    <div className="o-input_error">{getFieldError('password')}</div>
                                </div>
                                <div className="o-input_wrapper">
                                    {getFieldDecorator('organizationId', {
                                        initialValue: organizationId,
                                    })(<input type="text" hidden={true} />)}
                                    <button type="submit" className="o-button login-form_submit">
                                        Login
                                    </button>
                                </div>
                                <div className="o-form_overlay">
                                    <div className="o-form_spinner -centered u-text-center">
                                        <Spinner />
                                    </div>
                                </div>
                            </div>
                            <div className="login-form_inner-tiny -footer">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Link route="account-request-confirmation-email" overrideClass="u-text-tiny">
                                            Resend email confirmation
                                        </Link>
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2">
                                        <div className="u-text-right">
                                            <Link
                                                route="account-request-reset-password-email"
                                                overrideClass="u-text-tiny"
                                            >
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            </div>
                        </form>
                    </div>
                </TrackVisibility>
                <LoginBackground />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps)(createForm()(AccountLoginView))
