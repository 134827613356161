import { split, sortBy } from 'lodash-es'
import moment from 'moment'
import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import ContentLoader from 'react-content-loader'
import FlipMove from 'react-flip-move'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { userCanDo } from '../../helpers/auth'
import { blobUrl } from '../../helpers/blobUrl'
import { getVideoImage } from '../../helpers/getVideoImage'
import randomIdent from '../../utils/randomIdent'

import { Container } from '../Container'
import { Dropzone } from '../Dropzone'
import { Fade } from '../Fade'
import { GalleryItem } from '../Gallery'
import { Icon } from '../Icon'
import { IconButton } from '../IconButton'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'

Modal.setAppElement('#root')
Modal.defaultStyles = {}

class Gallery extends React.Component {
    state = {
        gallery: this.props.gallery,
        hasLoaded: !this.props.isLoading,
        dropzoneKey: randomIdent(),
        modalIsOpen: false,
        modalContentType: null,
        modalContentSrc: null,
        newGalleryItem: {
            inputValue: '',
            preparedFile: null,
            description: '',
        },
    }

    /**
     * Open the modal with the correct markup
     */
    handleGalleryClick = event => {
        this.setState({
            modalIsOpen: true,
            modalContentType: event.currentTarget.getAttribute('data-type'),
            modalContentSrc: event.currentTarget.getAttribute('data-src'),
        })
    }

    /**
     * Close the modal
     *
     */
    handleCloseModal = event => {
        this.setState({ modalIsOpen: false })
    }

    /**
     * Manages the change of the text area
     *
     * @param {event}
     * @param {type} input type
     */
    handleInputChange = event => {
        const {
            target: { value },
        } = event
        this.setState(({ newGalleryItem }) => ({
            newGalleryItem: {
                ...newGalleryItem,
                description: value,
            },
        }))
    }

    /**
     * Manages the change of input file
     *
     * @param {string} media file
     * @param {string} media src
     * @param {string} media type
     */
    handleDropzoneOnProcessFile = file => {
        const { newGalleryItem } = this.state

        this.setState({
            newGalleryItem: {
                ...newGalleryItem,
                inputValue: file,
            },
        })
    }

    handleDropzoneOnPrepareFile = file => {
        const { newGalleryItem } = this.state

        this.setState({
            newGalleryItem: {
                ...newGalleryItem,
                preparedFile: file,
            },
        })
    }

    /**
     * Takes a filepond file and return a prepared Media item.
     *
     * @param file File type ex: 'video, image'.
     * @return {Promise<{thumbnail: *, file: *, label: string, type: *}|unknown>}
     */
    static parseFileMedia = async (file, blob) => {
        const src = blobUrl(blob)
        const type = split(file.fileType, '/')[0]

        if (type === 'video') {
            return await new Promise(resolve =>
                getVideoImage(src, 0.1, thumbnail => {
                    resolve({
                        thumbnail: thumbnail.src,
                        file: src,
                        type: type,
                        label: 'Video',
                    })
                })
            )
        } else if (type === 'image') {
            return {
                thumbnail: src,
                file: src,
                type: type,
                label: 'Image',
            }
        }
    }

    /**
     * Update the Gallery state with new GalleryItem
     *
     * @param {event}
     */
    addMedia = event => {
        event.preventDefault()
        const { gallery, newGalleryItem } = this.state
        const {
            onNewMedia,
            form: { validateFields },
        } = this.props
        const { inputValue, preparedFile } = newGalleryItem
        const date = moment()

        validateFields(async (formErrors, formValues) => {
            if (formErrors) {
                return false
            }

            Gallery.parseFileMedia(inputValue, preparedFile).then(media => {
                const item = {
                    ...newGalleryItem,
                    id: randomIdent(),
                    isNew: true,
                    inputValue: inputValue.serverId,
                    date: {
                        timestamp: date.unix(),
                        display: date.format('MMM DD, YYYY'),
                    },
                    media: media,
                }

                onNewMedia(item)

                this.setState({
                    gallery: [...gallery, item],
                    dropzoneKey: randomIdent(),
                    newGalleryItem: {
                        inputValue: '',
                        description: '',
                    },
                })
            })
        })
    }

    /**
     * Clear the current image in the Dropzone
     *
     */
    cancelDropzone = () => {
        this.setState(({ newGalleryItem }) => ({
            newGalleryItem: {
                ...newGalleryItem,
                inputValue: '',
            },
        }))
    }

    /**
     * Delete the clicked GalleryItem
     *
     * @param {object} media
     */
    deleteMedia(media) {
        const { gallery } = this.state
        const { onDeleteMedia } = this.props

        onDeleteMedia(media)

        this.setState({
            gallery: gallery.filter(item => item.id !== media.id),
        })
    }

    render() {
        const {
            auth: { user },
            readOnly,
            form,
            isLoading,
        } = this.props
        const { modalIsOpen, modalContentType, modalContentSrc, gallery, newGalleryItem, dropzoneKey } = this.state

        // sort item by date
        const filteredItems = sortBy(gallery, 'date.timestamp').reverse()

        return (
            <div className="gallery u-padding-small-y">
                {userCanDo(user, 'report-cards/object/report-media.create') && readOnly !== true && (
                    <React.Fragment>
                        <h2 className="o-h2 u-margin-small">Upload images</h2>
                        <form className="gallery-form u-padding-small-x" onSubmit={this.addMedia}>
                            <Layout overrideClass=" -gutter-small">
                                <LayoutItem overrideClass="u-1/2@from-small">
                                    <div className="o-input_wrapper">
                                        <Label inputId="description">Description</Label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            value={newGalleryItem.description}
                                            onChange={this.handleInputChange}
                                            placeholder="Type here"
                                            className="o-textarea gallery-form_textarea"
                                        />
                                    </div>
                                </LayoutItem>
                                <LayoutItem overrideClass="u-1/4@from-small">
                                    <div className="o-input_wrapper">
                                        <Label>File</Label>
                                        <Dropzone
                                            currentFile={newGalleryItem.inputValue}
                                            form={form}
                                            inputName="media"
                                            isRequired
                                            key={dropzoneKey}
                                            onCancel={this.cancelDropzone}
                                            onPrepareFile={this.handleDropzoneOnPrepareFile}
                                            onProcessFile={this.handleDropzoneOnProcessFile}
                                            overrideClass="-gallery-form"
                                            validFileTypes={[
                                                'image/jpeg',
                                                'image/png',
                                                'image/gif',
                                                'video/mp4',
                                                'video/quicktime',
                                            ]}
                                        />
                                    </div>
                                </LayoutItem>
                                <LayoutItem overrideClass="u-1/4@from-small">
                                    <div className="o-input_wrapper">
                                        <IconButton type="submit" name="plus" />
                                    </div>
                                </LayoutItem>
                            </Layout>
                        </form>
                    </React.Fragment>
                )}

                <h2 className="o-h2 u-margin-small">Gallery</h2>

                <div className="gallery-grid u-padding-small-x">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoading}>
                            {isLoading ? (
                                <Gallery.Placeholder />
                            ) : (
                                <React.Fragment>
                                    {filteredItems.length > 0 && (
                                        <Layout component={Layout} overrideClass="-gutter-small u-relative">
                                            <FlipMove
                                                typeName={null}
                                                easing="cubic-bezier(.5, 0, .5, 1)"
                                                appearAnimation={'elevator'}
                                                staggerDurationBy={15}
                                                staggerDelayBy={20}
                                                maintainContainerHeight={true}
                                            >
                                                {filteredItems.map((item, index) => (
                                                    <Container key={item.id}>
                                                        <LayoutItem overrideClass="u-1/2@from-small gallery-grid_item">
                                                            <GalleryItem
                                                                id={item.id}
                                                                date={item.date}
                                                                description={item.description}
                                                                media={item.media}
                                                                handleGalleryClick={this.handleGalleryClick}
                                                            />
                                                            {userCanDo(
                                                                user,
                                                                'report-cards/object/report-media.delete'
                                                            ) &&
                                                                readOnly !== true && (
                                                                    <React.Fragment>
                                                                        <button
                                                                            type="button"
                                                                            className="link -red"
                                                                            onClick={() => this.deleteMedia(item)}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </React.Fragment>
                                                                )}
                                                        </LayoutItem>
                                                    </Container>
                                                ))}
                                            </FlipMove>
                                        </Layout>
                                    )}

                                    {filteredItems.length === 0 && (
                                        <Layout overrideClass="-gutter-small">
                                            <LayoutItem overrideClass="u-text-center">
                                                <p>No images yet</p>
                                            </LayoutItem>
                                        </Layout>
                                    )}
                                </React.Fragment>
                            )}
                        </Fade>
                    </SwitchTransition>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    portalClassName="gallery-lightbox"
                    overlayClassName="gallery-lightbox_inner"
                    onRequestClose={this.handleCloseModal}
                >
                    <button onClick={this.handleCloseModal} className="gallery-lightbox_button">
                        <Icon name="close" overrideClass="gallery-lightbox_button_icon" />
                    </button>

                    {modalContentType === 'video' && (
                        <React.Fragment>
                            <div className="gallery-lightbox_inner_container">
                                <video src={modalContentSrc} autoPlay={true} controls />
                            </div>
                        </React.Fragment>
                    )}

                    {modalContentType === 'image' && (
                        <React.Fragment>
                            <div className="gallery-lightbox_inner_container">
                                <img className="gallery-lightbox_inner_image_ghost" src={modalContentSrc} alt="" />

                                <div
                                    className="gallery-lightbox_inner_image"
                                    style={{
                                        backgroundImage: `url('${modalContentSrc}')`,
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </Modal>
            </div>
        )
    }
}

Gallery.propTypes = {
    gallery: PropTypes.array,
    isLoading: PropTypes.bool,
    onNewMedia: PropTypes.func,
    onDeleteMedia: PropTypes.func,
    readOnly: PropTypes.bool,
}

Gallery.defaultProps = {
    gallery: [],
    isLoading: true,
    readOnly: false,
    onNewMedia: () => {},
    onDeleteMedia: () => {},
}

Gallery.getDerivedStateFromProps = (props, state) => {
    if (!state.hasLoaded && !props.isLoading) {
        return {
            hasLoaded: true,
            gallery: props.gallery,
        }
    }

    return null
}

Gallery.Placeholder = () => (
    <ContentLoader height={346} width={760} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="30" y="215" width="114" height="15" />
        <rect x="395" y="215" width="163" height="15" />
        <rect x="30" y="0" width="335" height="203" rx="5" />
        <rect x="395" y="0" width="335" height="203" rx="5" />
        <rect x="30" y="243" width="335" height="15" />
        <rect x="394" y="243" width="315" height="15" />
        <rect x="30" y="264" width="217" height="15" />
        <rect x="394" y="264" width="136" height="15" />
    </ContentLoader>
)

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps)(createForm()(Gallery))
