import axios from 'axios'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { Label } from '../Label'
import { Spinner } from '../Spinner'

class SearchableSelect extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        defaultItems: [],
        isLoading: true,
    }

    componentDidMount() {
        const { dispatch, fetchMethod } = this.props

        dispatch(fetchMethod({ cancelToken: this.signal.token })).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    handleSelectOnChange = value => {
        const {
            form: { setFieldsValue },
            inputName,
        } = this.props
        value = value
            ? value.map(item => {
                  return item.id
              })
            : []
        setFieldsValue({ [inputName]: value })
    }

    filterItems = value => {
        return this.props.items.filter(string => string.name.toLowerCase().includes(value.toLowerCase()))
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldError },
            mapItemLabel,
            mapItemValue,
            inputName,
            inputLabel,
            initialValue,
            items,
        } = this.props
        const { isLoading } = this.state

        return (
            <div className="o-input_wrapper">
                <Label inputId={inputName} isRequired>
                    {inputLabel}
                </Label>
                {isLoading ? (
                    <Spinner isSmall />
                ) : (
                    <React.Fragment>
                        {getFieldDecorator(inputName, {
                            initialValue: map(initialValue, mapItemValue),
                        })(<input type="hidden" />)}
                        <Select
                            id={inputName}
                            placeholder="Choose parents..."
                            isMulti
                            defaultValue={initialValue}
                            onChange={this.handleSelectOnChange}
                            options={items}
                            getOptionLabel={mapItemLabel}
                            getOptionValue={mapItemValue}
                            noOptionsMessage={({ inputValue }) => (inputValue.length ? 'No results' : null)}
                        />
                        <div className="o-input_error">{getFieldError(inputName)}</div>
                    </React.Fragment>
                )}
            </div>
        )
    }
}

SearchableSelect.propTypes = {
    form: formShape.isRequired,
    inputName: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    mapItemLabel: PropTypes.func,
    mapItemValue: PropTypes.func,
    initialValue: PropTypes.array,
    fetchMethod: PropTypes.func.isRequired,
    requestState: PropTypes.string.isRequired,
    storeKey: PropTypes.string.isRequired,
}

SearchableSelect.defaultProps = {
    initialValue: [],
    mapItemLabel: ({ name }) => name,
    mapItemValue: ({ id }) => id,
}

const mapStateToProps = (state, ownProps) => {
    const { storeKey } = ownProps
    return {
        items: state[storeKey].entities,
    }
}

export default connect(mapStateToProps)(SearchableSelect)
