import arrayMove from 'array-move'
import axios from 'axios'
import classNames from 'classnames'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { fetchInstructor, fetchInstructors, updateInstructor } from '../actions/instructorActions'
import { fetchSelf } from '../actions/userActions'
import { apiClient } from '../helpers/ApiClient'
import { getHistory } from '../store'
import { formatFormMessages } from '../utils/forms'

import { FlexibleList } from '../components/FlexibleList'
import { Footer } from '../components/Footer'
import { FormMessages } from '../components/FormMessages'
import {
    ConfirmPassword,
    CurrentPassword,
    Email,
    FirstName,
    LastName,
    NewPassword,
    ProfileImage,
    Telephone,
} from '../components/FormFields'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class InstructorEditView extends React.Component {
    state = {
        certificationList: [],
        errors: [],
        hasNewPassword: false,
        initialValues: Immutable.Map({}),
        isInstructorLoaded: false,
        isInstructorLoading: true,
        isFormSubmitted: false,
        isSavingInstructor: false,
    }

    signal = axios.CancelToken.source()

    handleClickCancel = event => {
        getHistory().goBack()
    }

    handleNewPasswordInputChange = event => {
        const {
            form: { validateFields },
        } = this.props
        this.setState(
            {
                hasNewPassword: event.target.value !== '',
            },
            () => {
                validateFields(['passwordConfirmation'], { force: true })
            }
        )
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            instructorId,
            isInstructorConnected,
        } = this.props
        const { certificationList, isSavingInstructor } = this.state

        if (isSavingInstructor) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingInstructor: true,
                })
                updateInstructor(
                    instructorId,
                    {
                        ...formValues,
                        certifications: certificationList.map(item => {
                            return item.description
                        }),
                    },
                    { cancelToken: this.signal.token }
                )
                    .then(response => {
                        this.setState({
                            errors: [],
                            hasNewPassword: false,
                            isFormSubmitted: true,
                            isSavingInstructor: false,
                        })
                        apiClient.clearCache('instructors')
                        dispatch(fetchInstructors())
                        /** Refresh the connected user. */
                        if (isInstructorConnected) {
                            dispatch(fetchSelf())
                        }
                        setFieldsValue({
                            currentPassword: '',
                            password: '',
                            passwordConfirmation: '',
                            profileImage: response.data.profileImage.path,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingInstructor: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    handleFlexibleListItemCreate = newItem => {
        this.setState(({ certificationList }) => ({
            certificationList: [...certificationList, newItem],
        }))
    }

    handleFlexibleListItemDelete = itemIndex => {
        this.setState(({ certificationList }) => ({
            certificationList: certificationList.filter((item, index) => itemIndex !== index),
        }))
    }

    handleFlexibleListItemSort = (oldIndex, newIndex) => {
        this.setState(({ certificationList }) => ({
            certificationList: arrayMove(certificationList, oldIndex, newIndex),
        }))
    }

    setInstructorFormValues(values) {
        this.setState({
            certificationList: values.certifications.map(item => {
                return {
                    description: item,
                }
            }),
            initialValues: Immutable.Map({
                ...values,
                profileImage: values.profileImage.path,
            }),
            isInstructorLoaded: true,
        })
    }

    componentDidMount() {
        const { instructorId } = this.props

        this.setState({ isInstructorLoading: true }, () => {
            fetchInstructor(instructorId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setInstructorFormValues(response.data)
                    this.setState({
                        isInstructorLoading: false,
                    })
                })
                .catch(error => {
                    console.warn(error)
                    if (!axios.isCancel(error)) {
                        window.scrollTo(0, 0)
                        this.setState({
                            errors: error.response.data,
                            isInstructorLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            form,
            form: { getFieldDecorator, getFieldError },
            isInstructorConnected,
            title,
        } = this.props
        const {
            certificationList,
            errors,
            hasNewPassword,
            initialValues,
            isInstructorLoading,
            isFormSubmitted,
            isSavingInstructor,
        } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? isInstructorConnected
                ? [{ message: 'Your info has been saved successfully.' }]
                : [{ message: 'Instructor has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <Main>
                <Header>{title}</Header>
                <form
                    autoComplete="off"
                    className={classNames('o-form', {
                        'is-loading': isInstructorLoading || isSavingInstructor,
                    })}
                    onSubmit={this.handleSubmit}
                    noValidate
                >
                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        {hasNotifications && (
                            <div className="o-input_wrapper">
                                <FormMessages
                                    hasErrors={hasErrors}
                                    hasSuccess={isFormSubmitted}
                                    items={notifications}
                                />
                            </div>
                        )}
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <FirstName form={form} initialValue={initialValues.get('firstName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <LastName form={form} initialValue={initialValues.get('lastName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Email form={form} initialValue={initialValues.get('email')} />
                                {isInstructorConnected && (
                                    <React.Fragment>
                                        <CurrentPassword form={form} hasNewPassword={hasNewPassword} />
                                        <NewPassword
                                            form={form}
                                            handleInputChange={this.handleNewPasswordInputChange}
                                        />
                                        <ConfirmPassword form={form} hasNewPassword={hasNewPassword} />
                                    </React.Fragment>
                                )}
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} initialValue={initialValues.get('profileImage')} />
                            </LayoutItem>
                        </Layout>
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <Telephone form={form} initialValue={initialValues.get('telephone') || ''} />
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <div className="o-input_wrapper">
                                            <label htmlFor="yearsOfExperience" className="o-label">
                                                Years of Experience
                                            </label>
                                            {getFieldDecorator('yearsOfExperience', {
                                                initialValue: initialValues.get('yearsOfExperience') || '',
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    switch (true) {
                                                                        case parseInt(value) < 0:
                                                                            callback(
                                                                                'Are you sure about that? That seems like less than we expected'
                                                                            )
                                                                            break
                                                                        case parseInt(value) === 69:
                                                                            callback('Hehe!')
                                                                            break
                                                                        case parseInt(value) > 60:
                                                                            callback('This is really unlikely!')
                                                                            break
                                                                        default:
                                                                            callback()
                                                                    }
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            })(
                                                <input
                                                    type="number"
                                                    id="yearsOfExperience"
                                                    className="o-input"
                                                    min="0"
                                                    max="100"
                                                />
                                            )}
                                            <div className="o-input_error">{getFieldError('yearsOfExperience')}</div>
                                        </div>
                                    </LayoutItem>
                                    <LayoutItem>
                                        <div className="o-input_wrapper">
                                            <label htmlFor="highestCertification" className="o-label">
                                                Highest Certification
                                            </label>
                                            {getFieldDecorator('highestCertification', {
                                                initialValue: initialValues.get('highestCertification') || '',
                                            })(<input type="text" id="highestCertification" className="o-input" />)}
                                        </div>
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <div className="o-input_wrapper">
                                            <label htmlFor="certificationsCompleted" className="o-label">
                                                Certifications Completed
                                            </label>
                                            {getFieldDecorator('certificationsCompleted', {
                                                initialValue: initialValues.get('certificationsCompleted') || '',
                                            })(
                                                <input type="number" id="certificationsCompleted" className="o-input" />
                                            )}
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="biography" className="o-label">
                                        Bio
                                    </label>
                                    {getFieldDecorator('biography', {
                                        initialValue: initialValues.get('biography') || '',
                                    })(<textarea id="biography" className="o-textarea" rows="10"></textarea>)}
                                </div>
                            </LayoutItem>
                        </Layout>
                    </TrackVisibility>

                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        <FlexibleList
                            handleItemCreate={this.handleFlexibleListItemCreate}
                            handleItemDelete={this.handleFlexibleListItemDelete}
                            handleItemSort={this.handleFlexibleListItemSort}
                            headers={['#', 'Name']}
                            isCard={true}
                            isEditable={true}
                            items={certificationList}
                            title="Certifications"
                        />
                    </TrackVisibility>

                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small u-margin-small-y">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button className="o-button -huge" onClick={this.handleClickCancel} type="button">
                                    Cancel
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small u-margin-small-y">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button className="o-button -huge -green" type="submit">
                                    Save
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                    </Layout>
                    <div className="o-form_overlay">
                        <div className="o-form_spinner u-text-center">
                            <Spinner />
                        </div>
                    </div>
                </form>
                <Footer />
            </Main>
        )
    }
}

InstructorEditView.defaultProps = {
    title: (
        <React.Fragment>
            Edit <br />
            Instructor
        </React.Fragment>
    ),
}

const mapStateToProps = (state, ownProps) => {
    if (state.auth.user.pairedObjectId === ownProps.objectId) {
        return {
            isInstructorConnected: true,
            instructorId: ownProps.objectId,
        }
    } else {
        return {
            isInstructorConnected: false,
            instructorId: ownProps.match.params.instructorId,
        }
    }
}

export default connect(mapStateToProps)(createForm()(InstructorEditView))
