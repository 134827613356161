import React from 'react'

export default class extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 600)
    }

    render() {
        return null
    }
}
