import { get } from 'lodash-es'
import React from 'react'
import { Helmet } from 'react-helmet'

import { CertificateDetails, withCertificateDetailsData } from '../components/Certificate'
import { Footer } from '../components/Footer'
import { Main } from '../components/Main'

import shareImage from '../assets/images/opengraph-certificate.png'

const PublicCertificateDetailsView = ({ certificateData }) => {
    const student = get(certificateData, 'certificate.student', null)
    const sport = get(certificateData, 'certificate.sport', { name: '' })
    const title =
        student !== null ? `${student.firstName}'s ${sport.name} Certification` : `${sport.name} Certification`

    return (
        <Main isPublic={true}>
            <Helmet titleTemplate="%s | Report.Cards">
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:image" content={shareImage} />
                <meta name="description" content="Check out my amazing Certificate!" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.toString()} />
            </Helmet>
            <CertificateDetails {...certificateData} />
            <Footer />
        </Main>
    )
}

export default withCertificateDetailsData(true)(PublicCertificateDetailsView)
