import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'

const List = ({ button, children, overrideClass, title }) => (
    <div className={classNames('list', 'u-card', 'u-margin-y', overrideClass)}>
        <CardHeader>{title}</CardHeader>
        <div className="list_main">{children}</div>
        {button !== null && (
            <CardFooter>
                <Button route={button.route} overrideClass="card-footer_cta">
                    {button.label}
                </Button>
            </CardFooter>
        )}
    </div>
)

List.propTypes = {
    button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
    }),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    overrideClass: PropTypes.string,
    title: PropTypes.string.isRequired,
}

List.defaultProps = {
    button: null,
    overrideClass: '',
}

List.Placeholder = () => (
    <div className="list u-card u-margin-y">
        <ContentLoader height={640} width={831} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="30" y="99" width="171" height="86" rx="5" />
            <rect x="30" y="244" width="171" height="86" rx="5" />
            <rect x="30" y="23" width="250" height="23" />
            <rect x="0" y="68" width="831" height="1" />
            <rect x="31" y="214" width="769" height="1" />
            <rect x="31" y="359" width="769" height="1" />
            <rect x="229" y="130" width="250" height="23" />
            <rect x="229" y="275" width="202" height="23" />
            <rect x="630" y="135" width="29" height="14" />
            <rect x="630" y="280" width="29" height="14" />
            <rect x="726" y="135" width="74" height="14" />
            <rect x="737" y="280" width="63" height="14" />
            <rect x="30" y="389" width="171" height="86" rx="5" />
            <rect x="31" y="504" width="769" height="1" />
            <rect x="229" y="420" width="286" height="23" />
            <rect x="630" y="425" width="29" height="14" />
            <rect x="740" y="425" width="60" height="14" />
            <rect x="30" y="534" width="171" height="86" rx="5" />
            <rect x="229" y="565" width="158" height="23" />
            <rect x="630" y="570" width="29" height="14" />
            <rect x="726" y="570" width="74" height="14" />
        </ContentLoader>
    </div>
)

export default List
