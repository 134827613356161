import React from 'react'

import axios from 'axios'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { connect } from 'react-redux'

import { fetchPublicReport } from '../../actions/reportActions'
import {
    galleryStates,
    loadGallery,
    loadMessages,
    loadLatestStickers,
    loadReport,
    loadReportStickers,
    loadTasks,
    messagesStates,
    reportStates,
    stickersStates,
    tasksStates,
} from '../../helpers/ReportHelpers'
import { fetchStickers } from '../../actions/stickerActions'

const withReportDetailsData = (isPublic = false) => WrappedComponent => {
    class EnhancedComponent extends React.Component {
        signal = axios.CancelToken.source()

        state = {
            errors: [],
            ...galleryStates,
            ...messagesStates,
            ...reportStates,
            ...stickersStates,
            ...tasksStates,
        }

        loadPublicReport(reportId) {
            this.setState(
                {
                    isLoadingGallery: true,
                    isLoadingLatestStickers: true,
                    isLoadingMessages: true,
                    isLoadingReport: true,
                    isLoadingReportStickers: true,
                    isLoadingTasks: true,
                },
                () => {
                    fetchPublicReport(reportId, {
                        cancelToken: this.signal.token,
                    })
                        .then(({ data }) => {
                            this.setState({
                                gallery: data.gallery,
                                latestStickers: data.latestStickers,
                                messages: data.messages,
                                report: data.report,
                                reportStickers: data.reportStickers,
                                tasks: data.tasks,
                            })
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                this.setState({
                                    error: error,
                                })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoadingGallery: false,
                                isLoadingLatestStickers: false,
                                isLoadingMessages: false,
                                isLoadingReport: false,
                                isLoadingReportStickers: false,
                                isLoadingTasks: false,
                            })
                        })
                }
            )
        }

        componentDidMount() {
            const {
                dispatch,
                match: {
                    params: { reportId },
                },
            } = this.props

            if (isPublic === true) {
                this.loadPublicReport(reportId)
            } else {
                Promise.all([
                    loadGallery.call(this, reportId),
                    loadMessages.call(this, reportId),
                    loadReport.call(this, reportId),
                    loadReportStickers.call(this, reportId),
                    loadLatestStickers.call(this, reportId),
                    loadTasks.call(this, reportId),
                ])
            }

            dispatch(fetchStickers({ cancelToken: this.signal.token }))
        }

        componentWillUnmount() {
            this.signal.cancel('API call is being canceled.')
        }

        render() {
            return <WrappedComponent reportData={this.state} {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        isLoadingStickers: state.requestStates.FETCH_STICKER_LIST === true,
        stickers: state.stickers,
        user: state.auth.user,
    })

    hoistNonReactStatics(EnhancedComponent, WrappedComponent)
    return connect(mapStateToProps)(EnhancedComponent)
}

export default withReportDetailsData
