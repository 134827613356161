import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Layout, LayoutItem } from '../Layout'

const FilteredListItem = ({ headerCount, item }) => (
    <article className="filtered-list_item">
        <RouterLink className="filtered-list_item_link u-padding-tiny-y" to={item.url}>
            <Layout>
                {Object.entries(item.columns).map(([header, value], index) => (
                    <LayoutItem overrideClass={`u-1/${headerCount}@from-small`} key={index}>
                        <span className={index > 0 ? 'u-blue' : ''}>{value}</span>
                    </LayoutItem>
                ))}
            </Layout>
        </RouterLink>
    </article>
)

FilteredListItem.propTypes = {
    headerCount: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
}

export default FilteredListItem
