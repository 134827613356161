import { isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import Datetime from 'react-datetime'

import { datetimeAsString } from '../../utils/datetimes'
import pad from '../../utils/pad'
import { CardHeader } from '../Card'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'

class Schedule extends React.Component {
    static propTypes = {
        handleItemCreate: PropTypes.func,
        handleItemDelete: PropTypes.func,
        isEditable: PropTypes.bool,
        items: PropTypes.array.isRequired,
    }

    static defaultProps = {
        handleItemCreate: () => {},
        handleItemDelete: () => {},
        isEditable: false,
    }

    handleDatetimeChange = moment => {
        const {
            form: { setFieldsValue },
        } = this.props
        setFieldsValue({ startDate: moment.format('YYYY-MM-DD HH:mm') })
    }

    handleSubmit = event => {
        event.preventDefault()

        const {
            form: { resetFields, validateFields },
            handleItemCreate,
        } = this.props

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                handleItemCreate({
                    ...formValues,
                    duration: parseInt(formValues.duration),
                })
                resetFields()
            }
        })
    }

    handleItemDelete = event => {
        const { handleItemDelete } = this.props
        const itemIndex = parseInt(event.currentTarget.getAttribute('data-index'))
        handleItemDelete(itemIndex)
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldsError },
            items,
        } = this.props
        const errors = map(getFieldsError()).filter(item => !isEmpty(item))
        const hasErrors = errors.length > 0
        const { moment } = Datetime

        const currentTimestamp = moment().valueOf()
        const formattedItems = items.map(item => {
            const startDateMoment = moment(datetimeAsString(item.startDate))
            return {
                ...item,
                duration: moment.utc(moment.duration(item.duration, 'minutes').asMilliseconds()).format('HH[h]mm'),
                isCompleted: currentTimestamp > startDateMoment.valueOf(),
                startDate: startDateMoment.format('ll HH:mm'),
            }
        })

        let now = new Date()
        now.setHours(8)
        now.setMinutes(0)

        return (
            <div className="schedule u-card u-margin-y">
                <CardHeader>Schedule</CardHeader>
                <form className="u-padding-small" onSubmit={this.handleSubmit}>
                    <p className="schedule_subtitle">Add a Course</p>
                    <Layout overrideClass="-gutter-small -bottom">
                        <LayoutItem overrideClass="u-1/4@from-medium u-1/2@from-small-to-medium">
                            <div className="o-input_wrapper">
                                <label className="o-label">Pick a date and time</label>
                                <div className="u-relative">
                                    <Datetime
                                        className="o-input"
                                        dateFormat="ll"
                                        inputProps={{ readOnly: true }}
                                        onChange={this.handleDatetimeChange}
                                        timeFormat="HH:mm"
                                        defaultValue={now}
                                        timeConstraints={{
                                            minutes: {
                                                step: 15,
                                            },
                                        }}
                                    />
                                    <span className="o-input_icon">
                                        <Icon name="calendar" />
                                    </span>
                                    {getFieldDecorator('startDate', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Required field',
                                            },
                                        ],
                                    })(<input type="text" hidden={true} />)}
                                </div>
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-medium u-1/2@from-small-to-medium">
                            <div className="o-input_wrapper">
                                <label htmlFor="duration" className="o-label">
                                    Duration
                                </label>
                                <div className="o-select_wrapper">
                                    {getFieldDecorator('duration', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Required field',
                                            },
                                        ],
                                        type: 'number',
                                    })(
                                        <select id="duration" className="o-select">
                                            <option value="" disabled>
                                                Pick a duration
                                            </option>
                                            <option value="15">00:15</option>
                                            <option value="30">00:30</option>
                                            <option value="45">00:45</option>
                                            <option value="60">01:00</option>
                                            <option value="75">01:15</option>
                                            <option value="90">01:30</option>
                                            <option value="105">01:45</option>
                                            <option value="120">02:00</option>
                                        </select>
                                    )}
                                </div>
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-medium u-1/2@from-small-to-medium">
                            <div className="o-input_wrapper">
                                <label htmlFor="location" className="o-label">
                                    Location
                                </label>
                                {getFieldDecorator('location', {
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a curriculum',
                                        },
                                    ],
                                })(<input type="text" id="location" placeholder="Gym 1" className="o-input" />)}
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-medium u-1/2@from-small-to-medium">
                            <div className="o-input_wrapper">
                                <button type="submit" className="icon-button">
                                    <Icon name="plus" />
                                </button>
                            </div>
                        </LayoutItem>
                        {hasErrors && (
                            <LayoutItem>
                                <div className="o-input_error">One or more fields are empty.</div>
                            </LayoutItem>
                        )}
                    </Layout>
                </form>
                <div className="schedule_wrapper">
                    <div className="schedule_header u-padding-small-x">
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/4@from-small u-1/8@to-small u-text-center@to-small">
                                <span className="u-none@to-small">Course</span>#
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">Date & Time</LayoutItem>
                            <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">Duration</LayoutItem>
                            <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">Location</LayoutItem>
                        </Layout>
                    </div>
                    {formattedItems.length > 0 ? (
                        <ul className="schedule_list">
                            {formattedItems.map((item, index) => (
                                <li key={index} className="schedule_list_item u-padding-small-x">
                                    <Layout overrideClass="-gutter-small -middle">
                                        <LayoutItem overrideClass="u-1/4@from-small u-1/8@to-small u-text-center@to-small">
                                            {item.isCompleted ? (
                                                <span className="schedule_check">
                                                    <Icon name="check" overrideClass="schedule_check_icon" />
                                                </span>
                                            ) : (
                                                pad(index + 1, 2)
                                            )}
                                        </LayoutItem>
                                        <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">
                                            {item.startDate}
                                        </LayoutItem>
                                        <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">
                                            {item.duration}
                                        </LayoutItem>
                                        <LayoutItem overrideClass="u-1/4@from-small u-2/8@to-small">
                                            {item.location}
                                        </LayoutItem>
                                    </Layout>
                                    <button
                                        className="schedule_delete"
                                        onClick={this.handleItemDelete}
                                        data-index={index}
                                        type="button"
                                    >
                                        <Icon name="close" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="schedule_empty">Course list is empty</p>
                    )}
                </div>
            </div>
        )
    }
}

export default createForm()(Schedule)
