import React from 'react'

import { Layout, LayoutItem } from '../Layout'
import classNames from 'classnames'

const Message = ({ onSuccess, title, message, closeLabel, type }) => {
    return (
        <div className="o-modal_wrapper">
            {title && (
                <header className="o-modal_header">
                    <span
                        className={classNames('o-h2', {
                            'u-blue': type === 'success',
                            'u-red': type === 'warning',
                        })}
                    >
                        {title}
                    </span>
                </header>
            )}
            <div className="o-modal_content">
                <p>{message}</p>

                <div className="o-modal_actions">
                    <Layout overrideClass="-gutter-small -middle u-text-center">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <button
                                autoFocus
                                className={classNames('o-button', '-full', {
                                    '-red': type === 'warning',
                                })}
                                type="button"
                                onClick={() => onSuccess()}
                            >
                                <span className="o-button_inner">{closeLabel}</span>
                            </button>
                        </LayoutItem>
                    </Layout>
                </div>
            </div>
        </div>
    )
}

Message.defaultProps = {
    title: null,
    type: 'success',
    closeLabel: 'Close',
}

export default Message
