import axios from 'axios'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchSessions } from '../actions/sessionActions'
import { isUserAdministrator, userCanDo } from '../helpers/auth'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { SessionCardContainer, SessionListContainer } from '../components/Session'
import { TrackVisibility } from '../components/TrackVisibility'
import { Spinner } from '../components/Spinner'
import { Fade } from '../components/Fade'

class SessionListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch, fetchMethod, user } = this.props

        if (!isUserAdministrator(user)) {
            dispatch(fetchMethod({ cancelToken: this.signal.token }))
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { emptyLabel, fetchMethod, requestState, sessions, storeKey, title, user, isLoading } = this.props
        const isDraft = storeKey !== 'sessions'

        return (
            <Main>
                <div>
                    <Header>{title}</Header>
                    {isUserAdministrator(user) ? (
                        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                            <SessionListContainer
                                fetchMethod={fetchMethod}
                                listProps={{
                                    emptyLabel: emptyLabel,
                                    hasFooter: userCanDo(user, 'report-cards/object/session.create'),
                                }}
                                requestState={requestState}
                                storeKey={storeKey}
                                isDraft={isDraft}
                            />
                        </TrackVisibility>
                    ) : (
                        <SwitchTransition>
                            <Fade timeout={150} key={isLoading}>
                                {isLoading ? (
                                    <div className="u-text-center">
                                        <Spinner />
                                    </div>
                                ) : !isEmpty(sessions) ? (
                                    sessions.map((session, index) => (
                                        <TrackVisibility overrideClass="u-anim-scroll -delay-1" key={index}>
                                            <SessionCardContainer
                                                cardProps={{ isFull: true }}
                                                isDraft={isDraft}
                                                session={session}
                                            />
                                        </TrackVisibility>
                                    ))
                                ) : (
                                    <TrackVisibility overrideClass="u-anim-scroll">
                                        <p>{emptyLabel}</p>
                                    </TrackVisibility>
                                )}
                            </Fade>
                        </SwitchTransition>
                    )}
                </div>
                <Footer />
            </Main>
        )
    }
}

SessionListView.propTypes = {
    emptyLabel: PropTypes.string,
    fetchMethod: PropTypes.func,
    requestState: PropTypes.string,
    storeKey: PropTypes.string,
    title: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
    const sessions = state[ownProps.storeKey].entities
    return {
        isLoading: state.requestStates[ownProps.requestState] === true,
        sessions: sessions,
        user: state.auth.user,
    }
}

const ConnectedSessionListView = connect(mapStateToProps)(SessionListView)

ConnectedSessionListView.defaultProps = {
    emptyLabel: 'No active sessions to display.',
    fetchMethod: fetchSessions,
    requestState: 'FETCH_SESSION_LIST',
    storeKey: 'sessions',
    title: 'Active Sessions',
}

export default ConnectedSessionListView
