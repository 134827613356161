import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Layout, LayoutItem } from '../Layout'
import { UserImage } from '../User'

const ListUserItem = ({ url, user, details }) => (
    <article className="list_item">
        <RouterLink className="list_item_link u-padding-small-x" to={url}>
            <div className="list_item_link_inner u-padding-small-y">
                <Layout overrideClass="-gutter-small -middle">
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <div className="u-inline-block u-align-middle">
                            <UserImage user={user} overrideClass={'user-image'} />
                        </div>
                        <h1 className="list_item_title o-h2 u-inline-block u-align-middle u-margin-none u-margin-small-x">
                            {user.firstName} <br />
                            {user.lastName}
                        </h1>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <Layout overrideClass="-gutter-small">
                            {details.map(item => (
                                <LayoutItem overrideClass="u-1/2@from-small" key={item.id}>
                                    {item.label} <br />
                                    <span className="u-blue">{user[item.id]}</span>
                                </LayoutItem>
                            ))}
                        </Layout>
                    </LayoutItem>
                </Layout>
            </div>
        </RouterLink>
    </article>
)

ListUserItem.propTypes = {
    url: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    details: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })
    ),
}

ListUserItem.defaultProps = {
    details: [],
}

export default ListUserItem
