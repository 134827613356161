import React from 'react'

import { connect } from 'react-redux'

import { Header } from '../components/Header'
import { DashboardAdministrator, DashboardInstructor, DashboardStudent, DashboardParent } from '../components/Dashboard'
import { Footer } from '../components/Footer'
import { Main } from '../components/Main'

const DASHBOARDS = {
    administrator: DashboardAdministrator,
    instructor: DashboardInstructor,
    student: DashboardStudent,
    parent: DashboardParent,
}

const DashboardView = ({ dashboard: DashboardComponent, userFirstname }) => (
    <Main>
        <Header>
            Hello,
            <br /> {userFirstname}
        </Header>
        <DashboardComponent />
        <Footer />
    </Main>
)

const mapStateToProps = state => {
    const {
        auth: { user },
    } = state
    return {
        dashboard: DASHBOARDS[user.role.id],
        userFirstname: user.firstName,
    }
}

export default connect(mapStateToProps)(DashboardView)
