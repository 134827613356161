import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'

import { fetchAllStudents } from '../actions/studentActions'
import { userCanDo } from '../helpers/auth'

import UserListView from './UserListView'

class AllStudentListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchAllStudents({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { user, ...rest } = this.props
        const title = 'All Students'

        return (
            <UserListView
                {...rest}
                detailsRoute={{
                    id: 'student-details',
                    bind: 'studentId',
                }}
                listTitle="Students"
                hasFooter={userCanDo(user, 'report-cards/object/student.create')}
                buttonData={{
                    label: 'Create a student',
                    route: 'student-create',
                }}
                title={title}
            />
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.requestStates.FETCH_ALL_STUDENT_LIST === true,
    items: state.allStudents.entities,
    user: state.auth.user,
})

export default connect(mapStateToProps)(AllStudentListView)
