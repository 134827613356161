import React from 'react'

import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

import { CardHeader } from '../Card'
import { FlexibleList } from '../FlexibleList'
import { Layout, LayoutItem } from '../Layout'
import { Logo } from '../Logo'
import { SimpleList } from '../SimpleList'

const CurriculumCard = ({ curriculum }) => (
    <section className="u-card u-margin-y u-relative">
        <CardHeader>
            <Logo /> {curriculum.name}
        </CardHeader>
        <Layout overrideClass="-gutter-small u-padding-small-y u-padding-small-x">
            <LayoutItem overrideClass="u-1/2@from-small">
                <div className="o-image -rounded">
                    <img src={curriculum.programLogo.url} alt="" />
                </div>
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <SimpleList
                    items={[
                        {
                            label: 'Program Name',
                            value: curriculum.programName,
                        },
                        { label: 'Code', value: curriculum.certificationCode },
                    ]}
                />
            </LayoutItem>
        </Layout>
        <FlexibleList headers={['#', 'Description']} items={curriculum.tasks} title="Steps" />
    </section>
)

CurriculumCard.propType = {
    curriculum: PropTypes.object.isRequired,
}

CurriculumCard.Placeholder = () => (
    <div className="session-card u-card u-margin-y">
        <ContentLoader height={730} width={780} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="18" y="22" rx="0" ry="0" width="243" height="24" />
            <rect x="0" y="58" rx="0" ry="0" width="780" height="2" />
            <rect x="20" y="88" rx="5" ry="5" width="360" height="204" />
            <rect x="400" y="97" rx="0" ry="0" width="99" height="12" />
            <rect x="400" y="137" rx="0" ry="0" width="99" height="12" />
            <rect x="400" y="179" rx="0" ry="0" width="99" height="12" />
            <rect x="400" y="222" rx="0" ry="0" width="99" height="12" />
            <rect x="680" y="97" rx="0" ry="0" width="77" height="12" />
            <rect x="744" y="137" rx="0" ry="0" width="13" height="12" />
            <rect x="656" y="179" rx="0" ry="0" width="102" height="12" />
            <rect x="740" y="222" rx="0" ry="0" width="18" height="12" />
            <rect x="400" y="122" rx="0" ry="0" width="358" height="2" />
            <rect x="400" y="162" rx="0" ry="0" width="358" height="2" />
            <rect x="400" y="205" rx="0" ry="0" width="358" height="2" />
            <rect x="19" y="322" rx="0" ry="0" width="118" height="22" />
            <rect x="0" y="354" rx="0" ry="0" width="780" height="31" />
            <rect x="22" y="404" rx="0" ry="0" width="15" height="12" />
            <rect x="20" y="430" rx="0" ry="0" width="740" height="2" />
            <rect x="200" y="404" rx="0" ry="0" width="477" height="12" />
            <rect x="22" y="447" rx="0" ry="0" width="15" height="12" />
            <rect x="200" y="447" rx="0" ry="0" width="431" height="12" />
        </ContentLoader>
    </div>
)

export default CurriculumCard
