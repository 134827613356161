import arrayMove from 'array-move'
import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import Immutable from 'immutable'
import { isEmpty, map, pick } from 'lodash-es'
import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'

import { createCurriculum, updateCurriculum } from '../../actions/curriculumActions'
import { fetchCurriculumPreset, fetchCurriculumPresets } from '../../actions/curriculumPresetActions'
import { apiClient } from '../../helpers/ApiClient'
import { getHistory } from '../../store'
import { formatFormMessages } from '../../utils/forms'
import { renderRoute } from '../../utils/routes'
import { Dropzone } from '../Dropzone'
import { FlexibleList } from '../FlexibleList'
import { ProgramLogo, ProgramName } from '../FormFields'
import { FormMessages } from '../FormMessages'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'
import { Spinner } from '../Spinner'
import { TrackVisibility } from '../TrackVisibility'

class CurriculumForm extends React.Component {
    state = {
        curriculumPresetValue: '',
        errors: [],
        initialValues: Immutable.Map({}),
        isFormSubmitted: false,
        isSavingCurriculum: false,
        tasks: [],
    }

    signal = axios.CancelToken.source()

    handleChangePreset = event => {
        const {
            target: { value: curriculumPresetId },
        } = event
        this.setState({ curriculumPresetValue: curriculumPresetId }, () => {
            if (!isEmpty(curriculumPresetId)) {
                this.setState({ isSavingCurriculum: true }, () => {
                    fetchCurriculumPreset(curriculumPresetId, {
                        cancelToken: this.signal.token,
                    })
                        .then(response => {
                            this.setCurriculumFormValues(response.data)
                            this.setState({
                                errors: [],
                                isSavingCurriculum: false,
                            })
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                this.setState({
                                    errors: error.response.data,
                                    isSavingCurriculum: false,
                                })
                            }
                        })
                        .finally(() => window.scrollTo(0, 0))
                })
            } else {
                this.setCurriculumFormValues(null)
                window.scrollTo(0, 0)
            }
        })
    }

    handleClickCancel = event => {
        getHistory().goBack()
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            curriculum,
        } = this.props
        const { isSavingCurriculum, tasks } = this.state

        if (isSavingCurriculum) {
            return false
        }

        const hasCurriculum = curriculum !== null
        const action = hasCurriculum ? updateCurriculum : createCurriculum
        const firstParam = hasCurriculum ? [curriculum.id] : []

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingCurriculum: true,
                })
                const actionParams = [
                    ...firstParam,
                    {
                        ...formValues,
                        tasks: tasks,
                    },
                    { cancelToken: this.signal.token },
                ]
                action(...actionParams)
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingCurriculum: false,
                        })
                        apiClient.clearCache('curriculums')
                        if (hasCurriculum) {
                            setFieldsValue({
                                certificationImage: response.data.certificationImage.path,
                                programLogo: response.data.programLogo.path,
                            })
                        } else {
                            dispatch(
                                push(
                                    renderRoute({
                                        id: 'curriculum-edit',
                                        bind: 'curriculumId',
                                        param: response.data.id,
                                    })
                                )
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingCurriculum: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    handleFlexibleListItemCreate = newItem => {
        this.setState(({ tasks }) => ({
            tasks: [...tasks, newItem],
        }))
    }

    handleFlexibleListItemDelete = itemIndex => {
        this.setState(({ tasks }) => ({
            tasks: tasks.filter((item, index) => itemIndex !== index),
        }))
    }

    handleFlexibleListItemSort = (oldIndex, newIndex) => {
        this.setState(({ tasks }) => ({
            tasks: arrayMove(tasks, oldIndex, newIndex),
        }))
    }

    setCurriculumFormValues(values) {
        if (isEmpty(values)) {
            // @todo Do we really want to erase the form?
            // this.setState({
            //     initialValues: Immutable.Map({}),
            // })
        } else {
            this.setState({
                initialValues: Immutable.Map({
                    ...values,
                    programLogo: values.programLogo.path,
                    certificationImage: values.certificationImage.path,
                }),
                tasks: map(values.tasks, task => pick(task, 'description')),
            })
        }
    }

    componentDidMount() {
        const { curriculum, dispatch } = this.props

        dispatch(fetchCurriculumPresets({ cancelToken: this.signal.token }))

        if (curriculum !== null) {
            this.setCurriculumFormValues(curriculum)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            curriculumPresets,
            form,
            form: { getFieldDecorator, getFieldError },
            curriculum,
        } = this.props
        const { curriculumPresetValue, errors, initialValues, isFormSubmitted, isSavingCurriculum, tasks } = this.state
        const hasCurriculum = curriculum !== null
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? [{ message: 'Curriculum has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)
        const layoutClassPresetRow = hasCurriculum ? '' : 'u-1/2@from-small'

        return (
            <div
                className={classNames('o-form', {
                    'is-loading': isSavingCurriculum,
                })}
            >
                <form onSubmit={this.handleSubmit}>
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isFormSubmitted} items={notifications} />
                        </div>
                    )}
                    <Layout overrideClass="-gutter-small">
                        {!hasCurriculum && (
                            <LayoutItem overrideClass={layoutClassPresetRow}>
                                <div className="o-input_wrapper">
                                    <Label inputId="curriculumPreset">Choose a preset</Label>
                                    <div className="o-select_wrapper">
                                        <select
                                            id="curriculumPreset"
                                            className="o-select"
                                            onChange={this.handleChangePreset}
                                            value={curriculumPresetValue}
                                            disabled={curriculumPresetValue !== ''}
                                        >
                                            <option value="">None</option>
                                            {curriculumPresets.map(item => (
                                                <option value={item.id} key={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </LayoutItem>
                        )}
                        <LayoutItem overrideClass={layoutClassPresetRow}>
                            <div className="o-input_wrapper">
                                <Label inputId="name" isRequired={true}>
                                    Name of the Curriculum
                                </Label>
                                {getFieldDecorator('name', {
                                    initialValue: initialValues.get('name') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a name',
                                        },
                                    ],
                                })(<input className="o-input" type="text" id="name" />)}
                                <div className="o-input_error">{getFieldError('name')}</div>
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <ProgramLogo
                                form={form}
                                initialValue={initialValues.get('programLogo') || ''}
                                isRequired={true}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label isRequired={true}>Certification Image</Label>
                                <Dropzone
                                    form={form}
                                    initialFiles={initialValues.get('certificationImage') || ''}
                                    initialValue={initialValues.get('certificationImage') || ''}
                                    inputName="certificationImage"
                                    recommendedSize="1920&times;1080px"
                                />
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <ProgramName
                                form={form}
                                initialValue={initialValues.get('programName') || ''}
                                isRequired={true}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label inputId="certificationCode" isRequired={true}>
                                    Certification Code
                                </Label>
                                {getFieldDecorator('certificationCode', {
                                    initialValue: initialValues.get('certificationCode') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a certification code',
                                        },
                                    ],
                                })(<input className="o-input" type="text" id="certificationCode" />)}
                                <div className="o-input_error">{getFieldError('certificationCode')}</div>
                            </div>
                        </LayoutItem>
                    </Layout>

                    <TrackVisibility overrideClass="u-anim-scroll">
                        <FlexibleList
                            handleItemCreate={this.handleFlexibleListItemCreate}
                            handleItemDelete={this.handleFlexibleListItemDelete}
                            handleItemSort={this.handleFlexibleListItemSort}
                            headers={['#', 'Description']}
                            isCard={true}
                            isEditable={true}
                            items={tasks}
                            title="Steps"
                        />
                    </TrackVisibility>

                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button
                                    className="o-button -huge u-margin-tiny-y"
                                    onClick={this.handleClickCancel}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll">
                                <button className="o-button -huge -green u-margin-tiny-y" type="submit">
                                    {hasCurriculum ? 'Save Curriculum' : 'Create Curriculum'}
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                    </Layout>
                </form>
                <div className="o-form_overlay">
                    <div className="o-form_spinner u-text-center">
                        <Spinner />
                    </div>
                </div>
            </div>
        )
    }
}

CurriculumForm.Placeholder = () => (
    <ContentLoader height={978} width={1000} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="27" rx="5" ry="5" width="1000" height="48" />
        {/*<rect x="775" y="27" rx="5" ry="5" width="224" height="48" />*/}
        <rect x="0" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="517" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="0" y="219" rx="5" ry="5" width="1000" height="48" />
        <rect x="0" y="316" rx="5" ry="5" width="1000" height="114" />
        <rect x="34" y="723" rx="5" ry="5" width="210" height="46" />
        <rect x="277" y="723" rx="5" ry="5" width="206" height="49" />
        <rect x="516" y="723" rx="5" ry="5" width="209" height="47" />
        <circle cx="785" cy="740" r="30" />
        <rect x="0" y="802" rx="0" ry="0" width="1000" height="53" />
        <rect x="0" y="6" rx="0" ry="0" width="112" height="13" />
        {/*<rect x="775" y="6" rx="0" ry="0" width="112" height="13" />*/}
        <rect x="0" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="517" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="0" y="196" rx="0" ry="0" width="112" height="13" />
        <rect x="1" y="290" rx="0" ry="0" width="112" height="13" />
        <rect x="34" y="545" rx="0" ry="0" width="115" height="29" />
        <rect x="0" y="516" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="595" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="998" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="34" y="635" rx="0" ry="0" width="106" height="23" />
        <rect x="34" y="701" rx="0" ry="0" width="132" height="13" />
        <rect x="277" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="517" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="275" y="875" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="875" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="875" rx="0" ry="0" width="128" height="21" />
        <rect x="27" y="867" rx="0" ry="0" width="34" height="34" />
        <rect x="0" y="913" rx="0" ry="0" width="1000" height="2" />
        <rect x="27" y="928" rx="0" ry="0" width="34" height="34" />
        <rect x="275" y="934" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="934" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="934" rx="0" ry="0" width="128" height="21" />
    </ContentLoader>
)

CurriculumForm.propTypes = {
    curriculum: PropTypes.object,
}

CurriculumForm.defaultProps = {
    curriculum: null,
}

const mapStateToProps = state => ({
    curriculumPresets: state.curriculumPresets.entities,
})

export default connect(mapStateToProps)(createForm()(CurriculumForm))
