import axios from 'axios'
import { uniqWith } from 'lodash-es'

import {
    fetchReport,
    fetchReportLatestStickers,
    fetchReportMedias,
    fetchReportMessages,
    fetchReportStickers,
    fetchReportTasks,
} from '../actions/reportActions'

export const tasksStates = {
    tasks: [],
    isLoadingTasks: true,
}

export const reportStates = {
    report: null,
    isLoadingReport: true,
}

export const galleryStates = {
    gallery: [],
    isLoadingGallery: true,
}

export const stickersStates = {
    latestStickers: [],
    reportStickers: [],
    isLoadingLatestStickers: true,
    isLoadingReportStickers: true,
}

export const messagesStates = {
    messages: [],
    isLoadingMessages: true,
}

export function loadTasks(reportId) {
    this.setState({ isLoadingTasks: true }, () => {
        return fetchReportTasks(reportId, {
            cancelToken: this.signal.token,
        })
            .then(({ data }) => {
                this.setState({
                    tasks: data,
                    isLoadingTasks: false,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: uniqWith(
                            [...this.state.errors, error.response.data],
                            (val, newVal) => val.message === newVal.message
                        ),
                        isLoadingTasks: false,
                    })
                }
            })
    })
}

export function loadReport(reportId) {
    this.setState({ isLoadingReport: true }, () => {
        return fetchReport(reportId, { cancelToken: this.signal.token })
            .then(({ data }) => {
                this.setState({
                    report: data,
                    isLoadingReport: false,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: uniqWith(
                            [...this.state.errors, error.response.data],
                            (val, newVal) => val.message === newVal.message
                        ),
                        isLoadingReport: false,
                    })
                }
            })
    })
}

export function loadGallery(reportId) {
    this.setState({ isLoadingGallery: true }, () => {
        return fetchReportMedias(reportId, {
            cancelToken: this.signal.token,
        })
            .then(({ data }) => {
                this.setState({
                    gallery: data,
                    isLoadingGallery: false,
                })
            })
            .catch(error => {
                this.setState({
                    errors: uniqWith(
                        [...this.state.errors, error.response.data],
                        (val, newVal) => val.message === newVal.message
                    ),
                    isLoadingGallery: false,
                })
            })
    })
}

export function loadLatestStickers(reportId) {
    this.setState({ isLoadingLatestStickers: true }, () => {
        return fetchReportLatestStickers(reportId, {
            cancelToken: this.signal.token,
        })
            .then(({ data }) => {
                this.setState({
                    latestStickers: data,
                    isLoadingLatestStickers: false,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: uniqWith(
                            [...this.state.errors, error.response.data],
                            (val, newVal) => val.message === newVal.message
                        ),
                        isLoadingLatestStickers: false,
                    })
                }
            })
    })
}

export function loadReportStickers(reportId) {
    this.setState({ isLoadingReportStickers: true }, () => {
        return fetchReportStickers(reportId, {
            cancelToken: this.signal.token,
        })
            .then(({ data }) => {
                this.setState({
                    reportStickers: data,
                    isLoadingReportStickers: false,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: uniqWith(
                            [...this.state.errors, error.response.data],
                            (val, newVal) => val.message === newVal.message
                        ),
                        isLoadingReportStickers: false,
                    })
                }
            })
    })
}

export function loadMessages(reportId) {
    this.setState({ isLoadingMessages: true }, () => {
        return fetchReportMessages(reportId, {
            cancelToken: this.signal.token,
        })
            .then(({ data }) => {
                this.setState({
                    messages: data,
                    isLoadingMessages: false,
                })
            })
            .catch(error => {
                this.setState({
                    errors: uniqWith(
                        [...this.state.errors, error.response.data],
                        (val, newVal) => val.message === newVal.message
                    ),
                    isLoadingMessages: false,
                })
            })
    })
}
