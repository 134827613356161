import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'session-drafts'

/**
 * Fetch a list of all draft sessions for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_SESSION_DRAFT_LIST_REQUEST = 'FETCH_SESSION_DRAFT_LIST_REQUEST'
export const FETCH_SESSION_DRAFT_LIST_SUCCESS = 'FETCH_SESSION_DRAFT_LIST_SUCCESS'
export const FETCH_SESSION_DRAFT_LIST_FAILURE = 'FETCH_SESSION_DRAFT_LIST_FAILURE'
export const FETCH_SESSION_DRAFT_LIST_PENDING = 'FETCH_SESSION_DRAFT_LIST_PENDING'
export const fetchSessionDrafts = (config = {}) => {
    return {
        types: [
            FETCH_SESSION_DRAFT_LIST_REQUEST,
            FETCH_SESSION_DRAFT_LIST_SUCCESS,
            FETCH_SESSION_DRAFT_LIST_FAILURE,
            FETCH_SESSION_DRAFT_LIST_PENDING,
        ],
        promise: client => client.get(baseUrl, config),
    }
}

/**
 * Fetch a session draft.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionDraft = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}`, config)
}

/**
 * Create a new session draft.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createSessionDraft = (formData, config = {}) => {
    return apiClient.post(baseUrl, formData, config)
}

/**
 * Update a session draft.
 *
 * @param  {String} sessionId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateSessionDraft = (sessionId, formData, config = {}) => {
    return apiClient.patch(`${baseUrl}/${sessionId}`, formData, config)
}

/**
 * Fetch a list of a session draft's courses.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionDraftCourses = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}/courses`, config)
}

/**
 * Fetch a list of a session draft's students.
 *
 * @param  {String} sessionId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchSessionDraftStudents = (sessionId, config = {}) => {
    return apiClient.get(`${baseUrl}/${sessionId}/students`, config)
}
