import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { USER_LOGOUT } from '../actions/userActions'

import { requestStates } from './api'
import { auth } from './auth'
import { certificates } from './certificates'
import { curriculums } from './curriculums'
import { curriculumPresets } from './curriculumPresets'
import { instructors } from './instructors'
import { modal } from './modal'
import { organization } from './organization'
import { parents } from './parents'
import { plans } from './plans'
import { reports } from './reports'
import { sessions } from './sessions'
import { sessionDrafts } from './sessionDrafts'
import { skus } from './skus'
import { students } from './students'
import { allStudents } from './allStudents'
import { studentMedals } from './studentMedals'
import { stickers } from './stickers'

const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth,
        certificates,
        curriculums,
        curriculumPresets,
        instructors,
        modal,
        organization,
        parents,
        plans,
        reports,
        sessions,
        sessionDrafts,
        skus,
        students,
        allStudents,
        studentMedals,
        stickers,
        requestStates,
    })

/** If you want to keep some data in store on USER_LOGOUT, here is where you do it. */
const rootReducer = history => (state, action) => {
    if (action.type === USER_LOGOUT) {
        const { organization } = state
        state = { organization }
    }
    return appReducer(history)(state, action)
}

export default rootReducer
