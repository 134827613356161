import PropTypes from 'prop-types'
import React from 'react'

import { Icon } from '../Icon'

class FilterableSelect extends React.Component {
    state = {
        isOpen: false,
    }

    handleInputBlur = event => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({
            isOpen: false,
        })
    }

    handleInputFocus = event => {
        this.setState({
            isOpen: true,
        })
    }

    handleMouseDownItem = event => {
        const { handleItemSelection } = this.props
        this.setState({
            isOpen: false,
        })
        handleItemSelection(event.currentTarget.getAttribute('data-id'))
    }

    render() {
        const { handleInputChange, inputValue, items } = this.props
        const { isOpen } = this.state

        const filteredItems = items.filter(item => {
            return item.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
        })

        return (
            <div className="filterable-select">
                <input
                    className="o-input"
                    onBlur={this.handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={this.handleInputFocus}
                    placeholder="Search"
                    type="text"
                    value={inputValue}
                />
                <span className="o-input_icon">
                    <Icon name="search" />
                </span>
                {isOpen && (
                    <ul className="filterable-select_list">
                        {filteredItems.map((item, index) => (
                            <li
                                className="filterable-select_list_item"
                                data-id={item.id}
                                key={index}
                                onMouseDown={this.handleMouseDownItem}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    }
}

FilterableSelect.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    handleItemSelection: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
}

export default FilterableSelect
