import axios from 'axios'
import { first, isEmpty, reverse, slice, sortBy } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchInstructors } from '../../actions/instructorActions'
import { fetchCertificates } from '../../actions/certificateActions'
import { fetchStudentMedals } from '../../actions/studentMedalActions'

import { DashboardCardLoader, DashboardInfos } from '../Dashboard'
import { InstructorCard } from '../InstructorCard'
import { Layout, LayoutItem } from '../Layout'
import { List, ListCertificateItem, ListMedalItem } from '../List'
import { ReportCard } from '../Report'
import { TrackVisibility } from '../TrackVisibility'
import { Fade } from '../Fade'

class DashboardStudent extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchInstructors({ cancelToken: this.signal.token }))
        dispatch(fetchCertificates({ cancelToken: this.signal.token }))
        dispatch(fetchStudentMedals({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            certificates,
            infoItems,
            instructor,
            isLoadingInstructor,
            isLoadingReport,
            isLoadingAchievements,
            medals,
            report,
        } = this.props

        return (
            <React.Fragment>
                <DashboardInfos items={infoItems} />
                <Layout overrideClass="-gutter-small">
                    {isLoadingReport ? (
                        <DashboardCardLoader overrideClass="u-1/2@from-small" />
                    ) : (
                        !isEmpty(report) && (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-1" key="target">
                                    <ReportCard report={report} />
                                </TrackVisibility>
                            </LayoutItem>
                        )
                    )}
                    {isLoadingInstructor ? (
                        <DashboardCardLoader overrideClass="u-1/2@from-small" />
                    ) : (
                        !isEmpty(instructor) && (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2" key="target">
                                    <InstructorCard instructor={instructor} />
                                </TrackVisibility>
                            </LayoutItem>
                        )
                    )}
                </Layout>
                <TrackVisibility overrideClass="u-anim-scroll -delay-3">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingAchievements}>
                            {isLoadingAchievements ? (
                                <List.Placeholder />
                            ) : (
                                <List
                                    button={{
                                        route: 'achievement-list',
                                        label: 'View achievements',
                                    }}
                                    title="My Achievements"
                                >
                                    {medals.map(studentMedal => (
                                        <ListMedalItem key={studentMedal.id} studentMedal={studentMedal} />
                                    ))}
                                    {certificates.map(certificate => (
                                        <ListCertificateItem key={certificate.id} studentCertificate={certificate} />
                                    ))}
                                </List>
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    let certificates = reverse(sortBy(state.certificates.entities, ['date.timestamp']))
    let studentMedals = reverse(sortBy(state.studentMedals.entities, ['date.timestamp']))
    studentMedals = slice(studentMedals, 0, 2)
    certificates = slice(certificates, 0, 3 - studentMedals.length)

    return {
        infoItems: [
            { label: 'Report Cards', value: state.reports.entities.length || '-' },
            { label: 'Certificates', value: state.certificates.entities.length || '-' },
        ],
        instructor: first(state.instructors.entities),
        isLoadingInstructor: state.requestStates.FETCH_INSTRUCTOR_LIST === true,
        report: first(state.reports.entities),
        isLoadingReport: state.requestStates.FETCH_REPORT_LIST === true,
        certificates: certificates,
        medals: studentMedals,
        isLoadingAchievements:
            state.requestStates.FETCH_CERTIFICATE_LIST === true || state.requestStates.FETCH_MEDAL_LIST === true,
    }
}

export default connect(mapStateToProps)(DashboardStudent)
