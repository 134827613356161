import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { fetchParents } from '../actions/parentActions'
import { deleteStudent, fetchAllStudents, fetchStudent, fetchStudents, updateStudent } from '../actions/studentActions'
import { fetchSelf } from '../actions/userActions'
import { apiClient } from '../helpers/ApiClient'
import { getHistory } from '../store'
import { datetimeAsString } from '../utils/datetimes'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { Footer } from '../components/Footer'
import { FormMessages } from '../components/FormMessages'
import {
    BirthDate,
    ConfirmPassword,
    CurrentPassword,
    Email,
    FirstName,
    LastName,
    NewPassword,
    ProfileImage,
} from '../components/FormFields'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { SearchableSelect } from '../components/SearchableSelect'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class StudentEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        errors: [],
        hasNewPassword: false,
        initialValues: Immutable.Map({}),
        isFormSubmitted: false,
        isSavingStudent: false,
        isStudentLoaded: false,
        isStudentLoading: true,
    }

    handleBirthDateDatetimeChange = moment => {
        const {
            form: { setFieldsValue },
        } = this.props
        setFieldsValue({ birthDate: moment.format('YYYY-MM-DD') })
    }

    handleNewPasswordInputChange = event => {
        const {
            form: { validateFields },
        } = this.props
        this.setState(
            {
                hasNewPassword: event.target.value !== '',
            },
            () => {
                validateFields(['passwordConfirmation'], { force: true })
            }
        )
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            isStudentConnected,
            studentId,
        } = this.props
        const { isSavingStudent } = this.state

        if (isSavingStudent) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingStudent: true,
                })
                updateStudent(studentId, formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            hasNewPassword: false,
                            isFormSubmitted: true,
                            isSavingStudent: false,
                        })
                        apiClient.clearCache('students')
                        dispatch(fetchStudents())
                        dispatch(fetchAllStudents())
                        /** Refresh the connected user. */
                        if (isStudentConnected) {
                            dispatch(fetchSelf())
                        }
                        const fieldValues = isStudentConnected
                            ? {
                                  currentPassword: '',
                                  password: '',
                                  passwordConfirmation: '',
                              }
                            : {}
                        setFieldsValue({
                            ...fieldValues,
                            profileImage: response.data.profileImage.path,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingStudent: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    setStudentFormValues(values) {
        this.setState({
            initialValues: Immutable.Map({
                ...values,
                birthDate: datetimeAsString(values.birthDate),
                profileImage: values.profileImage.path,
            }),
            isStudentLoaded: true,
        })
    }

    handleDelete = event => {
        const { dispatch, studentId } = this.props

        if (window.confirm('Are you sure you want to delete this account')) {
            this.setState({ isStudentLoading: true }, () => {
                deleteStudent(studentId, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState(
                            {
                                isStudentLoading: false,
                            },
                            () => {
                                dispatch(push(renderRoute('student-list')))
                            }
                        )
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            this.setState({
                                errors: error.response.data,
                                isStudentLoading: false,
                            })
                        }
                    })
            })
        }
    }

    componentDidMount() {
        const { studentId } = this.props

        this.setState({ isStudentLoading: true }, () => {
            fetchStudent(studentId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setStudentFormValues(response.data)
                    this.setState({
                        isStudentLoading: false,
                    })
                })
                .catch(error => {
                    console.warn(error)
                    if (!axios.isCancel(error)) {
                        window.scrollTo(0, 0)
                        this.setState({
                            errors: error.response.data,
                            isStudentLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form, isStudentConnected, title /*, user*/ } = this.props
        const {
            errors,
            hasNewPassword,
            initialValues,
            isFormSubmitted,
            isSavingStudent,
            isStudentLoaded,
            isStudentLoading,
        } = this.state

        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? isStudentConnected
                ? [{ message: 'Your info has been saved successfully.' }]
                : [{ message: 'Student has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <Main>
                <Header>{title}</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isFormSubmitted} items={notifications} />
                        </div>
                    )}
                    <form
                        autoComplete="off"
                        className={classNames('o-form', {
                            'is-loading': isStudentLoading || isSavingStudent,
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        <Layout overrideClass="-gutter-small">
                            {!isStudentConnected && (
                                <React.Fragment>
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <FirstName form={form} initialValue={initialValues.get('firstName') || ''} />
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <LastName form={form} initialValue={initialValues.get('lastName') || ''} />
                                    </LayoutItem>
                                </React.Fragment>
                            )}
                            <LayoutItem overrideClass="u-1/2@from-small">
                                {isStudentLoaded && !isStudentConnected && (
                                    <SearchableSelect
                                        fetchMethod={fetchParents}
                                        form={form}
                                        initialValue={initialValues.get('parents') || []}
                                        inputLabel="Parents"
                                        inputName="studentParentIds"
                                        requestState="FETCH_PARENT_LIST"
                                        storeKey="parents"
                                    />
                                )}
                                {!isStudentConnected && (
                                    <BirthDate
                                        displayPicker={isStudentLoaded}
                                        form={form}
                                        handleDatetimeChange={this.handleBirthDateDatetimeChange}
                                        initialValue={initialValues.get('birthDate') || ''}
                                    />
                                )}
                                <Email form={form} initialValue={initialValues.get('email')} />
                                {isStudentConnected && (
                                    <React.Fragment>
                                        <CurrentPassword form={form} hasNewPassword={hasNewPassword} />
                                        <NewPassword
                                            form={form}
                                            handleInputChange={this.handleNewPasswordInputChange}
                                        />
                                        <ConfirmPassword form={form} hasNewPassword={hasNewPassword} />
                                    </React.Fragment>
                                )}
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} initialValue={initialValues.get('profileImage')} />
                            </LayoutItem>
                        </Layout>
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <button className="o-button -huge" onClick={() => getHistory().goBack()} type="button">
                                        Cancel
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                    <button className="o-button -huge -green" type="submit">
                                        Save
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                        </Layout>
                        <div className="o-form_overlay">
                            <div className="o-form_spinner u-text-center">
                                <Spinner />
                            </div>
                        </div>
                    </form>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

StudentEditView.defaultProps = {
    title: (
        <React.Fragment>
            Edit <br />
            Student
        </React.Fragment>
    ),
}

const mapStateToProps = (state, ownProps) => {
    if (state.auth.user.pairedObjectId === ownProps.objectId) {
        return {
            isStudentConnected: true,
            studentId: ownProps.objectId,
        }
    } else {
        return {
            isStudentConnected: false,
            studentId: ownProps.match.params.studentId,
        }
    }
}

export default connect(mapStateToProps)(createForm()(StudentEditView))
