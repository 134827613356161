import React from 'react'

import classNames from 'classnames'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'
import { Link } from '../Link'
import { SimpleList } from '../SimpleList'
import { UserImage } from '../User'

const UserCard = ({ button, link, listItems, overrideClass, title, user }) => (
    <article className={classNames('user-card u-card', overrideClass)}>
        <CardHeader>{title}</CardHeader>
        <div className="u-padding-tiny-y u-padding-small-x">
            <div className="user-card_main">
                <div className="u-inline-block u-align-middle">
                    <UserImage user={user} overrideClass={'user-image'} />
                </div>
                <h1 className="o-h2 u-margin-tiny-y u-margin-small-x u-inline-block u-align-middle">
                    {user.firstName} <br />
                    {user.lastName}
                </h1>
            </div>
            <SimpleList items={listItems} />
        </div>
        <CardFooter>
            {button && (
                <div className="u-inline-block u-align-middle">
                    <Button type="button" route={button.route} overrideClass="card-footer_cta">
                        {button.label}
                    </Button>
                </div>
            )}
            {link && (
                <div className="u-inline-block u-align-middle">
                    <Link route={link.route}>{link.label}</Link>
                </div>
            )}
        </CardFooter>
    </article>
)

UserCard.propTypes = {
    button: PropTypes.object,
    link: PropTypes.object,
    listItems: PropTypes.array,
    overrideClass: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
}

UserCard.defaultProps = {
    button: {},
    link: {},
    listItems: [],
    overrideClass: '',
}

UserCard.Placeholder = ({ overrideClass }) => (
    <div className={`${overrideClass}`}>
        <ContentLoader
            className="user-card u-card"
            height={405}
            width={445}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="16" y="23" rx="4" ry="4" width="111" height="20" />
            <rect x="112" y="145" rx="3" ry="3" width="85" height="20" />
            <circle cx="57" cy="142" r="40" />
            <rect x="112" y="118" rx="0" ry="0" width="129" height="20" />
            <rect x="14" y="212" rx="0" ry="0" width="86" height="16" />
            <rect x="14" y="248" rx="0" ry="0" width="97" height="16" />
            <rect x="14" y="287" rx="0" ry="0" width="57" height="16" />
            <rect x="324" y="248" rx="0" ry="0" width="97" height="16" />
            <rect x="345" y="212" rx="0" ry="0" width="77" height="16" />
            <rect x="339" y="287" rx="0" ry="0" width="82" height="16" />
            <rect x="1" y="62" rx="2" ry="20" width="445" height="2" />
            <rect x="14" y="236" rx="2" ry="2" width="408" height="2" />
            <rect x="14" y="274" rx="2" ry="2" width="408" height="2" />
            <rect x="1" y="326" rx="0" ry="0" width="445" height="2" />
            <rect x="15" y="343" rx="5" ry="5" width="149" height="47" />
            <rect x="174" y="358" rx="0" ry="0" width="63" height="21" />
        </ContentLoader>
    </div>
)

export default UserCard
