import { find, has, isArray, isEmpty, isObject, isString } from 'lodash-es'

import navConfig from '../config/navConfig.json'
import { renderRoute } from './routes'

/**
 * Determine if a nav item is active based on the pathname.
 *
 * @param  {Object}  item
 * @param  {String}  pathname
 * @return {Boolean}
 */
function isNavItemActive(item, pathname) {
    let activeMatched = false

    if (isArray(item.match) && !isEmpty(item.match)) {
        const matched = find(item.match, function(match) {
            const regex = new RegExp(`^${renderRoute(match)}$`)
            return regex.test(pathname)
        })

        activeMatched = typeof matched !== 'undefined'
    }

    if (activeMatched) {
        return true
    }

    if (!isEmpty(item.url)) {
        return item.url === pathname
    } else {
        return false
    }
}

/**
 * Format a list of nav items based on the current pathname and their children or lack of.
 *
 * @param  {Array}  items
 * @param  {String} pathname
 * @return {array}
 */
function parseNavItemsForActiveState(items, pathname) {
    return items.map(item => {
        item.isActive = isNavItemActive(item, pathname)
        // item.isOpen = false
        item.hasActiveNavItems = false

        if (isArray(item.items)) {
            item.items = parseNavItemsForActiveState(item.items, pathname)
            const hasActiveNavItems = item.items.filter(item => item.isActive || item.hasActiveNavItems).length > 0
            // item.isOpen = hasActiveNavItems || item.items.filter(item => item.isOpen).length > 0
            item.hasActiveNavItems = hasActiveNavItems
        }

        return item
    })
}

/**
 * Retrieve the main nav item that is currently active.
 *
 * @param items
 * @return {*}
 */
function parseMainNavItemForActiveState(items) {
    return items.find(item => item.hasActiveNavItems) || items.find(item => item.isActive)
}

function parseNavItemsRenderUrl(items, role) {
    return items
        .filter(item => {
            if (!isEmpty(item.role)) {
                if (isObject(item.role) && !item.role[role]) {
                    return false
                }
                if (isString(item.role) && item.role !== role) return false
            }

            return true
        })
        .map(item => {
            /** Don't attempt to render a route if the URL is present */
            if (typeof item.route !== 'undefined') {
                item.url = renderRoute(item.route)
            }

            if (isArray(item.items)) {
                item.items = parseNavItemsRenderUrl(item.items, role)
            }

            return item
        })
}

/**
 * Retrieve a list of account nav items based on current pathname and current role.
 *
 * @param  {String} pathname
 * @param  {String} role
 * @return {array}
 */
export function renderNavItems(pathname, role) {
    const items = has(navConfig.roles, role)
        ? [
              ...navConfig.roles[role].map(item => {
                  if (isString(item)) {
                      return { ...navConfig.items[item] }
                  } else {
                      return { ...item }
                  }
              }),
          ]
        : []

    return parseNavItemsForActiveState(parseNavItemsRenderUrl(items, role), pathname)
}

/**
 * Retrieve the active main item.
 *
 * @param pathname
 * @param role
 * @return {*}
 */
export function activeNavItem(pathname, role) {
    return parseMainNavItemForActiveState(renderNavItems(pathname, role))
}
