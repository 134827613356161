import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

const Label = ({ children, inputId, isRequired }) => (
    <label htmlFor={inputId} className={classNames('o-label', { '-required': isRequired })}>
        {children}
    </label>
)

Label.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    inputId: PropTypes.string,
    isRequired: PropTypes.bool,
}

Label.defaultProps = {
    inputId: null,
    isRequired: false,
}

export default Label
