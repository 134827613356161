import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'medals'

/**
 * Fetch a list of all medals.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchMedals = (config = {}) => {
    return apiClient.get(baseUrl, config)
}

/**
 * Fetch a medal.
 *
 * @param  {String} medalId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchMedal = (medalId, config = {}) => {
    return apiClient.get(`${baseUrl}/${medalId}`, config)
}

/**
 * Create a new medal.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createMedal = (formData, config = {}) => {
    return apiClient.post(baseUrl, formData, config)
}

/**
 * Update a medal.
 *
 * @param  {String} medalId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateMedal = (medalId, formData, config = {}) => {
    return apiClient.patch(`${baseUrl}/${medalId}`, formData, config)
}
