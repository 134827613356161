import React from 'react'

import { Icon } from '../Icon'

const GalleryItem = ({ id, date, description, media, handleGalleryClick }) => (
    <React.Fragment>
        <div
            className="o-image -rounded -background gallery-grid_item_image"
            style={{ backgroundImage: `url('${media.thumbnail}')` }}
            data-type={media.type}
            data-src={media.file}
            onClick={handleGalleryClick}
        >
            {media.type === 'video' && <Icon name="play" overrideClass="gallery-grid_play" />}
            <img src={media.thumbnail} alt={`${media.label} - ${date.display}`} />
        </div>

        <div className="gallery-grid_content">
            <p className="gallery-grid_content_title">
                {media.label} - {date.display}
            </p>
            <p className="gallery-grid_content_description">{description}</p>
        </div>
    </React.Fragment>
)

export default GalleryItem
