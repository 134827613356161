import React from 'react'

import { Elements, StripeProvider } from 'react-stripe-elements'

const withStripeWrapper = WrappedComponent => {
    class EnhancedComponent extends React.Component {
        render() {
            const stripeKey = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`

            return (
                <StripeProvider apiKey={stripeKey}>
                    <Elements>
                        <WrappedComponent {...this.props} />
                    </Elements>
                </StripeProvider>
            )
        }
    }

    return EnhancedComponent
}

export default withStripeWrapper
