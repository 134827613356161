import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import propTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import { injectStripe } from 'react-stripe-elements'

import { formatFormMessages } from '../../utils/forms'
import { updateBillingInformation } from '../../actions/BillingInformationAction'
import { withBillingInformation } from '../withBillingInformation'
import { withStripeWrapper } from './index'
import { Layout, LayoutItem } from '../Layout'
import { Spinner } from '../Spinner'
import { BillingForm } from '../BillingForm'
import { FormMessages } from '../FormMessages'

class BillingModal extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        isSubmitting: false,
        errors: [],
    }

    handleSubmit = async event => {
        event.preventDefault()

        const {
            onSuccess,
            form: { setFieldsValue, validateFields, getFieldInstance },
        } = this.props
        const { isSubmitting } = this.state

        if (isSubmitting) {
            return false
        }

        // Inject tokenized card into form.
        if (getFieldInstance('stripeSource') !== undefined) {
            let { token, error } = await this.props.stripe.createToken()

            if (error) {
                this.setState({
                    errors: error,
                    isSubmitting: false,
                })

                return false
            }

            setFieldsValue({
                stripeSource: token.id,
            })
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState(
                    {
                        errors: [],
                        isSubmitting: true,
                    },
                    () => {
                        updateBillingInformation(formValues, {
                            cancelToken: this.signal.token,
                        })
                            .then(response => {
                                this.setState({
                                    errors: [],
                                    isSubmitting: false,
                                })

                                onSuccess()
                            })
                            .catch(error => {
                                this.setState({
                                    errors: error.response.data,
                                    isSubmitting: false,
                                })
                            })
                    }
                )
            }
        })

        return false
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form, onCancel, billingInformation } = this.props
        const { errors, isSubmitting } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <div className="o-modal_wrapper">
                <div className="o-modal_content">
                    <h2 className="o-modal_title">Update your billing information</h2>
                    <div className="o-modal_summary">
                        <p>Please complete the form below to update your billing information.</p>
                    </div>
                    <hr />
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={!hasErrors} items={notifications} />
                        </div>
                    )}
                    {billingInformation.isLoading ? (
                        <Layout>
                            <LayoutItem overrideClass="u-text-center u-padding">
                                <Spinner />
                            </LayoutItem>
                        </Layout>
                    ) : (
                        <form
                            onSubmit={e => this.handleSubmit(e)}
                            className={classNames('o-form', {
                                'is-loading': isSubmitting,
                            })}
                        >
                            <BillingForm form={form} billingInformation={billingInformation.data} />
                            <div className="o-form_overlay">
                                <div className="o-form_spinner u-text-center">
                                    <Spinner />
                                </div>
                            </div>
                        </form>
                    )}
                    <div className="o-modal_actions">
                        <Layout overrideClass="-gutter-small -middle u-text-center">
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <button className="o-button -full" onClick={() => onCancel()}>
                                    Cancel
                                </button>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <button className="o-button -full -green" onClick={event => this.handleSubmit(event)}>
                                    Update info
                                </button>
                            </LayoutItem>
                        </Layout>
                    </div>
                </div>
            </div>
        )
    }

    static props = {
        onCancel: propTypes.func,
        onSuccess: propTypes.func,
    }

    static defaultProps = {
        onCancel: () => {},
        onSuccess: () => {},
    }
}

const InjectedBillingModal = injectStripe(createForm()(withBillingInformation(BillingModal)))

export default withStripeWrapper(InjectedBillingModal)
