import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute } from '../../utils/routes'

const UserProgress = ({ color, isLink, user, value }) => {
    const radius = 120
    const dashArray = radius * 2 * Math.PI
    const ratio = dashArray / 100
    const LinkEl = isLink ? RouterLink : 'span'
    const linkProps = isLink
        ? {
              to: renderRoute({
                  id: 'student-details',
                  bind: 'studentId',
                  param: user.id,
              }),
          }
        : {}

    return (
        <div className={`user-progress${color ? ` -${color}` : ''}`}>
            <LinkEl {...linkProps}>
                <svg className="user-progress_svg" viewBox="0 0 250 250" preserveAspectRatio="xMidYMid meet">
                    <circle
                        className="user-progress_circle"
                        cx="125"
                        cy="125"
                        r={radius}
                        strokeDasharray={dashArray}
                        strokeDashoffset={parseInt(dashArray - value * ratio)}
                    />
                </svg>
                <img
                    src={user.profileImage.url}
                    alt={`${user.firstName} ${user.lastName}`}
                    className="user-progress_picture"
                />
            </LinkEl>
        </div>
    )
}

UserProgress.propTypes = {
    color: PropTypes.string,
    user: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
}

UserProgress.defaultProps = {
    color: '',
    isLink: true,
}

export default UserProgress
