import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import Immutable from 'immutable'
import { isEmpty, sortBy } from 'lodash-es'
import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CREATE_STUDENT_MODAL, MESSAGE_MODAL } from '../Modal/ModalTypes'
import { loadModal } from '../../actions/modalActions'
import { fetchOrganization } from '../../actions/organizationActions'
import { createSession, updateAndPushSession } from '../../actions/sessionActions'
import { createSessionDraft } from '../../actions/sessionDraftActions'
import { fetchAllStudents } from '../../actions/studentActions'
import { apiClient } from '../../helpers/ApiClient'
import { isUserAdministrator } from '../../helpers/auth'
import { datetimeAsString } from '../../utils/datetimes'
import { formatFormMessages } from '../../utils/forms'
import { renderRoute } from '../../utils/routes'
import { CurriculumCardContainer } from '../Curriculum'
import { FormMessages } from '../FormMessages'
import { ItemManager, ItemManagerUserItem } from '../ItemManager'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'
import { Schedule } from '../Schedule'
import { SessionProgress } from '../Session'
import { Spinner } from '../Spinner'
import { TrackVisibility } from '../TrackVisibility'

class SessionForm extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        courseList: [],
        errors: [],
        initialValues: Immutable.Map({}),
        isFormSubmitted: false,
        isSavingSession: false,
        selectedStudentIds: [],
        students: [],
    }

    handleClickActivateSession = event => {
        const { dispatch, organizationId } = this.props

        this.saveFormData(createSession, []).then(
            response => {
                /**
                 * Updates the session list in store.
                 * Updates the My Students student list for Instructors.
                 */
                apiClient.clearCache('sessions')
                apiClient.clearCache('students')
                apiClient.clearCache('organizations')
                dispatch(fetchOrganization(organizationId))
                dispatch(
                    push(
                        renderRoute({
                            id: 'session-edit',
                            bind: 'sessionId',
                            param: response.data.id,
                        })
                    )
                )
            },
            reason => {
                console.log(reason)
            }
        )
    }

    handleClickUpdatePushSession = event => {
        event.preventDefault()

        const { dispatch, fetchMethod, organizationId, session } = this.props

        this.saveFormData(updateAndPushSession, [session.id]).then(
            response => {
                apiClient.clearCache('sessions')
                apiClient.clearCache('students')
                apiClient.clearCache('organizations')
                dispatch(fetchOrganization(organizationId))
                dispatch(fetchMethod)
            },
            reason => {
                console.log(reason)
            }
        )

        return false
    }

    handleSubmit = event => {
        event.preventDefault()

        const { dispatch, fetchMethod, isDraft, organizationId, session, updateMethod } = this.props
        const hasSession = session !== null
        const action = hasSession ? updateMethod : createSessionDraft
        const firstParam = hasSession ? [session.id] : []

        this.saveFormData(action, firstParam).then(
            response => {
                if (isDraft) {
                    apiClient.clearCache('session-drafts')
                } else {
                    apiClient.clearCache('sessions')
                    apiClient.clearCache('organizations')
                    dispatch(fetchOrganization(organizationId))
                }
                dispatch(fetchMethod)
                if (!hasSession) {
                    dispatch(
                        push(
                            renderRoute({
                                id: isDraft ? 'session-draft-edit' : 'session-edit',
                                bind: 'sessionId',
                                param: response.data.id,
                            })
                        )
                    )
                }
            },
            reason => {
                console.log(reason)
            }
        )

        return false
    }

    handleScheduleItemCreate = newItem => {
        this.setState(({ courseList }) => ({
            courseList: sortBy([...courseList, newItem], 'startDate'),
        }))
    }

    handleScheduleItemDelete = itemIndex => {
        this.setState(({ courseList }) => ({
            courseList: courseList.filter((item, index) => itemIndex !== index),
        }))
    }

    handleCreateItem = newItem => {
        const { showModal } = this.props

        showModal(CREATE_STUDENT_MODAL, {
            onSuccess: student => {
                showModal(MESSAGE_MODAL, {
                    title: 'Success',
                    message: `${student.name} successfully created !`,
                })

                this.handleItemManagerAddItem(student.id)
            },
        })
    }

    handleItemManagerAddItem = newItem => {
        this.setState(({ selectedStudentIds }) => ({
            selectedStudentIds: [...selectedStudentIds, newItem],
        }))
    }

    handleItemManagerRemoveItem = itemIdToRemove => {
        this.setState(({ selectedStudentIds }) => ({
            selectedStudentIds: selectedStudentIds.filter(itemId => itemId !== itemIdToRemove),
        }))
    }

    saveFormData = (action, firstParam) =>
        new Promise((resolve, reject) => {
            this.setState({
                isFormSubmitted: false,
            })

            const {
                form: { validateFields },
                isDraft,
                session,
            } = this.props
            const { courseList, isSavingSession, selectedStudentIds } = this.state
            const hasSession = session !== null

            if (isSavingSession) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (!formErrors) {
                    this.setState({
                        errors: [],
                        isSavingSession: true,
                    })
                    if (hasSession && isDraft && isEmpty(firstParam)) {
                        formValues.sessionDraftId = session.id
                    }
                    const actionParams = [
                        ...firstParam,
                        {
                            ...formValues,
                            courses: courseList,
                            studentIds: selectedStudentIds,
                        },
                        { cancelToken: this.signal.token },
                    ]
                    action(...actionParams)
                        .then(response => {
                            this.setState({
                                errors: [],
                                isFormSubmitted: true,
                                isSavingSession: false,
                            })
                            resolve(response)
                        })
                        .catch(error => {
                            this.setState({
                                errors: error.response.data,
                                isSavingSession: false,
                            })
                            reject(error)
                        })
                        .finally(() => {
                            window.scrollTo(0, 0)
                        })
                } else {
                    reject(formErrors)
                    window.scrollTo(0, 0)
                }
            })
        })

    static getDerivedStateFromProps(props, state) {
        if (state.students.length !== props.students.length && !props.isLoadingStudents) {
            return {
                students: props.students.map(student => {
                    return {
                        ...student,
                        url: renderRoute({
                            id: 'student-details',
                            bind: 'studentId',
                            param: student.id,
                        }),
                    }
                }),
            }
        }

        return null
    }

    componentDidMount() {
        const { session, loadStudents } = this.props

        loadStudents({ cancelToken: this.signal.token })

        if (session !== null) {
            this.setState({
                courseList: session.courses.map(course => {
                    return {
                        ...course,
                        startDate: datetimeAsString(course.startDate),
                    }
                }),
                initialValues: Immutable.Map(session),
                selectedStudentIds: session.studentIds,
            })
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            curriculums,
            form: { getFieldDecorator, getFieldError, getFieldValue },
            instructors,
            isDraft,
            session,
            user,
        } = this.props
        const {
            courseList,
            errors,
            initialValues,
            isFormSubmitted,
            isSavingSession,
            selectedStudentIds,
            students,
        } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? [{ message: 'Session has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)
        const isAdministrator = isUserAdministrator(user)
        const layoutClassInstructorRow = isAdministrator ? 'u-3/4@from-small' : ''
        const layoutClassProgressRow = isDraft ? '' : 'u-1/2@from-small'

        return (
            <div
                className={classNames('o-form', {
                    'is-loading': isSavingSession,
                })}
            >
                <form onSubmit={this.handleSubmit} noValidate>
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isFormSubmitted} items={notifications} />
                        </div>
                    )}
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass={layoutClassInstructorRow}>
                            <div className="o-input_wrapper">
                                <Label inputId="curriculumId" isRequired>
                                    Curriculum
                                </Label>
                                <div className="o-select_wrapper">
                                    {getFieldDecorator('curriculumId', {
                                        initialValue: initialValues.getIn(['curriculum', 'id']) || '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select a curriculum',
                                            },
                                        ],
                                    })(
                                        <select id="curriculumId" className="o-select" disabled={!isDraft}>
                                            <option disabled value="">
                                                None
                                            </option>
                                            {curriculums.map((item, index) => (
                                                <option value={item.id} key={index}>
                                                    {item.certificationCode} &mdash; {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                <div className="o-input_error">{getFieldError('curriculumId')}</div>
                            </div>
                        </LayoutItem>
                        {isAdministrator && (
                            <LayoutItem overrideClass="u-1/4@from-small">
                                <div className="o-input_wrapper">
                                    <Label inputId="instructorId" isRequired>
                                        Instructor
                                    </Label>
                                    <div className="o-select_wrapper">
                                        {getFieldDecorator('instructorId', {
                                            initialValue: initialValues.getIn(['instructor', 'id']) || '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select an instructor',
                                                },
                                            ],
                                        })(
                                            <select id="instructorId" className="o-select">
                                                <option disabled value="">
                                                    None
                                                </option>
                                                {instructors.map((item, index) => (
                                                    <option value={item.id} key={index}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div className="o-input_error">{getFieldError('instructorId')}</div>
                                </div>
                            </LayoutItem>
                        )}
                    </Layout>
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label inputId="name" isRequired>
                                    Session Name
                                </Label>
                                {getFieldDecorator('name', {
                                    initialValue: initialValues.get('name') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ],
                                })(<input type="text" id="name" className="o-input" placeholder="Winter Session" />)}
                                <div className="o-input_error">{getFieldError('name')}</div>
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-input_wrapper">
                                <Label inputId="primaryLocation" isRequired>
                                    Location
                                </Label>
                                {getFieldDecorator('primaryLocation', {
                                    initialValue: initialValues.get('primaryLocation') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ],
                                })(
                                    <input
                                        type="text"
                                        id="primaryLocation"
                                        className="o-input"
                                        placeholder="123 Main Street, Exampleville"
                                    />
                                )}
                                <div className="o-input_error">{getFieldError('primaryLocation')}</div>
                            </div>
                        </LayoutItem>
                    </Layout>
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem>
                            <div className="o-input_wrapper">
                                <Label inputId="registrationUrl" isRequired>
                                    URL for registration
                                </Label>
                                {getFieldDecorator('registrationUrl', {
                                    initialValue: initialValues.get('registrationUrl') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                        {
                                            type: 'url',
                                            message:
                                                'Field is not a valid url (make sure to include the protocol : http, https)',
                                        },
                                    ],
                                })(
                                    <input
                                        type="url"
                                        id="registrationUrl"
                                        className="o-input"
                                        placeholder="http://example.com"
                                    />
                                )}
                                <div className="o-input_error">{getFieldError('registrationUrl')}</div>
                            </div>
                        </LayoutItem>
                    </Layout>
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass={layoutClassProgressRow}>
                            <div className="o-input_wrapper">
                                <Label inputId="description" isRequired>
                                    Description
                                </Label>
                                {getFieldDecorator('description', {
                                    initialValue: initialValues.get('description') || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ],
                                })(<textarea id="description" className="o-textarea"></textarea>)}
                                <div className="o-input_error">{getFieldError('description')}</div>
                            </div>
                        </LayoutItem>
                        {!isDraft && (
                            <LayoutItem overrideClass={layoutClassProgressRow}>
                                <div className="o-input_wrapper">
                                    <Label>Course Progress</Label>
                                    <SessionProgress courses={session.courses} overrideClass="-margin -full" />
                                </div>
                            </LayoutItem>
                        )}
                    </Layout>
                    <button className="u-none" type="submit" />
                </form>

                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <Schedule
                        handleItemCreate={this.handleScheduleItemCreate}
                        handleItemDelete={this.handleScheduleItemDelete}
                        isEditable={true}
                        items={courseList}
                    />
                </TrackVisibility>

                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <ItemManager
                        addLabel="Assign a student"
                        createButtonPopup={{
                            label: 'Create Student',
                            route: 'student-create',
                        }}
                        handleCreateItem={this.handleCreateItem}
                        handleAddItem={this.handleItemManagerAddItem}
                        handleRemoveItem={this.handleItemManagerRemoveItem}
                        header="Students"
                        listHeaders={['Name', 'Email']}
                        selectedItemIds={selectedStudentIds}
                        itemComponent={ItemManagerUserItem}
                        itemList={students}
                    />
                </TrackVisibility>

                {getFieldValue('curriculumId') && (
                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        <CurriculumCardContainer curriculum={{ id: getFieldValue('curriculumId') }} />
                    </TrackVisibility>
                )}

                <Layout overrideClass="-gutter-small">
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                            {isDraft ? (
                                <button
                                    className="o-button -huge u-margin-tiny-y"
                                    type="button"
                                    onClick={this.handleSubmit}
                                >
                                    Save as Draft
                                </button>
                            ) : (
                                <button
                                    className="o-button -huge u-margin-tiny-y"
                                    type="button"
                                    onClick={this.handleSubmit}
                                >
                                    Update Session
                                </button>
                            )}
                        </TrackVisibility>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2@from-small">
                        <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                            {isDraft ? (
                                <button
                                    className="o-button -huge -green u-margin-tiny-y"
                                    type="button"
                                    onClick={this.handleClickActivateSession}
                                >
                                    Activate Session
                                </button>
                            ) : (
                                <button
                                    className="o-button -huge -green u-margin-tiny-y"
                                    type="button"
                                    onClick={this.handleClickUpdatePushSession}
                                >
                                    Update & Send to Students
                                </button>
                            )}
                        </TrackVisibility>
                    </LayoutItem>
                </Layout>
                <div className="o-form_overlay">
                    <div className="o-form_spinner u-text-center">
                        <Spinner />
                    </div>
                </div>
            </div>
        )
    }
}

SessionForm.propTypes = {
    fetchMethod: PropTypes.func.isRequired,
    isDraft: PropTypes.bool,
    session: PropTypes.object,
    updateMethod: PropTypes.func.isRequired,
}

SessionForm.defaultProps = {
    isDraft: false,
    session: null,
}

SessionForm.Placeholder = () => (
    <ContentLoader height={978} width={1000} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="27" rx="5" ry="5" width="1000" height="48" />
        {/*<rect x="775" y="27" rx="5" ry="5" width="224" height="48" />*/}
        <rect x="0" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="517" y="124" rx="5" ry="5" width="488" height="48" />
        <rect x="0" y="219" rx="5" ry="5" width="1000" height="48" />
        <rect x="0" y="316" rx="5" ry="5" width="1000" height="114" />
        <rect x="34" y="723" rx="5" ry="5" width="210" height="46" />
        <rect x="277" y="723" rx="5" ry="5" width="206" height="49" />
        <rect x="516" y="723" rx="5" ry="5" width="209" height="47" />
        <circle cx="785" cy="740" r="30" />
        <rect x="0" y="802" rx="0" ry="0" width="1000" height="53" />
        <rect x="0" y="6" rx="0" ry="0" width="112" height="13" />
        {/*<rect x="775" y="6" rx="0" ry="0" width="112" height="13" />*/}
        <rect x="0" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="517" y="102" rx="0" ry="0" width="112" height="13" />
        <rect x="0" y="196" rx="0" ry="0" width="112" height="13" />
        <rect x="1" y="290" rx="0" ry="0" width="112" height="13" />
        <rect x="34" y="545" rx="0" ry="0" width="115" height="29" />
        <rect x="0" y="516" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="595" rx="0" ry="0" width="1000" height="2" />
        <rect x="0" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="998" y="516" rx="0" ry="0" width="2" height="459" />
        <rect x="34" y="635" rx="0" ry="0" width="106" height="23" />
        <rect x="34" y="701" rx="0" ry="0" width="132" height="13" />
        <rect x="277" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="517" y="701" rx="0" ry="0" width="84" height="13" />
        <rect x="275" y="875" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="875" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="875" rx="0" ry="0" width="128" height="21" />
        <rect x="27" y="867" rx="0" ry="0" width="34" height="34" />
        <rect x="0" y="913" rx="0" ry="0" width="1000" height="2" />
        <rect x="27" y="928" rx="0" ry="0" width="34" height="34" />
        <rect x="275" y="934" rx="0" ry="0" width="140" height="21" />
        <rect x="514" y="934" rx="0" ry="0" width="58" height="21" />
        <rect x="755" y="934" rx="0" ry="0" width="128" height="21" />
    </ContentLoader>
)

const mapStateToProps = state => ({
    curriculums: state.curriculums.entities,
    instructors: state.instructors.entities,
    organizationId: state.organization.id,
    user: state.auth.user,
    isLoadingStudents: state.requestStates.FETCH_ALL_STUDENT_LIST === true,
    students: state.allStudents.entities,
})

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            loadStudents: fetchAllStudents,
        },
        dispatch
    ),
    showModal: (modalType, modalProps = {}) => dispatch(loadModal(modalType, modalProps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(SessionForm))
