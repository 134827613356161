import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import pad from '../../utils/pad'
import { Layout, LayoutItem } from '../Layout'
import { Icon } from '../Icon'
import { IconButton } from '../IconButton'

const DragHandle = SortableHandle(() => (
    <span className="flexible-list_handler">
        <IconButton isSmall={true} name="drag" />
    </span>
))

const FlexibleListItem = ({ handleDeleteItem, isEditable, item, number: index }) => (
    <li
        className={classNames('flexible-list_item', 'u-padding-small-x', {
            'is-editable': isEditable,
        })}
    >
        <Layout overrideClass="-gutter-small">
            <LayoutItem overrideClass="u-1/5">{pad(index + 1, 2)}</LayoutItem>
            <LayoutItem overrideClass="u-4/5">
                <div className="flexible-list_item_content">{item.description}</div>
            </LayoutItem>
        </Layout>
        <DragHandle />
        {isEditable && (
            <button type="button" className="flexible-list_item_delete" onClick={handleDeleteItem} data-index={index}>
                <Icon name="close" />
            </button>
        )}
    </li>
)

FlexibleListItem.propTypes = {
    handleDeleteItem: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
}

export default SortableElement(FlexibleListItem)
