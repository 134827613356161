import React from 'react'

import PropTypes from 'prop-types'

const MedalVisual = ({ image }) => (
    <figure className="medal-visual" style={{ backgroundImage: `url('${image}')` }}></figure>
)

MedalVisual.propTypes = {
    image: PropTypes.string.isRequired,
}

export default MedalVisual
