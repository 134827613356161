import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { SwitchTransition } from 'react-transition-group'

import { ReportDetailsView } from '../../views'
import { CardHeader } from '../Card'
import { DashboardInfos } from '../Dashboard'
import { Fade } from '../Fade'
import { FlexibleList } from '../FlexibleList'
import { Gallery } from '../Gallery'
import { Header } from '../Header'
import { Logo } from '../Logo'
import { Messages } from '../Messages'
import { ReportCertification, ReportIntro } from '../Report'
import { StickersSelector } from '../Stickers'
import { TrackVisibility } from '../TrackVisibility'
import { FormMessages } from '../FormMessages'

const ReportDetails = ({
    gallery,
    isLoadingGallery,
    isLoadingLatestStickers,
    isLoadingMessages,
    isLoadingReport,
    isLoadingReportStickers,
    isLoadingTasks,
    latestStickers,
    messages,
    report,
    reportStickers,
    tasks,
    errors,
}) => {
    const [dashboardItems, setDashboardItems] = useReducer(
        (state, action) => [
            { label: 'Messages', value: isLoadingMessages ? '-' : messages.length },
            { label: 'Images', value: isLoadingGallery ? '-' : gallery.length },
        ],
        [
            { label: 'Messages', value: '-' },
            { label: 'Images', value: '-' },
        ]
    )
    const { student, session } = report || {}
    const isLoadingAnyStickers = isLoadingLatestStickers || isLoadingReportStickers
    const isLoadingIntro = isLoadingReport || isLoadingTasks || isLoadingAnyStickers
    const isLoadingDashboard = isLoadingMessages || isLoadingGallery
    const hasErrors = !isEmpty(errors)

    useEffect(() => {
        setDashboardItems()
    }, [isLoadingDashboard])

    return hasErrors ? (
        <TrackVisibility overrideClass="u-anim-scroll">
            <SwitchTransition>
                <Fade timeout={150} key={isLoadingReport}>
                    <Header>
                        Report <br />
                        Card
                    </Header>
                    <div className="o-input_wrapper">
                        <FormMessages hasErrors={true} items={errors} />
                    </div>
                </Fade>
            </SwitchTransition>
        </TrackVisibility>
    ) : (
        <React.Fragment>
            <TrackVisibility overrideClass="u-anim-scroll">
                <SwitchTransition>
                    <Fade timeout={150} key={isLoadingReport}>
                        {isLoadingReport ? (
                            <ReportDetailsView.Placeholder.Header />
                        ) : (
                            <Header>
                                {student.firstName} <br />
                                {student.lastName}
                            </Header>
                        )}
                    </Fade>
                </SwitchTransition>
            </TrackVisibility>
            <DashboardInfos items={dashboardItems} />
            <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                <section className="u-card u-margin-y u-relative">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingReport}>
                            {isLoadingReport ? (
                                <CardHeader>
                                    <ReportDetailsView.Placeholder.CardHeader />
                                </CardHeader>
                            ) : (
                                <CardHeader>
                                    <Logo />
                                    {session.curriculum.name}
                                </CardHeader>
                            )}
                        </Fade>
                    </SwitchTransition>

                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingIntro}>
                            {isLoadingIntro ? (
                                <ReportIntro.Placeholder />
                            ) : (
                                <ReportIntro report={report} tasks={tasks} stickers={latestStickers} />
                            )}
                        </Fade>
                    </SwitchTransition>

                    <hr />

                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingReport || isLoadingTasks ? 'loading' : 'loaded'}>
                            {isLoadingReport || isLoadingTasks ? (
                                <ReportCertification.Placeholder />
                            ) : (
                                <ReportCertification report={report} tasks={tasks} />
                            )}
                        </Fade>
                    </SwitchTransition>

                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingTasks}>
                            {isLoadingTasks ? (
                                <ReportDetailsView.Placeholder.TaskList />
                            ) : (
                                <FlexibleList
                                    title="Progress"
                                    headers={['Step #', 'Description']}
                                    items={tasks.map(task => ({
                                        isCompleted: task.isCompleted,
                                        description: task.sessionTask.description,
                                    }))}
                                    isProgress
                                    isDisabled
                                />
                            )}
                        </Fade>
                    </SwitchTransition>
                    <Gallery gallery={gallery} isLoading={isLoadingGallery} readOnly />
                    <StickersSelector
                        isLoading={isLoadingReportStickers}
                        selectedStickersIds={latestStickers.map(s => s.sticker.id)}
                        readOnly
                        reportStickers={reportStickers}
                    />
                    <Messages messages={messages} isLoading={isLoadingMessages} readOnly />
                </section>
            </TrackVisibility>
        </React.Fragment>
    )
}

ReportDetails.props = {
    gallery: PropTypes.array,
    isLoadingLatestStickers: PropTypes.bool,
    isLoadingMessages: PropTypes.bool,
    isLoadingReport: PropTypes.bool,
    isLoadingReportStickers: PropTypes.bool,
    isLoadingTasks: PropTypes.bool,
    latestStickers: PropTypes.array,
    messages: PropTypes.array,
    report: PropTypes.object,
    reportStickers: PropTypes.array,
    tasks: PropTypes.array,
    errors: PropTypes.array,
}

ReportDetails.defaultProps = {
    gallery: [],
    isLoadingGallery: true,
    isLoadingLatestStickers: true,
    isLoadingMessages: true,
    isLoadingReport: true,
    isLoadingReportStickers: true,
    isLoadingTasks: true,
    latestStickers: [],
    messages: [],
    report: null,
    reportStickers: [],
    tasks: [],
    errors: [],
}

export default ReportDetails
