import React from 'react'

import PropTypes from 'prop-types'

const CertificateVisual = ({ name, image }) => (
    <figure className="certificate-visual" style={{ backgroundImage: `url('${image}')` }}>
        <span className="certificate-visual_title">{name}</span>
    </figure>
)

CertificateVisual.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default CertificateVisual
