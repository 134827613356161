import { each, toArray } from 'lodash-es'

const mapFetchToState = map => {
    return new Promise((resolve, reject) => {
        Promise.all(toArray(map))
            .then(values => {
                values = values.map(({ data }) => data)
                const newState = each(map, (value, key, collection) => (collection[key] = values.shift()))
                resolve(newState)
            })
            .catch(error => reject(error))
    })
}

export default mapFetchToState
