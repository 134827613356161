import { get } from 'lodash-es'
import React from 'react'
import { Helmet } from 'react-helmet'

import { Footer } from '../components/Footer'
import { Main } from '../components/Main'
import { StudentMedalDetails, withStudentMedalDetailsData } from '../components/StudentMedal'

import shareImage from '../assets/images/opengraph-certificate.png'

const PublicStudentMedalDetailsView = ({ studentMedalData }) => {
    const student = get(studentMedalData, 'studentMedal.student', null)
    const medal = get(studentMedalData, 'studentMedal.medal', { programName: '' })
    const title = student !== null ? `${student.firstName}'s ${medal.programName} Medal` : `${medal.programName} Medal`

    return (
        <Main isPublic={true}>
            <Helmet titleTemplate="%s | Report.Cards">
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:image" content={shareImage} />
                <meta name="description" content="Check out my amazing Medal!" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.toString()} />
            </Helmet>
            <StudentMedalDetails {...studentMedalData} />
            <Footer />
        </Main>
    )
}

export default withStudentMedalDetailsData(true)(PublicStudentMedalDetailsView)
