import html2pdf from 'html2pdf.js'

export function exportAsPdf(filename) {
    const cloneApp = document.getElementById('root').cloneNode(true)
    cloneApp.className += ' s-pdf'
    cloneApp.style.width = 950

    const options = {
        margin: 1,
        filename: filename,
        pagebreak: { mode: 'avoid-all' },
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            width: 950,
            windowWidth: 1000,
            scrollX: 0,
            scrollY: 0,
            x: 25,
            y: 0,
            scale: 2,
            useCORS: true,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    html2pdf()
        .set(options)
        .from(cloneApp)
        .save()
}
