import PropTypes from 'prop-types'
import React from 'react'

import { Transition } from 'react-transition-group'

const defaultStyle = (duration, state, distance) => {
    return {
        transition: `opacity ${duration}ms ${easing[state]}, transform ${duration}ms ${easing[state]}`,
        opacity: 0,
        transform: `translateY(${distance}px)`,
    }
}

const easing = {
    entering: 'ease-in',
    entered: 'ease-in',
    exiting: 'ease-out',
    exited: 'ease-out',
}

const transitionStyles = {
    entering: { opacity: 0 },
    entered: {
        opacity: 1,
        transform: 'translateY(0)',
    },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
}

const Slide = props => (
    <Transition {...props} timeout={props.timeout}>
        {state => (
            <div
                style={{
                    ...defaultStyle(props.timeout, state, props.distance),
                    ...transitionStyles[state],
                }}
            >
                {props.children}
            </div>
        )}
    </Transition>
)

Slide.propTypes = {
    distance: PropTypes.number,
    timeout: PropTypes.number,
}

Slide.defaultProps = {
    distance: 25,
    timeout: 250,
}

export default Slide
