import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'

import { fetchParents } from '../actions/parentActions'
import { isUserStudent } from '../helpers/auth'

import UserListView from './UserListView'

class ParentListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchParents({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { user, ...rest } = this.props
        const title = isUserStudent(user) ? 'My Parents' : 'Parents'
        return (
            <UserListView
                {...rest}
                detailsRoute={{
                    id: 'parent-details',
                    bind: 'parentId',
                }}
                listTitle="Parents"
                title={title}
            />
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.requestStates.FETCH_PARENT_LIST === true,
    items: state.parents.entities,
    user: state.auth.user,
})

export default connect(mapStateToProps)(ParentListView)
