import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { Transition } from 'react-transition-group'

const Progress = ({ label, overrideClass, value }) => {
    const radius = 120
    const dashArray = radius * 2 * Math.PI
    const ratio = dashArray / 100
    const classes = classNames('progress', overrideClass)

    return (
        <div className={classes}>
            <svg className="progress_svg" viewBox="0 0 250 250" preserveAspectRatio="xMidYMid meet">
                <circle className="progress_background" cx="125" cy="125" r={radius} />
                <Transition in={true} appear={true} timeout={{ enter: 250 }}>
                    {state => {
                        return (
                            <circle
                                className="progress_circle"
                                cx="125"
                                cy="125"
                                r={radius}
                                strokeDasharray={dashArray}
                                strokeDashoffset={
                                    state !== 'entered' ? dashArray - ratio : parseInt(dashArray - value * ratio)
                                }
                            />
                        )
                    }}
                </Transition>
            </svg>
            <p className="progress_value">{isEmpty(label) ? `${value}%` : label}</p>
        </div>
    )
}

Progress.propTypes = {
    label: PropTypes.string,
    overrideClass: PropTypes.string,
    value: PropTypes.number.isRequired,
}

Progress.defaultProps = {
    label: '',
    overrideClass: '',
}

export default Progress
