import React from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'

import { isUserAdministrator } from '../../helpers/auth'

import { Layout, LayoutItem } from '../Layout'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { Slide } from '../Fade'

const SubscriptionStatusBar = ({ user, subscriptionActive, endDate }) => {
    return (
        <Layout overrideClass="-middle -gutter-small -reverse">
            <LayoutItem>
                <div className="subscription-status-bar_container">
                    <SwitchTransition>
                        <Slide appear={true} in={subscriptionActive === false} timeout={350} distance={100}>
                            {subscriptionActive === false && (
                                <div className="subscription-status-bar">
                                    <div className="subscription-status-bar_inner">
                                        <div className="subscription-status-bar_icon">
                                            <Icon name="sticker-warning" />
                                        </div>
                                        <Layout overrideClass="-middle -gutter-small">
                                            <LayoutItem overrideClass="u-3/4@from-small">
                                                <h3 className="subscription-status-bar_title">
                                                    Your subscription has ended!
                                                </h3>
                                                <p className="subscription-status-bar_date u-blue">
                                                    Since <time>{endDate.display}</time>
                                                </p>
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/4@from-small">
                                                <div className="u-text-right@from-small">
                                                    {user && isUserAdministrator(user) && (
                                                        <Button route="account-plan-edit">See my Plan</Button>
                                                    )}
                                                </div>
                                            </LayoutItem>
                                        </Layout>
                                    </div>
                                </div>
                            )}
                        </Slide>
                    </SwitchTransition>
                </div>
            </LayoutItem>
        </Layout>
    )
}

SubscriptionStatusBar.propTypes = {
    user: propTypes.object,
    subscriptionActive: propTypes.bool,
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        subscriptionActive: state.organization.data.subscription.active,
        endDate: state.organization.data.subscription.endDate || null,
    }
}

export default connect(mapStateToProps)(SubscriptionStatusBar)
