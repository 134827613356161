import { get } from 'lodash-es'
import React from 'react'
import { Helmet } from 'react-helmet'

import { Footer } from '../components/Footer'
import { Main } from '../components/Main'
import { ReportDetails, withReportDetailsData } from '../components/Report'

import shareImage from '../assets/images/opengraph-report.png'

const PublicReportDetailsView = ({ reportData }) => {
    const student = get(reportData, 'report.student', null)
    const curriculum = get(reportData, 'report.session.curriculum', { name: '' })
    const title =
        student !== null ? `${student.firstName}'s ${curriculum.name} Report Card` : `${curriculum.name} Report Card`

    return (
        <Main isPublic={true}>
            <Helmet titleTemplate="%s | Report.Cards">
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:image" content={shareImage} />
                <meta name="description" content="Check out my amazing Report Card!" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.toString()} />
            </Helmet>
            <ReportDetails {...reportData} />
            <Footer />
        </Main>
    )
}

export default withReportDetailsData(true)(PublicReportDetailsView)
