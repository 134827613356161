import React from 'react'

import { Icon } from '../Icon'
import { TrackVisibility } from '../TrackVisibility'

const StickersList = ({ reportStickers }) => (
    <ul className="stickers-list">
        {reportStickers.map(reportSticker => (
            <li key={reportSticker.id} className="stickers-list_item">
                <TrackVisibility overrideClass="stickers-list_item_inner">
                    <Icon name={reportSticker.sticker.image} />
                </TrackVisibility>
            </li>
        ))}
    </ul>
)

export default StickersList
