import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import propTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { apiClient } from '../../helpers/ApiClient'
import { createStudent, fetchAllStudents, fetchStudents } from '../../actions/studentActions'
import { Layout, LayoutItem } from '../Layout'
import { CreateStudentForm } from '../CreateStudentForm'
import { FormMessages } from '../FormMessages'
import { formatFormMessages } from '../../utils/forms'
import { Spinner } from '../Spinner'

class CreateStudentModal extends React.Component {
    state = {
        errors: [],
        isFormSubmitted: false,
        isSavingStudent: false,
        parentsListLoaded: false,
    }

    signal = axios.CancelToken.source()

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    handleSubmit = event => {
        event.preventDefault()

        const { dispatch, form, onSuccess } = this.props
        const { isFormSubmitted, isSavingStudent } = this.state

        if (isFormSubmitted || isSavingStudent) {
            return false
        }

        form.validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingStudent: true,
                })
                createStudent(formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingStudent: false,
                        })
                        /** Update the parent list in store through `fetchParents`. Empty cache first. */
                        apiClient.clearCache('students')
                        dispatch(fetchStudents())
                        dispatch(fetchAllStudents())

                        onSuccess(response.data)
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingStudent: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })

        return false
    }

    render() {
        const { onCancel, form } = this.props
        const { errors, isSavingStudent } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <div className="o-modal_wrapper">
                <div className="o-modal_content">
                    <h2 className="o-modal_title">Create a new student</h2>
                    <hr />

                    <form
                        onSubmit={this.handleSubmit}
                        className={classNames('o-form', {
                            'is-loading': isSavingStudent,
                        })}
                    >
                        {hasErrors && (
                            <div className="o-input_wrapper">
                                <FormMessages hasErrors={true} items={notifications} />
                            </div>
                        )}
                        <CreateStudentForm form={form} />
                        <div className="o-form_overlay">
                            <div className="o-form_spinner u-text-center">
                                <Spinner />
                            </div>
                        </div>
                    </form>

                    <div className="o-modal_actions">
                        <Layout overrideClass="-gutter-small -middle u-text-center">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <button className="o-button -full" onClick={onCancel}>
                                    Cancel
                                </button>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <button className="o-button -full -green" onClick={this.handleSubmit}>
                                    Create Student
                                </button>
                            </LayoutItem>
                        </Layout>
                    </div>
                </div>
            </div>
        )
    }
}

CreateStudentModal.propTypes = {
    onCancel: propTypes.func,
    onSuccess: propTypes.func,
}

CreateStudentModal.defaultProps = {
    onCancel: () => {},
    onSuccess: () => {},
}

export default connect()(createForm()(CreateStudentModal))
