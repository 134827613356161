import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { createParent, fetchParents } from '../actions/parentActions'
import { apiClient } from '../helpers/ApiClient'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { Footer } from '../components/Footer'
import { ConfirmEmail, Email, FirstName, LastName, ProfileImage } from '../components/FormFields'
import { FormMessages } from '../components/FormMessages'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class ParentCreateView extends React.Component {
    state = {
        errors: [],
        isFormSubmitted: false,
        isSavingParent: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const { dispatch, form } = this.props
        const { isFormSubmitted, isSavingParent } = this.state

        if (isFormSubmitted || isSavingParent) {
            return false
        }

        form.validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingParent: true,
                })
                createParent(formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingParent: false,
                        })
                        /** Update the parent list in store through `fetchParents`. Empty cache first. */
                        apiClient.clearCache('student-parents')
                        dispatch(fetchParents())
                        dispatch(
                            push(
                                renderRoute({
                                    id: 'parent-details',
                                    bind: 'parentId',
                                    param: response.data.id,
                                })
                            )
                        )
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingParent: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }

            return false
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            form,
            form: { getFieldDecorator, getFieldError },
        } = this.props
        const { errors, isSavingParent } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main>
                <Header>
                    Create <br />a Parent
                </Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <form
                        onSubmit={this.handleSubmit}
                        className={classNames('o-form', {
                            'is-loading': isSavingParent,
                        })}
                    >
                        {hasErrors && (
                            <div className="o-input_wrapper">
                                <FormMessages hasErrors={true} items={notifications} />
                            </div>
                        )}
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <FirstName form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <LastName form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Email form={form} />
                                <ConfirmEmail form={form} />
                                <div className="o-input_wrapper">
                                    <label htmlFor="role" className="o-label -required">
                                        Role
                                    </label>
                                    <div className="o-select_wrapper">
                                        {getFieldDecorator('type', {
                                            initialValue: '',
                                            validate: [
                                                {
                                                    trigger: 'onBlur',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please choose a role',
                                                        },
                                                    ],
                                                },
                                            ],
                                        })(
                                            <select id="role" className="o-select">
                                                <option value="" disabled>
                                                    Choose a Role
                                                </option>
                                                <option value="parent">Parent</option>
                                                <option value="guardian">Guardian</option>
                                            </select>
                                        )}
                                    </div>
                                    <div className="o-input_error">{getFieldError('type')}</div>
                                </div>
                            </LayoutItem>
                        </Layout>
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <RouterLink
                                        className="o-button -huge u-margin-small-y"
                                        to={renderRoute('parent-list')}
                                    >
                                        Cancel
                                    </RouterLink>
                                </TrackVisibility>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <button className="o-button -huge -green u-margin-small-y" type="submit">
                                        Create Parent
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                        </Layout>
                        <div className="o-form_overlay">
                            <div className="o-form_spinner u-text-center">
                                <Spinner />
                            </div>
                        </div>
                    </form>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

export default connect()(createForm()(ParentCreateView))
