import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const CurrentPassword = ({ form, hasNewPassword }) => (
    <div className="o-input_wrapper">
        <label htmlFor="currentPassword" className="o-label">
            Verify current password
        </label>
        {form.getFieldDecorator('currentPassword', {
            initialValue: '',
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            required: hasNewPassword,
                            message: 'Please verify your current password before changing it.',
                        },
                    ],
                },
            ],
        })(<input className="o-input" type="password" id="currentPassword" />)}
        <div className="o-input_error">{form.getFieldError('currentPassword')}</div>
    </div>
)

CurrentPassword.propTypes = {
    form: formShape,
    hasNewPassword: PropTypes.bool,
}

CurrentPassword.defaultProps = {
    hasNewPassword: false,
}

export default CurrentPassword
