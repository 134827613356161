import { apiClient } from '../helpers/ApiClient'

/**
 * Fetch an administrator.
 *
 * @param  {String} administratorId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchAdministrator = (administratorId, config = {}) => {
    return apiClient.get(`administrators/${administratorId}`, config)
}

/**
 * Update an administrator.
 *
 * @param  {String} administratorId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateAdministrator = (administratorId, formData, config = {}) => {
    return apiClient.patch(`administrators/${administratorId}`, formData, config)
}
