import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { fetchParent, fetchParentReports, fetchParentStudents } from '../actions/parentActions'
import { userCanDo } from '../helpers/auth'
import mapFetchToState from '../helpers/mapFetchToState'
import { renderRoute } from '../utils/routes'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { StudentCard } from '../components/StudentCard'
import { TrackVisibility } from '../components/TrackVisibility'
import { UserHeader } from '../components/User'

class ParentDetailsView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        isLoadingParent: true,
        isLoadingStudents: true,
        parent: null,
        reports: [],
        students: [],
    }

    loadParent(parentId) {
        this.setState({ isLoadingParent: true }, () => {
            fetchParent(parentId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        parent: response.data,
                        isLoadingParent: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoadingParent: false,
                        })
                    }
                })
        })
    }

    loadStudents(parentId) {
        const config = { cancelToken: this.signal.token }
        this.setState({ isLoadingStudent: true }, () => {
            mapFetchToState({
                students: fetchParentStudents(parentId, config),
                reports: fetchParentReports(parentId, config),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoadingStudent: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoadingStudent: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        const parentId = this.props.match.params.parentId
        this.loadParent(parentId)
        this.loadStudents(parentId)
    }

    componentDidUpdate(prevProps) {
        const parentId = this.props.match.params.parentId
        if (prevProps.match.params.parentId !== parentId) {
            this.loadParent(parentId)
            this.loadStudents(parentId)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    listItems() {
        const { parent, reports, students } = this.state

        if (!parent) {
            return []
        }

        return [
            [
                { label: 'Report Cards', value: reports.length },
                { label: 'Students', value: students.length },
            ],
            [
                {
                    label: 'Email',
                    value: <a href={'mailto:' + parent.email}>{parent.email}</a>,
                },
                {
                    label: 'Role',
                    value: parent.type,
                },
            ],
        ]
    }

    render() {
        const { error, parent, students, isLoadingParent, isLoadingStudents } = this.state
        const { user } = this.props
        const isLoading = isLoadingParent && isLoadingStudents

        const listItems = this.listItems()

        return (
            <Main>
                <Header>
                    Parent <br />
                    Profile
                </Header>
                {isLoading ? (
                    <UserHeader.Placeholder />
                ) : error !== null ? (
                    <section className="u-relative">{error.message}</section>
                ) : (
                    <React.Fragment>
                        <TrackVisibility overrideClass="u-anim-scroll">
                            <UserHeader listItems={listItems} user={parent} />
                        </TrackVisibility>
                        <Layout overrideClass="-gutter-small">
                            {students.map(student => (
                                <StudentCard key={student.id} student={student} />
                            ))}
                        </Layout>
                        {userCanDo(user, 'report-cards/object/student-parent.update') && (
                            <Layout>
                                <LayoutItem>
                                    <TrackVisibility overrideClass="u-anim-scroll">
                                        <RouterLink
                                            className="o-button -huge -green u-margin-small-y"
                                            to={renderRoute({
                                                id: 'parent-edit',
                                                bind: 'parentId',
                                                param: parent.id,
                                            })}
                                        >
                                            Edit parent
                                        </RouterLink>
                                    </TrackVisibility>
                                </LayoutItem>
                            </Layout>
                        )}
                    </React.Fragment>
                )}
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ParentDetailsView)
