import React from 'react'
import { Layout, LayoutItem } from '../Layout'
import { CardElement } from 'react-stripe-elements'
import { Button } from '../Button'
import propTypes from 'prop-types'

class BillingForm extends React.Component {
    state = {
        updatingCard: false,
    }

    static props = {
        form: propTypes.shape.isRequired,
        billingInformation: propTypes.object.isRequired,
    }

    render() {
        const { form, billingInformation } = this.props
        const { updatingCard } = this.state
        const { brand, last4, exp_month, exp_year } = billingInformation.currentSource || {}

        const currentCard = `************${last4} ${brand}, exp:${exp_month}/${exp_year}`

        return (
            <Layout overrideClass="-gutter-small">
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="name" className="o-label -required">
                            Name
                        </label>
                        {form.getFieldDecorator('name', {
                            initialValue: billingInformation.name || '',
                            validate: [
                                {
                                    trigger: 'onBlur',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a name',
                                        },
                                    ],
                                },
                            ],
                        })(<input type="text" placeholder="Name" autoFocus className="o-input" />)}
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="email" className="o-label -required">
                            Email
                        </label>
                        {form.getFieldDecorator('email', {
                            initialValue: billingInformation.email || '',
                            validate: [
                                {
                                    trigger: 'onBlur',
                                    rules: [
                                        {
                                            type: 'email',
                                            required: true,
                                            message: 'Please enter an email',
                                        },
                                    ],
                                },
                            ],
                        })(<input type="email" placeholder="email@email.com" className="o-input" />)}
                        <div className="o-input_error">{form.getFieldError('name')}</div>
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="address" className="o-label">
                            Address
                        </label>
                        {form.getFieldDecorator('address', {
                            initialValue: billingInformation.address || '',
                        })(<input type="text" placeholder="185 Berry Street Suite 550" className="o-input" />)}
                        <div className="o-input_error">{form.getFieldError('address')}</div>
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="city" className="o-label">
                            City
                        </label>
                        {form.getFieldDecorator('city', {
                            initialValue: billingInformation.city || '',
                        })(<input type="text" id="city" placeholder="Montreal" className="o-input" />)}
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="state" className="o-label">
                            State
                        </label>
                        {form.getFieldDecorator('province', {
                            initialValue: billingInformation.province || '',
                        })(<input type="text" id="state" placeholder="QC" className="o-input" />)}
                    </div>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <div className="o-input_wrapper">
                        <label htmlFor="state" className="o-label">
                            ZIP
                        </label>
                        {form.getFieldDecorator('postalCode', {
                            initialValue: billingInformation.postalCode || '',
                        })(<input type="text" id="state" placeholder="H2X 2V6" className="o-input" />)}
                    </div>
                </LayoutItem>
                <LayoutItem>
                    <div className="o-input_wrapper">
                        <label htmlFor="cb" className="o-label">
                            Card number
                        </label>
                        <Layout overrideClass="-gutter-small u-flex">
                            <LayoutItem>
                                {updatingCard ? (
                                    <React.Fragment>
                                        <CardElement className="o-input" />
                                        {form.getFieldDecorator('stripeSource', {
                                            initialValue: '',
                                        })(<input type="hidden" />)}
                                        <div className="o-input_error">{form.getFieldError('stripeSource')}</div>
                                    </React.Fragment>
                                ) : (
                                    <input type="text" placeholder={currentCard} disabled={true} className="o-input" />
                                )}
                            </LayoutItem>
                            <LayoutItem
                                extraProps={{
                                    style: {
                                        flex: '0 1 0',
                                    },
                                }}
                            >
                                <Button
                                    overrideClass="-small"
                                    onClick={click => this.setState({ updatingCard: !updatingCard })}
                                >
                                    {updatingCard ? 'Cancel' : 'Change'}
                                </Button>
                            </LayoutItem>
                        </Layout>
                    </div>
                    <input type="submit" hidden />
                </LayoutItem>
            </Layout>
        )
    }
}

export default BillingForm
