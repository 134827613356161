import React from 'react'
import { activeNavItem } from '../../utils/nav'
import { connect } from 'react-redux'

Breadcrumb.propTypes = {}

function Breadcrumb({ item }) {
    return <div className="breadcrumb"> {item && item.label} </div>
}

const mapStateToProps = state => {
    const {
        auth: { user },
        router: {
            location: { pathname },
        },
    } = state

    return {
        item: activeNavItem(pathname, user.role.id),
    }
}

export default connect(mapStateToProps)(Breadcrumb)
