import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute } from '../../utils/routes'

import { Layout, LayoutItem } from '../Layout'
import { MedalVisual } from '../MedalVisual'

const ListEditMedalItem = ({ medal }) => (
    <article className="list_item list-user-item">
        <RouterLink
            className="list_item_link u-padding-small-x"
            to={renderRoute({ id: 'medal-edit', bind: 'medalId', param: medal.id })}
        >
            <div className="list_item_link_inner u-padding-small-y">
                <Layout overrideClass="-gutter-small -middle">
                    <LayoutItem overrideClass="u-3/4@from-small">
                        <Layout overrideClass="-gutter-small -middle">
                            <LayoutItem overrideClass="u-1/3@from-small">
                                <MedalVisual image={medal.image.url} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-2/3@from-small">
                                <h1 className="list_item_title o-h2 u-margin-none">{medal.name}</h1>
                            </LayoutItem>
                        </Layout>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/4@from-small">
                        <p className="u-blue">{medal.programName}</p>
                    </LayoutItem>
                </Layout>
            </div>
        </RouterLink>
    </article>
)

ListEditMedalItem.propTypes = {
    medal: PropTypes.object.isRequired,
}

export default ListEditMedalItem
