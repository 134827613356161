import React from 'react'

import { fetchSessionDrafts } from '../actions/sessionDraftActions'

import SessionListView from './SessionListView'

const SessionDraftListView = props => (
    <SessionListView
        {...props}
        emptyLabel="No session drafts to display."
        fetchMethod={fetchSessionDrafts}
        requestState="FETCH_SESSION_DRAFT_LIST"
        storeKey="sessionDrafts"
        title="Draft Sessions"
    />
)

export default SessionDraftListView
