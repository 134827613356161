import React from 'react'

import { CurriculumForm } from '../components/Curriculum'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

const CurriculumCreateView = () => (
    <Main>
        <Header>
            Create <br /> a Curriculum
        </Header>
        <TrackVisibility overrideClass="u-anim-scroll">
            <CurriculumForm />
        </TrackVisibility>
        <Footer />
    </Main>
)

export default CurriculumCreateView
