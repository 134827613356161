import { apiClient } from '../helpers/ApiClient'

/**
 * Initiate the account confirmation via an email token.
 */
export const confirmUser = (userId, formData, config = {}) => {
    return apiClient.patch(`users/${userId}`, formData, config)
}

/**
 * Initiate the sending of a new confirmation email.
 */
export const resendConfirmationEmail = (formData, config = {}) => {
    return apiClient.post('users/email-confirmation', formData, config)
}

/**
 * Create a new login session, authenticating a specific user.
 */
export const CREATE_USER_LOGIN_REQUEST = 'CREATE_USER_LOGIN_REQUEST'
export const CREATE_USER_LOGIN_SUCCESS = 'CREATE_USER_LOGIN_SUCCESS'
export const CREATE_USER_LOGIN_FAILURE = 'CREATE_USER_LOGIN_FAILURE'
export const login = (formData, config = {}) => {
    return {
        types: [CREATE_USER_LOGIN_REQUEST, CREATE_USER_LOGIN_SUCCESS, CREATE_USER_LOGIN_FAILURE],
        promise: client => client.post('users/login', formData, config),
    }
}

/**
 * Delete a login session, unauthenticating the authenticated user.
 */
export const DELETE_USER_LOGIN_REQUEST = 'DELETE_USER_LOGIN_REQUEST'
export const DELETE_USER_LOGIN_SUCCESS = 'DELETE_USER_LOGIN_SUCCESS'
export const DELETE_USER_LOGIN_FAILURE = 'DELETE_USER_LOGIN_FAILURE'
export const USER_LOGOUT = 'USER_LOGOUT'
export const logout = (config = {}) => dispatch => {
    dispatch({ type: USER_LOGOUT })
}

/**
 * Retrieve the authenticated user's profile.
 */
export const FETCH_USER_SELF_REQUEST = 'FETCH_USER_SELF_REQUEST'
export const FETCH_USER_SELF_SUCCESS = 'FETCH_USER_SELF_SUCCESS'
export const FETCH_USER_SELF_FAILURE = 'FETCH_USER_SELF_FAILURE'
export function fetchSelf(config = {}) {
    return {
        types: [FETCH_USER_SELF_REQUEST, FETCH_USER_SELF_SUCCESS, FETCH_USER_SELF_FAILURE],
        promise: client => client.get('users/self', config),
    }
}

/**
 * Retrieve a user's profile based on their confirmation token.
 */
export function fetchUserByToken(token, config = {}) {
    return apiClient.get(`user-from-token/${token}`, config)
}

/**
 * Create a new reset-password token and sends a reset password link to user's email.
 * Does not use Redux, therefore no state altering actions are dispatched.
 * Kept here for code consolidation.
 */
export const createLostPasswordToken = (formData, config = {}) => {
    return apiClient.post('users/lost-password-tokens', formData, config)
}

/**
 * Consume a reset-password token and change a user's password.
 * Does not use Redux, therefore no state altering actions are dispatched.
 * Kept here for code consolidation.
 */
export const resetPassword = (tokenId, formData, config = {}) => {
    return apiClient.post(`users/lost-password-tokens/${tokenId}`, formData, config)
}

/**
 * Create a new access token for an authenticated user.
 */
export const AUTH_SET_TOKENS = 'AUTH_SET_TOKENS'
export const refreshAccessToken = (formData, config = {}) => dispatch => {
    return apiClient
        .post('users/self/access-tokens', formData, config)
        .then(response => {
            dispatch({ type: AUTH_SET_TOKENS, tokens: response.data })
        })
        .catch(error => {
            console.log('Could not refresh token.')
            console.log(error)
        })
}
