import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { createStudent, fetchAllStudents, fetchStudents } from '../actions/studentActions'
import { apiClient } from '../helpers/ApiClient'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { Footer } from '../components/Footer'
import { FormMessages } from '../components/FormMessages'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { Layout, LayoutItem } from '../components/Layout'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'
import { CreateStudentForm } from '../components/CreateStudentForm'

class StudentCreateView extends React.Component {
    state = {
        errors: [],
        isFormSubmitted: false,
        isSavingStudent: false,
        parentsListLoaded: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const { dispatch, form } = this.props
        const { isFormSubmitted, isSavingStudent } = this.state

        if (isFormSubmitted || isSavingStudent) {
            return false
        }

        form.validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingStudent: true,
                })
                createStudent(formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingStudent: false,
                        })
                        /** Update the parent list in store through `fetchParents`. Empty cache first. */
                        apiClient.clearCache('students')
                        dispatch(fetchStudents())
                        dispatch(fetchAllStudents())
                        dispatch(
                            push(
                                renderRoute({
                                    id: 'student-details',
                                    bind: 'studentId',
                                    param: response.data.id,
                                })
                            )
                        )
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingStudent: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })

        return false
    }

    handleBirthDateDatetimeChange = moment => {
        const {
            form: { setFieldsValue },
        } = this.props
        setFieldsValue({ birthDate: moment.format('YYYY-MM-DD') })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form } = this.props
        const { errors, isSavingStudent } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main>
                <Header>
                    Create <br /> a Student
                </Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <form
                        onSubmit={this.handleSubmit}
                        className={classNames('o-form', {
                            'is-loading': isSavingStudent,
                        })}
                    >
                        {hasErrors && (
                            <div className="o-input_wrapper">
                                <FormMessages hasErrors={true} items={notifications} />
                            </div>
                        )}
                        <CreateStudentForm form={form} />
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <RouterLink
                                        className="o-button -huge u-margin-small-y"
                                        to={renderRoute('student-list')}
                                    >
                                        Cancel
                                    </RouterLink>
                                </TrackVisibility>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <button className="o-button -huge -green u-margin-small-y" type="submit">
                                        Create Student
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                        </Layout>
                        <div className="o-form_overlay">
                            <div className="o-form_spinner u-text-center">
                                <Spinner />
                            </div>
                        </div>
                    </form>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

export default connect()(createForm()(StudentCreateView))
