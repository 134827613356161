import classNames from 'classnames'
import { Status as FilePondStatus } from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { isEmpty, isString } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { formShape } from 'rc-form'
import { FilePond, registerPlugin } from 'react-filepond'

import { Spinner } from '../Spinner'

const INITIAL_LABEL =
    '<span class="filepond--label-action-wrap"><span class="filepond--label-action">Browse</span></span>'

registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImageTransform,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
)

class Dropzone extends React.Component {
    filePondServer = `${process.env.REACT_APP_API_URL}/file-pond`

    state = {
        filePondInstance: null,
        hasNewFile: false,
    }

    handleRemoveFile = (error, file) => {
        const {
            form: { setFieldsValue },
            inputName,
        } = this.props
        this.setState({ hasNewFile: true })
        const options = {}
        options[inputName] = ''
        setFieldsValue(options)
    }

    handlePrepareFile = (file, output) => {
        const { onPrepareFile } = this.props

        onPrepareFile(output)
    }

    handleProcessFile = (error, file) => {
        const {
            form: { setFieldsValue },
            inputName,
            onProcessFile,
        } = this.props

        if (typeof file !== 'undefined') {
            onProcessFile(file)

            this.setState({ hasNewFile: true })
            const options = {}
            options[inputName] = file.serverId
            setFieldsValue(options)
        }
    }

    handleUpdateFiles = files => {
        // this.setState({
        //     files: files.map(file => file.file)
        // })
        // if (files.length > 0) {
        //     this.props.onChange(files[0].file, window.URL.createObjectURL(files[0].file), files[0].file.type)
        // }
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldError },
            initialFiles,
            initialValue,
            inputName,
            isRequired,
            isRounded,
            overrideClass,
            recommendedSize,
            validFileTypes,
        } = this.props
        const { hasNewFile } = this.state

        const label = isEmpty(recommendedSize)
            ? INITIAL_LABEL
            : `<br>Recommended size: <br/> ${recommendedSize} ${INITIAL_LABEL}`
        const filePondProps = isRounded
            ? {
                  styleButtonProcessItemPosition: 'center bottom',
                  styleButtonRemoveItemPosition: 'center bottom',
                  styleLoadIndicatorPosition: 'center bottom',
                  stylePanelLayout: 'compact circle',
                  styleProgressIndicatorPosition: 'center bottom',
              }
            : {
                  stylePanelLayout: 'compact',
              }
        const filePondClasses = classNames('o-filepond', overrideClass)
        const wrapperClasses = classNames('o-filepond-wrap')
        // const fileType = isEmpty(currentFile) ? '' : currentFile[0].type
        // const wrapperClasses = classNames('o-filepond-wrap', { '-video': fileType.includes('video') })

        if (!hasNewFile && !isEmpty(initialFiles)) {
            const files = isString(initialFiles) ? [initialFiles] : initialFiles
            filePondProps.files = files.map(file => {
                return {
                    source: file,
                    options: {
                        type: 'local',
                    },
                }
            })
        }

        const rules = isRequired
            ? [
                  {
                      required: true,
                      message: 'Please enter a file',
                  },
              ]
            : []

        return (
            <React.Fragment>
                <div className={wrapperClasses}>
                    <div className="dropzone_label">
                        <Spinner />
                    </div>
                    <FilePond
                        acceptedFileTypes={validFileTypes}
                        allowMultiple={false}
                        className={filePondClasses}
                        imagePreviewHeight={200}
                        labelIdle={label}
                        onpreparefile={this.handlePrepareFile}
                        onprocessfile={this.handleProcessFile}
                        onremovefile={this.handleRemoveFile}
                        onupdatefiles={this.handleUpdateFiles}
                        ref={ref => {
                            this.pond = ref
                        }}
                        required={false}
                        server={this.filePondServer}
                        {...filePondProps}
                    />
                </div>
                {getFieldDecorator(inputName, {
                    initialValue: initialValue,
                    validate: [
                        {
                            rules: [
                                ...rules,
                                {
                                    validator: (rule, value, callback) => {
                                        switch (this.pond._pond.status) {
                                            case FilePondStatus.BUSY:
                                            case FilePondStatus.ERROR:
                                                callback('The upload has not finished yet')
                                                break
                                            default:
                                                callback()
                                        }
                                    },
                                    message: 'The upload has not finished yet',
                                },
                            ],
                        },
                    ],
                })(<input type="text" hidden={true} />)}
                <div className="o-input_error">{getFieldError(inputName)}</div>
            </React.Fragment>
        )
    }
}

Dropzone.propTypes = {
    form: formShape.isRequired,
    inputName: PropTypes.string.isRequired,
    initialFiles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
    isRounded: PropTypes.bool,
    overrideClass: PropTypes.string,
    recommendedSize: PropTypes.string,
    validFileTypes: PropTypes.array,
    onPrepareFile: PropTypes.func,
    onProcessFile: PropTypes.func,
    onRemoveFile: PropTypes.func,
}

Dropzone.defaultProps = {
    initialFiles: [],
    initialValue: '',
    isRequired: false,
    isRounded: false,
    overrideClass: '',
    recommendedSize: '',
    validFileTypes: ['image/jpeg', 'image/png'],
    onPrepareFile: () => {},
    onProcessFile: () => {},
    onRemoveFile: () => {},
}

export default Dropzone
