import { isArray } from 'lodash-es'

export function formatFormMessages(items) {
    if (isArray(items)) {
        return items
    } else {
        if (typeof items.message === 'string') {
            return [items]
        } else {
            return []
        }
    }
}
