import {
    FETCH_ORGANIZATION_SUCCESS,
    FETCH_ORGANIZATION_FAILURE,
    UPDATE_ORGANIZATION_SUCCESS,
    // UPDATE_ORGANIZATION_FAILURE,
} from '../actions/organizationActions'

const initialState = {
    data: {},
    id: null,
    slug: null,
    wasQueried: false,
}

export function organization(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORGANIZATION_SUCCESS:
        case UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                data: action.result.data,
                id: action.result.data.id,
                slug: action.result.data.slug,
                wasQueried: true,
            }
        case FETCH_ORGANIZATION_FAILURE:
            // case UPDATE_ORGANIZATION_FAILURE:
            return {
                ...state,
                data: {},
                id: null,
                slug: null,
                wasQueried: true,
            }
        default:
            return state
    }
}
