import { find, sortBy } from 'lodash-es'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import FlipMove from 'react-flip-move'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { userCanDo } from '../../helpers/auth'
import randomIdent from '../../utils/randomIdent'

import { Container } from '../Container'
import { Fade } from '../Fade'
import { IconButton } from '../IconButton'
import { Label } from '../Label'
import { Layout, LayoutItem } from '../Layout'
import { MessagesItem } from '../Messages'

class Messages extends React.Component {
    state = {
        messageTextArea: '',
        hasLoaded: !this.props.isLoading,
        messages: this.props.messages,
    }

    onInputChange = event => {
        this.setState({
            messageTextArea: event.target.value,
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        const { messages } = this.state
        const { onNewMessage, user } = this.props

        const date = moment()

        const newMessage = {
            isNew: true,
            id: randomIdent(),
            date: {
                timestamp: date.unix(),
                display: date.format('MMM DD, YYYY'),
            },
            message: this.state.messageTextArea,
            user: user,
        }

        onNewMessage(newMessage)

        this.setState({
            messageTextArea: '',
            messages: [newMessage, ...messages],
        })
    }

    handleMessagesItemsDeleteItem = event => {
        const { messages } = this.state
        const { onDeleteMessage } = this.props
        const id = event.currentTarget.getAttribute('data-id')

        onDeleteMessage(find(messages, item => item.id === id))

        this.setState({
            messages: messages.filter(item => item.id !== id),
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.hasLoaded && !props.isLoading) {
            return {
                hasLoaded: true,
                messages: props.messages,
            }
        }

        return null
    }

    render() {
        const { user, readOnly, isLoading } = this.props
        const { messageTextArea, messages } = this.state

        // order by dates
        const filteredMessages = sortBy(messages, 'date.timestamp').reverse()

        return (
            <div className="messages u-padding-small">
                {userCanDo(user, 'report-cards/object/report-message.create') && readOnly !== true && (
                    <React.Fragment>
                        <h2>Write a message</h2>

                        <form onSubmit={this.handleSubmit}>
                            <Layout overrideClass="-bottom -gutter-small">
                                <LayoutItem overrideClass="u-3/4@from-small">
                                    <div className="o-input_wrapper">
                                        <Label inputId="description">Description</Label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            value={messageTextArea}
                                            onChange={this.onInputChange}
                                            placeholder="Type here"
                                            className="o-textarea"
                                        />
                                    </div>
                                </LayoutItem>
                                <LayoutItem overrideClass="u-1/4@from-small">
                                    <div className="o-input_wrapper">
                                        <IconButton type="submit" name="plus" />
                                    </div>
                                </LayoutItem>
                            </Layout>
                        </form>
                    </React.Fragment>
                )}
                {(!userCanDo(user, 'report-cards/object/report-message.create') || readOnly === true) && (
                    <React.Fragment>
                        <h2>{filteredMessages.length} Messages</h2>
                        {filteredMessages.length < 1 && (
                            <p className="u-text-center u-margin-small-y">No messages yet</p>
                        )}
                    </React.Fragment>
                )}
                <div className="messages_inner">
                    {userCanDo(user, 'report-cards/object/report-message.create') && readOnly !== true && (
                        <React.Fragment>
                            {filteredMessages.length > 0 && <h3>{filteredMessages.length} Messages</h3>}
                        </React.Fragment>
                    )}
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoading}>
                            {isLoading ? (
                                <MessagesItem.Placeholder />
                            ) : (
                                <div className="u-relative">
                                    <FlipMove
                                        typeName={null}
                                        easing="cubic-bezier(.5, 0, .5, 1)"
                                        appearAnimation="elevator"
                                        staggerDurationBy={15}
                                        staggerDelayBy={20}
                                        maintainContainerHeight={true}
                                    >
                                        {filteredMessages.map((message, index) => (
                                            <Container key={message.id}>
                                                <MessagesItem
                                                    canDelete={userCanDo(
                                                        user,
                                                        'report-cards/object/report-message.delete'
                                                    )}
                                                    handleDeleteItem={id => this.handleMessagesItemsDeleteItem(id)}
                                                    message={message}
                                                    readOnly={readOnly}
                                                />
                                            </Container>
                                        ))}
                                    </FlipMove>
                                </div>
                            )}
                        </Fade>
                    </SwitchTransition>
                </div>
            </div>
        )
    }
}

Messages.propTypes = {
    messages: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
    onNewMessage: PropTypes.func,
    onDeleteMessage: PropTypes.func,
}

Messages.defaultProps = {
    messages: [],
    readOnly: false,
    isLoading: false,
    onNewMessage: () => {},
    onDeleteMessage: () => {},
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(Messages)
