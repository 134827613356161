import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

const Spinner = ({ isSmall }) => (
    <svg className={classNames('spinner', { '-small': isSmall })} viewBox="25 25 50 50">
        <circle className="spinner_circle" cx="50" cy="50" r="20"></circle>
    </svg>
)

Spinner.propTypes = {
    isSmall: PropTypes.bool,
}

Spinner.defaultProps = {
    isSmall: false,
}

export default Spinner
