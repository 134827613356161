import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

export const ConfirmPassword = ({ form, hasNewPassword }) => (
    <div className="o-input_wrapper">
        <label htmlFor="passwordConfirmation" className="o-label">
            Confirm new password
        </label>
        {form.getFieldDecorator('passwordConfirmation', {
            initialValue: '',
            validateFirst: true,
            validate: [
                {
                    trigger: 'onBlur',
                    required: hasNewPassword,
                    rules: [
                        {
                            message: 'Both password fields must be identical',
                            validator: (rule, value, callback) => {
                                value !== form.getFieldValue('password') ? callback(false) : callback()
                            },
                        },
                    ],
                },
            ],
        })(<input className="o-input" type="password" id="passwordConfirmation" />)}
        <div className="o-input_error">{form.getFieldError('passwordConfirmation')}</div>
    </div>
)

ConfirmPassword.propTypes = {
    form: formShape,
    hasNewPassword: PropTypes.bool,
}

ConfirmPassword.defaultProps = {
    hasNewPassword: false,
}

export default ConfirmPassword
