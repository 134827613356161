import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import pad from '../../utils/pad'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'

const FlexibleListItemCheckbox = ({ handleCheckboxChange, inputId, isDisabled, item, number: index }) => (
    <li
        className={classNames('flexible-list_item', 'u-padding-small-x', {
            '-completed': item.isCompleted === true,
        })}
    >
        <Layout overrideClass="-gutter-small">
            <LayoutItem overrideClass="u-1/5@from-small u-1/8@to-small">
                <div className="flexible-list-item-checkbox">
                    <input
                        className="flexible-list-item-checkbox_input"
                        type="checkbox"
                        id={inputId}
                        onChange={handleCheckboxChange}
                        data-index={index}
                        defaultChecked={item.isCompleted}
                        disabled={isDisabled}
                    />
                    <label className="flexible-list-item-checkbox_label" htmlFor={inputId}>
                        {item.description}
                        <span className="flexible-list-item-checkbox_index">{pad(index + 1, 2)}</span>
                        <Icon name="check" overrideClass="flexible-list-item-checkbox_icon" />
                    </label>
                </div>
            </LayoutItem>
            <LayoutItem overrideClass="u-4/5@from-small u-7/8@to-small">{item.description}</LayoutItem>
        </Layout>
    </li>
)

FlexibleListItemCheckbox.propTypes = {
    handleCheckboxChange: PropTypes.func.isRequired,
    inputId: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
}

FlexibleListItemCheckbox.defaultProps = {
    isDisabled: false,
}

export default FlexibleListItemCheckbox
