import axios from 'axios'
import { fill, indexOf } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchStickers } from '../../actions/stickerActions'
import { userCanDo } from '../../helpers/auth'

import { Fade } from '../Fade'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'

class StickersSelector extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        stickers: [],
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchStickers({ cancelToken: this.signal.token }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoading !== this.props.isLoading && this.props.isLoading === false) {
            const stickers = this.state.stickers.map(sticker => {
                return {
                    ...sticker,
                    active: indexOf(this.props.selectedStickersIds, sticker.id) >= 0,
                    stack: this.props.reportStickers.filter(reportSticker => reportSticker.sticker.id === sticker.id),
                }
            })

            this.setState({
                stickers: stickers,
            })
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    handleChange = event => {
        const itemIndex = parseInt(event.currentTarget.getAttribute('data-index'))
        const { stickers } = this.state

        stickers[itemIndex].active = !stickers[itemIndex].active

        this.props.onStickerChange(stickers[itemIndex])

        this.setState({
            stickers: stickers,
        })
    }

    selectedStickers() {
        const { stickers } = this.state
        return stickers.filter(sticker => sticker.active).map(sticker => sticker.id)
    }

    render() {
        const { user, readOnly, isLoadingStickers, reportStickers } = this.props
        const { stickers, isLoading } = this.state

        return (
            <div className="stickers-selector u-padding-small">
                <h2>{readOnly ? `${reportStickers.length} Stickers` : 'Select Stickers (3 max)'}</h2>

                <SwitchTransition>
                    <Fade timeout={150} key={isLoadingStickers ? 'loading' : 'loaded'}>
                        <Layout overrideClass="-gutter-small stickers-selector_list">
                            {isLoadingStickers || isLoading
                                ? fill(Array(8), 'placeholder').map((item, index) => (
                                      <LayoutItem key={index} overrideClass="u-1/4@from-medium u-1/2@to-medium">
                                          <StickersSelector.Placeholder.Sticker />
                                      </LayoutItem>
                                  ))
                                : stickers.map((sticker, index) => (
                                      <LayoutItem key={index} overrideClass="u-1/4@from-medium u-1/2@to-medium">
                                          <div className="stickers-selector_item">
                                              <span className="stickers-selector_item_counter">
                                                  x {sticker.stack.length}
                                              </span>
                                              <figure className="stickers-selector_item_image">
                                                  <Icon name={sticker.image} />
                                                  {userCanDo(user, 'report-cards/object/report-sticker.create') &&
                                                      readOnly !== true && (
                                                          <div
                                                              className={
                                                                  this.selectedStickers().length >= 3
                                                                      ? `stickers-selector_item_checkbox_wrapper is-disabled`
                                                                      : `stickers-selector_item_checkbox_wrapper`
                                                              }
                                                          >
                                                              <input
                                                                  className="stickers-selector_item_checkbox"
                                                                  disabled={
                                                                      this.selectedStickers().length >= 3 &&
                                                                      !sticker.active
                                                                  }
                                                                  checked={sticker.active}
                                                                  type="checkbox"
                                                                  name={sticker.id}
                                                                  id={sticker.id}
                                                                  data-index={index}
                                                                  onChange={this.handleChange}
                                                              />
                                                              <label
                                                                  htmlFor={sticker.id}
                                                                  className="stickers-selector_item_checkbox_label"
                                                              />
                                                              <Icon
                                                                  name="plus"
                                                                  overrideClass="stickers-selector_item_checkbox_plus"
                                                              />
                                                              <Icon
                                                                  name="check"
                                                                  overrideClass="stickers-selector_item_checkbox_check"
                                                              />
                                                          </div>
                                                      )}
                                              </figure>
                                              <p className="stickers-selector_item_name">{sticker.name}</p>
                                          </div>
                                      </LayoutItem>
                                  ))}
                        </Layout>
                    </Fade>
                </SwitchTransition>
            </div>
        )
    }
}

StickersSelector.getDerivedStateFromProps = (props, state) => {
    if (props.stickers.length !== state.stickers.length) {
        return {
            stickers: props.stickers.map(sticker => {
                return {
                    ...sticker,
                    active: indexOf(props.selectedStickersIds, sticker.id) >= 0,
                    stack: props.reportStickers.filter(reportSticker => reportSticker.sticker.id === sticker.id),
                }
            }),
        }
    }
    return null
}

StickersSelector.propTypes = {
    isLoading: PropTypes.bool,
    isLoadingStickers: PropTypes.bool,
    stickers: PropTypes.array,
    selectedStickersIds: PropTypes.array,
    reportStickers: PropTypes.array,
    readOnly: PropTypes.bool,
    onStickerChange: PropTypes.func,
}

StickersSelector.defaultProps = {
    isLoading: false,
    isLoadingStickers: true,
    stickers: [],
    selectedStickersIds: [],
    reportStickers: [],
    readOnly: false,
    onStickerChange: () => {},
}

StickersSelector.Placeholder = {
    Sticker: () => (
        <ContentLoader height={219} width={194} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <circle cx="97" cy="75" r="75" />
            <rect x="61" y="170" width="75" height="15" />
        </ContentLoader>
    ),
}

const mapStateToProps = state => ({
    user: state.auth.user,
    isLoadingStickers: state.requestStates.FETCH_STICKER_LIST === true,
    stickers: state.stickers.entities,
})

export default connect(mapStateToProps)(StickersSelector)
