import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Nav } from '../Nav'
import { Sidebar } from '../Sidebar'
import { ScrollToTop } from '../ScrollToTop'
import { TransitionSpinner } from '../TransitionSpinner'
import { SubscriptionStatusBar } from '../SubscriptionStatusBar'
import { ModalRoot } from '../Modal'
import { Breadcrumb } from '../Breadcrumb'

const Main = ({ children, isPublic, isSimple, layoutClass }) => (
    <main
        className={classNames('main', layoutClass, {
            '-public': isPublic,
            '-simple': isSimple,
        })}
    >
        {!isPublic && <SubscriptionStatusBar />}
        {!isSimple && !isPublic && <Nav />}
        {!isSimple && !isPublic && <Breadcrumb />}

        <div className="main_inner">
            {children}
            <TransitionSpinner />
        </div>
        {!isSimple && !isPublic && <Sidebar />}
        <ScrollToTop />
        <ModalRoot />
    </main>
)

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    isPublic: PropTypes.bool,
    isSimple: PropTypes.bool,
    layoutClass: PropTypes.string,
}

Main.defaultProps = {
    isPublic: false,
    isSimple: false,
    layoutClass: '',
}

export default Main
