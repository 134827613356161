import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { Slide } from '../Fade'
import { SwitchTransition } from 'react-transition-group'

import { MESSAGE_MODAL } from './ModalTypes'
import { MODAL_COMPONENTS } from './ModalComponents'

import { hideModal, loadModal } from '../../actions/modalActions'

const ModalRoot = ({ modalType, modalProps, hideModal, showModal }) => {
    const SpecificModal = MODAL_COMPONENTS[modalType]
    return (
        <Modal
            isOpen={!!modalType}
            contentLabel="Modal"
            portalClassName="o-modal"
            overlayClassName="o-modal_overlay"
            className="o-modal_inner"
            htmlOpenClassName="ReactModal__Html--open"
            closeTimeoutMS={150}
            onRequestClose={() => hideModal()}
        >
            <SwitchTransition>
                <Slide appear={true} timeout={150} key={modalType || 'none'}>
                    {SpecificModal && (
                        <SpecificModal
                            onCancel={() => hideModal()}
                            onSuccess={() => hideModal()}
                            onFailure={error =>
                                showModal(MESSAGE_MODAL, {
                                    message: error.message,
                                })
                            }
                            {...modalProps}
                        />
                    )}
                </Slide>
            </SwitchTransition>
        </Modal>
    )
}

ModalRoot.propTypes = {
    modalProps: PropTypes.object,
    modalType: PropTypes.string,
}

const mapStateToProps = state => state.modal

const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(hideModal()),
    showModal: (modalType, modalProps = {}) => dispatch(loadModal(modalType, modalProps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot)
