import React from 'react'
import { SwitchTransition } from 'react-transition-group'

import { exportAsPdf } from '../../helpers/exportAsPdf'
import { AchievementIntro } from '../Achievement'
import { Fade } from '../Fade'
import { Header } from '../Header'
import { List, ListCertificateItem } from '../List'
import { TrackVisibility } from '../TrackVisibility'

const StudentMedalDetails = props => {
    const {
        isLoading,
        organization,
        studentMedal,
        studentMedal: { certificates, medal, student },
    } = props

    const introListItems = isLoading
        ? []
        : [
              { label: 'Medal Name', value: medal.name },
              { label: 'Program', value: medal.programName },
              { label: 'Organization', value: organization.name },
              { label: 'Date', value: studentMedal.date.display },
          ]

    const handleAchievementIntroClickDownload = event => {
        exportAsPdf(`${student.name}_${medal.name}_${studentMedal.date.datetime}.pdf`)
    }

    return (
        <React.Fragment>
            <Header>Student Medal</Header>
            <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                <SwitchTransition>
                    <Fade timeout={150} key={isLoading}>
                        {isLoading ? (
                            <AchievementIntro.Placeholder />
                        ) : (
                            <AchievementIntro
                                handleClickDownload={handleAchievementIntroClickDownload}
                                image={medal.image.url}
                                listItems={introListItems}
                                programLogo={medal.programLogo.url}
                                sharerProps={{
                                    label: 'Share medal',
                                    text: 'Check out my Medal!',
                                    url: studentMedal.shareUrl,
                                }}
                                student={student}
                                title={medal.name}
                            />
                        )}
                    </Fade>
                </SwitchTransition>
                <section className="u-relative">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoading}>
                            {isLoading ? (
                                <List.Placeholder />
                            ) : (
                                <List title="Certificates completed">
                                    {certificates.map(certificate => (
                                        <ListCertificateItem key={certificate.id} studentCertificate={certificate} />
                                    ))}
                                </List>
                            )}
                        </Fade>
                    </SwitchTransition>
                </section>
            </TrackVisibility>
        </React.Fragment>
    )
}

export default StudentMedalDetails
