import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const Email = ({ form, initialValue }) => (
    <div className="o-input_wrapper">
        <label htmlFor="email" className="o-label -required">
            Email
        </label>
        {form.getFieldDecorator('email', {
            initialValue: initialValue,
            validateFirst: true,
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            type: 'email',
                            required: true,
                            message: 'Please enter an email',
                        },
                    ],
                },
            ],
        })(<input type="email" id="email" placeholder="example@example.com" className="o-input" />)}
        <div className="o-input_error">{form.getFieldError('email')}</div>
    </div>
)

Email.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
}

Email.defaultProps = {
    initialValue: '',
}

export default Email
