import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'
import { fetchSkus } from '../../actions/skuAction'

const withSkus = WrappedComponent => {
    class EnhancedComponent extends React.Component {
        signal = axios.CancelToken.source()

        componentDidMount() {
            const { dispatch } = this.props

            dispatch(fetchSkus({ cancelToken: this.signal.token }))
        }

        componentWillUnmount() {
            this.signal.cancel('API call is being canceled.')
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        skus: {
            isLoading: state.requestStates.FETCH_SKU_LIST === true,
            items: state.skus.entities,
        },
    })

    return connect(mapStateToProps)(EnhancedComponent)
}

export default withSkus
