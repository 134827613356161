import React from 'react'

import PropTypes from 'prop-types'

import { Layout, LayoutItem } from '../Layout'

const ItemManagerCurriculumItem = ({ item }) => (
    <Layout overrideClass="-gutter-small -middle">
        <LayoutItem overrideClass="u-1/2@from-small">{item.certificationCode}</LayoutItem>
        <LayoutItem overrideClass="u-1/2@from-small">{item.name}</LayoutItem>
    </Layout>
)

ItemManagerCurriculumItem.propTypes = {
    item: PropTypes.shape({
        certificationCode: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
}

export default ItemManagerCurriculumItem
