import React from 'react'

import { Spinner } from '../Spinner'

const TransitionSpinner = () => (
    <div className="transition-spinner">
        <Spinner />
    </div>
)

export default TransitionSpinner
