import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'

import { withSkus } from '../withSkus'
import { addReportCards } from '../../actions/membershipActions'
import { Layout, LayoutItem } from '../Layout'
import { formatFormMessages } from '../../utils/forms'
import { FormMessages } from '../FormMessages'

class AddReportCardsModal extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        isSubmitting: false,
        errors: [],
    }

    handleSubmit = event => {
        event.preventDefault()
        const { onSuccess, skuId } = this.props
        const { isSubmitting } = this.state

        if (isSubmitting) {
            return false
        }

        this.setState(
            {
                errors: [],
                isSubmitting: true,
            },
            () => {
                addReportCards(
                    {
                        sku: skuId,
                    },
                    { cancelToken: this.signal.token }
                )
                    .then(response => {
                        this.setState({
                            errors: [],
                            isSubmitting: false,
                        })

                        onSuccess()
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSubmitting: false,
                        })
                    })
            }
        )
    }

    getTotal(sku) {
        return ((sku.unitPrice * sku.quantity) / 100).toFixed(2)
    }

    getUnitaryPrice(sku) {
        return (sku.unitPrice / 100).toFixed(2)
    }

    render() {
        const { skuId, onCancel, skus } = this.props
        const { errors, isSubmitting } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []
        const hasNotifications = !isEmpty(notifications)
        const sku = skus.items.find(item => item.id === skuId) || []
        const infos = [
            { label: 'Quantity', value: sku.quantity },
            { label: 'Unitary price', value: `${this.getUnitaryPrice(sku)} CDN` },
            { label: 'Total', value: `${this.getTotal(sku)} CDN` },
        ]

        return (
            !skus.isLoading && (
                <div className="o-modal_wrapper">
                    <div className="o-modal_content">
                        <h2 className="o-modal_title ">
                            You're about to buy <br />
                            <span className="u-blue">{sku.quantity} report cards</span>
                        </h2>
                        <div className="o-modal_summary">
                            <p>
                                This amount will be charged using the stored billing information on your account. Once
                                purchased, your report cards will not expire and will remain on this account until used.
                            </p>

                            <hr />
                            {hasNotifications && (
                                <div className="o-input_wrapper">
                                    <FormMessages hasErrors={hasErrors} hasSuccess={!hasErrors} items={notifications} />
                                </div>
                            )}
                            <Layout overrideClass="-right">
                                <LayoutItem overrideClass="u-2/3@from-small">
                                    <div className="simple-list">
                                        {infos.map(({ label, value }, index) => (
                                            <Layout overrideClass="simple-list_line" key={index}>
                                                <LayoutItem overrideClass="u-1/2">
                                                    <p className="simple-list_label">{label}</p>
                                                </LayoutItem>
                                                <LayoutItem overrideClass="u-1/2 u-text-right">
                                                    <p className="simple-list_value">{value}</p>
                                                </LayoutItem>
                                            </Layout>
                                        ))}
                                    </div>
                                </LayoutItem>
                            </Layout>
                        </div>
                        <hr />
                        <div className="o-modal_actions">
                            <div className="u-text-center">
                                <button
                                    autoFocus
                                    className={classNames('o-button', '-full', '-green', '-spinner', {
                                        'is-loading': isSubmitting,
                                    })}
                                    onClick={e => this.handleSubmit(e)}
                                >
                                    <span className="o-button_inner">Authorize payment</span>
                                </button>
                                <button className="link -red" onClick={() => onCancel()}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
}

AddReportCardsModal.propTypes = {
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    skuId: PropTypes.string.isRequired,
}

AddReportCardsModal.defaultProps = {
    onCancel: () => {},
    onSuccess: () => {},
}

export default withSkus(AddReportCardsModal)
