import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'billing-information'

/**
 * Fetch the billing information of the current organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchBillingInformation = (config = {}) => {
    return apiClient.get(baseUrl, config)
}

/**
 * Update the billing information of the current organization.
 *
 * @param  {Object} config
 * @param  {FormData} formData
 * @return {Promise}
 */
export const updateBillingInformation = (formData, config = {}) => {
    return apiClient.patch(baseUrl, formData, config)
}
