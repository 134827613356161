// import React, { useRef, useLayoutEffect, useState } from 'react'
import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import UserAvatar from 'react-user-avatar'

const UserImage = ({ overrideClass, user, url }) => {
    // const ref = useRef(0)
    // const [height, setHeight] = useState(0)

    // useLayoutEffect(() => {
    //     setHeight(ref.current.clientHeight)
    // }, [])

    //<figure ref={ref} className={classNames(overrideClass)}>
    return (
        <figure className={classNames(overrideClass)}>
            {url ? (
                <img src={url} alt="" />
            ) : (
                <UserAvatar name={user.name} src={user.profileImage.url} color="#FBFBFB" />
            )}
        </figure>
    )
}

UserImage.propTypes = {
    overrideClass: PropTypes.string,
    user: PropTypes.shape({
        name: PropTypes.string,
        profileImage: PropTypes.object,
    }),
}

UserImage.defaultProps = {
    overrideClass: '',
}

export default UserImage
