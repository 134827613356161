import React from 'react'

import { connect } from 'react-redux'

import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

import { Icon } from '../components/Icon'
import { Button } from '../components/Button'
import { Footer } from '../components/Footer'

const NotFoundView = ({ auth, organizationId }) => {
    const isPublic = !auth.isAuthenticated || organizationId === null
    return (
        <Main isPublic={isPublic}>
            {isPublic && <Icon name="logo" overrideClass="not-found_logo" />}

            <TrackVisibility overrideClass="u-anim-scroll">
                <div className="not-found_title-wrapper">
                    <h1 className="not-found_title">
                        Page not <br />
                        found
                    </h1>
                    <Icon name="not-found-smiley" overrideClass="not-found_icon" />
                </div>
            </TrackVisibility>

            {isPublic ? (
                <Button overrideClass="not-found_button" link="/">
                    Back to home
                </Button>
            ) : (
                <Footer />
            )}
        </Main>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps)(NotFoundView)
