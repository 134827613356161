import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { SwitchTransition } from 'react-transition-group'

import { AchievementIntro } from '../../components/Achievement'
import { Fade } from '../../components/Fade'
import { FlexibleList } from '../../components/FlexibleList'
import { Gallery } from '../../components/Gallery'
import { Header } from '../../components/Header'
import { Messages } from '../../components/Messages'
import { StickersSelector } from '../../components/Stickers'
import { TrackVisibility } from '../../components/TrackVisibility'
import { ReportDetailsView } from '../../views'

const CertificateDetails = props => {
    const {
        certificate,
        certificate: { student, sport },
        gallery,
        isLoadingCertificate,
        isLoadingGallery,
        isLoadingMessages,
        isLoadingReportStickers,
        isLoadingTasks,
        messages,
        organization,
        reportStickers,
        tasks,
    } = props

    const { session } = certificate || {}
    const { curriculum } = session || {}
    const introListItems = isLoadingCertificate
        ? []
        : [
              { label: 'Curriculum', value: curriculum.name },
              { label: 'Program', value: curriculum.programName },
              { label: 'Organization', value: organization.name },
              { label: 'Date', value: certificate.date.display },
          ]

    const handleAchievementIntroClickDownload = event => {
        window.open(certificate.pdfUrl, '_blank')
    }

    return (
        <React.Fragment>
            <TrackVisibility overrideClass="u-anim-scroll">
                <SwitchTransition>
                    <Fade timeout={150} key={isLoadingCertificate}>
                        {isLoadingCertificate ? (
                            <ReportDetailsView.Placeholder.Header />
                        ) : (
                            <Header>
                                {sport.name} <br />
                                Certification
                            </Header>
                        )}
                    </Fade>
                </SwitchTransition>
            </TrackVisibility>
            <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                <SwitchTransition>
                    <Fade timeout={150} key={isLoadingCertificate}>
                        {isLoadingCertificate ? (
                            <AchievementIntro.Placeholder />
                        ) : (
                            <AchievementIntro
                                button={
                                    isEmpty(session.registrationUrl)
                                        ? null
                                        : {
                                              label: 'Register for next level',
                                              url: session.registrationUrl,
                                          }
                                }
                                handleClickDownload={handleAchievementIntroClickDownload}
                                image={curriculum.certificationImage.url}
                                listItems={introListItems}
                                programLogo={curriculum.programLogo.url}
                                sharerProps={{
                                    label: 'Share certificate',
                                    text: 'Check out my Certificate!',
                                    url: certificate.shareUrl,
                                }}
                                student={student}
                                title={curriculum.certificationCode}
                            />
                        )}
                    </Fade>
                </SwitchTransition>

                <section className="u-card u-margin-y u-relative">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingTasks}>
                            {isLoadingTasks ? (
                                <ReportDetailsView.Placeholder.TaskList />
                            ) : (
                                <FlexibleList
                                    title="All steps completed!"
                                    headers={['Step #', 'Description']}
                                    items={tasks.map(task => ({
                                        isCompleted: task.isCompleted,
                                        description: task.sessionTask.description,
                                    }))}
                                    isProgress
                                    isEditable={false}
                                    isDisabled={true}
                                />
                            )}
                        </Fade>
                    </SwitchTransition>
                    <Gallery gallery={gallery} isLoading={isLoadingGallery} readOnly />
                    <StickersSelector reportStickers={reportStickers} isLoading={isLoadingReportStickers} readOnly />
                    <Messages messages={messages} isLoading={isLoadingMessages} readOnly />
                </section>
            </TrackVisibility>
        </React.Fragment>
    )
}

CertificateDetails.propTypes = {
    certificate: PropTypes.object,
    gallery: PropTypes.array,
    isLoadingCertificate: PropTypes.bool,
    isLoadingGallery: PropTypes.bool,
    isLoadingMessages: PropTypes.bool,
    isLoadingReportStickers: PropTypes.bool,
    isLoadingTasks: PropTypes.bool,
    messages: PropTypes.array,
    organization: PropTypes.object,
    reportStickers: PropTypes.array,
    sport: PropTypes.object,
    tasks: PropTypes.array,
}

CertificateDetails.defaultProps = {
    certificate: null,
    gallery: [],
    isLoadingCertificate: true,
    isLoadingGallery: true,
    isLoadingMessages: true,
    isLoadingReportStickers: true,
    isLoadingTasks: true,
    messages: [],
    organization: {},
    reportStickers: [],
    sport: {},
    tasks: [],
}

export default CertificateDetails
