import { apiClient } from '../helpers/ApiClient'

export const baseUrl = 'reports'

/**
 * Fetch a list of all reports for the active organization for the logged in user.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_REPORT_LIST_REQUEST = 'FETCH_REPORT_LIST_REQUEST'
export const FETCH_REPORT_LIST_SUCCESS = 'FETCH_REPORT_LIST_SUCCESS'
export const FETCH_REPORT_LIST_FAILURE = 'FETCH_REPORT_LIST_FAILURE'
export const FETCH_REPORT_LIST_PENDING = 'FETCH_REPORT_LIST_PENDING'
export const fetchReports = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_REPORT_LIST_REQUEST,
            FETCH_REPORT_LIST_SUCCESS,
            FETCH_REPORT_LIST_FAILURE,
            FETCH_REPORT_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a report.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReport = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}`, config)
}

/**
 * Update a report.
 *
 * @param  {String} reportId
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}
 */
export const updateReport = (reportId, data, config = {}) => {
    return apiClient.patch(`${baseUrl}/${reportId}`, data, config)
}

/**
 * Fetch a report messages
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReportMessages = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}/messages`, config)
}

/**
 * Create a report messages
 *
 * @param  {String} reportId
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}
 */
export const createReportMessage = (reportId, data, config = {}) => {
    return apiClient.post(`${baseUrl}/${reportId}/messages`, data, config)
}

/**
 * Delete a report messages
 *
 * @param  {String} reportId
 * @param  {String} messageId
 * @param  {Object} config
 * @return {Promise}
 */
export const deleteReportMessage = (reportId, messageId, config = {}) => {
    return apiClient.delete(`${baseUrl}/${reportId}/messages/${messageId}`, config)
}

/**
 * Fetch a report's stickers.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReportStickers = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}/stickers`, config)
}

/**
 * Fetch a report's latest stickers.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReportLatestStickers = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}/stickers/latest`, config)
}

/**
 * Create a report's sticker.
 *
 * @param  {String} reportId
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}
 */
export const createReportSticker = (reportId, data, config = {}) => {
    return apiClient.post(`${baseUrl}/${reportId}/stickers`, data, config)
}

/**
 * Delete a report's sticker.
 *
 * @param  {String} reportId
 * @param  {string} stickerId
 * @param  {Object} config
 * @return {Promise}
 */
export const deleteReportSticker = (reportId, reportStickerId, config = {}) => {
    return apiClient.delete(`${baseUrl}/${reportId}/stickers/${reportStickerId}`, config)
}

/**
 * Fetch a report's tasks.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReportTasks = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}/tasks`, config)
}

/**
 * Update a report's tasks.
 *
 * @param  {String} reportId
 * @param  {String} taskId
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}
 */
export const updateReportTasks = (reportId, taskId, data, config = {}) => {
    return apiClient.patch(`${baseUrl}/${reportId}/tasks/${taskId}`, data, config)
}

/**
 * Fetch a report's medias.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchReportMedias = (reportId, config = {}) => {
    return apiClient.get(`${baseUrl}/${reportId}/medias`, config)
}

/**
 * Create a report's media.
 *
 * @param  {String} reportId
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}
 */
export const createReportMedias = (reportId, data, config = {}) => {
    return apiClient.post(`${baseUrl}/${reportId}/medias`, data, config)
}

/**
 * Delete a report's media.
 *
 * @param  {String} reportId
 * @param  {Object} mediaId
 * @param  {Object} config
 * @return {Promise}
 */
export const deleteReportMedias = (reportId, mediaId, config = {}) => {
    return apiClient.delete(`${baseUrl}/${reportId}/medias/${mediaId}`, config)
}

/**
 * Fetch all the data required to display a Report publicly.
 *
 * @param  {String} reportId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchPublicReport = (reportId, config = {}) => {
    return apiClient.get(`public/${baseUrl}/${reportId}`, config)
}
