import axios from 'axios'
import classNames from 'classnames'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { fetchAdministrator, updateAdministrator } from '../actions/administratorActions'
import { fetchSelf } from '../actions/userActions'
import { isCurrentUser } from '../helpers/auth'
import { getHistory } from '../store'
import { formatFormMessages } from '../utils/forms'
import { Footer } from '../components/Footer'
import { FormMessages } from '../components/FormMessages'
import {
    ConfirmPassword,
    CurrentPassword,
    Email,
    FirstName,
    LastName,
    NewPassword,
    ProfileImage,
} from '../components/FormFields'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class AdministratorEditView extends React.Component {
    state = {
        errors: [],
        hasNewPassword: false,
        initialValues: Immutable.Map({}),
        isAdministratorLoaded: false,
        isAdministratorLoading: true,
        isFormSubmitted: false,
        isSavingAdministrator: false,
    }

    signal = axios.CancelToken.source()

    handleNewPasswordInputChange = event => {
        const {
            form: { validateFields },
        } = this.props
        this.setState(
            {
                hasNewPassword: event.target.value !== '',
            },
            () => {
                validateFields(['passwordConfirmation'], { force: true })
            }
        )
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            form: { setFieldsValue, validateFields },
            dispatch,
            objectId,
        } = this.props
        const { isSavingAdministrator } = this.state

        if (isSavingAdministrator) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingAdministrator: true,
                })
                updateAdministrator(objectId, formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            hasNewPassword: false,
                            isFormSubmitted: true,
                            isSavingAdministrator: false,
                        })
                        dispatch(fetchSelf())
                        setFieldsValue({
                            currentPassword: '',
                            password: '',
                            passwordConfirmation: '',
                            profileImage: response.data.profileImage.path,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingAdministrator: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })

        return false
    }

    setAdministratorFormValues(values) {
        this.setState({
            initialValues: Immutable.Map({
                ...values,
                profileImage: values.profileImage.path,
            }),
            isAdministratorLoaded: true,
        })
    }

    componentDidMount() {
        const { objectId } = this.props

        this.setState({ isAdministratorLoading: true }, () => {
            fetchAdministrator(objectId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setAdministratorFormValues(response.data)
                    this.setState({
                        isAdministratorLoading: false,
                    })
                })
                .catch(error => {
                    console.warn(error)
                    if (!axios.isCancel(error)) {
                        window.scrollTo(0, 0)
                        this.setState({
                            errors: error.response.data,
                            isAdministratorLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form, user } = this.props
        const {
            errors,
            hasNewPassword,
            initialValues,
            isAdministratorLoading,
            isFormSubmitted,
            isSavingAdministrator,
        } = this.state
        const hasErrors = !isEmpty(errors)
        const successMessage = isCurrentUser(user, initialValues.toObject())
            ? 'Your account has been saved successfully.'
            : 'Administrator has been saved successfully.'
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? [{ message: successMessage }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <Main>
                <Header>My Account</Header>
                <form
                    autoComplete="off"
                    className={classNames('o-form', {
                        'is-loading': isAdministratorLoading || isSavingAdministrator,
                    })}
                    onSubmit={this.handleSubmit}
                >
                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        {hasNotifications && (
                            <div className="o-input_wrapper">
                                <FormMessages
                                    hasErrors={hasErrors}
                                    hasSuccess={isFormSubmitted}
                                    items={notifications}
                                />
                            </div>
                        )}
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <FirstName form={form} initialValue={initialValues.get('firstName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <LastName form={form} initialValue={initialValues.get('lastName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Email form={form} initialValue={initialValues.get('email')} />
                                <CurrentPassword form={form} hasNewPassword={hasNewPassword} />
                                <NewPassword form={form} handleInputChange={this.handleNewPasswordInputChange} />
                                <ConfirmPassword form={form} hasNewPassword={hasNewPassword} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} initialValue={initialValues.get('profileImage')} />
                            </LayoutItem>
                        </Layout>
                    </TrackVisibility>

                    <Layout overrideClass="-gutter-small u-margin-small-y">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                <button className="o-button -huge" onClick={() => getHistory().goBack()} type="button">
                                    Cancel
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                <button className="o-button -huge -green" type="submit">
                                    Save
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                    </Layout>
                    <div className="o-form_overlay">
                        <div className="o-form_spinner u-text-center">
                            <Spinner />
                        </div>
                    </div>
                </form>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(createForm()(AdministratorEditView))
