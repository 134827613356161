import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const NewPassword = ({ form: { getFieldDecorator, getFieldError }, handleInputChange }) => (
    <div className="o-input_wrapper">
        <label htmlFor="password" className="o-label">
            New password
        </label>
        {getFieldDecorator('password', {
            initialValue: '',
        })(<input className="o-input" type="password" id="password" onChange={handleInputChange} />)}
        <div className="o-input_error">{getFieldError('password')}</div>
    </div>
)

NewPassword.propTypes = {
    form: formShape,
    handleInputChange: PropTypes.func.isRequired,
}

export default NewPassword
