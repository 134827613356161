import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute } from '../../utils/routes'

const Link = ({ children, overrideClass = '', route = null, to = null }) => {
    const classes = classNames('link', overrideClass)
    return route !== null ? (
        <RouterLink className={classes} to={renderRoute(route)}>
            {children}
        </RouterLink>
    ) : (
        <a href={to} className={classes}>
            {children}
        </a>
    )
}

Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    overrideClass: PropTypes.string,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.string,
}

export default Link
