import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Icon } from '../Icon'

const IconButton = ({ handleClick, isSmall, name, type }) => (
    <button type={type} className={classNames('icon-button', { '-small': isSmall })} onClick={handleClick}>
        <Icon name={name} />
    </button>
)

IconButton.propTypes = {
    handleClick: PropTypes.func,
    isSmall: PropTypes.bool,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
}

IconButton.defaultProps = {
    handleClick: () => {},
    isSmall: false,
    type: 'button',
}

export default IconButton
