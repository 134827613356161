import PropTypes from 'prop-types'
import React from 'react'

import { connect } from 'react-redux'
import { ADD_REPORT_CARDS_MODAL } from '../Modal/ModalTypes'

import { loadModal } from '../../actions/modalActions'

import { Layout } from '../Layout'
import { LayoutItem } from '../Layout'
import { SelectableList } from '../SelectableList'
import { SwitchTransition } from 'react-transition-group'
import { Slide } from '../Fade'
import { withSkus } from '../withSkus'
import { Spinner } from '../Spinner'

class PlanReportCards extends React.Component {
    state = {
        selectedSkuId: null,
    }

    onSelectSku(skuId) {
        this.setState({
            selectedSkuId: skuId,
        })
    }

    getSku() {
        const { selectedSkuId } = this.state
        const { skus } = this.props

        return (
            skus.items.find(item => {
                return item.id === selectedSkuId
            }) || []
        )
    }

    onReportAdded() {
        const { onReportAdded } = this.props

        this.setState(
            {
                selectedSkuId: null,
            },
            onReportAdded
        )
    }

    render() {
        const { remainingReportCards, showModal, skus } = this.props
        const { selectedSkuId } = this.state

        return (
            <div className="u-card u-margin-y">
                <header className="card-header">Add Report Cards</header>
                <div className="u-padding-small">
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="u-text-big u-margin-none">
                                Current Count <br />
                                <span className="u-blue">
                                    <span className="u-inline-block">
                                        <SwitchTransition>
                                            <Slide
                                                appear={false}
                                                key={remainingReportCards}
                                                timeout={150}
                                                distance={10}
                                            >
                                                {remainingReportCards} + &nbsp;
                                            </Slide>
                                        </SwitchTransition>
                                    </span>
                                    <span className="u-inline-block">
                                        <SwitchTransition>
                                            <Slide appear={false} key={selectedSkuId} timeout={150} distance={10}>
                                                {this.getSku().quantity || 0}
                                            </Slide>
                                        </SwitchTransition>
                                    </span>
                                </span>
                            </div>
                            <LayoutItem overrideClass="u-3/4@from-small">
                                <p className="u-text-tiny">
                                    Top up any time, from $0.18 per Report Card. Report Cards credit stays active forever.
                                </p>
                            </LayoutItem>
                        </LayoutItem>
                        {skus.isLoading ? (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Spinner />
                            </LayoutItem>
                        ) : (
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <SelectableList
                                    items={skus.items.map(item => ({
                                        value: item.id,
                                        label: `${item.quantity} report cards`,
                                        content: `${item.amount / 100} CDN, (${item.unitPrice / 100} each)`,
                                    }))}
                                    name="reports-select"
                                    defaultValue={selectedSkuId}
                                    onChange={(e, item) => this.onSelectSku(item.value)}
                                />

                                <Layout overrideClass="-gutter-small u-margin-small-y">
                                    <LayoutItem overrideClass="u-1/2">
                                        <button
                                            disabled={!selectedSkuId}
                                            className="o-button -full"
                                            onClick={() =>
                                                showModal(ADD_REPORT_CARDS_MODAL, {
                                                    skuId: selectedSkuId,
                                                    onSuccess: () => this.onReportAdded(),
                                                })
                                            }
                                        >
                                            Buy now
                                        </button>
                                    </LayoutItem>
                                </Layout>
                            </LayoutItem>
                        )}
                    </Layout>
                </div>
            </div>
        )
    }
}

PlanReportCards.propTypes = {
    onReportAdded: PropTypes.func,
}

PlanReportCards.defaultProps = {
    onReportAdded: () => {},
}

const mapStateToProps = state => ({
    remainingReportCards: state.organization.data.reportCards.remaining,
})

const mapDispatchToProps = dispatch => ({
    showModal: (modalType, modalProps = {}) => dispatch(loadModal(modalType, modalProps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withSkus(PlanReportCards))
