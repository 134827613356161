import moment from 'moment'

import { FETCH_PLAN_LIST_REQUEST, FETCH_PLAN_LIST_SUCCESS, FETCH_PLAN_LIST_FAILURE } from '../actions/planAction'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function plans(state = initialState, action) {
    switch (action.type) {
        case FETCH_PLAN_LIST_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_PLAN_LIST_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_PLAN_LIST_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
