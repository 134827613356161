import classNames from 'classnames'
import { isArray } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const FormMessages = ({ hasErrors, hasSuccess, items }) => {
    const listClass = classNames('form-messages', { 'has-errors': hasErrors }, { 'has-success': hasSuccess })
    return (
        <Fragment>
            {isArray(items) && items.length !== 0 && (
                <ul className={listClass}>
                    {items.map((item, index) => (
                        <li className="form-messages_item" key={index}>
                            {item.message}
                        </li>
                    ))}
                </ul>
            )}
        </Fragment>
    )
}

FormMessages.propTypes = {
    hasErrors: PropTypes.bool,
    hasSuccess: PropTypes.bool,
    items: PropTypes.array,
}

FormMessages.defaultProps = {
    hasErrors: false,
    hasSuccess: false,
    items: [],
}

export default FormMessages
