import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import propTypes from 'prop-types'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'

import { fetchPlans } from '../../actions/planAction'
import { updateSubscription } from '../../actions/subscriptionAction'
import { updateBillingInformation } from '../../actions/BillingInformationAction'

import { withBillingInformation } from '../withBillingInformation'
import { withStripeWrapper } from './index'

import { Layout, LayoutItem } from '../Layout'
import { formatFormMessages } from '../../utils/forms'
import { Spinner } from '../Spinner'
import { BillingForm } from '../BillingForm'
import { FormMessages } from '../FormMessages'

class SubscribeModal extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        isFormSubmitting: false,
        isFormSubmitted: false,
        errors: [],
    }

    handleSubmit = async event => {
        event.preventDefault()

        const {
            form: { setFieldsValue, validateFields, getFieldInstance },
        } = this.props
        const { isFormSubmitting } = this.state

        if (isFormSubmitting) {
            return false
        }

        // Inject tokenized card into form.
        if (getFieldInstance('stripeSource') !== undefined) {
            let { token, error } = await this.props.stripe.createToken()

            if (error) {
                this.setState({
                    errors: error,
                    isFormSubmitting: false,
                })

                return false
            }

            setFieldsValue({
                stripeSource: token.id,
            })
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState(
                    {
                        errors: [],
                        isFormSubmitting: true,
                        isFormSubmitted: false,
                    },
                    () => {
                        updateBillingInformation(formValues, {
                            cancelToken: this.signal.token,
                        })
                            .then(response => {
                                this.subscribeToPlan()
                            })
                            .catch(error => {
                                this.setState({
                                    errors: error.response.data,
                                    isFormSubmitting: false,
                                })
                            })
                    }
                )
            }
        })

        return false
    }

    subscribeToPlan() {
        const { selectedPlanId, onSuccess } = this.props

        updateSubscription({ stripePlanId: selectedPlanId }, { cancelToken: this.signal.token })
            .then(response => {
                this.setState({
                    errors: [],
                    isFormSubmitted: true,
                    isFormSubmitting: false,
                })

                onSuccess()
            })
            .catch(error => {
                console.warn(error)
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: error.response.data,
                        isFormSubmitting: false,
                    })
                }
            })
    }

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchPlans({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { form, onCancel, plans, selectedPlanId, billingInformation } = this.props
        const { errors, isFormSubmitting } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []
        const hasNotifications = !isEmpty(notifications)
        const plan = plans.filter(item => item.stripePlanId === selectedPlanId)[0]

        return (
            <div className="o-modal_wrapper">
                <div className="o-modal_content">
                    <h2 className="o-modal_title">
                        Subscribe to <span className="u-blue">{plan.name} plan</span>
                    </h2>
                    <div className="o-modal_summary">
                        <p>
                            Fill the form below.
                        </p>
                    </div>
                    <hr />
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={!hasErrors} items={notifications} />
                        </div>
                    )}
                    {billingInformation.isLoading ? (
                        <Layout>
                            <LayoutItem overrideClass="u-text-center u-padding">
                                <Spinner />
                            </LayoutItem>
                        </Layout>
                    ) : (
                        <form
                            onSubmit={e => this.handleSubmit(e)}
                            className={classNames('o-form', {
                                'is-loading': isFormSubmitting,
                            })}
                        >
                            <BillingForm form={form} billingInformation={billingInformation.data} />
                            <div className="o-form_overlay">
                                <div className="o-form_spinner u-text-center">
                                    <Spinner />
                                </div>
                            </div>
                        </form>
                    )}
                    <div className="o-modal_actions">
                        <Layout overrideClass="-gutter-small -middle u-text-center">
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <button className="o-button -full" onClick={() => onCancel()}>
                                    Cancel
                                </button>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                                <button className="o-button -full -green" onClick={event => this.handleSubmit(event)}>
                                    Subscribe to plan
                                </button>
                            </LayoutItem>
                        </Layout>
                    </div>
                </div>
            </div>
        )
    }

    static props = {
        onCancel: propTypes.func,
        onSuccess: propTypes.func,
        selectedPlanId: propTypes.string.isRequired,
    }

    static defaultProps = {
        onCancel: () => {},
        onSuccess: () => {},
    }
}

const mapStateToProps = state => {
    return {
        plans: state.plans.entities,
        isLoadingPlans: state.requestStates.FETCH_PLAN_LIST === true,
    }
}

const InjectedSubscribeModal = injectStripe(
    connect(mapStateToProps)(createForm()(withBillingInformation(SubscribeModal)))
)

export default withStripeWrapper(InjectedSubscribeModal)
