let urls = new WeakMap()

/**
 * Ensures that no duplicate URLs are generated from blob.
 * Plus it's garbage collector friendly.
 *
 * @param blob
 * @return {string|any}
 */
export const blobUrl = blob => {
    if (urls.has(blob)) {
        return urls.get(blob)
    } else {
        let url = URL.createObjectURL(blob)
        urls.set(blob, url)
        return url
    }
}
