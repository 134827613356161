import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { renderRoute } from '../../utils/routes'

import { FilteredList, FilteredListItem } from '../FilteredList'

const HEADERS = [
    { id: 'curriculum', label: 'Curriculum' },
    { id: 'name', label: 'Name' },
    { id: 'instructor', label: 'Instructor' },
]

class SessionListContainer extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch, fetchMethod } = this.props
        dispatch(fetchMethod({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { listProps, isLoading, isSmall, items, sport } = this.props

        return (
            <FilteredList
                emptyLabel={`No ${sport.name} sessions`}
                hasLogo={true}
                hasSorting={true}
                isLoading={isLoading}
                isSmall={isSmall}
                itemComponent={FilteredListItem}
                listHeaders={HEADERS}
                listItems={items}
                title={`${items.length} sessions`}
                buttonData={{ label: 'Create a session', route: 'session-create' }}
                {...listProps}
            />
        )
    }
}

SessionListContainer.propTypes = {
    fetchMethod: PropTypes.func.isRequired,
    isSmall: PropTypes.bool,
    isDraft: PropTypes.bool,
    listProps: PropTypes.object,
    requestState: PropTypes.string.isRequired,
    storeKey: PropTypes.string.isRequired,
}

SessionListContainer.defaultProps = {
    isSmall: false,
    isDraft: false,
    listProps: {},
}

const mapStateToProps = (state, ownProps) => {
    const { requestState, storeKey, isDraft } = ownProps
    return {
        isLoading: state.requestStates[requestState] === true,
        items: state[storeKey].entities.map(item => {
            return {
                id: item.id,
                columns: {
                    curriculum: item.curriculum.name,
                    name: item.name,
                    instructor: item.instructor.name,
                },
                data: item,
                url: renderRoute({
                    id: isDraft ? 'session-draft-edit' : 'session-edit',
                    bind: 'sessionId',
                    param: item.id,
                }),
            }
        }),
        sport: state.auth.user.sport,
    }
}

export default connect(mapStateToProps)(SessionListContainer)
