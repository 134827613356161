import React from 'react'

import ContentLoader from 'react-content-loader'

import { userCanDo } from '../helpers/auth'

import { Footer } from '../components/Footer'
import { Main } from '../components/Main'
import { ReportDetails, ReportEdit, withReportDetailsData } from '../components/Report'

const ReportDetailsView = ({ reportData, user }) => (
    <Main>
        {userCanDo(user, 'report-cards/object/report.update') ? (
            <ReportEdit {...reportData} />
        ) : (
            <ReportDetails {...reportData} />
        )}
        <Footer />
    </Main>
)

ReportDetailsView.Placeholder = {
    Header: () => (
        <ContentLoader height={171} width={900} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="0" y="6" rx="0" ry="0" width="209" height="55" />
            <rect x="0" y="74" rx="0" ry="0" width="362" height="55" />
        </ContentLoader>
    ),
    CardHeader: () => (
        <ContentLoader height={34} width={900} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="0" y="6" rx="0" ry="0" width="209" height="55" />
        </ContentLoader>
    ),
    TaskList: () => (
        <ContentLoader
            className="u-margin-y"
            height={313}
            width={898}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="13" y="0" width="55" height="22" />
            <circle cx="29" cy="62" r="15" />
            <circle cx="29" cy="121" r="15" />
            <circle cx="29" cy="181" r="15" />
            <circle cx="29" cy="238" r="15" />
            <circle cx="29" cy="298" r="15" />
            <rect x="190" y="0" width="96" height="22" />
            <rect x="190" y="49" width="281" height="22" />
            <rect x="190" y="108" width="619" height="22" />
            <rect x="190" y="168" width="445" height="22" />
            <rect x="190" y="226" width="281" height="22" />
            <rect x="190" y="286" width="182" height="22" />
            <rect x="0" y="30" width="898" height="2" />
            <rect x="0" y="91" width="898" height="2" />
            <rect x="0" y="149" width="898" height="2" />
            <rect x="0" y="208" width="898" height="2" />
            <rect x="0" y="266" width="898" height="2" />
        </ContentLoader>
    ),
}

export default withReportDetailsData()(ReportDetailsView)
