import { filter } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { isCurrentUser } from '../../helpers/auth'
import { renderRoute } from '../../utils/routes'

import { CertificateVisual } from '../Certificate'
import { Layout, LayoutItem } from '../Layout'
import { SimpleList } from '../SimpleList'

const ListCertificateItem = ({ studentCertificate, user, hideStudent }) => {
    const {
        session: { curriculum },
    } = studentCertificate

    const listItems = filter([
        !hideStudent &&
            (user !== null && !isCurrentUser(user, studentCertificate.student)) && {
                label: 'Student',
                value: studentCertificate.student.name,
            },
        { label: 'Date', value: studentCertificate.date.display },
    ])

    return (
        <article className="list_item list-user-item">
            <RouterLink
                className="list_item_link u-padding-small-x"
                to={renderRoute({
                    id: 'certificate-details',
                    bind: 'certificateId',
                    param: studentCertificate.id,
                })}
            >
                <div className="list_item_link_inner u-padding-small-y">
                    <Layout overrideClass="-gutter-small -middle">
                        <LayoutItem overrideClass="u-3/4@from-small">
                            <Layout overrideClass="-gutter-small -middle">
                                <LayoutItem overrideClass="u-1/3@from-small">
                                    <CertificateVisual
                                        image={curriculum.certificationImage.url}
                                        name={curriculum.certificationCode}
                                    />
                                </LayoutItem>
                                <LayoutItem overrideClass="u-2/3@from-small">
                                    <h1 className="list_item_title o-h2 u-margin-none">{curriculum.name}</h1>
                                </LayoutItem>
                            </Layout>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-small">
                            <SimpleList items={listItems} />
                        </LayoutItem>
                    </Layout>
                </div>
            </RouterLink>
        </article>
    )
}

ListCertificateItem.propTypes = {
    studentCertificate: PropTypes.object.isRequired,
    hideStudent: PropTypes.bool,
}

ListCertificateItem.defaultProps = {
    hideStudent: false,
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
}

export default connect(mapStateToProps)(ListCertificateItem)
