import React from 'react'

import { CertificateDetails, withCertificateDetailsData } from '../components/Certificate'
import { Footer } from '../components/Footer'
import { Main } from '../components/Main'

const CertificateDetailsView = ({ certificateData }) => (
    <Main>
        <CertificateDetails {...certificateData} />
        <Footer />
    </Main>
)

export default withCertificateDetailsData()(CertificateDetailsView)
