import axios from 'axios'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { deleteParent, fetchParent, fetchParents, updateParent } from '../actions/parentActions'
import { fetchSelf } from '../actions/userActions'
import { apiClient } from '../helpers/ApiClient'
import { getHistory } from '../store'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { Footer } from '../components/Footer'
import {
    ConfirmPassword,
    CurrentPassword,
    Email,
    FirstName,
    LastName,
    NewPassword,
    ProfileImage,
} from '../components/FormFields'
import { FormMessages } from '../components/FormMessages'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class ParentEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        errors: [],
        hasNewPassword: false,
        initialValues: Immutable.Map({}),
        isParentLoading: true,
        isFormSubmitted: false,
        isSavingParent: false,
        isParentLoaded: false,
    }

    handleNewPasswordInputChange = event => {
        const {
            form: { validateFields },
        } = this.props
        this.setState(
            {
                hasNewPassword: event.target.value !== '',
            },
            () => {
                validateFields(['passwordConfirmation'], { force: true })
            }
        )
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            isParentConnected,
            parentId,
        } = this.props
        const { isSavingParent } = this.state

        if (isSavingParent) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingParent: true,
                })
                updateParent(parentId, formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            hasNewPassword: false,
                            isFormSubmitted: true,
                            isSavingParent: false,
                        })
                        apiClient.clearCache('student-parents')
                        dispatch(fetchParents())
                        /** Refresh the connected user. */
                        if (isParentConnected) {
                            dispatch(fetchSelf())
                        }
                        setFieldsValue({
                            currentPassword: '',
                            password: '',
                            passwordConfirmation: '',
                            profileImage: response.data.profileImage.path,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingParent: false,
                        })
                    })
                    .finally(() => window.scrollTo(0, 0))
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    setParentFormValues(values) {
        this.setState({
            initialValues: Immutable.Map({
                ...values,
                profileImage: values.profileImage.path,
            }),
            isParentLoaded: true,
        })
    }

    handleDelete = event => {
        const { dispatch, parentId } = this.props

        if (window.confirm('Are you sure you want to delete this account')) {
            this.setState({ isParentLoading: true }, () => {
                deleteParent(parentId, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState(
                            {
                                isParentLoading: false,
                            },
                            () => {
                                dispatch(push(renderRoute('parent-list')))
                            }
                        )
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            this.setState({
                                errors: error.response.data,
                                isParentLoading: false,
                            })
                        }
                    })
            })
        }
    }

    componentDidMount() {
        const { parentId } = this.props

        this.setState({ isParentLoading: true }, () => {
            fetchParent(parentId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setParentFormValues(response.data)
                    this.setState({
                        isParentLoading: false,
                    })
                })
                .catch(error => {
                    console.warn(error)
                    if (!axios.isCancel(error)) {
                        window.scrollTo(0, 0)
                        this.setState({
                            errors: error.response.data,
                            isParentLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            form,
            form: { getFieldDecorator, getFieldError },
            isParentConnected,
            title,
        } = this.props
        const { errors, hasNewPassword, initialValues, isFormSubmitted, isParentLoading, isSavingParent } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? isParentConnected
                ? [{ message: 'Your info has been saved successfully.' }]
                : [{ message: 'Parent has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <Main>
                <Header>{title}</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isFormSubmitted} items={notifications} />
                        </div>
                    )}
                    <form
                        autoComplete="off"
                        className={classNames('o-form', {
                            'is-loading': isParentLoading || isSavingParent,
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <FirstName form={form} initialValue={initialValues.get('firstName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <LastName form={form} initialValue={initialValues.get('lastName') || ''} />
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="role" className="o-label -required">
                                        Role
                                    </label>
                                    <div className="o-select_wrapper">
                                        {getFieldDecorator('type', {
                                            initialValue: initialValues.get('type') || '',
                                            validate: [
                                                {
                                                    trigger: 'onBlur',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please choose a role',
                                                        },
                                                    ],
                                                },
                                            ],
                                        })(
                                            <select id="role" className="o-select">
                                                <option value="" disabled>
                                                    Choose a Role
                                                </option>
                                                <option value="parent">Parent</option>
                                                <option value="guardian">Guardian</option>
                                            </select>
                                        )}
                                    </div>
                                    <div className="o-input_error">{getFieldError('type')}</div>
                                </div>
                                <Email form={form} initialValue={initialValues.get('email')} />
                                {isParentConnected && (
                                    <React.Fragment>
                                        <CurrentPassword form={form} hasNewPassword={hasNewPassword} />
                                        <NewPassword
                                            form={form}
                                            handleInputChange={this.handleNewPasswordInputChange}
                                        />
                                        <ConfirmPassword form={form} hasNewPassword={hasNewPassword} />
                                    </React.Fragment>
                                )}
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <ProfileImage form={form} initialValue={initialValues.get('profileImage')} />
                            </LayoutItem>
                        </Layout>
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                    <button
                                        className="o-button -huge u-margin-tiny-y"
                                        onClick={() => getHistory().goBack()}
                                        type="button"
                                    >
                                        Cancel
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                    <button className="o-button -huge -green u-margin-tiny-y" type="submit">
                                        Save
                                    </button>
                                </TrackVisibility>
                            </LayoutItem>
                        </Layout>
                        <div className="o-form_overlay">
                            <div className="o-form_spinner u-text-center">
                                <Spinner />
                            </div>
                        </div>
                    </form>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

ParentEditView.defaultProps = {
    title: (
        <React.Fragment>
            Edit <br />
            Parent
        </React.Fragment>
    ),
}

const mapStateToProps = (state, ownProps) => {
    if (state.auth.user.pairedObjectId === ownProps.objectId) {
        return {
            isParentConnected: true,
            parentId: ownProps.objectId,
        }
    } else {
        return {
            isParentConnected: false,
            parentId: ownProps.match.params.parentId,
        }
    }
}

export default connect(mapStateToProps)(createForm()(ParentEditView))
