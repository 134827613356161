import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'student-medals'

/**
 * Fetch a list of all student medals for the active organization for the logged in user.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_STUDENT_MEDAL_LIST_REQUEST = 'FETCH_STUDENT_MEDAL_LIST_REQUEST'
export const FETCH_STUDENT_MEDAL_LIST_SUCCESS = 'FETCH_STUDENT_MEDAL_LIST_SUCCESS'
export const FETCH_STUDENT_MEDAL_LIST_FAILURE = 'FETCH_STUDENT_MEDAL_LIST_FAILURE'
export const FETCH_STUDENT_MEDAL_LIST_PENDING = 'FETCH_STUDENT_MEDAL_LIST_PENDING'
export const fetchStudentMedals = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_STUDENT_MEDAL_LIST_REQUEST,
            FETCH_STUDENT_MEDAL_LIST_SUCCESS,
            FETCH_STUDENT_MEDAL_LIST_FAILURE,
            FETCH_STUDENT_MEDAL_LIST_PENDING,
        ],
    }
}

/**
 * Get a medal.
 *
 * @param  {String} certificateId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchStudentMedal = (medalId, config = {}) => {
    return apiClient.get(`${baseUrl}/${medalId}`, config)
}

/**
 * Fetch all the data required to display a Medal publicly.
 *
 * @param  {String} medalId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchPublicStudentMedal = (medalId, config = {}) => {
    return apiClient.get(`public/${baseUrl}/${medalId}`, config)
}
