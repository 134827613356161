import { filter } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { isCurrentUser } from '../../helpers/auth'
import { renderRoute } from '../../utils/routes'

import { Layout, LayoutItem } from '../Layout'
import { MedalVisual } from '../MedalVisual'
import { SimpleList } from '../SimpleList'

const ListMedalItem = ({ hideStudent, studentMedal, user }) => {
    const { medal } = studentMedal

    const listItems = filter([
        !hideStudent &&
            !isCurrentUser(user, studentMedal.student) && { label: 'Student', value: studentMedal.student.name },
        { label: 'Date', value: studentMedal.date.display },
    ])

    return (
        <article className="list_item list-user-item">
            <RouterLink
                className="list_item_link u-padding-small-x"
                to={renderRoute({
                    id: 'student-medal-details',
                    bind: 'medalId',
                    param: studentMedal.id,
                })}
            >
                <div className="list_item_link_inner u-padding-small-y">
                    <Layout overrideClass="-gutter-small -middle">
                        <LayoutItem overrideClass="u-3/4@from-small">
                            <Layout overrideClass="-gutter-small -middle">
                                <LayoutItem overrideClass="u-1/3@from-small">
                                    <MedalVisual image={medal.image.url} />
                                </LayoutItem>
                                <LayoutItem overrideClass="u-2/3@from-small">
                                    <h1 className="list_item_title o-h2 u-margin-none">{medal.name}</h1>
                                </LayoutItem>
                            </Layout>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/4@from-small">
                            <SimpleList items={listItems} />
                        </LayoutItem>
                    </Layout>
                </div>
            </RouterLink>
        </article>
    )
}

ListMedalItem.propTypes = {
    studentMedal: PropTypes.object.isRequired,
    hideStudent: PropTypes.bool,
}

ListMedalItem.defaultProps = {
    hideStudent: false,
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ListMedalItem)
