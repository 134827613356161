import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { SwitchTransition } from 'react-transition-group'

import { fetchReportLatestStickers, fetchReportMessages, fetchReportTasks } from '../../actions/reportActions'
import mapFetchToState from '../../helpers/mapFetchToState'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'
import { Fade } from '../Fade'
import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { Logo } from '../Logo'
import { Progress } from '../Progress'
import { SimpleList } from '../SimpleList'
import { StickersList } from '../Stickers'

class ReportCard extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        isLoading: true,
        lastMessage: [],
        ReportStickers: [],
        tasks: [],
    }

    loadReportDetails() {
        const { report } = this.props

        this.setState({ isLoading: true }, () => {
            const config = { cancelToken: this.signal.token }

            mapFetchToState({
                lastMessage: fetchReportMessages(report.id, {
                    ...config,
                    params: { limit: 1 },
                }),
                reportStickers: fetchReportLatestStickers(report.id, config),
                tasks: fetchReportTasks(report.id, config),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        this.loadReportDetails()
    }

    completedTasks() {
        return this.state.tasks.filter(task => task.isCompleted)
    }

    progress() {
        return Math.ceil((this.completedTasks().length / this.state.tasks.length) * 100)
    }

    listItems() {
        const {
            report: { session },
        } = this.props
        const { tasks } = this.state

        return [
            { label: 'Session', value: session.name },
            {
                label: 'Steps',
                value: `${this.completedTasks().length}/${tasks.length}`,
            },
            { label: 'Started', value: session.startDate.display },
            { label: 'Ends', value: session.endDate.display },
        ]
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const listItems = this.listItems()
        const {
            isFull,
            overrideClass,
            report: {
                id,
                session: { curriculum },
            },
        } = this.props
        const { isLoading, lastMessage, reportStickers } = this.state

        return (
            <React.Fragment>
                <SwitchTransition>
                    <Fade timeout={150} key={isLoading}>
                        {isLoading ? (
                            <ReportCard.Placeholder isFull={isFull} />
                        ) : (
                            <article
                                className={classNames(
                                    'report-card',
                                    'u-card',
                                    'u-margin-y',
                                    'u-fade-in',
                                    overrideClass
                                )}
                            >
                                <CardHeader>
                                    <Logo /> {curriculum.name}
                                </CardHeader>
                                <div className="u-padding-tiny-y u-padding-small-x">
                                    <Layout overrideClass="-reverse -middle">
                                        <LayoutItem overrideClass="u-1/2@from-small">
                                            <div className="u-text-center">
                                                <Progress
                                                    overrideClass={classNames('-margin', { '-full': isFull })}
                                                    value={this.progress()}
                                                />
                                            </div>
                                        </LayoutItem>
                                        <LayoutItem overrideClass="u-1/2@from-small">
                                            <SimpleList items={listItems} />
                                            {isFull && lastMessage.length > 0 && (
                                                <LastMessage message={lastMessage.shift()} />
                                            )}
                                        </LayoutItem>
                                    </Layout>
                                    {isFull && <StickersList reportStickers={reportStickers} />}
                                    {!isFull && lastMessage.length > 0 && <LastMessage message={lastMessage.shift()} />}
                                </div>
                                <CardFooter>
                                    <div className="u-inline-block u-align-middle">
                                        <Button
                                            route={{
                                                id: 'report-details',
                                                bind: 'reportId',
                                                param: id,
                                            }}
                                            overrideClass="card-footer_cta"
                                        >
                                            Full report
                                        </Button>
                                    </div>
                                    {!isFull && (
                                        <div className="u-inline-block u-align-middle">
                                            <Link route="report-list">View all</Link>
                                        </div>
                                    )}
                                </CardFooter>
                            </article>
                        )}
                    </Fade>
                </SwitchTransition>
            </React.Fragment>
        )
    }
}

ReportCard.propTypes = {
    isFull: PropTypes.bool,
    overrideClass: PropTypes.string,
    report: PropTypes.object.isRequired,
}

ReportCard.defaultProps = {
    isFull: false,
    overrideClass: '',
}

ReportCard.Placeholder = ({ isFull, overrideClass }) => (
    <div className={classNames('report-card u-card u-margin-y', overrideClass)}>
        {isFull ? (
            <ContentLoader height={451} width={921} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                <rect x="16" y="24" rx="0" ry="0" width="15" height="19" />
                <rect x="40" y="24" rx="0" ry="0" width="250" height="19" />
                <rect x="16" y="92" rx="0" ry="0" width="46" height="17" />
                <rect x="16" y="130" rx="0" ry="0" width="33" height="17" />
                <rect x="16" y="168" rx="0" ry="0" width="46" height="17" />
                <rect x="16" y="206" rx="0" ry="0" width="29" height="17" />
                <rect x="400" y="92" rx="0" ry="0" width="61" height="17" />
                <rect x="417" y="130" rx="0" ry="0" width="45" height="17" />
                <rect x="399" y="168" rx="0" ry="0" width="63" height="17" />
                <rect x="382" y="206" rx="0" ry="0" width="80" height="17" />
                <rect x="16" y="251" rx="0" ry="0" width="120" height="17" />
                <rect x="80" y="290" rx="0" ry="0" width="376" height="17" />
                <rect x="80" y="315" rx="0" ry="0" width="350" height="17" />
                <rect x="80" y="340" rx="0" ry="0" width="290" height="17" />
                <circle cx="41" cy="311" r="27" />
                <rect x="14" y="386" rx="5" ry="5" width="132" height="49" />
                <circle cx="681" cy="220" r="124" />
                <circle cx="859" cy="57" r="32" />
                <circle cx="858" cy="136" r="32" />
                <circle cx="860" cy="218" r="32" />
                <rect x="0" y="65" rx="0" ry="0" width="921" height="2" />
                <rect x="0" y="372" rx="0" ry="0" width="921" height="2" />
                <rect x="16" y="119" rx="0" ry="0" width="444" height="2" />
                <rect x="16" y="156" rx="0" ry="0" width="444" height="2" />
                <rect x="16" y="195" rx="0" ry="0" width="444" height="2" />
                <rect x="16" y="232" rx="0" ry="0" width="444" height="2" />
            </ContentLoader>
        ) : (
            <ContentLoader height={579} width={537} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                <rect x="37" y="30" width="15" height="20" />
                <rect x="68" y="30" width="194" height="20" />
                <rect x="1" y="78" width="536" height="1" />
                <rect x="1" y="487" width="536" height="1" />
                <rect x="37" y="158" width="232" height="1" />
                <rect x="37" y="205" width="232" height="1" />
                <rect x="36" y="130" width="51" height="12" />
                <rect x="36" y="177" width="44" height="12" />
                <rect x="166" y="130" width="102" height="12" />
                <rect x="193" y="177" width="75" height="12" />
                <rect x="37" y="252" width="232" height="1" />
                <rect x="36" y="224" width="57" height="12" />
                <rect x="188" y="224" width="80" height="12" />
                <rect x="37" y="315" width="465" height="1" />
                <rect x="36" y="271" width="37" height="12" />
                <rect x="166" y="271" width="102" height="12" />
                <circle cx="413.5" cy="206.5" r="74.5" />
                <rect x="36" y="338" width="125" height="17" />
                <circle cx="61" cy="400" r="25" />
                <rect x="102" y="380" width="382" height="17" />
                <rect x="102" y="408" width="392" height="17" />
                <rect x="102" y="436" width="386" height="17" />
                <rect x="213" y="522" width="61" height="20" />
                <rect x="36" y="505" width="145" height="52" rx="5" />
            </ContentLoader>
        )}
    </div>
)

const LastMessage = ({ message }) => (
    <div className="report-card_main">
        <p>Latest comment</p>
        <div className="report-card_comment">
            <div className="report-card_comment_user">
                <img src={message.user.profileImage.url} alt={message.user.name} />
            </div>
            <div className="report-card_comment_content">
                <p>{message.message}</p>
            </div>
        </div>
    </div>
)

LastMessage.propTypes = {
    message: PropTypes.shape({
        message: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
    }).isRequired,
}

export default ReportCard
