import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { Label } from '../Label'
import { Dropzone } from '../Dropzone'

const ProgramLogo = ({ form, initialValue, isRequired }) => (
    <div className="o-input_wrapper">
        <Label isRequired={isRequired}>Program Logo</Label>
        <Dropzone
            form={form}
            initialFiles={initialValue}
            initialValue={initialValue}
            inputName="programLogo"
            recommendedSize="350&times;200px"
        />
    </div>
)

ProgramLogo.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
}

ProgramLogo.defaultProps = {
    initialValue: '',
    isRequired: false,
}

export default ProgramLogo
