import axios from 'axios'
import classNames from 'classnames'
import Immutable from 'immutable'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { fetchOrganization, updateOrganization } from '../actions/organizationActions'
import { apiClient } from '../helpers/ApiClient'
import { getHistory } from '../store'
import { formatFormMessages } from '../utils/forms'

import { Dropzone } from '../components/Dropzone'
import { Footer } from '../components/Footer'
import { Telephone } from '../components/FormFields'
import { FormMessages } from '../components/FormMessages'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'

class AccountOrganizationEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        errors: [],
        initialValues: Immutable.Map({}),
        isOrganizationLoading: true,
        isFormSubmitted: false,
        isSavingOrganization: false,
        isOrganizationLoaded: false,
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({
            isFormSubmitted: false,
        })

        const {
            dispatch,
            form: { setFieldsValue, validateFields },
            organization,
        } = this.props
        const { isSavingOrganization } = this.state

        if (isSavingOrganization) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                this.setState({
                    errors: [],
                    isSavingOrganization: true,
                })

                dispatch(
                    updateOrganization(organization.id, formValues, {
                        cancelToken: this.signal.token,
                    })
                )
                    .then(response => {
                        this.setState({
                            errors: [],
                            isFormSubmitted: true,
                            isSavingOrganization: false,
                        })
                        apiClient.clearCache('organizations')
                        setFieldsValue({
                            logoImage: response.data.logoImage.path,
                            backgroundImage: response.data.backgroundImage.path,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isSavingOrganization: false,
                        })
                    })
                    .finally(() => {
                        window.scrollTo(0, 0)
                    })
            } else {
                window.scrollTo(0, 0)
            }
        })
        return false
    }

    setOrganizationFormValues(values) {
        this.setState({
            initialValues: Immutable.Map({
                ...values,
                logoImage: values.logoImage.path,
                backgroundImage: values.backgroundImage.path,
            }),
            isOrganizationLoaded: true,
        })
    }

    componentDidMount() {
        const {
            dispatch,
            organization: { id: organizationId },
        } = this.props
        this.setState({ isOrganizationLoading: true }, () => {
            dispatch(
                fetchOrganization(organizationId, {
                    cancelToken: this.signal.token,
                })
            )
                .then(response => {
                    this.setOrganizationFormValues(response.data)
                    this.setState({
                        isOrganizationLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            errors: error.response.data,
                            isOrganizationLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            form,
            form: { getFieldDecorator, getFieldError },
        } = this.props
        const { errors, initialValues, isFormSubmitted, isOrganizationLoading, isSavingOrganization } = this.state
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isFormSubmitted
            ? [{ message: 'Organization has been saved successfully.' }]
            : []
        const hasNotifications = !isEmpty(notifications)
        const formClasses = classNames('o-form', {
            'is-loading': isOrganizationLoading || isSavingOrganization,
        })

        return (
            <Main>
                <Header>My Organization</Header>
                <form className={formClasses} onSubmit={this.handleSubmit}>
                    <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                        {hasNotifications && (
                            <div className="o-input_wrapper">
                                <FormMessages
                                    hasErrors={hasErrors}
                                    hasSuccess={isFormSubmitted}
                                    items={notifications}
                                />
                            </div>
                        )}
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="name" className="o-label -required">
                                        Organization Name
                                    </label>
                                    {form.getFieldDecorator('name', {
                                        initialValue: initialValues.get('name') || '',
                                        validate: [
                                            {
                                                trigger: 'onBlur',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter a name',
                                                    },
                                                ],
                                            },
                                        ],
                                    })(<input type="text" id="name" className="o-input" />)}
                                    <div className="o-input_error">{getFieldError('name')}</div>
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small"></LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label className="o-label">Logo</label>
                                    <Dropzone
                                        form={form}
                                        initialFiles={initialValues.get('logoImage') || ''}
                                        initialValue={initialValues.get('logoImage') || ''}
                                        inputName="logoImage"
                                        isRounded={true}
                                        overrideClass="-user"
                                        recommendedSize="256&times;256px"
                                    />
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label className="o-label">Login Background</label>
                                    <Dropzone
                                        form={form}
                                        initialFiles={initialValues.get('backgroundImage') || ''}
                                        initialValue={initialValues.get('backgroundImage') || ''}
                                        inputName="backgroundImage"
                                        recommendedSize="720&times;900px"
                                    />
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="address" className="o-label">
                                        Address
                                    </label>
                                    {form.getFieldDecorator('address', {
                                        initialValue: initialValues.get('address') || '',
                                    })(<input type="text" id="address" className="o-input" />)}
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="city" className="o-label">
                                        City
                                    </label>
                                    {form.getFieldDecorator('city', {
                                        initialValue: initialValues.get('city') || '',
                                    })(<input type="text" id="city" className="o-input" />)}
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="province" className="o-label">
                                        Province/State
                                    </label>
                                    {form.getFieldDecorator('province', {
                                        initialValue: initialValues.get('province') || '',
                                    })(<input type="text" id="province" className="o-input" />)}
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="country" className="o-label">
                                        Country
                                    </label>
                                    {form.getFieldDecorator('country', {
                                        initialValue: initialValues.get('country') || '',
                                    })(<input type="text" id="country" className="o-input" />)}
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <div className="o-input_wrapper">
                                    <label htmlFor="postalCode" className="o-label">
                                        Postal Code/ZIP Code
                                    </label>
                                    {form.getFieldDecorator('postalCode', {
                                        initialValue: initialValues.get('postalCode') || '',
                                    })(<input type="text" id="postalCode" className="o-input" />)}
                                </div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2@from-small">
                                <Telephone form={form} initialValue={initialValues.get('telephone') || ''} />
                            </LayoutItem>
                            <LayoutItem>
                                <div className="o-input_wrapper">
                                    <label htmlFor="description" className="o-label">
                                        Description
                                    </label>
                                    {getFieldDecorator('description', {
                                        initialValue: initialValues.get('description') || '',
                                    })(<textarea id="description" className="o-textarea" rows="10"></textarea>)}
                                </div>
                            </LayoutItem>
                        </Layout>
                    </TrackVisibility>

                    <Layout overrideClass="-gutter-small u-margin-small-y">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                <button className="o-button -huge" onClick={() => getHistory().goBack()} type="button">
                                    Cancel
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                <button className="o-button -huge -green" type="submit">
                                    Save
                                </button>
                            </TrackVisibility>
                        </LayoutItem>
                    </Layout>
                    <div className="o-form_overlay">
                        <div className="o-form_spinner u-text-center">
                            <Spinner />
                        </div>
                    </div>
                </form>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    return {
        organization: state.organization,
    }
}

export default connect(mapStateToProps)(createForm()(AccountOrganizationEditView))
