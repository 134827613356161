import React from 'react'

import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'

const Footer = () => (
    <footer className="footer">
        <Layout overrideClass="-gutter-small">
            <LayoutItem overrideClass="u-1/2">&copy; 2019</LayoutItem>
            <LayoutItem overrideClass="u-1/2">
                <Layout overrideClass="-gutter-small -middle">
                    <LayoutItem overrideClass="u-1/2@from-small footer_link">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </LayoutItem>
                    <LayoutItem overrideClass="u-1/2@from-small footer_link">
                        <Link to="/contact">Support</Link>
                    </LayoutItem>
                </Layout>
            </LayoutItem>
        </Layout>
    </footer>
)

export default Footer
