import { isInteger } from 'lodash-es'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

/**
 * This method uses
 * @param id
 * @constructor
 */
const ScrollTo = id => {
    let offset = 0
    const behavior = 'smooth'

    if (isInteger(id)) {
        offset = id
    } else {
        const element = document.getElementById(id.replace('#', ''))
        offset = element.offsetTop || 0
    }

    setTimeout(() => {
        window.scrollTo({
            behavior: behavior,
            top: offset,
        })
    }, 100)
}

export default ScrollTo
