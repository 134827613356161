import axios from 'axios'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'

import { fetchSessions } from '../../actions/sessionActions'
import { fetchStudents } from '../../actions/studentActions'
import { renderRoute } from '../../utils/routes'

import { DashboardCardLoader, DashboardInfos } from '../Dashboard'
import { Layout, LayoutItem } from '../Layout'
import { List, ListUserItem } from '../List'
import { SessionCardContainer } from '../Session'
import { TrackVisibility } from '../TrackVisibility'

class DashboardInstructor extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchSessions({ cancelToken: this.signal.token }))
        dispatch(fetchStudents({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { infoItems, sessions, students, isLoadingSessions } = this.props

        return (
            <React.Fragment>
                <DashboardInfos items={infoItems} />
                {isLoadingSessions ? (
                    <Layout overrideClass="-gutter-small">
                        <DashboardCardLoader />
                    </Layout>
                ) : (
                    sessions.length > 0 && (
                        <Layout overrideClass="-gutter-small">
                            {!isEmpty(sessions) &&
                                sessions.map((session, index) => (
                                    <LayoutItem overrideClass="u-1/2@from-small" key={index}>
                                        <TrackVisibility overrideClass={`u-anim-scroll -delay-${index + 1}`}>
                                            <SessionCardContainer session={session} />
                                        </TrackVisibility>
                                    </LayoutItem>
                                ))}
                        </Layout>
                    )
                )}
                <TrackVisibility overrideClass="u-anim-scroll -delay-3">
                    <List
                        button={{
                            label: 'View all students',
                            route: 'student-list',
                        }}
                        title="My Students"
                    >
                        {students.map((student, index) => (
                            <ListUserItem
                                key={index}
                                details={[
                                    {
                                        id: 'curriculumNames',
                                        label: 'Curriculum(s)',
                                    },
                                    {
                                        id: 'sessionNames',
                                        label: 'Session(s)',
                                    },
                                ]}
                                url={renderRoute({
                                    id: 'student-details',
                                    bind: 'studentId',
                                    param: student.id,
                                })}
                                user={student}
                            />
                        ))}
                    </List>
                </TrackVisibility>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const sessions = state.sessions.entities
    const students = state.students.entities
    return {
        infoItems: [
            { label: 'Active Sessions', value: sessions.length },
            { label: 'Students', value: students.length },
        ],
        sessions: sessions.slice(0, 2),
        students: students.slice(0, 3),
        isLoadingSessions: state.requestStates.FETCH_SESSION_LIST === true,
    }
}

export default connect(mapStateToProps)(DashboardInstructor)
