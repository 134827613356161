import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { UserImage } from '../User'

const FilteredUserItem = ({ item, options }) =>
    options.isStudent ? (
        <article className="list_item">
            <RouterLink className="list_item_link u-padding-small-x" to={item.url}>
                <div className="list_item_link_inner u-padding-small-y">
                    <Layout overrideClass="-gutter-small -middle">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="u-inline-block u-align-middle">
                                <UserImage user={item.data} overrideClass={'user-image'} />
                            </div>
                            <h1 className="list_item_title o-h2 u-inline-block u-align-middle u-margin-none u-margin-small-x">
                                {item.data.firstName} <br />
                                {item.data.lastName}
                            </h1>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <Layout overrideClass="-gutter-small">
                                <LayoutItem overrideClass="u-1/2@from-small">
                                    Curriculum(s) <br />
                                    <span className="u-blue">{item.data.curriculumNames}</span>
                                </LayoutItem>
                                <LayoutItem overrideClass="u-1/2@from-small">
                                    Session(s) <br />
                                    <span className="u-blue">{item.data.sessionNames}</span>
                                </LayoutItem>
                            </Layout>
                        </LayoutItem>
                    </Layout>
                </div>
            </RouterLink>
        </article>
    ) : (
        <article className="filtered-list_item">
            <Layout overrideClass="filtered-list_main_data_line -middle">
                <LayoutItem overrideClass="u-1/2@from-small">
                    <RouterLink className="u-block filtered-list_item_user_link u-padding-tiny-y" to={item.url}>
                        <div className="u-inline-block u-align-middle">
                            <UserImage user={item.data} overrideClass={'filtered-list_item_image'} />
                        </div>
                        <div className="u-inline-block u-align-middle">
                            {item.data.name}
                            <p className="u-none@from-small u-margin-none u-blue">{item.data.email}</p>
                        </div>
                    </RouterLink>
                </LayoutItem>
                <LayoutItem overrideClass="u-1/2@from-small">
                    <span className="u-none@to-small">
                        <Link to={`mailto:${item.data.email}`}>{item.data.email}</Link>
                    </span>
                </LayoutItem>
            </Layout>
        </article>
    )

FilteredUserItem.propTypes = {
    item: PropTypes.object.isRequired,
    options: PropTypes.object,
}

FilteredUserItem.defaultProps = {
    options: {},
}

export default FilteredUserItem
