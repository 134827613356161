import classNames from 'classnames'
import { isArray } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Icon } from '../Icon'
import { NavAccordion } from '../Nav'

const NavItem = ({ handleAccordionClick, id, item, openAccordionId }) => {
    const isAccordion = isArray(item.items)
    const navItemClass = classNames(
        'nav_group_item',
        { 'nav-accordion': isAccordion },
        { 'nav-link-main': !isAccordion },
        { '-active': item.isActive || item.hasActiveNavItems }
    )

    return (
        <li className={navItemClass}>
            {isAccordion ? (
                <NavAccordion handleClick={handleAccordionClick} id={id} isOpen={id === openAccordionId} item={item} />
            ) : (
                <React.Fragment>
                    <RouterLink to={item.url} className="nav_group_item_label">
                        {item.label}
                    </RouterLink>
                    <Icon name="arrow-small" overrideClass="nav-link-main_icon" />
                </React.Fragment>
            )}
        </li>
    )
}

NavItem.propTypes = {
    handleAccordionClick: PropTypes.func,
    id: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    openAccordionId: PropTypes.number,
}

NavItem.defaultProps = {
    openAccordionId: null,
}

export default NavItem
