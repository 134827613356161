import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import { isUserInstructor, isUserParent, userCanDo } from '../helpers/auth'

import { fetchStudents } from '../actions/studentActions'

import UserListView from './UserListView'

const StudentListView = ({ user, loadStudents, ...rest }) => {
    const title = isUserInstructor(user) ? 'My Students' : isUserParent(user) ? 'My Kids' : 'Students'
    const listTitle = isUserParent(user) ? 'Kids' : 'Students'

    useEffect(() => {
        loadStudents()
    }, [loadStudents])

    return (
        <UserListView
            {...rest}
            detailsRoute={{
                id: 'student-details',
                bind: 'studentId',
            }}
            listTitle={listTitle}
            isDetailed
            hasFooter={userCanDo(user, 'report-cards/object/student.create')}
            listDetails={[
                {
                    id: 'curriculumNames',
                    label: 'Curriculum(s)',
                },
                {
                    id: 'sessionNames',
                    label: 'Session(s)',
                },
            ]}
            buttonData={{
                label: 'Create a student',
                route: 'student-create',
            }}
            title={title}
        />
    )
}

const mapStateToProps = state => ({
    isLoading: state.requestStates.FETCH_STUDENT_LIST === true,
    items: state.students.entities,
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
    loadStudents: opts => dispatch(fetchStudents(opts)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentListView)
