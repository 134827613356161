import React, { useState } from 'react'

import { Layout, LayoutItem } from '../Layout'
import classNames from 'classnames'

const Prompt = ({ onSuccess, onFailure, onConfirm, onCancel, title, message, confirmLabel, cancelLabel, type }) => {
    const [loading, setLoading] = useState(0)

    return (
        <div className="o-modal_wrapper">
            {title && (
                <header className="o-modal_header">
                    <span
                        className={classNames('o-h2', {
                            'u-blue': type === 'success',
                            'u-red': type === 'warning',
                        })}
                    >
                        {title}
                    </span>
                </header>
            )}
            <div className="o-modal_content">
                <p>{message}</p>
                <div className="o-modal_actions">
                    <Layout overrideClass="-gutter-small -middle u-text-center">
                        <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                            <button disabled={loading} className="o-button -full" onClick={() => onCancel()}>
                                {cancelLabel}
                            </button>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small u-margin-tiny-y">
                            <button
                                autoFocus
                                className={classNames('o-button', '-full', '-spinner', {
                                    '-red': type === 'warning',
                                    'is-loading': loading,
                                })}
                                type="button"
                                onClick={() => Prompt.triggerConfirm(onConfirm, setLoading, onSuccess, onFailure)}
                            >
                                <span className="o-button_inner">{confirmLabel}</span>
                            </button>
                        </LayoutItem>
                    </Layout>
                </div>
            </div>
        </div>
    )
}

Prompt.triggerConfirm = (onConfirm, setLoading, onSuccess, onFailure) => {
    if (!onConfirm) {
        onSuccess()
    }

    setLoading(true)
    onConfirm(
        () => {
            setLoading(false)
            onSuccess()
        },
        error => {
            setLoading(false)
            onFailure(error)
        }
    )
}

Prompt.defaultProps = {
    title: null,
    type: 'success',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
}

export default Prompt
