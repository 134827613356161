import { isEmpty } from 'lodash-es'

const baseUrl = 'organizations'

/**
 * Fetches a single organization from our API.
 *
 * @param  {string} organizationId
 * @param  {object} config
 * @return {Promise}
 */
export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST'
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS'
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE'
export const fetchOrganization = (organizationId, config = {}) => {
    const types = [FETCH_ORGANIZATION_REQUEST, FETCH_ORGANIZATION_SUCCESS, FETCH_ORGANIZATION_FAILURE]
    if (isEmpty(organizationId)) {
        return {
            types,
            promise: client => Promise.reject(),
        }
    } else {
        return {
            types,
            promise: client => client.get(`${baseUrl}/${organizationId}`, config),
        }
    }
}

/**
 * Update an organization.
 *
 * @param  {string} organizationId
 * @param  {object} formData
 * @param  {object} config
 * @return {Promise}
 */
export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE'
export const updateOrganization = (organizationId, formData, config = {}) => {
    return {
        types: [UPDATE_ORGANIZATION_REQUEST, UPDATE_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_FAILURE],
        promise: client => client.patch(`${baseUrl}/${organizationId}`, formData, config),
    }
}
