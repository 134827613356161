import axios from 'axios'
import classNames from 'classnames'
import { each, isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { Prompt } from 'react-router-dom'
import { SwitchTransition, TransitionGroup } from 'react-transition-group'

import {
    createReportMedias,
    createReportMessage,
    createReportSticker,
    deleteReportMedias,
    deleteReportMessage,
    deleteReportSticker,
    updateReport,
    updateReportTasks,
} from '../../actions/reportActions'
import ScrollTo from '../../helpers/ScrollTo'
import { completedCourses, parseCourseList } from '../../utils/courses'
import { formatFormMessages } from '../../utils/forms'
import randomIdent from '../../utils/randomIdent'
import { completedTasks } from '../../utils/tasks'
import { apiClient } from '../../helpers/ApiClient'
import { baseUrl as studentEndPoint } from '../../actions/studentActions'
import { baseUrl as reportEndPoint } from '../../actions/reportActions'
import { ReportDetailsView } from '../../views'
import { CardHeader } from '../Card'
import { DashboardInfos } from '../Dashboard'
import { Fade, Slide } from '../Fade'
import { FlexibleList } from '../FlexibleList'
import { FormMessages } from '../FormMessages'
import { Gallery } from '../Gallery'
import { Header } from '../Header'
import { Layout, LayoutItem } from '../Layout'
import { Logo } from '../Logo'
import { Messages } from '../Messages'
import { SimpleList } from '../SimpleList'
import { StickersSelector } from '../Stickers'
import { TrackVisibility } from '../TrackVisibility'

class QueueItem {
    _id: String
    _promise: Promise
    _targetId: String
    _action: Function
    _data
    _error

    get id() {
        return this._id
    }

    get promise() {
        return this._promise
    }

    get targetId() {
        return this._targetId
    }

    get error() {
        return this._error
    }

    data(ident = null) {
        return ident ? this._data[ident] : this._data
    }

    execute() {
        return this.promise()
            .then(({ data }) => (this._data = data))
            .catch(error => (this._error = error))
    }

    resolveAction(target) {
        return this._action(target, this.data())
    }

    constructor(id: String, targetId: String, promise: Promise, action: Function = () => {}) {
        this._id = id
        this._targetId = targetId
        this._promise = promise
        this._action = action
    }
}

class ReportEdit extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        hasLoadedMessages: !this.props.isLoadingMessages,
        messages: [],

        hasLoadedGallery: !this.props.isLoadingGallery,
        gallery: this.props.gallery,

        hasLoadedStickers: !this.props.isLoadingReportStickers || !this.props.isLoadingLatestStickers,
        latestStickers: this.props.latestStickers,

        hasLoadedTasks: !this.props.isLoadingTasks,
        tasks: this.props.tasks,

        isReportPushed: false,
        errors: [],

        updateQueue: [],
        resetBuster: randomIdent(),
        isActionsVisible: false,
        actionLoading: false,
    }

    /**
     * Dequeue the queued update list, using the queued item's action to update the state.
     * @return {Promise<[]>}
     */
    dequeue = async () => {
        const state = this.state
        let operations = []
        let errors = state.errors

        await Promise.all(state.updateQueue.map(queue => queue.execute())).then(response => {
            each(state.updateQueue, queue => {
                if (!isEmpty(queue.error)) {
                    if (!axios.isCancel(queue.error)) {
                        errors.push(queue.error.response.data)
                    }

                    console.log(queue.error)
                } else {
                    state[queue.targetId] = queue.resolveAction(state[queue.targetId])
                }

                operations.push(queue)
            })
        })

        if (state.updateQueue.length > 0)
            this.setState({
                ...state,
                updateQueue: [],
                resetBuster: randomIdent(),
                errors: errors,
            })

        return operations
    }

    handleClickViewChanges = event => {
        ScrollTo('#report-actions')
    }

    /**
     * Trigger the updateQueue
     * This will refresh the state once complete.
     */
    handleClickReportUpdate = event => {
        this.setState(
            {
                actionLoading: true,
                errors: [],
            },
            () =>
                this.dequeue().then(response => {
                    const { errors } = this.state
                    const { report } = this.props

                    if (isEmpty(errors)) {
                        updateReport(
                            report.id,
                            {
                                /* @todo Shall we pass any data? */
                            },
                            { cancelToken: this.signal.token }
                        )

                        this.setState({
                            isReportPushed: true,
                            actionLoading: false,
                        })

                        apiClient.clearCache(`${studentEndPoint}/${report.studentId}/reports`)
                    } else {
                        this.setState({
                            isReportPushed: false,
                            actionLoading: false,
                        })
                    }
                    ScrollTo(0)
                })
        )
    }

    /**
     * We should be able to cancel changes
     * Using a resetBuster as key on key components, we can force them to reset themselves.
     */
    handleClickCancelChanges = event => {
        this.setState(
            {
                actionLoading: true,
                updateQueue: [],
                resetBuster: randomIdent(),
            },
            () => {
                this.setState({
                    actionLoading: false,
                })
            }
        )
    }

    /**
     * Add a queue item instance to the component queue list
     */
    queueForUpdate(queueItem: QueueItem) {
        const { updateQueue } = this.state

        // If a queued item with the same id is found, remove it from queue
        const queue = updateQueue.filter(item => item.id !== queueItem.id)
        if (queue.length !== updateQueue.length) {
            this.setState({
                updateQueue: queue,
            })
        }

        this.setState({
            updateQueue: [...updateQueue, queueItem],
        })
    }

    /**
     * Manually clear a queue item from the queue list
     */
    clearQueueItem(queueItemId: String) {
        const { updateQueue } = this.state

        this.setState({
            updateQueue: updateQueue.filter(item => item.id !== queueItemId),
        })
    }

    /**
     * Handle the Task queue
     */
    queueTaskUpdate(index, isCompleted) {
        const { tasks } = this.state
        const { report } = this.props
        const task = tasks[index]

        if (task.isCompleted !== isCompleted) {
            const promise = () =>
                updateReportTasks(
                    report.id,
                    task.id,
                    { isCompleted: isCompleted },
                    { cancelToken: this.signal.token }
                ).then(response => {
                    apiClient.clearCache(`${reportEndPoint}/${report.id}/tasks`)
                    return response
                })
            const action = (tasks, data) => tasks.map(item => (item.id === task.id ? data : item))
            const queueItem = new QueueItem(task.id, 'tasks', promise, action)

            return this.queueForUpdate(queueItem)
        }

        return this.clearQueueItem(task.id)
    }

    /**
     * Handle the sticker queue
     */
    queueStickerUpdate(sticker) {
        const { report } = this.props,
            { latestStickers } = this.state,
            targetId = 'latestStickers'
        let stickerFound = latestStickers.filter(item => item.sticker.id === sticker.id)
        let queueItem = null

        if (stickerFound.length > 0) {
            if (!sticker.active) {
                const promise = () =>
                    deleteReportSticker(report.id, stickerFound[0].id, {
                        cancelToken: this.signal.token,
                    }).then(response => {
                        apiClient.clearCache(`${reportEndPoint}/${report.id}/stickers`)
                        apiClient.clearCache(`${reportEndPoint}/${report.id}/stickers/latest`)
                        return response
                    })

                const action = stickers => stickers.filter(item => item.sticker.id !== sticker.id)

                // Delete Item Queue
                queueItem = new QueueItem(sticker.id, targetId, promise, action)
            }
        } else {
            if (sticker.active) {
                const promise = () =>
                    createReportSticker(report.id, { stickerId: sticker.id }, { cancelToken: this.signal.token }).then(
                        response => {
                            apiClient.clearCache(`${reportEndPoint}/${report.id}/stickers`)
                            apiClient.clearCache(`${reportEndPoint}/${report.id}/stickers/latest`)
                            return response
                        }
                    )
                const action = (stickers, data) => [...stickers, data]

                // Create Item Queue
                queueItem = new QueueItem(sticker.id, targetId, promise, action)
            }
        }

        if (queueItem) {
            return this.queueForUpdate(queueItem)
        }

        return this.clearQueueItem(sticker.id)
    }

    queueNewMessage(message) {
        const { report } = this.props
        const promise = () =>
            createReportMessage(
                report.id,
                {
                    message: message.message,
                    date: message.date.datetime,
                },
                { cancelToken: this.signal.token }
            )

        const action = (messages, data) => [...messages, data]

        // Create Item Queue
        const queueItem = new QueueItem(message.id, 'messages', promise, action)
        return this.queueForUpdate(queueItem)
    }

    queueDeleteMessage(message) {
        if (message.isNew) {
            this.clearQueueItem(message.id)

            return
        }

        const { report } = this.props
        const promise = () =>
            deleteReportMessage(report.id, message.id, {
                cancelToken: this.signal.token,
            }).then(response => {
                apiClient.clearCache(`${reportEndPoint}/${report.id}/messages`)
                return response
            })
        const action = messages => messages.filter(item => item.id !== message.id)

        // Create Item Queue
        const queueItem = new QueueItem(message.id, 'messages', promise, action)
        return this.queueForUpdate(queueItem)
    }

    queueNewMedia(media) {
        const promise = () =>
            createReportMedias(
                this.props.report.id,
                {
                    file: media.inputValue,
                    type: media.media.type,
                    description: media.description,
                    date: media.date.timestamp,
                    thumbnail: media.media.thumbnail,
                },
                { cancelToken: this.signal.token }
            )

        const action = (medias, data) => [...medias, data]

        // Create Item Queue
        const queueItem = new QueueItem(media.id, 'gallery', promise, action)
        return this.queueForUpdate(queueItem)
    }

    queueDeleteMedia(media) {
        if (media.isNew) {
            this.clearQueueItem(media.id)

            return
        }

        const { report } = this.props
        const promise = () =>
            deleteReportMedias(report.id, media.id, {
                cancelToken: this.signal.token,
            }).then(response => {
                apiClient.clearCache(`${reportEndPoint}/${report.id}/medias`)
            })
        const action = medias => medias.filter(item => item.id !== media.id)

        // Create Item Queue
        const queueItem = new QueueItem(media.id, 'gallery', promise, action)
        return this.queueForUpdate(queueItem)
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
                report,
                reportStickers,
                isLoadingReport,
                isLoadingTasks,
                isLoadingReportStickers,
                isLoadingLatestStickers,
                isLoadingMessages,
                isLoadingGallery,
            } = this.props,
            {
                messages,
                latestStickers,
                tasks,
                gallery,
                updateQueue,
                resetBuster,
                isActionsVisible,
                errors,
                isReportPushed,
                actionLoading,
            } = this.state,
            dashboardItems = [
                {
                    label: 'Messages',
                    value: isLoadingMessages ? '-' : messages.length,
                },
                {
                    label: 'Images',
                    value: isLoadingGallery ? '-' : gallery.length,
                },
            ],
            { student, session } = report || {},
            { courses, curriculum } = session || {},
            lastCourse = session && parseCourseList(this, courses).last,
            nextCourse = session && parseCourseList(this, courses).next,
            listItems = [
                [
                    {
                        label: 'Session',
                        value: (session && session.name) || '-',
                    },
                    {
                        label: 'Last course',
                        value: lastCourse ? lastCourse.startDate.display : '-',
                    },
                    {
                        label: 'Next course',
                        value: nextCourse ? nextCourse.startDate.display : '-',
                    },
                ],
                [
                    {
                        label: 'Steps',
                        value: !isLoadingTasks ? `${completedTasks(tasks).length}/${tasks.length}` : '-',
                    },
                    {
                        label: 'Course progress',
                        value: courses ? `${completedCourses(courses).length}/${courses.length}` : '-',
                    },
                    {
                        label: 'Certification Code',
                        value: curriculum ? curriculum.certificationCode : '-',
                    },
                ],
            ]

        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors
            ? formatFormMessages(errors)
            : isReportPushed
            ? [{ message: 'The report card has been updated with success.' }]
            : []
        const hasNotifications = !isEmpty(notifications)

        return (
            <React.Fragment>
                <Prompt
                    when={updateQueue.length > 0}
                    message={location =>
                        `You have ${updateQueue.length} change(s) that are still to be pushed to report cards. If you continue, you'll lose them. Are you sure you want to go to ${location.pathname}`
                    }
                />
                <TrackVisibility overrideClass="u-anim-scroll">
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingReport}>
                            {isLoadingReport ? (
                                <ReportDetailsView.Placeholder.Header />
                            ) : (
                                <Header>
                                    {student.firstName} <br />
                                    {student.lastName}
                                </Header>
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
                {hasNotifications && (
                    <TrackVisibility overrideClass="u-anim-scroll">
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={isReportPushed} items={notifications} />
                        </div>
                    </TrackVisibility>
                )}
                <DashboardInfos items={dashboardItems} />
                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                    <section className="u-card u-margin-y u-relative">
                        <SwitchTransition>
                            <Fade timeout={150} key={isLoadingReport}>
                                {isLoadingReport ? (
                                    <CardHeader>
                                        <ReportDetailsView.Placeholder.CardHeader />
                                    </CardHeader>
                                ) : (
                                    <CardHeader>
                                        <Logo />
                                        {session.curriculum.name}
                                    </CardHeader>
                                )}
                            </Fade>
                        </SwitchTransition>

                        {listItems.length > 0 && (
                            <div className="u-padding-tiny-y u-padding-small-x">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <SimpleList items={listItems[0]} />
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2@from-small">
                                        <SimpleList items={listItems[1]} />
                                    </LayoutItem>
                                </Layout>
                            </div>
                        )}

                        <SwitchTransition>
                            <Fade timeout={150} key={isLoadingTasks}>
                                {isLoadingTasks ? (
                                    <ReportDetailsView.Placeholder.TaskList />
                                ) : (
                                    <FlexibleList
                                        key={resetBuster}
                                        title="Progress"
                                        headers={['Step #', 'Description']}
                                        items={tasks.map(task => ({
                                            isCompleted: task.isCompleted,
                                            description: task.sessionTask.description,
                                        }))}
                                        handleItemUpdate={(ident, { isCompleted }) =>
                                            this.queueTaskUpdate(ident, isCompleted)
                                        }
                                        isProgress
                                    />
                                )}
                            </Fade>
                        </SwitchTransition>
                        <Gallery
                            key={`gallery-${resetBuster}`}
                            gallery={gallery}
                            isLoading={isLoadingGallery}
                            onNewMedia={media => this.queueNewMedia(media)}
                            onDeleteMedia={media => this.queueDeleteMedia(media)}
                        />
                        <StickersSelector
                            key={`stickers-${resetBuster}`}
                            selectedStickersIds={latestStickers.map(s => s.sticker.id)}
                            reportStickers={reportStickers}
                            isLoading={isLoadingLatestStickers || isLoadingReportStickers}
                            onStickerChange={sticker => this.queueStickerUpdate(sticker)}
                        />
                        <Messages
                            key={`messages-${resetBuster}`}
                            messages={messages}
                            isLoading={isLoadingMessages}
                            onNewMessage={message => this.queueNewMessage(message)}
                            onDeleteMessage={message => this.queueDeleteMessage(message)}
                        />
                        <button
                            className={classNames('o-button -pill -green -pop-in', {
                                '-is-show': updateQueue.length > 0 && isActionsVisible === false,
                            })}
                            type="button"
                            onClick={this.handleClickViewChanges}
                        >
                            &darr;{updateQueue.length} change
                            {updateQueue.length > 1 && 's'}
                        </button>
                    </section>

                    <section id="report-actions">
                        <TrackVisibility onChange={flag => this.setState({ isActionsVisible: flag })}>
                            <TransitionGroup>
                                {updateQueue.length > 0 && (
                                    <Slide timeout={150} key={true} distance={10}>
                                        <div className="u-padding-tiny-y u-padding-small-x">
                                            <Layout overrideClass="-gutter-small">
                                                <LayoutItem overrideClass="u-1/2@from-small">
                                                    <button
                                                        className={classNames(
                                                            'o-button -huge u-margin-small-y -spinner',
                                                            {
                                                                'is-loading': actionLoading,
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={this.handleClickCancelChanges}
                                                    >
                                                        <span className="o-button_inner">Cancel</span>
                                                    </button>
                                                </LayoutItem>
                                                <LayoutItem overrideClass="u-1/2@from-small">
                                                    <button
                                                        className={classNames(
                                                            'o-button -huge -green u-margin-small-y -spinner',
                                                            {
                                                                'is-loading': actionLoading,
                                                            }
                                                        )}
                                                        disabled={actionLoading}
                                                        type="button"
                                                        onClick={this.handleClickReportUpdate}
                                                    >
                                                        <span className="o-button_inner">Push Report Card Update</span>
                                                    </button>
                                                </LayoutItem>
                                            </Layout>
                                        </div>
                                    </Slide>
                                )}
                            </TransitionGroup>
                        </TrackVisibility>
                    </section>
                </TrackVisibility>
            </React.Fragment>
        )
    }
}

ReportEdit.propTypes = {
    report: PropTypes.object,
    tasks: PropTypes.array,
    latestStickers: PropTypes.array,
    reportStickers: PropTypes.array,
    messages: PropTypes.array,
    gallery: PropTypes.array,
    isLoadingReport: PropTypes.bool,
    isLoadingTasks: PropTypes.bool,
    isLoadingReportStickers: PropTypes.bool,
    isLoadingLatestStickers: PropTypes.bool,
    isLoadingMessages: PropTypes.bool,
    isLoadingGallery: PropTypes.bool,
}

ReportEdit.defaultProps = {
    report: null,
    tasks: [],
    latestStickers: [],
    reportStickers: [],
    messages: [],
    isLoadingReport: true,
    isLoadingTasks: true,
    isLoadingReportStickers: true,
    isLoadingLatestStickers: true,
    isLoadingMessages: true,
    isLoadingGallery: true,
}

ReportEdit.getDerivedStateFromProps = (props, state) => {
    let newState = {}

    if (!state.hasLoadedMessages && !props.isLoadingMessages) {
        newState = {
            ...newState,
            hasLoadedMessages: true,
            messages: props.messages,
        }
    }

    if (!state.hasLoadedStickers && !props.isLoadingLatestStickers) {
        newState = {
            ...newState,
            hasLoadedStickers: true,
            latestStickers: props.latestStickers,
        }
    }

    if (!state.hasLoadedTasks && !props.isLoadingTasks) {
        newState = {
            ...newState,
            hasLoadedTasks: true,
            tasks: props.tasks,
        }
    }

    if (!state.hasLoadedGallery && !props.isLoadingGallery) {
        newState = {
            ...newState,
            hasLoadedGallery: true,
            gallery: props.gallery,
        }
    }

    if (newState !== {}) {
        return newState
    }
    return null
}

export default ReportEdit
