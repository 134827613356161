import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { fetchInstructors } from '../../actions/instructorActions'
import { fetchReports } from '../../actions/reportActions'
import { fetchSessions } from '../../actions/sessionActions'
import { fetchStudents } from '../../actions/studentActions'
import { isUserAdministrator, isUserInstructor, isUserStudent, isUserParent } from '../../helpers/auth'

import { IconButton } from '../IconButton'
import { Layout, LayoutItem } from '../Layout'
import { Logo } from '../Logo'
import { UserMyCard } from '../User'
import { fetchCertificates } from '../../actions/certificateActions'
import { fetchStudentMedals } from '../../actions/studentMedalActions'
import moment from 'moment'

class Sidebar extends React.Component {
    state = {
        isSidebarOpen: false,
    }

    signal = axios.CancelToken.source()

    handleToggleSidebar = () => {
        this.setState({
            isSidebarOpen: !this.state.isSidebarOpen,
        })
    }

    componentDidMount() {
        const {
            auth: { user },
            dispatch,
        } = this.props

        if (isUserAdministrator(user)) {
            dispatch(fetchInstructors({ cancelToken: this.signal.token }))
        } else if (isUserInstructor(user)) {
            dispatch(fetchSessions({ cancelToken: this.signal.token }))
        } else if (isUserStudent(user)) {
        } else if (isUserParent(user)) {
        }

        if (isUserParent(user) || isUserStudent(user)) {
            dispatch(fetchReports({ cancelToken: this.signal.token }))
            dispatch(fetchCertificates({ cancelToken: this.signal.token }))
            dispatch(fetchStudentMedals({ cancelToken: this.signal.token }))
        }

        if (isUserAdministrator(user) || isUserInstructor(user) || isUserParent(user)) {
            dispatch(fetchStudents({ cancelToken: this.signal.token }))
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    renderListItems() {
        const {
            // auth: { user },
            listItems,
            // organization,
        } = this.props
        return [
            ...listItems,
            // { label: 'Role', value: user.role.label },
            // { label: 'Organization', value: organization.data.name },
        ]
    }

    render() {
        const {
            auth: { user },
        } = this.props
        const { isSidebarOpen } = this.state

        return (
            <aside className={classNames('sidebar', { '-open': isSidebarOpen })}>
                <div className="sidebar_button -open" onClick={this.handleToggleSidebar}>
                    <IconButton name="profile" />
                </div>
                <div className="sidebar_button -close" onClick={this.handleToggleSidebar}>
                    <IconButton name="close" />
                </div>

                <div className="sidebar_wrap">
                    <span className="sidebar_title u-none@from-medium">My profile</span>
                    <div className="sidebar_inner">
                        <UserMyCard listItems={this.renderListItems()} user={user} />
                    </div>
                    <footer className="sidebar_footer">
                        <Layout overrideClass="-gutter-small">
                            <LayoutItem overrideClass="u-1/2">
                                <div className="sidebar_footer_item">Powered By</div>
                            </LayoutItem>
                            <LayoutItem overrideClass="u-1/2">
                                <div className="sidebar_footer_item -copyright">
                                    <Logo />
                                    Report.Cards
                                </div>
                            </LayoutItem>
                        </Layout>
                    </footer>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = state => {
    let listItems

    moment.fn.formNowInDays = function(a) {
        if (Math.abs(moment().diff(this)) < 8.64e7) {
            // a day in milliseconds
            return 'in less than a day'
        }
        return this.fromNow(a)
    }

    const subscription = state.organization.data.subscription

    switch (state.auth.user.role.id) {
        case 'administrator':
            listItems = [
                { label: 'Students', value: state.students.entities.length },
                {
                    label: 'Instructors',
                    value: state.instructors.entities.length,
                },
                ...(subscription.trialing
                    ? [
                          { label: 'Subscription', value: 'Trial' },
                          {
                              label: 'Trial ends',
                              value: (() => {
                                  const ts = subscription.trialEnd.timestamp
                                  return moment.unix(ts).formNowInDays()
                              })(),
                          },
                      ]
                    : []),
                ...(subscription.canceledDate
                    ? [
                          {
                              label: 'Subscription',
                              value: React.createElement('span', { className: 'u-red' }, 'Canceled'),
                          },
                          {
                              label: 'Canceled on',
                              value: React.createElement(
                                  'span',
                                  { className: 'u-red' },
                                  subscription.canceledDate.display
                              ),
                          },
                          {
                              label:
                                  moment.unix(subscription.endDate.timestamp).diff(moment(), 'days') > 0
                                      ? 'Active until'
                                      : 'Ended on',
                              value: React.createElement('span', { className: 'u-red' }, subscription.endDate.display),
                          },
                      ]
                    : []),
                {
                    label: 'Remaining Report Cards',
                    value: React.createElement(
                        'span',
                        { className: 'simple-list_value_huge' },
                        state.organization.data.reportCards.remaining
                    ),
                },
            ]
            break
        case 'instructor':
            listItems = [
                { label: 'My Students', value: state.students.entities.length },
                {
                    label: 'Active Sessions',
                    value: state.sessions.entities.length,
                },
            ]
            break
        case 'parent':
            listItems = [
                { label: 'Report Cards', value: state.reports.entities.length },
                { label: 'Kids', value: state.students.entities.length },
                {
                    label: 'Achievements',
                    value: state.certificates.entities.length + state.studentMedals.entities.length,
                },
            ]
            break
        case 'student':
            listItems = [
                { label: 'Report Cards', value: state.reports.entities.length },
                { label: 'Certificates', value: state.certificates.entities.length },
                { label: 'Medals', value: state.studentMedals.entities.length },
            ]
            break
        default:
            listItems = []
            break
    }

    return {
        auth: state.auth,
        organization: state.organization,
        listItems,
    }
}

export default connect(mapStateToProps)(Sidebar)
