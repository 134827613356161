import React from 'react'

import PropTypes from 'prop-types'

import { filterCompletedCourses } from '../../utils/courses'

import { Progress } from '../Progress'

const SessionProgress = ({ courses, isDraft, ...otherProps }) => {
    const courseLength = courses.length
    const completedCourses = filterCompletedCourses(courses)
    let value = courseLength > 0 ? Math.floor((completedCourses.length / courseLength) * 100) : 0
    if (isDraft) {
        otherProps.label = 'Draft'
        value = 0
    }

    return <Progress value={value} {...otherProps} />
}

SessionProgress.propTypes = {
    courses: PropTypes.array.isRequired,
    isDraft: PropTypes.bool,
}

SessionProgress.defaultProps = {
    isDraft: false,
}

export default SessionProgress
