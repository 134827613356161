import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute } from '../../utils/routes'

import { Button } from '../Button'
import { CardFooter, CardHeader } from '../Card'
import { Layout, LayoutItem } from '../Layout'
import { Logo } from '../Logo'
import { Progress } from '../Progress'
import { UserImage } from '../User'

const ReportCardList = ({ items, overrideClass }) => (
    <article className={classNames('report-card-list u-card u-margin-y', overrideClass)}>
        <CardHeader>
            <Logo /> Report Cards
        </CardHeader>
        {items.length > 0 ? (
            <React.Fragment>
                <ul className="report-card-list_list">
                    {items.map(report => {
                        const {
                            id,
                            progression,
                            session: { curriculum },
                            student,
                            student: { firstName, lastName },
                        } = report

                        return (
                            <li className="report-card-list_item" key={id}>
                                <RouterLink
                                    className="report-card-list_item_link u-padding-small-x"
                                    to={renderRoute({
                                        id: 'report-details',
                                        bind: 'reportId',
                                        param: id,
                                    })}
                                >
                                    <div className="report-card-list_item_link_inner u-padding-tiny-y">
                                        <Layout overrideClass="-gutter-small -middle">
                                            <LayoutItem overrideClass="u-1/4">
                                                <UserImage user={student} overrideClass={'user-image'} />
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/2">
                                                <p className="report-card-list_item_name o-h2">
                                                    {firstName} <br />
                                                    {lastName}
                                                </p>
                                                <p className="report-card-list_item_info u-blue">{curriculum.name}</p>
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/4">
                                                <div className="u-text-center">
                                                    <Progress
                                                        label={`${progression}%`}
                                                        overrideClass="-small"
                                                        value={progression}
                                                    />
                                                </div>
                                            </LayoutItem>
                                        </Layout>
                                    </div>
                                </RouterLink>
                            </li>
                        )
                    })}
                </ul>
                <CardFooter>
                    <div className="u-inline-block u-align-middle">
                        <Button route="report-list" overrideClass="card-footer_cta">
                            View all
                        </Button>
                    </div>
                </CardFooter>
            </React.Fragment>
        ) : (
            <p className="report-card-list_empty">Empty</p>
        )}
    </article>
)

ReportCardList.propTypes = {
    items: PropTypes.array,
    overrideClass: PropTypes.string,
}

ReportCardList.defaultProps = {
    items: [],
    overrideClass: '',
}

export default ReportCardList
