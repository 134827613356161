import React from 'react'

import propTypes from 'prop-types'

import { Layout, LayoutItem } from '../Layout'
import { SelectableList } from '../SelectableList'
import { FadeList, Slide } from '../Fade'
import { SwitchTransition } from 'react-transition-group'

class PlanFrequency extends React.Component {
    render() {
        const { infos, subscription, children, plans, onCancelPlan, onSelectPlan, selectedPlanId } = this.props
        const currentlySubscribed = !!subscription.stripePlanId

        return (
            <div className="u-card u-margin-y">
                <header className="card-header">{currentlySubscribed ? 'My plan' : 'Choose a plan'}</header>
                <div className="u-padding-small">
                    <Layout overrideClass="-gutter-small">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <SwitchTransition>
                                <Slide timeout={150} key={selectedPlanId}>
                                    {plans.map(
                                        plan =>
                                            plan.stripePlanId === selectedPlanId && (
                                                <React.Fragment key={plan.stripePlanId}>
                                                    <div className="u-text-big u-margin-none">
                                                        {plan.name}
                                                        <br />
                                                        <span className="u-blue">
                                                            {plan.stripePlanData.amount / 100} CDN
                                                        </span>
                                                    </div>
                                                    <LayoutItem overrideClass="u-3/4@from-small">
                                                        <p className="u-text-tiny">{plan.description}</p>
                                                    </LayoutItem>
                                                    {subscription && plan.stripePlanId === subscription.stripePlanId && (
                                                        <LayoutItem overrideClass="u-1/2">
                                                            <button
                                                                className="link -red u-margin-tiny-y"
                                                                onClick={() => onCancelPlan()}
                                                            >
                                                                Cancel my plan
                                                            </button>
                                                        </LayoutItem>
                                                    )}
                                                </React.Fragment>
                                            )
                                    )}
                                </Slide>
                            </SwitchTransition>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="u-padding-small-y">
                                <SelectableList
                                    name="plan-select"
                                    items={plans.map(item => {
                                        const stripeData = item.stripePlanData
                                        const amountByMonth =
                                            stripeData.interval === 'month' ? stripeData.amount : stripeData.amount / 12

                                        return {
                                            value: item.stripePlanId,
                                            label: item.name + ' subscription',
                                            content: (amountByMonth / 100).toFixed(2) + ' CDN / Month',
                                        }
                                    })}
                                    defaultValue={selectedPlanId}
                                    onChange={(e, item) => onSelectPlan(item.value)}
                                />
                            </div>
                            <div className="simple-list">
                                <SwitchTransition>
                                    <FadeList appear={false} key={selectedPlanId} timeout={200} distance={10}>
                                        {infos.map(({ label, value }, index) => (
                                            <Layout overrideClass="simple-list_line" key={index}>
                                                <LayoutItem overrideClass="u-1/2">
                                                    <p className="simple-list_label">{label}</p>
                                                </LayoutItem>
                                                <LayoutItem overrideClass="u-1/2 u-text-right">
                                                    <p className="simple-list_value">{value}</p>
                                                </LayoutItem>
                                            </Layout>
                                        ))}
                                    </FadeList>
                                </SwitchTransition>
                            </div>
                            {children}
                        </LayoutItem>
                    </Layout>
                </div>
            </div>
        )
    }

    static props = {
        infos: propTypes.array,
        subscription: propTypes.object,
        plans: propTypes.array.isRequired,
        onCancelPlan: propTypes.func,
        onSelectPlan: propTypes.func,
    }

    static defaultProps = {
        infos: [],
        subscription: null,
        onCancelPlan: () => {},
        onSelectPlan: () => {},
    }
}

export default PlanFrequency
