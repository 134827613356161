import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { createLostPasswordToken } from '../actions/userActions'
import { formatFormMessages } from '../utils/forms'
import { FormMessages } from '../components/FormMessages'
import { Layout, LayoutItem } from '../components/Layout'
import { Link } from '../components/Link'
import { LoginBackground } from '../components/Login'
import { Logo } from '../components/Logo'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

class AccountRequestResetPasswordEmailView extends React.Component {
    state = {
        errors: [],
        isLoading: false,
        isSubmitted: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const {
            form: { validateFields },
        } = this.props
        const { isLoading, isSubmitted } = this.state

        if (isLoading || isSubmitted) {
            return false
        }

        validateFields(async (formError, formValues) => {
            if (!formError) {
                this.setState({ isLoading: true })
                createLostPasswordToken(formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isLoading: false,
                            isSubmitted: true,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isLoading: false,
                        })
                    })
            }
        })

        return false
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { errors, isLoading, isSubmitted } = this.state
        const {
            form: { getFieldDecorator, getFieldError },
            organizationId,
        } = this.props
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main isSimple={true}>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <div className="login-form_wrapper">
                        <div className="login-form u-card">
                            <div className="login-form_inner-tiny">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Logo /> Report.Cards
                                    </LayoutItem>
                                </Layout>
                            </div>
                            {isSubmitted ? (
                                <div className="login-form_inner">
                                    <h1 className="o-h1 u-text-big">Reset password</h1>
                                    <p>
                                        If the email is found, you should receive an email shortly to reset your
                                        password.
                                    </p>
                                </div>
                            ) : (
                                <form className="login-form_inner" onSubmit={this.handleSubmit}>
                                    <h1 className="o-h1 u-text-big">Reset password</h1>
                                    {hasErrors && (
                                        <div className="o-input_wrapper">
                                            <FormMessages hasErrors={true} items={notifications} />
                                        </div>
                                    )}
                                    <div className="o-input_wrapper">
                                        <label className="o-label" htmlFor="email">
                                            Email
                                        </label>
                                        {getFieldDecorator('email', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter your email',
                                                },
                                            ],
                                        })(
                                            <input
                                                type="email"
                                                className="o-input"
                                                id="email"
                                                placeholder="example@example.com"
                                            />
                                        )}
                                        <div className="o-input_error">{getFieldError('email')}</div>
                                    </div>
                                    <div className="o-input_wrapper">
                                        {getFieldDecorator('organizationId', {
                                            initialValue: organizationId,
                                        })(<input type="text" hidden={true} />)}
                                        <button
                                            type="submit"
                                            className={classNames('o-button', '-spinner', 'login-form_submit', {
                                                'is-loading': isLoading,
                                            })}
                                        >
                                            <span className="o-button_inner">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            )}
                            <div className="login-form_inner-tiny -footer">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Link route="account-request-confirmation-email" overrideClass="u-text-tiny">
                                            Resend confirmation email
                                        </Link>
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2">
                                        <div className="u-text-right">
                                            <Link route="account-login" overrideClass="u-text-tiny">
                                                Login
                                            </Link>
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            </div>
                        </div>
                    </div>
                </TrackVisibility>
                <LoginBackground />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps)(createForm()(AccountRequestResetPasswordEmailView))
