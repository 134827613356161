import { apiClient } from '../helpers/ApiClient'

/**
 * Fetch a list of all active curriculums for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_CURRICULUM_LIST_REQUEST = 'FETCH_CURRICULUM_LIST_REQUEST'
export const FETCH_CURRICULUM_LIST_SUCCESS = 'FETCH_CURRICULUM_LIST_SUCCESS'
export const FETCH_CURRICULUM_LIST_FAILURE = 'FETCH_CURRICULUM_LIST_FAILURE'
export const FETCH_CURRICULUM_LIST_PENDING = 'FETCH_CURRICULUM_LIST_PENDING'
export const fetchCurriculums = (config = {}) => {
    return {
        promise: client => client.get('curriculums', config),
        types: [
            FETCH_CURRICULUM_LIST_REQUEST,
            FETCH_CURRICULUM_LIST_SUCCESS,
            FETCH_CURRICULUM_LIST_FAILURE,
            FETCH_CURRICULUM_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a curriculum.
 *
 * @param  {String} curriculumId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchCurriculum = (curriculumId, config = {}) => {
    return apiClient.get(`curriculums/${curriculumId}`, config)
}

/**
 * Create a new curriculum.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createCurriculum = (formData, config = {}) => {
    return apiClient.post('curriculums', formData, config)
}

/**
 * Update a curriculum.
 *
 * @param  {String} curriculumId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateCurriculum = (curriculumId, formData, config = {}) => {
    return apiClient.patch(`curriculums/${curriculumId}`, formData, config)
}
