import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { resetPassword } from '../actions/userActions'
import { formatFormMessages } from '../utils/forms'
import { renderRoute } from '../utils/routes'
import { FormMessages } from '../components/FormMessages'
import { Layout, LayoutItem } from '../components/Layout'
import { LoginBackground } from '../components/Login'
import { Logo } from '../components/Logo'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'
import { Link } from '../components/Link'

class AccountResetPasswordView extends React.Component {
    state = {
        errors: [],
        isLoading: false,
        isPasswordReset: false,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const {
            form: { validateFields },
            match: {
                params: { token },
            },
        } = this.props
        const { isLoading, isPasswordReset } = this.state

        if (isLoading || isPasswordReset) {
            return false
        }

        validateFields(async (formError, formValues) => {
            if (!formError) {
                this.setState({ isLoading: true })
                resetPassword(token, formValues, {
                    cancelToken: this.signal.token,
                })
                    .then(response => {
                        this.setState({
                            errors: [],
                            isLoading: false,
                            isPasswordReset: true,
                        })
                    })
                    .catch(error => {
                        this.setState({
                            errors: error.response.data,
                            isLoading: false,
                        })
                    })
            }
        })

        return false
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { errors, isLoading, isPasswordReset } = this.state
        const {
            form: { getFieldDecorator, getFieldError, getFieldValue },
            organizationId,
        } = this.props
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main isSimple={true}>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <div className="login-form_wrapper">
                        <div className="login-form u-card">
                            <div className="login-form_inner-tiny">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Logo /> Report.Cards
                                    </LayoutItem>
                                </Layout>
                            </div>
                            {isPasswordReset ? (
                                <div className="login-form_inner">
                                    <h1 className="o-h1 u-text-big">Reset password</h1>
                                    <div className="u-margin-small-y">
                                        <p>Your password has been reset!</p>
                                    </div>
                                    <div className="u-margin-small-y">
                                        <RouterLink
                                            className="o-button login-form_submit"
                                            to={renderRoute('account-login')}
                                        >
                                            Login
                                        </RouterLink>
                                    </div>
                                </div>
                            ) : (
                                <form className="login-form_inner" onSubmit={this.handleSubmit}>
                                    <h1 className="o-h1 u-text-big">Reset password</h1>
                                    {hasErrors && (
                                        <div className="o-input_wrapper">
                                            <FormMessages hasErrors={true} items={notifications} />
                                        </div>
                                    )}
                                    <div className="o-input_wrapper">
                                        <label className="o-label" htmlFor="email">
                                            Email
                                        </label>
                                        {getFieldDecorator('email', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter your email',
                                                },
                                            ],
                                        })(<input type="email" className="o-input" id="email" />)}
                                        <div className="o-input_error">{getFieldError('email')}</div>
                                    </div>

                                    <div className="o-input_wrapper">
                                        <label className="o-label" htmlFor="password">
                                            New Password
                                        </label>
                                        {getFieldDecorator('password', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter a password',
                                                },
                                            ],
                                        })(<input type="password" className="o-input" id="password" />)}
                                        <div className="o-input_error">{getFieldError('password')}</div>
                                    </div>

                                    <div className="o-input_wrapper">
                                        <label className="o-label" htmlFor="passwordConfirmation">
                                            Confirm your password
                                        </label>
                                        {getFieldDecorator('passwordConfirmation', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Required field',
                                                },
                                                {
                                                    validator: (rule, value, callback) => {
                                                        if (value && value !== getFieldValue('password')) {
                                                            callback("The passwords don't match.")
                                                        } else {
                                                            callback()
                                                        }
                                                    },
                                                },
                                            ],
                                        })(<input type="password" className="o-input" id="passwordConfirmation" />)}
                                        <div className="o-input_error">{getFieldError('passwordConfirmation')}</div>
                                    </div>

                                    <div className="o-input_wrapper">
                                        {getFieldDecorator('organizationId', {
                                            initialValue: organizationId,
                                        })(<input type="text" hidden={true} />)}
                                        <button
                                            type="submit"
                                            className={classNames('o-button', '-spinner', 'login-form_submit', {
                                                'is-loading': isLoading,
                                            })}
                                        >
                                            <span className="o-button_inner">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            )}
                            <div className="login-form_inner-tiny -footer">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <div className="u-text-left">
                                            <Link route="account-login" overrideClass="u-text-tiny">
                                                Login
                                            </Link>
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            </div>
                        </div>
                    </div>
                </TrackVisibility>
                <LoginBackground />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps)(createForm()(AccountResetPasswordView))
