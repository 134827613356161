import React from 'react'

import axios from 'axios'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { fetchReports } from '../actions/reportActions'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { ReportCard } from '../components/Report'
import { TrackVisibility } from '../components/TrackVisibility'
import { FormMessages } from '../components/FormMessages'
import { Spinner } from '../components/Spinner'
import { Fade } from '../components/Fade'

class ReportListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchReports({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { reports, isLoadingReports, sport } = this.props
        return (
            <Main>
                <section>
                    <Header>
                        {sport.name} <br /> Report Cards
                    </Header>
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoadingReports}>
                            {isLoadingReports ? (
                                <div className="u-text-center u-padding-tiny-y">
                                    <Spinner />
                                </div>
                            ) : !reports.length ? (
                                <FormMessages
                                    hasErrors={true}
                                    items={[{ message: 'No active report cards at the moment.' }]}
                                />
                            ) : (
                                reports.map(report => (
                                    <TrackVisibility key={report.id} overrideClass="u-anim-scroll -delay-1">
                                        <ReportCard report={report} isFull />
                                    </TrackVisibility>
                                ))
                            )}
                        </Fade>
                    </SwitchTransition>
                </section>

                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    reports: state.reports.entities,
    isLoadingReports: state.requestStates.FETCH_REPORT_LIST === true,
    sport: state.auth.user.sport,
})

export default connect(mapStateToProps)(ReportListView)
