import {
    ADD_REPORT_CARDS_MODAL,
    BILLING_MODAL,
    CREATE_STUDENT_MODAL,
    MESSAGE_MODAL,
    PROMPT_MODAL,
    SUBSCRIBE_MODAL,
} from './ModalTypes'
import BillingModal from './BillingModal'
import SubscribeModal from './SubscribeModal'
import AddReportCardsModal from './AddReportCardsModal'
import Prompt from './Prompt'
import Message from './Message'
import CreateStudentModal from './CreateStudentModal'

export const MODAL_COMPONENTS = {
    [BILLING_MODAL]: BillingModal,
    [CREATE_STUDENT_MODAL]: CreateStudentModal,
    [SUBSCRIBE_MODAL]: SubscribeModal,
    [ADD_REPORT_CARDS_MODAL]: AddReportCardsModal,
    [PROMPT_MODAL]: Prompt,
    [MESSAGE_MODAL]: Message,
}
