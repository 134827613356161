import React from 'react'
import { TrackVisibility } from '../TrackVisibility'
import { Spinner } from '../Spinner'
import { LayoutItem } from '../Layout'

const DashboardCardLoader = ({ overrideClass, key }) => (
    <LayoutItem overrideClass={overrideClass}>
        <TrackVisibility overrideClass="u-anim-scroll -delay-1 u-text-center u-padding" key="loader">
            <Spinner />
        </TrackVisibility>
    </LayoutItem>
)

DashboardCardLoader.defaultProps = {
    overrideClass: '',
    key: 'loader',
}

export default DashboardCardLoader
