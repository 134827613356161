import { camelCase, forOwn, has, upperFirst } from 'lodash-es'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import routes from '../../config/routes.json'
import { userCanViewRoute, userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from '../../helpers/auth'

import * as Views from '../../views'

// This component allows us to define all routes through routes.json.
// Using this config format allows us to centralize slugs and their related view component.
const RouteResolver = ({ organizationId }) => {
    let views = []
    forOwn(routes, (route, key) => {
        const componentIdent = has(route, 'component') ? route.component : upperFirst(camelCase(`${key}-view`))
        let component = null

        /** Top level access denial if the queried organization does not exist. */
        if (route.isOrganizationRoute && organizationId === null) {
            component = Views['NotFoundView']
        } else {
            if (route.isAuthenticated) {
                if (typeof route.authRule === 'string') {
                    component = userIsAuthenticatedRedir(userCanViewRoute(route.authRule)(Views[componentIdent]))
                } else {
                    component = userIsAuthenticatedRedir(Views[componentIdent])
                }
            } else if (route.isNotAuthenticated) {
                component = userIsNotAuthenticatedRedir(Views[componentIdent])
            } else {
                component = Views[componentIdent]
            }
        }

        if (typeof component === 'undefined') {
            console.error(`View ${componentIdent} could not be found.`)
        } else {
            views.push({
                path: route.path,
                ViewComponent: component,
            })
        }
    })

    return (
        <Route
            render={({ location }) => (
                <TransitionGroup>
                    <CSSTransition appear classNames="o-transition" key={location.key} timeout={{ enter: 600 }}>
                        <Switch location={location}>
                            {views.map(({ path, ViewComponent }, index) => (
                                <Route key={index} exact path={path} component={ViewComponent} />
                            ))}
                            <Route component={Views['NotFoundView']} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )}
        />
    )
}

export default RouteResolver
