export function getVideoImage(path, secs, callback) {
    const me = this,
        video = document.createElement('video')
    video.onloadedmetadata = function() {
        if ('function' === typeof secs) {
            secs = secs(this.duration)
        }
        this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration)
    }
    video.onseeked = function(e) {
        let canvas = document.createElement('canvas')
        canvas.height = video.videoHeight
        canvas.width = video.videoWidth
        let ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        let img = new Image()
        img.src = canvas.toDataURL()
        callback.call(me, img, this.currentTime, e)
    }
    video.onerror = function(e) {
        callback.call(me, undefined, undefined, e)
    }
    video.src = path
}
