import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { Frontload } from 'react-frontload'
import { Helmet } from 'react-helmet'

import { ErrorBoundary } from '../ErrorBoundary'
import { RouteResolver } from '../RouteResolver'

import '../../styles/main.scss'

const App = ({ history, organizationId }) => (
    <React.Fragment>
        <Helmet>
            <title>Report.Cards</title>
        </Helmet>
        <ConnectedRouter history={history}>
            <Frontload noServerRender={true}>
                <ErrorBoundary>
                    <RouteResolver organizationId={organizationId} />
                </ErrorBoundary>
            </Frontload>
        </ConnectedRouter>
    </React.Fragment>
)

App.propTypes = {
    history: PropTypes.object.isRequired,
}

export default App
