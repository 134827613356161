import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'

import { fetchStudentCertificates, fetchStudentMedals, fetchStudentReports } from '../../actions/studentActions'
import mapFetchToState from '../../helpers/mapFetchToState'

import { Fade } from '../Fade'
import { LayoutItem } from '../Layout'
import { TrackVisibility } from '../TrackVisibility'
import { UserCard } from '../User'

class StudentCard extends React.Component {
    signal = axios.CancelToken.source()

    static propTypes = {
        student: PropTypes.object.isRequired,
    }

    state = {
        error: null,
        isLoading: true,
        reports: [],
        certificates: [],
        medals: [],
    }

    loadStudentDetails() {
        const { student } = this.props

        this.setState({ isLoading: true }, () => {
            let config = { cancelToken: this.signal.token }
            mapFetchToState({
                reports: fetchStudentReports(student.id, config),
                studentMedals: fetchStudentMedals(student.id, config),
                certificates: fetchStudentCertificates(student.id, config),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    this.setState({
                        error: error.response.data,
                        isLoading: false,
                    })
                })
        })
    }

    componentDidMount() {
        this.loadStudentDetails()
    }

    render() {
        const { student } = this.props
        const { isLoading, reports, medals, certificates, error } = this.state

        return (
            <LayoutItem overrideClass="u-1/2@from-small">
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <SwitchTransition>
                        <Fade timeout={250} key={isLoading}>
                            {isLoading ? (
                                <UserCard.Placeholder />
                            ) : error !== null ? (
                                <section className="u-relative">{error.message}</section>
                            ) : (
                                <UserCard
                                    user={student}
                                    title="Student"
                                    listItems={[
                                        {
                                            label: 'Report Cards',
                                            value: reports.length,
                                        },
                                        {
                                            label: 'Certificates',
                                            value: certificates.length,
                                        },
                                        {
                                            label: 'Medals',
                                            value: medals.length,
                                        },
                                    ]}
                                    button={{
                                        label: 'View Student',
                                        route: {
                                            id: 'student-details',
                                            bind: 'studentId',
                                            param: student.id,
                                        },
                                    }}
                                    link={{
                                        label: 'View All',
                                        route: 'student-list',
                                    }}
                                />
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
            </LayoutItem>
        )
    }
}

export default StudentCard
