import axios from 'axios'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { fetchInstructor, fetchInstructorSessions, fetchInstructorStudents } from '../actions/instructorActions'
import { userCanDo } from '../helpers/auth'
import { renderRoute } from '../utils/routes'
import { Button } from '../components/Button'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Layout, LayoutItem } from '../components/Layout'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'
import { UserHeader } from '../components/User'

class InstructorDetailsView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        instructor: null,
        isLoading: true,
        sessions: [],
        students: [],
    }

    loadInstructor(instructorId) {
        this.setState({ isLoading: true }, () => {
            fetchInstructor(instructorId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        instructor: response.data,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    loadInstructorStudents(instructorId) {
        fetchInstructorStudents(instructorId, {
            cancelToken: this.signal.token,
        }).then(response => {
            this.setState({
                students: response.data,
            })
        })
    }

    loadInstructorSessions(instructorId) {
        fetchInstructorSessions(instructorId, {
            cancelToken: this.signal.token,
        }).then(response => {
            this.setState({
                sessions: response.data,
            })
        })
    }

    componentDidMount() {
        this.loadInstructor(this.props.match.params.instructorId)
        this.loadInstructorStudents(this.props.match.params.instructorId)
        this.loadInstructorSessions(this.props.match.params.instructorId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.instructorId !== this.props.match.params.instructorId) {
            this.loadInstructor(this.props.match.params.instructorId)
            this.loadInstructorStudents(this.props.match.params.instructorId)
            this.loadInstructorSessions(this.props.match.params.instructorId)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { user } = this.props
        const { error, instructor, isLoading, sessions, students } = this.state

        return (
            <Main>
                <Header>Instructor Profile</Header>
                {isLoading ? (
                    <UserHeader.Placeholder />
                ) : error !== null ? (
                    <section className="u-relative">{error.message}</section>
                ) : (
                    <React.Fragment>
                        <TrackVisibility overrideClass="u-anim-scroll">
                            <UserHeader user={instructor}>
                                <React.Fragment>
                                    <div className="u-padding-small-x">
                                        <div className="u-margin-small">
                                            <Layout overrideClass="-gutter-small">
                                                <LayoutItem
                                                    overrideClass={
                                                        !isEmpty(instructor.telephone)
                                                            ? 'u-1/2@from-small u-text-right@from-small'
                                                            : 'u-text-center'
                                                    }
                                                >
                                                    <Button link={`mailto:${instructor.email}`}>Email me</Button>
                                                </LayoutItem>
                                                {!isEmpty(instructor.telephone) && (
                                                    <LayoutItem overrideClass="u-1/2@from-small">
                                                        <Button link={`tel:${instructor.telephone}`}>Call me</Button>
                                                    </LayoutItem>
                                                )}
                                            </Layout>
                                        </div>
                                        {!isEmpty(instructor.biography) && !isEmpty(instructor.certifications) && (
                                            <div className="user-header_infos u-margin-small-y u-padding-small-y">
                                                <Layout overrideClass="-gutter-small">
                                                    <LayoutItem overrideClass="u-1/2@from-small">
                                                        {!isEmpty(instructor.biography) && (
                                                            <div className="o-wysiwyg">
                                                                <p>Bio</p>
                                                                <p>{instructor.biography}</p>
                                                            </div>
                                                        )}
                                                    </LayoutItem>
                                                    <LayoutItem overrideClass="u-1/2@from-small">
                                                        {!isEmpty(instructor.certifications) && (
                                                            <div className="o-wysiwyg">
                                                                <p>Certifications</p>
                                                                <ul>
                                                                    {instructor.certifications.map((item, index) => (
                                                                        <li key={index}>{item}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </LayoutItem>
                                                </Layout>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-header_data">
                                        <Layout>
                                            <LayoutItem overrideClass="u-1/2@from-small">
                                                <div className="user-header_data_item -border">
                                                    <p className="user-header_data_label">Active Sessions</p>
                                                    <span className="user-header_data_value -big">
                                                        <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                                            {sessions.length}
                                                        </TrackVisibility>
                                                    </span>
                                                </div>
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/2@from-small">
                                                <div className="user-header_data_item">
                                                    <p className="user-header_data_label">Active Students</p>
                                                    <span className="user-header_data_value -big">
                                                        <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                                            {students.length}
                                                        </TrackVisibility>
                                                    </span>
                                                </div>
                                            </LayoutItem>
                                        </Layout>
                                        <Layout>
                                            <LayoutItem overrideClass="u-1/2@from-small">
                                                <Layout>
                                                    <LayoutItem overrideClass="u-1/2@from-small">
                                                        <div className="user-header_data_item -border">
                                                            <p className="user-header_data_label">
                                                                Years of experience
                                                            </p>
                                                            <span className="user-header_data_value">
                                                                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                                                                    {instructor.yearsOfExperience}
                                                                </TrackVisibility>
                                                            </span>
                                                        </div>
                                                    </LayoutItem>
                                                    <LayoutItem overrideClass="u-1/2@from-small">
                                                        <div className="user-header_data_item -border">
                                                            <p className="user-header_data_label">
                                                                Certification completed
                                                            </p>
                                                            <span className="user-header_data_value">
                                                                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                                                                    {instructor.certificationsCompleted}
                                                                </TrackVisibility>
                                                            </span>
                                                        </div>
                                                    </LayoutItem>
                                                </Layout>
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/2@from-small">
                                                <div className="user-header_data_item">
                                                    <p className="user-header_data_label">Highest certification</p>
                                                    <span className="user-header_data_value">
                                                        <TrackVisibility overrideClass="u-anim-scroll -delay-3">
                                                            {instructor.highestCertification}
                                                        </TrackVisibility>
                                                    </span>
                                                </div>
                                            </LayoutItem>
                                        </Layout>
                                    </div>
                                </React.Fragment>
                            </UserHeader>
                        </TrackVisibility>
                        {userCanDo(user, 'report-cards/object/instructor.update') && (
                            <Layout>
                                <LayoutItem>
                                    <TrackVisibility overrideClass="u-anim-scroll">
                                        <RouterLink
                                            className="o-button -huge -green u-margin-small-y"
                                            to={renderRoute({
                                                id: 'instructor-edit',
                                                bind: 'instructorId',
                                                param: instructor.id,
                                            })}
                                        >
                                            Edit Instructor
                                        </RouterLink>
                                    </TrackVisibility>
                                </LayoutItem>
                            </Layout>
                        )}
                    </React.Fragment>
                )}
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(InstructorDetailsView)
