import axios from 'axios'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'
import React from 'react'

import { fetchInstructorStudents, fetchInstructorUpcomingSession } from '../../actions/instructorActions'
import mapFetchToState from '../../helpers/mapFetchToState'
import { getNextCourse } from '../../utils/courses'

import { Fade } from '../Fade'
import { UserCard } from '../User'

class InstructorCard extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        isLoading: true,
        students: [],
        upcomingSession: null,
    }

    componentDidMount() {
        const { instructor } = this.props

        this.setState({ isLoading: true }, () => {
            const config = { cancelToken: this.signal.token }
            mapFetchToState({
                students: fetchInstructorStudents(instructor.id, config),
                upcomingSession: new Promise((resolve, reject) =>
                    fetchInstructorUpcomingSession(instructor.id, config)
                        .then(response => resolve(response))
                        .catch(error => {
                            if (error.response.status === 404) {
                                return resolve({ data: null })
                            }
                            reject(error)
                        })
                ),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    console.warn(error)
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    renderListItems() {
        const { students, upcomingSession } = this.state
        const nextCourse = getNextCourse(get(upcomingSession, 'courses', []))
        return [
            {
                label: 'Students',
                value: students.length,
            },
            {
                label: 'Next course',
                value: get(nextCourse, 'startDate.display', '-'),
            },
            {
                label: 'Session',
                value: get(upcomingSession, 'name', '-'),
            },
            {
                label: 'Curriculum',
                value: get(upcomingSession, 'curriculum.name', '-'),
            },
        ]
    }

    render() {
        const { instructor } = this.props
        const { error, isLoading } = this.state

        return (
            <SwitchTransition>
                <Fade timeout={250} key={isLoading}>
                    {isLoading ? (
                        <UserCard.Placeholder />
                    ) : error !== null ? (
                        <section className="u-relative">{error.message}</section>
                    ) : (
                        <UserCard
                            user={instructor}
                            title="Instructor"
                            listItems={this.renderListItems()}
                            button={{
                                label: 'View Instructor',
                                route: {
                                    id: 'instructor-details',
                                    bind: 'instructorId',
                                    param: instructor.id,
                                },
                            }}
                            link={{
                                label: 'View all',
                                route: 'instructor-list',
                            }}
                        />
                    )}
                </Fade>
            </SwitchTransition>
        )
    }
}

InstructorCard.propTypes = {
    instructor: PropTypes.object.isRequired,
}

export default InstructorCard
