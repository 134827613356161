import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import Datetime from 'react-datetime'

import { Icon } from '../Icon'

const now = Datetime.moment()

const BirthDate = ({ displayPicker, form, handleDatetimeChange, initialValue }) => (
    <div className="o-input_wrapper">
        <label className="o-label -required">Birth Date</label>
        <div className="u-relative">
            {form.getFieldDecorator('birthDate', {
                initialValue: initialValue,
            })(<input type="hidden" />)}
            {displayPicker && (
                <Datetime
                    className="o-input"
                    dateFormat="ll"
                    defaultValue={Datetime.moment(initialValue)}
                    inputProps={{ placeholder: 'Pick a date' }}
                    onChange={handleDatetimeChange}
                    timeFormat={false}
                    viewMode="years"
                    isValidDate={current => current.isBefore(now)}
                />
            )}
            <span className="o-input_icon">
                <Icon name="calendar" />
            </span>
        </div>
    </div>
)

BirthDate.propTypes = {
    displayPicker: PropTypes.bool,
    form: formShape,
    handleDatetimeChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
}

BirthDate.defaultProps = {
    displayPicker: true,
    initialValue: '',
}

export default BirthDate
