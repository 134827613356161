const baseUrl = 'plans'

/**
 * Fetch a list of all active parents for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_PLAN_LIST_REQUEST = 'FETCH_PLAN_LIST_REQUEST'
export const FETCH_PLAN_LIST_SUCCESS = 'FETCH_PLAN_LIST_SUCCESS'
export const FETCH_PLAN_LIST_FAILURE = 'FETCH_PLAN_LIST_FAILURE'
export const FETCH_PLAN_LIST_PENDING = 'FETCH_PLAN_LIST_PENDING'
export const fetchPlans = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [FETCH_PLAN_LIST_REQUEST, FETCH_PLAN_LIST_SUCCESS, FETCH_PLAN_LIST_FAILURE, FETCH_PLAN_LIST_PENDING],
    }
}
