import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const FirstName = ({ form, initialValue }) => (
    <div className="o-input_wrapper">
        <label htmlFor="firstName" className="o-label -required">
            First Name
        </label>
        {form.getFieldDecorator('firstName', {
            initialValue: initialValue,
            validate: [
                {
                    trigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: 'Please enter a first name',
                        },
                    ],
                },
            ],
        })(<input type="text" id="firstName" placeholder="First Name" className="o-input" />)}
        <div className="o-input_error">{form.getFieldError('firstName')}</div>
    </div>
)

FirstName.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
}

FirstName.defaultProps = {
    initialValue: '',
}

export default FirstName
