import axios from 'axios'
import { mapKeys } from 'lodash-es'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import { fetchCurriculumPresets } from '../actions/curriculumPresetActions'
import { renderRoute } from '../utils/routes'
import { FilteredList, FilteredListItem } from '../components/FilteredList'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

const HEADERS = [
    { id: 'name', label: 'Name' },
    { id: 'lastModified', label: 'Last update' },
]

class CurriculumPresetListView extends React.Component {
    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchCurriculumPresets({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { isLoading, items } = this.props

        return (
            <Main>
                <Header>Curriculum Presets</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    <FilteredList
                        hasFooter={false}
                        hasSorting={true}
                        isFull={true}
                        isLoading={isLoading}
                        itemComponent={FilteredListItem}
                        listHeaders={HEADERS}
                        listItems={items}
                        title={`${items.length} Presets`}
                    />
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    const headers = mapKeys(HEADERS, 'id')
    return {
        isLoading: state.requestStates.FETCH_CURRICULUM_PRESET_LIST === true,
        items: state.curriculumPresets.entities.map(item => {
            return {
                data: item,
                url: renderRoute({
                    id: 'curriculum-preset-details',
                    bind: 'curriculumPresetId',
                    param: item.id,
                }),
                columns: {
                    ...headers,
                    name: item.name,
                    lastModified: moment.unix(item.lastModified.timestamp).format('YYYY-MM-DD - HH:mm'),
                },
            }
        }),
    }
}

export default connect(mapStateToProps)(CurriculumPresetListView)
