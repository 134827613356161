import { apiClient } from '../helpers/ApiClient'

/**
 * Fetch a list of all active parents for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_PARENT_LIST_REQUEST = 'FETCH_PARENT_LIST_REQUEST'
export const FETCH_PARENT_LIST_SUCCESS = 'FETCH_PARENT_LIST_SUCCESS'
export const FETCH_PARENT_LIST_FAILURE = 'FETCH_PARENT_LIST_FAILURE'
export const FETCH_PARENT_LIST_PENDING = 'FETCH_PARENT_LIST_PENDING'
export const fetchParents = (config = {}) => {
    return {
        promise: client => client.get('student-parents', config),
        types: [
            FETCH_PARENT_LIST_REQUEST,
            FETCH_PARENT_LIST_SUCCESS,
            FETCH_PARENT_LIST_FAILURE,
            FETCH_PARENT_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a parent.
 *
 * @param  {String} parentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchParent = (parentId, config = {}) => {
    return apiClient.get(`student-parents/${parentId}`, config)
}

/**
 * Fetch a parent students.
 *
 * @param  {String} parentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchParentStudents = (parentId, config = {}) => {
    return apiClient.get(`student-parents/${parentId}/students`, config)
}

/**
 * Fetch a parent reports.
 *
 * @param  {String} parentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchParentReports = (parentId, config = {}) => {
    return apiClient.get(`student-parents/${parentId}/reports`, config)
}

/**
 * Create a new parent.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createParent = (formData, config = {}) => {
    return apiClient.post('student-parents', formData, config)
}

/**
 * Update a parent.
 *
 * @param  {String} parentId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateParent = (parentId, formData, config = {}) => {
    return apiClient.patch(`student-parents/${parentId}`, formData, config)
}

/**
 * Delete a parent.
 *
 * @param  {String} parentId
 * @param  {Object} config
 * @return {Promise}
 */
export const deleteParent = (parentId, config = {}) => {
    return apiClient.delete(`student-parents/${parentId}`, config)
}
