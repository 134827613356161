import React from 'react'

import PropTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'

import { renderRoute } from '../utils/routes'

import { FilteredList, FilteredListUserItem } from '../components/FilteredList'
import { Fade } from '../components/Fade'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'
import { List, ListUserItem } from '../components/List'

const UserListView = ({
    detailsRoute,
    isLoading,
    items,
    listTitle,
    title,
    isDetailed,
    hasFooter,
    buttonData,
    listDetails,
}) => {
    const listItems = items.map(item => {
        return {
            id: item.id,
            columns: {
                email: item.email,
                name: item.name,
            },
            data: item,
            url: renderRoute({
                ...detailsRoute,
                param: item.id,
            }),
        }
    })

    return (
        <Main>
            <Header>{title}</Header>
            <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                {isDetailed ? (
                    <SwitchTransition>
                        <Fade timeout={150} key={isLoading}>
                            {isLoading ? (
                                <List.Placeholder />
                            ) : (
                                <List
                                    title={`${listItems.length} ${listTitle}`}
                                    {...(hasFooter && { button: buttonData })}
                                >
                                    {listItems.map((item, index) => (
                                        <ListUserItem key={index} {...item} user={item.data} details={listDetails} />
                                    ))}
                                </List>
                            )}
                        </Fade>
                    </SwitchTransition>
                ) : (
                    <FilteredList
                        buttonData={buttonData}
                        hasFooter={hasFooter}
                        hasSorting={true}
                        isLoading={isLoading}
                        itemComponent={FilteredListUserItem}
                        listHeaders={[
                            { id: 'name', label: 'Name' },
                            { id: 'email', label: 'Email' },
                        ]}
                        listItems={listItems}
                        title={`${listItems.length} ${listTitle}`}
                    />
                )}
            </TrackVisibility>
            <Footer />
        </Main>
    )
}

UserListView.propTypes = {
    buttonData: PropTypes.object,
    detailsRoute: PropTypes.shape({
        id: PropTypes.string.isRequired,
        bind: PropTypes.string.isRequired,
    }).isRequired,
    hasFooter: PropTypes.bool,
    isDetailed: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    isStudent: PropTypes.bool,
    items: PropTypes.array.isRequired,
    listDetails: PropTypes.array,
    listTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

UserListView.defaultProps = {
    buttonData: {},
    hasFooter: false,
    isDetailed: false,
}

export default UserListView
