import React from 'react'

import PropTypes from 'prop-types'

import { isUserAdministrator } from '../../helpers/auth'

import { Button } from '../Button'
import { SimpleList } from '../SimpleList'
import { UserImage } from '../User'
import { Logout } from '../Logout'

const UserMyCard = ({ listItems, user }) => (
    <div className="user-mycard">
        <UserImage user={user} overrideClass={'user-image'} />
        <h1 className="user-mycard_name o-h2">{user.name}</h1>
        <p className="user-mycard_label">{user.role.label}</p>

        <Logout />

        <div className="user-mycard_infos u-padding-small-y">
            <SimpleList items={listItems} />
        </div>
        <footer className="user-mycard_footer">
            <Button route="account-edit" overrideClass="user-mycard_button -small">
                My Account
            </Button>
            {isUserAdministrator(user) && (
                <Button route="account-organization-edit" overrideClass="user-mycard_button -small">
                    My Organization
                </Button>
            )}
            {isUserAdministrator(user) && (
                <Button route="account-plan-edit" overrideClass="user-mycard_button -small">
                    My Plan
                </Button>
            )}
        </footer>
    </div>
)

UserMyCard.propTypes = {
    listItems: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
}

export default UserMyCard
