import axios from 'axios'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { createForm } from 'rc-form'
import React from 'react'
import { connect } from 'react-redux'

import { confirmUser, fetchSelf, fetchUserByToken, login } from '../actions/userActions'
import { formatFormMessages } from '../utils/forms'

import { FormMessages } from '../components/FormMessages'
import { Layout, LayoutItem } from '../components/Layout'
import { LoginBackground } from '../components/Login'
import { Logo } from '../components/Logo'
import { Main } from '../components/Main'
import { Spinner } from '../components/Spinner'
import { TrackVisibility } from '../components/TrackVisibility'
import { Button } from '../components/Button'
import { Link } from '../components/Link'

class AccountOnboardingView extends React.Component {
    state = {
        errors: [],
        formValues: {},
        isLoadingUser: true,
        isSavingUser: false,
        isUserSaved: false,
        user: null,
    }

    signal = axios.CancelToken.source()

    handleSubmit = event => {
        event.preventDefault()

        const {
            dispatch,
            form: { validateFields },
        } = this.props
        const { formValues: stateFormValues, isLoadingUser, isSavingUser, user } = this.state

        if (isLoadingUser || isSavingUser) {
            return false
        }

        validateFields(async (formError, formValues) => {
            if (!formError) {
                const formData = { ...stateFormValues, ...formValues }
                this.setState({ isSavingUser: true }, () => {
                    confirmUser(user.id, formData, {
                        cancelToken: this.signal.token,
                    })
                        .then(response => {
                            this.setState({
                                formValues: formData,
                                errors: [],
                                // isSavingUser: false,
                                isUserSaved: true,
                            })

                            /** Authenticate the user */
                            dispatch(
                                login(
                                    {
                                        email: formData.email,
                                        password: formData.password,
                                        organizationId: formData.organizationId,
                                    },
                                    { cancelToken: this.signal.token }
                                )
                            ).then(response => {
                                /** Load the authenticated user's details. */
                                dispatch(
                                    fetchSelf({
                                        cancelToken: this.signal.token,
                                    })
                                )
                            })
                        })
                        .catch(error => {
                            this.setState({
                                errors: error.response.data,
                                isSavingUser: false,
                            })
                        })
                })
            }
        })

        return false
    }

    componentDidMount() {
        this.setState({ isLoadingUser: true }, () => {
            const token = this.props.match.params.token
            fetchUserByToken(token)
                .then(response => {
                    this.setState({
                        errors: [],
                        formValues: { token: token },
                        isLoadingUser: false,
                        user: response.data,
                    })
                })
                .catch(error => {
                    this.setState({
                        errors: error.response.data,
                        isLoadingUser: false,
                    })
                })
        })
    }

    render() {
        const { errors, isLoadingUser, isSavingUser, user } = this.state
        const {
            form: { getFieldDecorator, getFieldError, getFieldValue },
            organizationId,
        } = this.props
        const hasErrors = !isEmpty(errors)
        const notifications = hasErrors ? formatFormMessages(errors) : []

        return (
            <Main isSimple={true}>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <div className="login-form_wrapper">
                        <div className="login-form u-card">
                            <div className="login-form_inner-tiny">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Logo /> Report.Cards
                                    </LayoutItem>
                                </Layout>
                            </div>
                            `
                            <div className="login-form_inner">
                                <h1 className="o-h1 u-text-big">Confirm email & password</h1>
                                {isLoadingUser ? (
                                    <div className="u-text-center">
                                        <Spinner />
                                    </div>
                                ) : user === null ? (
                                    <React.Fragment>
                                        <p>Invalid confirmation token, could not find your account.</p>
                                        <Button route="account-login">Back to login</Button>
                                    </React.Fragment>
                                ) : (
                                    <form onSubmit={this.handleSubmit}>
                                        {hasErrors && (
                                            <div className="o-input_wrapper">
                                                <FormMessages items={notifications} />
                                            </div>
                                        )}
                                        <div className="o-input_wrapper">
                                            <label className="o-label" htmlFor="email">
                                                Email
                                            </label>
                                            {getFieldDecorator('email', {
                                                initialValue: user.email,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter your email',
                                                    },
                                                ],
                                            })(<input type="email" className="o-input" id="email" readOnly />)}
                                            <div className="o-input_error">{getFieldError('email')}</div>
                                        </div>

                                        <div className="o-input_wrapper">
                                            <label className="o-label" htmlFor="password">
                                                Password
                                            </label>
                                            {getFieldDecorator('password', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please enter a password',
                                                    },
                                                ],
                                            })(<input type="password" className="o-input" id="password" />)}
                                            <div className="o-input_error">{getFieldError('password')}</div>
                                        </div>

                                        <div className="o-input_wrapper">
                                            <label className="o-label" htmlFor="passwordConfirmation">
                                                Confirm your password
                                            </label>
                                            {getFieldDecorator('passwordConfirmation', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Required field',
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            if (value && value !== getFieldValue('password')) {
                                                                callback("The passwords don't match.")
                                                            } else {
                                                                callback()
                                                            }
                                                        },
                                                    },
                                                ],
                                            })(<input type="password" className="o-input" id="passwordConfirmation" />)}
                                            <div className="o-input_error">{getFieldError('passwordConfirmation')}</div>
                                        </div>
                                        <div className="o-input_wrapper">
                                            {getFieldDecorator('organizationId', {
                                                initialValue: organizationId,
                                            })(<input type="text" hidden={true} />)}
                                            <button
                                                type="submit"
                                                className={classNames('o-button', '-spinner', 'login-form_submit', {
                                                    'is-loading': isSavingUser,
                                                })}
                                            >
                                                <span className="o-button_inner">Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                            <div className="login-form_inner-tiny -footer">
                                <Layout overrideClass="-gutter-small">
                                    <LayoutItem overrideClass="u-1/2">
                                        <Link route="account-request-confirmation-email" overrideClass="u-text-tiny">
                                            Resend email confirmation
                                        </Link>
                                    </LayoutItem>
                                    <LayoutItem overrideClass="u-1/2">
                                        <div className="u-text-right">
                                            <Link
                                                route="account-request-reset-password-email"
                                                overrideClass="u-text-tiny"
                                            >
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </LayoutItem>
                                </Layout>
                            </div>
                        </div>
                    </div>
                </TrackVisibility>
                <LoginBackground />
            </Main>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps)(createForm()(AccountOnboardingView))
