import React, { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Icon } from '../Icon'

const Sharer = ({ children, text, url }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleIsOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen, setIsOpen])

    const share = useCallback(event => {
        event.preventDefault()

        const openWindow = url => {
            window.open(url, '', 'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=500, width=600')
        }

        const openMail = (subject, body) => {
            window.location = 'mailto:?subject=' + subject + '&body=' + body
        }

        const target = event.currentTarget
        const platform = target.getAttribute('data-platform')
        const url = target.getAttribute('data-url')

        switch (platform) {
            case 'facebook':
                openWindow(`https://facebook.com/sharer/sharer.php?u=${url}`)
                break

            case 'twitter':
                const text = target.getAttribute('data-text')
                openWindow(`https://twitter.com/share?url=${url}&amp;text=${text}`)
                break

            case 'mail':
                const subject = target.getAttribute('data-subject')
                const body = target.getAttribute('data-body')
                openMail(subject, body)
                break
            default:
                break
        }
    }, [])

    const onClickOutsideHandler = useCallback(() => {
        if (isOpen) {
            handleToggleIsOpen()
        }
    }, [handleToggleIsOpen, isOpen])

    useEffect(() => {
        window.addEventListener('click', onClickOutsideHandler)

        return () => {
            window.removeEventListener('click', onClickOutsideHandler)
        }
    })

    return (
        <div className={classNames('sharer', { 'is-open': isOpen })}>
            <button className="o-button -full" onClick={handleToggleIsOpen} type="button">
                {children}

                <ul className="sharer_list">
                    <li className="sharer_item">
                        <div className="sharer_item_link" data-platform="facebook" data-url={url} onClick={share}>
                            <Icon name="facebook" />
                        </div>
                    </li>
                    <li className="sharer_item">
                        <div
                            className="sharer_item_link"
                            data-platform="twitter"
                            data-url={url}
                            data-text={text}
                            onClick={share}
                        >
                            <Icon name="twitter" />
                        </div>
                    </li>
                    <li className="sharer_item">
                        <div
                            className="sharer_item_link"
                            data-platform="mail"
                            data-subject={text}
                            data-body={url}
                            onClick={share}
                        >
                            <Icon name="email" />
                        </div>
                    </li>
                </ul>
            </button>
        </div>
    )
}

Sharer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

export default Sharer
