import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute } from '../../utils/routes'

const Button = ({ children, isExternalLink, link, onClick, overrideClass, route }) => {
    const classes = classNames('button', overrideClass)
    return route !== null ? (
        <RouterLink to={renderRoute(route)} className={classes}>
            {children}
        </RouterLink>
    ) : link !== null ? (
        <a href={link} className={classes} target={isExternalLink ? '_blank' : null}>
            {children}
        </a>
    ) : (
        <button type="button" className={classes} onClick={onClick}>
            {children}
        </button>
    )
}

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    isExternalLink: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    overrideClass: PropTypes.string,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

Button.defaultProps = {
    link: null,
    isExternalLink: false,
    onClick: () => {},
    overrideClass: '',
    route: null,
}

export default Button
