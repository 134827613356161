import axios from 'axios'
import { isEmpty } from 'lodash-es'
import moment from 'moment'
import propTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { BILLING_MODAL, MESSAGE_MODAL, PROMPT_MODAL, SUBSCRIBE_MODAL } from '../components/Modal/ModalTypes'
import { apiClient } from '../helpers/ApiClient'
import { fetchPlans } from '../actions/planAction'
import { fetchSubscription, updateSubscription } from '../actions/subscriptionAction'
import { fetchOrganization } from '../actions/organizationActions'
import { loadModal } from '../actions/modalActions'
import { formatFormMessages } from '../utils/forms'
import { Main } from '../components/Main'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { TrackVisibility } from '../components/TrackVisibility'
import { Layout, LayoutItem } from '../components/Layout'
import { PlanFrequency, PlanReportCards } from '../components/Plan'
import { FormMessages } from '../components/FormMessages'
import { Slide } from '../components/Fade'

Modal.setAppElement('#root')

class AccountPlanEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        isLoadingSubscription: true,
        initSelectedPlan: false,
        subscription: {},
        selectedPlanId: null,
        errors: [],
        messages: [],
    }

    static props = {
        isLoadingPlans: propTypes.bool,
        plans: propTypes.array,
        currentPlan: propTypes.string,
    }

    resetRelatedAppData() {
        const { dispatch, organizationId } = this.props

        apiClient.clearCache('subscription')
        this.loadSubscription()

        apiClient.clearCache('organization')
        dispatch(fetchOrganization(organizationId))
    }

    getCurrentPlan() {
        const { plans } = this.props
        const { selectedPlanId } = this.state

        return plans.filter(item => item.stripePlanId === selectedPlanId)[0]
    }

    getSubscribedPlan() {
        const { plans } = this.props
        const { subscription } = this.state

        return plans.filter(item => item.stripePlanId === subscription.stripePlanId)[0]
    }

    onSubscribe() {
        const { showModal } = this.props

        this.resetRelatedAppData()

        showModal(MESSAGE_MODAL, {
            title: 'Success',
            message: (
                <span>
                    Congratulations, you successfully subscribed to the{' '}
                    <span className="u-blue">{this.getCurrentPlan().name} plan</span>.
                </span>
            ),
        })
    }

    onReportAdded() {
        const { showModal } = this.props

        this.resetRelatedAppData()

        showModal(MESSAGE_MODAL, {
            title: 'Success',
            message: <span>Your report cards were added to your plan!</span>,
        })
    }

    onChangePlan(success, failure) {
        const { selectedPlanId } = this.state

        updateSubscription({ stripePlanId: selectedPlanId }, { cancelToken: this.signal.token })
            .then(response => {
                this.resetRelatedAppData()
                success()
            })
            .catch(error => {
                console.warn(error)
                if (!axios.isCancel(error)) {
                    failure(error.response.data)
                }
            })
    }

    onCancelPlan(success, failure) {
        const { showModal } = this.props

        updateSubscription(
            {
                stripePlanId: '',
            },
            { cancelToken: this.signal.token }
        )
            .then(response => {
                this.resetRelatedAppData()

                showModal(MESSAGE_MODAL, {
                    title: 'Success',
                    message: <span>Congratulations you successfully cancel your plan.</span>,
                })
            })
            .catch(error => {
                console.warn(error)
                if (!axios.isCancel(error)) {
                    failure(error.response.data)
                }
            })
    }

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(fetchPlans({ cancelToken: this.signal.token }))
        this.loadSubscription()
    }

    loadSubscription() {
        fetchSubscription({ cancelToken: this.signal.token })
            .then(response => {
                this.setState({
                    subscription: response.data,
                    isLoadingSubscription: false,
                })
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({
                        errors: error.response.data,
                        isLoadingSubscription: false,
                    })
                }
            })
    }

    /**
     * Ensures an initial plan is always selected.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { plans, isLoadingPlans } = this.props
        const { isLoadingSubscription, subscription } = this.state
        if (!prevState.initSelectedPlan && !isLoadingPlans && !isLoadingSubscription) {
            this.setState({
                selectedPlanId: subscription.stripePlanId || plans[0].stripePlanId,
                initSelectedPlan: true,
            })
        }
    }

    isNewPlan() {
        const { subscription, selectedPlanId } = this.state

        return !(subscription && selectedPlanId === subscription.stripePlanId)
    }

    onSelectPlan(planId) {
        this.setState({
            selectedPlanId: planId,
        })
    }

    subscriptionInfo() {
        const { initSelectedPlan, subscription } = this.state

        if (!initSelectedPlan) {
            return []
        }

        // is subscribed
        if (!!subscription) {
            if (this.isNewPlan()) {
                const ts = subscription.currentPeriodEnd.timestamp
                const isPast = moment.unix(ts).diff(moment(), 'days') < 0

                return [
                    ...(subscription.status === 'cancelled'
                        ? [
                              {
                                  label: React.createElement('span', { className: 'u-red' }, 'Canceled on'),
                                  value: subscription.canceledAt.display,
                              },
                              {
                                  label: React.createElement('span', { className: 'u-red' }, 'Ended the'),
                                  value: subscription.endedAt.display,
                              },
                          ]
                        : []),
                    {
                        label: 'Plan transition date',
                        value: isPast ? moment().format('MMM D, YYYY') : subscription.currentPeriodEnd.display,
                    },
                    {
                        label: 'First payment',
                        value: isPast ? moment().format('MMM D, YYYY') : subscription.currentPeriodEnd.display,
                    },
                    {
                        label: 'Next Payment',
                        value: (() => {
                            const { interval, interval_count } = this.getCurrentPlan().stripePlanData
                            const ts = subscription.currentPeriodEnd.timestamp

                            return moment
                                .unix(ts)
                                .add(interval_count, interval)
                                .format('MMM D, YYYY')
                        })(),
                    },
                ]
            }

            const subscriptionData = [
                { label: 'Subscribed since', value: subscription.startDate ? subscription.startDate.display : '-' },
            ]

            switch (subscription.status) {
                case 'active':
                    return [
                        ...subscriptionData,
                        { label: 'Next Payment', value: subscription.currentPeriodEnd.display },
                    ]
                case 'trialing':
                    return [
                        ...subscriptionData,
                        {
                            label: 'Trial ends',
                            value: (() => {
                                const ts = subscription.currentPeriodEnd.timestamp
                                return moment.unix(ts).formNowInDays()
                            })(),
                        },
                        { label: 'First Payment', value: subscription.currentPeriodEnd.display },
                        {
                            label: 'Next Payment',
                            value: (() => {
                                const ts = subscription.currentPeriodEnd.timestamp
                                const interval = subscription.interval
                                const intervalCount = subscription.intervalCount

                                return moment
                                    .unix(ts)
                                    .add(intervalCount, interval)
                                    .format('MMM D, YYYY')
                            })(),
                        },
                    ]
                case 'past_due':
                case 'unpaid':
                    return []
                default:
                    return []
            }
        }

        return [
            { label: 'Membership start', value: 'Jun 8, 2018' },
            { label: 'First Payment', value: 'Jun 8, 2019' },
            { label: 'Next Payment', value: 'Jul 8, 2018' },
        ]
    }

    render() {
        // const listItems = [
        //     { label: 'Membership start', value: 'Jun 8, 2018' },
        //     { label: 'First Payment', value: 'Jun 8, 2019' },
        //     { label: 'Next Payment', value: 'Jul 8, 2018' },
        // ]
        const { initSelectedPlan, subscription, selectedPlanId, errors, messages } = this.state
        const { plans, showModal } = this.props
        const hasErrors = !isEmpty(errors)
        const notifications = formatFormMessages(hasErrors ? errors : messages)
        const hasNotifications = !isEmpty(notifications)
        const currentlySubscribed = !!subscription.stripePlanId
        const subscribedPlan = this.getSubscribedPlan()

        return (
            <Main>
                <Header>My plan</Header>
                <TrackVisibility overrideClass="u-anim-scroll -delay-1">
                    {hasNotifications && (
                        <div className="o-input_wrapper">
                            <FormMessages hasErrors={hasErrors} hasSuccess={!hasErrors} items={notifications} />
                        </div>
                    )}
                    {initSelectedPlan && (
                        <SwitchTransition>
                            <Slide appear={false} timeout={150} key={subscription.stripePlanId || 'none'}>
                                <PlanFrequency
                                    infos={this.subscriptionInfo()}
                                    plans={plans}
                                    subscription={subscription}
                                    selectedPlanId={selectedPlanId}
                                    onSelectPlan={planId => this.onSelectPlan(planId)}
                                    onCancelPlan={() =>
                                        showModal(PROMPT_MODAL, {
                                            title: 'Warning',
                                            type: 'warning',
                                            message: (
                                                <span>
                                                    You are about to cancel your subscription to the
                                                    <span className="u-blue">{subscribedPlan.name}</span>plan. If you
                                                    proceed with the cancellation, your membership will remain active
                                                    until the end of your current plan period which will end on{' '}
                                                    <span className="u-blue">
                                                        {subscription.currentPeriodEnd.display}.
                                                    </span>
                                                </span>
                                            ),
                                            onConfirm: (success, failure) => this.onCancelPlan(success, failure),
                                            cancelLabel: 'Abort',
                                            confirmLabel: 'Cancel Plan',
                                        })
                                    }
                                >
                                    {currentlySubscribed ? (
                                        <Layout overrideClass="-gutter-small u-margin-small-y">
                                            <LayoutItem overrideClass="u-1/2">
                                                <button
                                                    className="o-button -full"
                                                    onClick={() =>
                                                        showModal(BILLING_MODAL, {
                                                            onSuccess: () =>
                                                                showModal(MESSAGE_MODAL, {
                                                                    title: 'Success',
                                                                    message:
                                                                        'Your billing information was successfully updated',
                                                                }),
                                                        })
                                                    }
                                                >
                                                    Billing info
                                                </button>
                                            </LayoutItem>
                                            <LayoutItem overrideClass="u-1/2">
                                                <button
                                                    disabled={!this.isNewPlan()}
                                                    className="o-button -full"
                                                    onClick={() =>
                                                        showModal(PROMPT_MODAL, {
                                                            title: 'Warning',
                                                            type: 'warning',
                                                            onConfirm: (success, failure) =>
                                                                this.onChangePlan(success, failure),
                                                            cancelLabel: 'Abort',
                                                            message: (
                                                                <span>
                                                                    You are about to replace your subscription with the{' '}
                                                                    <span className="u-blue">
                                                                        {this.getCurrentPlan().name} plan
                                                                    </span>
                                                                    .
                                                                </span>
                                                            ),
                                                            confirmLabel: 'Change Plan',
                                                            onSuccess: () =>
                                                                showModal(MESSAGE_MODAL, {
                                                                    title: 'Success',
                                                                    message: (
                                                                        <span>
                                                                            Congratulations, you successfully changed to
                                                                            the{' '}
                                                                            <span className="u-blue">
                                                                                {this.getCurrentPlan().name} plan
                                                                            </span>
                                                                            .
                                                                        </span>
                                                                    ),
                                                                }),
                                                        })
                                                    }
                                                >
                                                    Change Plan
                                                </button>
                                            </LayoutItem>
                                        </Layout>
                                    ) : (
                                        <Layout overrideClass="-gutter-small u-margin-small-y">
                                            <LayoutItem overrideClass="u-1/2">
                                                <button
                                                    className="o-button -full"
                                                    onClick={() =>
                                                        showModal(SUBSCRIBE_MODAL, {
                                                            selectedPlanId: selectedPlanId,
                                                            onSuccess: () => this.onSubscribe(),
                                                        })
                                                    }
                                                >
                                                    Subscribe to Plan
                                                </button>
                                            </LayoutItem>
                                        </Layout>
                                    )}
                                </PlanFrequency>
                            </Slide>
                        </SwitchTransition>
                    )}
                </TrackVisibility>
                <TrackVisibility overrideClass="u-anim-scroll -delay-2">
                    <SwitchTransition>
                        <Slide appear={false} timeout={150} key={currentlySubscribed || 'none'}>
                            {currentlySubscribed && <PlanReportCards onReportAdded={() => this.onReportAdded()} />}
                        </Slide>
                    </SwitchTransition>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    showModal: (modalType, modalProps = {}) => dispatch(loadModal(modalType, modalProps)),
})

const mapStateToProps = state => {
    return {
        plans: state.plans.entities,
        isLoadingPlans: state.requestStates.FETCH_PLAN_LIST === true,
        organizationId: state.organization.id,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPlanEditView)
