import React from 'react'

import axios from 'axios'
import { SwitchTransition } from 'react-transition-group'

import { fetchMedal } from '../actions/medalActions'

import { Fade } from '../components/Fade'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { MedalForm } from '../components/MedalForm'
import { SessionForm } from '../components/Session'
import { TrackVisibility } from '../components/TrackVisibility'

class MedalEditView extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        medal: null,
        isLoading: true,
    }

    loadMedal(medalId) {
        this.setState({ isLoading: true }, () => {
            fetchMedal(medalId, {
                cancelToken: this.signal.token,
            })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        medal: response.data,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        this.loadMedal(this.props.match.params.medalId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.medalId !== this.props.match.params.medalId) {
            this.loadMedal(this.props.match.params.medalId)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { error, isLoading, medal } = this.state

        return (
            <Main>
                <Header>Edit Medal</Header>
                <TrackVisibility overrideClass="u-anim-scroll">
                    <SwitchTransition>
                        <Fade timeout={250} key={isLoading}>
                            {isLoading ? (
                                <SessionForm.Placeholder />
                            ) : error !== null ? (
                                <section className="u-relative">{error.message}</section>
                            ) : (
                                <MedalForm medal={medal} />
                            )}
                        </Fade>
                    </SwitchTransition>
                </TrackVisibility>
                <Footer />
            </Main>
        )
    }
}

export default MedalEditView
