import { apiClient } from '../helpers/ApiClient'

export const baseUrl = 'students'

/**
 * Fetch a list of all active students for the active organization.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_STUDENT_LIST_REQUEST = 'FETCH_STUDENT_LIST_REQUEST'
export const FETCH_STUDENT_LIST_SUCCESS = 'FETCH_STUDENT_LIST_SUCCESS'
export const FETCH_STUDENT_LIST_FAILURE = 'FETCH_STUDENT_LIST_FAILURE'
export const FETCH_STUDENT_LIST_PENDING = 'FETCH_STUDENT_LIST_PENDING'
export const fetchStudents = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_STUDENT_LIST_REQUEST,
            FETCH_STUDENT_LIST_SUCCESS,
            FETCH_STUDENT_LIST_FAILURE,
            FETCH_STUDENT_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a list of all active students for the active
 * organization without filtering by authentication context.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_ALL_STUDENT_LIST_REQUEST = 'FETCH_ALL_STUDENT_LIST_REQUEST'
export const FETCH_ALL_STUDENT_LIST_SUCCESS = 'FETCH_ALL_STUDENT_LIST_SUCCESS'
export const FETCH_ALL_STUDENT_LIST_FAILURE = 'FETCH_ALL_STUDENT_LIST_FAILURE'
export const FETCH_ALL_STUDENT_LIST_PENDING = 'FETCH_ALL_STUDENT_LIST_PENDING'
export const fetchAllStudents = (config = {}) => {
    return {
        promise: client =>
            client.get(baseUrl, {
                ...config,
                params: {
                    ignoreContext: 1,
                },
            }),
        types: [
            FETCH_ALL_STUDENT_LIST_REQUEST,
            FETCH_ALL_STUDENT_LIST_SUCCESS,
            FETCH_ALL_STUDENT_LIST_FAILURE,
            FETCH_ALL_STUDENT_LIST_PENDING,
        ],
    }
}

/**
 * Fetch a student.
 *
 * @param  {String} studentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchStudent = (studentId, config = {}) => {
    return apiClient.get(`${baseUrl}/${studentId}`, config)
}

/**
 * Create a new student.
 *
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const createStudent = (formData, config = {}) => {
    return apiClient.post(baseUrl, formData, config)
}

/**
 * Update a student.
 *
 * @param  {String} studentId
 * @param  {FormData} formData
 * @param  {Object} config
 * @return {Promise}
 */
export const updateStudent = (studentId, formData, config = {}) => {
    return apiClient.patch(`${baseUrl}/${studentId}`, formData, config)
}

/**
 * Delete a student.
 *
 * @param  {String} studentId
 * @param  {Object} config
 * @return {Promise}
 */
export const deleteStudent = (studentId, config = {}) => {
    return apiClient.delete(`${baseUrl}/${studentId}`, config)
}

/**
 * Get a student report cards.
 *
 * @param  {String} studentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchStudentReports = (studentId, config = {}) => {
    return apiClient.get(`${baseUrl}/${studentId}/reports`, config)
}

/**
 * Get a student studentMedals.
 *
 * @param  {String} studentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchStudentMedals = (studentId, config = {}) => {
    return apiClient.get(`${baseUrl}/${studentId}/medals`, config)
}

/**
 * Get a student certificates.
 *
 * @param  {String} studentId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchStudentCertificates = (studentId, config = {}) => {
    return apiClient.get(`${baseUrl}/${studentId}/certificates`, config)
}
