import React from 'react'

import { fetchCurriculumPreset } from '../actions/curriculumPresetActions'

import { CurriculumCardContainer } from '../components/Curriculum'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { TrackVisibility } from '../components/TrackVisibility'

const CurriculumPresetDetailsView = ({
    match: {
        params: { curriculumPresetId },
    },
}) => (
    <Main>
        <Header>Curriculum Preset Details</Header>
        <TrackVisibility overrideClass="u-anim-scroll">
            <CurriculumCardContainer curriculum={{ id: curriculumPresetId }} fetchMethod={fetchCurriculumPreset} />
        </TrackVisibility>
        <Footer />
    </Main>
)

export default CurriculumPresetDetailsView
