import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import 'filepond-polyfill'

import { isEmpty } from 'lodash-es'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import svg4everybody from 'svg4everybody'

import { fetchOrganization } from './actions/organizationActions'
import { AUTH_SET_TOKENS, fetchSelf } from './actions/userActions'
import * as serviceWorker from './serviceWorker'
import { getHistory, getStore } from './store'
import { isServer } from './utils/server'

import { App } from './components/App'

const store = getStore()
const history = getHistory()

if (!isServer) {
    history.listen(location => {
        window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
            'page_path': location.pathname,
            'page_title': document.title,
        })
    })
}

const organizationSlug = (function() {
    const slug = history.location.pathname.split('/')[1]
    return isEmpty(slug) ? null : slug
})()

function setAuthState(state) {
    try {
        if (state.organization.slug !== null) {
            localStorage.setItem(`${state.organization.slug}.tokens`, JSON.stringify((state.auth || {}).tokens))
        }
    } catch (error) {
        return undefined
    }
}

if (organizationSlug !== null) {
    /** Subscribe to auth state changes */
    store.subscribe(() => {
        setAuthState(store.getState())
    })

    store.dispatch({
        type: AUTH_SET_TOKENS,
        tokens: {},
        ...(function() {
            try {
                return {
                    tokens: JSON.parse(localStorage.getItem(`${organizationSlug}.tokens`)) || undefined,
                }
            } catch (error) {
                return {}
            }
        })(),
    })
}

const root = document.getElementById('root')

/** Initial authentication check before anything renders. */
store
    .dispatch(fetchOrganization(organizationSlug))
    .then(response => {
        /** I'm just here to swallow up found organization promis resolve... */
    })
    .catch(error => {
        /** ... and I'm just here to swallow up unfound organization promise rejection errors... */
    })
    .finally(() => {
        store
            .dispatch(fetchSelf())
            .then(response => {
                /** Do nothing. */
            })
            .catch(error => {
                /** Clean the existing token. */
                localStorage.removeItem(`${organizationSlug}.tokens`)
            })
            .finally(() => {
                const Application = (
                    <Provider store={store}>
                        <App history={history} organizationId={store.getState().organization.id} />
                    </Provider>
                )
                if (root.hasChildNodes() === true) {
                    hydrate(Application, root)
                } else {
                    svg4everybody()
                    render(Application, root)
                }
            })
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
