import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { UserProgress } from '../User'

const ItemManagerUserItem = ({ item }) => (
    <Layout overrideClass="-gutter-small -middle">
        <LayoutItem overrideClass="u-1/2@from-small">
            <RouterLink to={item.url} className="u-block item-manager_list_item_link">
                <div className="u-inline-block u-align-middle item-manager_picture">
                    <UserProgress isLink={false} user={item} value={0} />
                    UserProgress
                </div>
                <div className="u-inline-block u-align-middle">
                    {item.firstName} {item.lastName}
                    <p className="u-none@from-small u-margin-none">
                        <span className="link">{item.email}</span>
                    </p>
                </div>
            </RouterLink>
        </LayoutItem>
        <LayoutItem overrideClass="u-1/2@from-small">
            <span className="u-none@to-small">
                <Link to={`mailto:${item.email}`}>{item.email}</Link>
            </span>
        </LayoutItem>
    </Layout>
)

ItemManagerUserItem.propTypes = {
    item: PropTypes.shape({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
}

export default ItemManagerUserItem
