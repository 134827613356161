import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { renderRoute } from '../../utils/routes'

import { FilteredList, FilteredListUserItem } from '../FilteredList'

class DashboardAdministratorUserList extends React.Component {
    static propTypes = {
        buttonData: PropTypes.object.isRequired,
        fetchMethod: PropTypes.func.isRequired,
        requestState: PropTypes.string.isRequired,
        storeKey: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        userRoute: PropTypes.object.isRequired,
    }

    signal = axios.CancelToken.source()

    componentDidMount() {
        const { dispatch, fetchMethod } = this.props

        dispatch(fetchMethod({ cancelToken: this.signal.token }))
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { buttonData, isLoading, items, title } = this.props

        return (
            <FilteredList
                buttonData={buttonData}
                hasSorting={true}
                isLoading={isLoading}
                itemComponent={FilteredListUserItem}
                listHeaders={[
                    { id: 'name', label: 'Name' },
                    { id: 'email', label: 'Email' },
                ]}
                listItems={items}
                overrideClass="-small"
                title={`${items.length} ${title}`}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { requestState, storeKey, userRoute } = ownProps
    return {
        isLoading: state.requestStates[requestState] === true,
        items: state[storeKey].entities.map(item => {
            return {
                id: item.id,
                columns: {
                    email: item.email,
                    image: item.profileImage.url,
                    name: item.name,
                },
                data: item,
                url: renderRoute({
                    ...userRoute,
                    param: item.id,
                }),
            }
        }),
    }
}

export default connect(mapStateToProps)(DashboardAdministratorUserList)
