import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import sprite from '../../assets/images/sprite.svg'

const Icon = ({ name, overrideClass = '' }) => (
    <div className={classNames('icon', `-${name}`, overrideClass)}>
        <svg role="img">
            <use xlinkHref={`${sprite}#${name}`} />
        </svg>
    </div>
)

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    overrideClass: PropTypes.string,
}

export default Icon
