import React from 'react'

import axios from 'axios'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'

import { createCertificate } from '../../actions/certificateActions'
import { apiClient } from '../../helpers/ApiClient'
import { completedCourses } from '../../utils/courses'
import { renderRoute } from '../../utils/routes'

import { Button } from '../Button'
import { CertificateVisual } from '../Certificate'
import { Layout, LayoutItem } from '../Layout'
import { SimpleList } from '../SimpleList'
import { fetchReports } from '../../actions/reportActions'

class ReportCertification extends React.Component {
    signal = axios.CancelToken.source()

    handleClickGetCertification = event => {
        const {
            dispatch,
            report: { id },
        } = this.props

        createCertificate({ reportId: id }, { cancelToken: this.signal.token })
            .then(({ data }) => {
                apiClient.clearCache('certificates')
                apiClient.clearCache('report')
                apiClient.clearCache('student-medals')
                // fetch (reset) the stored report cards for the user
                dispatch(fetchReports({ cancelToken: this.signal.token }))
                dispatch(
                    push(
                        renderRoute({
                            id: 'certificate-details',
                            bind: 'certificateId',
                            param: data.id,
                        })
                    )
                )
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.warn(error.response.data)
                }
            })
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const {
            report: {
                session: { courses, curriculum },
                studentId,
            },
            tasks,
            user,
        } = this.props
        const completedTasks = tasks.filter(task => task.isCompleted)
        const isCertificateCompleted = completedTasks.length > 0 && completedTasks.length === tasks.length
        const listItems = [
            { label: 'Steps', value: `${completedTasks.length}/${tasks.length}` },
            { label: 'Course progress', value: `${completedCourses(courses).length}/${courses.length}` },
        ]
        const isStudentConnected = user !== null && user.pairedObjectId === studentId

        return (
            <React.Fragment>
                {isCertificateCompleted ? (
                    <Layout overrideClass="-gutter-small u-padding-small-y u-padding-small-x -middle">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <CertificateVisual
                                name={curriculum.certificationCode}
                                image={curriculum.certificationImage.url}
                            />
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small u-padding-small-y">
                            <h2 className="o-h2 u-text-big">
                                Certification <br />
                                completed!
                            </h2>
                            {isStudentConnected && (
                                <Button onClick={this.handleClickGetCertification}>Get Certificate</Button>
                            )}
                        </LayoutItem>
                    </Layout>
                ) : (
                    <Layout overrideClass="-gutter-small u-padding-small-y u-padding-small-x">
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <div className="o-image -rounded">
                                <img src={curriculum.programLogo.url} alt={curriculum.programName} />
                            </div>
                        </LayoutItem>
                        <LayoutItem overrideClass="u-1/2@from-small">
                            <SimpleList items={listItems} />
                            <div className="layout simple-list_line">
                                <div className="layout_item u-1/2">
                                    <p className="simple-list_label">Certification</p>
                                </div>
                                <div className="layout_item u-1/2 u-text-right">
                                    <CertificateVisual
                                        name={curriculum.certificationCode}
                                        image={curriculum.certificationImage.url}
                                    />
                                </div>
                            </div>
                        </LayoutItem>
                    </Layout>
                )}
            </React.Fragment>
        )
    }
}

ReportCertification.propTypes = {
    report: PropTypes.object.isRequired,
    tasks: PropTypes.array.isRequired,
}

ReportCertification.Placeholder = () => (
    <ContentLoader height={312} width={898} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="14" y="30" rx="5" ry="5" width="421" height="251" />
        <rect x="673" y="115" rx="5" ry="5" width="212" height="128" />
        <rect x="460" y="42" rx="0" ry="0" width="40" height="12" />
        <rect x="460" y="81" rx="0" ry="0" width="120" height="12" />
        <rect x="460" y="118" rx="0" ry="0" width="80" height="12" />
        <rect x="850" y="42" rx="0" ry="0" width="35" height="12" />
        <rect x="850" y="81" rx="0" ry="0" width="35" height="12" />
        <rect x="460" y="67" rx="0" ry="0" width="423" height="2" />
        <rect x="460" y="105" rx="0" ry="0" width="423" height="2" />
    </ContentLoader>
)

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ReportCertification)
