import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

const Telephone = ({ form, initialValue }) => (
    <div className="o-input_wrapper">
        <label htmlFor="telephone" className="o-label">
            Phone Number
        </label>
        {form.getFieldDecorator('telephone', {
            initialValue: initialValue,
        })(<input type="phone" id="telephone" className="o-input" />)}
    </div>
)

Telephone.propTypes = {
    form: formShape,
    initialValue: PropTypes.string,
}

Telephone.defaultProps = {
    initialValue: '',
}

export default Telephone
