import classNames from 'classnames'
import TweenMax from 'gsap'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Button } from '../Button'

class NavAccordion extends React.Component {
    static defaultProps = {
        isOpen: false,
    }

    accordionBody = React.createRef()
    accordionBodyInner = React.createRef()

    getAccordionBound() {
        const bound = this.accordionBodyInner.current.getBoundingClientRect()
        return Math.round(bound.height)
    }

    open() {
        const height = this.getAccordionBound()
        TweenMax.to(this.accordionBody.current, 0.35, { height: height })
    }

    close() {
        TweenMax.to(this.accordionBody.current, 0.35, { height: 0 })
    }

    componentDidUpdate() {
        if (this.props.isOpen) {
            this.open()
        } else {
            this.close()
        }
    }

    render() {
        const {
            handleClick,
            id,
            isOpen,
            item: { button, items, label },
        } = this.props

        return (
            <div
                className={classNames('nav-accordion_item', {
                    '-open': isOpen,
                })}
            >
                <span
                    className="nav_group_item_label"
                    onClick={() => {
                        handleClick(id)
                    }}
                >
                    {label} <span className="nav-accordion_item_icon"></span>
                </span>
                <div className="nav-accordion_body" ref={this.accordionBody}>
                    <div className="nav-accordion_item_inner" ref={this.accordionBodyInner}>
                        {items.length > 0 && (
                            <ul className="nav-subnav">
                                {items.map((childItem, index) => (
                                    <li key={index} className="nav-subnav_item">
                                        <RouterLink to={childItem.url} className="nav-subnav_link">
                                            {childItem.label}
                                        </RouterLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {!isEmpty(button) && <Button route={button.route}>+ {button.label}</Button>}
                    </div>
                </div>
            </div>
        )
    }
}
NavAccordion.propTypes = {
    id: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    item: PropTypes.object.isRequired,
}

export default NavAccordion
