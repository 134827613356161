import React from 'react'

import axios from 'axios'
import PropTypes from 'prop-types'
import { SwitchTransition } from 'react-transition-group'

import { fetchSessionCourses, fetchSessionReports, fetchSessionStudents } from '../../actions/sessionActions'
import { fetchSessionDraftCourses, fetchSessionDraftStudents } from '../../actions/sessionDraftActions'

import { Fade } from '../Fade'
import { SessionCard } from '../Session'
import mapFetchToState from '../../helpers/mapFetchToState'

class SessionCardContainer extends React.Component {
    signal = axios.CancelToken.source()

    state = {
        error: null,
        courses: [],
        isLoading: true,
        students: [],
        reports: [],
    }

    loadSessionData(sessionId) {
        const { isDraft } = this.props
        this.setState({ isLoading: true }, () => {
            const actionParams = [sessionId, { cancelToken: this.signal.token }]
            const courseAction = isDraft ? fetchSessionDraftCourses : fetchSessionCourses
            const studentAction = isDraft ? fetchSessionDraftStudents : fetchSessionStudents

            mapFetchToState({
                courses: courseAction(...actionParams),
                ...(isDraft
                    ? {
                          students: studentAction(...actionParams),
                      }
                    : {
                          reports: fetchSessionReports(...actionParams),
                      }),
            })
                .then(values => {
                    this.setState({
                        ...values,
                        isLoading: false,
                    })
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({
                            error: error.response.data,
                            isLoading: false,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        const { session } = this.props

        this.loadSessionData(session.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.session.isPlaceholder !== this.props.session.isPlaceholder) {
            this.loadSessionData(this.props.session.id)
        }
    }

    componentWillUnmount() {
        this.signal.cancel('API call is being canceled.')
    }

    render() {
        const { cardProps, isDraft, session } = this.props
        const { courses, error, isLoading, students, reports } = this.state

        return (
            <SwitchTransition>
                <Fade timeout={250} key={isLoading}>
                    {isLoading ? (
                        <SessionCard.Placeholder {...cardProps} />
                    ) : error !== null ? (
                        <section className="u-relative">{error.message}</section>
                    ) : (
                        <SessionCard
                            isDraft={isDraft}
                            session={{
                                ...session,
                                students,
                                reports,
                                courses,
                            }}
                            {...cardProps}
                        />
                    )}
                </Fade>
            </SwitchTransition>
        )
    }
}

SessionCardContainer.propTypes = {
    cardProps: PropTypes.object,
    isDraft: PropTypes.bool,
    session: PropTypes.object.isRequired,
}

SessionCardContainer.defaultProps = {
    cardProps: {},
    isDraft: false,
}

export default SessionCardContainer
