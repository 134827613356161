import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { renderNavItems } from '../../utils/nav'
import { renderRoute } from '../../utils/routes'

import { IconButton } from '../IconButton'
import { NavItem, NavSports } from '../Nav'
import { UserImage } from '../User'

class Nav extends React.Component {
    state = {
        isOpen: false,
        openAccordionId: null,
    }

    toggleNav = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    toggleAccordion = id => {
        const { openAccordionId } = this.state
        if (id === openAccordionId) {
            this.setState({
                openAccordionId: null,
            })
        } else {
            this.setState({
                openAccordionId: id,
            })
        }
    }

    render() {
        const { isOpen, openAccordionId } = this.state
        const { currentSport, navItems, organization, sports } = this.props

        return (
            <nav className={classNames('nav', { '-open': isOpen })}>
                <div className="nav_button -open" onClick={this.toggleNav}>
                    <IconButton name="burger" />
                </div>
                <div className="nav_button -close" onClick={this.toggleNav}>
                    <IconButton name="close" />
                </div>

                <div className="nav_inner">
                    <div className="nav_top">
                        <RouterLink to={renderRoute('organization')}>
                            <UserImage overrideClass="user-image nav_top_logo" url={organization.data.logoImage.url} />
                            <h1 className="o-h2 u-text-center nav_top_title">{organization.data.name}</h1>
                        </RouterLink>
                    </div>

                    <ul className="nav_group">
                        <NavSports currentSport={currentSport} sports={sports} />
                        {navItems.map((item, index) => (
                            <NavItem
                                key={index}
                                handleAccordionClick={this.toggleAccordion}
                                id={index}
                                item={item}
                                openAccordionId={openAccordionId}
                            />
                        ))}
                    </ul>
                    <div className="nav_footer">
                        <a className="nav_footer_link" href="/contact">
                            Contact us
                        </a>
                        <a className="nav_footer_link u-none@from-medium" href="/privacy-policy">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </nav>
        )
    }
}

Nav.propTypes = {
    navItems: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    const {
        auth: { user },
        organization,
        router: {
            location: { pathname },
        },
    } = state

    return {
        currentSport: user.sport,
        navItems: renderNavItems(pathname, user.role.id),
        organization,
        sports: organization.data.sports,
    }
}

export default connect(mapStateToProps)(Nav)
