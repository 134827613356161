import React from 'react'

import { connect } from 'react-redux'

import AdministratorEditView from './AdministratorEditView'
import InstructorEditView from './InstructorEditView'
import ParentEditView from './ParentEditView'
import StudentEditView from './StudentEditView'

const VIEWS = {
    administrator: AdministratorEditView,
    instructor: InstructorEditView,
    parent: ParentEditView,
    student: StudentEditView,
}

const AccountEditView = ({ user, view: UserEditView }) => (
    <UserEditView title="My Account" objectId={user.pairedObjectId} />
)

const mapStateToProps = state => {
    const {
        auth: { user },
    } = state
    return {
        user,
        view: VIEWS[user.role.id],
    }
}

export default connect(mapStateToProps)(AccountEditView)
