import React from 'react'

import PropTypes from 'prop-types'

import { Layout, LayoutItem } from '../Layout'

const DashboardInfos = ({ items }) =>
    items.length > 0 && (
        <div className="dashboard-infos">
            <Layout overrideClass="-gutter-small">
                {items.map((item, index) => (
                    <LayoutItem overrideClass="u-1/2" key={index}>
                        <p className="dashboard-infos_label u-anim -delay-1">{item.label}</p>
                        <p className="dashboard-infos_value u-anim -delay-2">{item.value}</p>
                    </LayoutItem>
                ))}
            </Layout>
        </div>
    )

DashboardInfos.propTypes = {
    items: PropTypes.array,
}

DashboardInfos.defaultProps = {
    items: [],
}

export default DashboardInfos
