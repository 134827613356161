import React from 'react'
import { Layout, LayoutItem } from '../Layout'
import { BirthDate, ConfirmEmail, Email, FirstName, LastName, ProfileImage } from '../FormFields'
import { SearchableSelect } from '../SearchableSelect'
import { fetchParents } from '../../actions/parentActions'
import { formShape } from 'rc-form'

const CreateStudentForm = ({ form }) => {
    const handleBirthDateDatetimeChange = moment => {
        form.setFieldsValue({ birthDate: moment.format('YYYY-MM-DD') })
    }

    return (
        <Layout overrideClass="-gutter-small">
            <LayoutItem overrideClass="u-1/2@from-small">
                <FirstName form={form} />
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <LastName form={form} />
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <Email form={form} />
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <ConfirmEmail form={form} />
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <ProfileImage form={form} />
            </LayoutItem>
            <LayoutItem overrideClass="u-1/2@from-small">
                <SearchableSelect
                    form={form}
                    inputName="studentParentIds"
                    inputLabel="Parents"
                    fetchMethod={fetchParents}
                    requestState="FETCH_PARENT_LIST"
                    storeKey="parents"
                />
                <BirthDate form={form} handleDatetimeChange={handleBirthDateDatetimeChange} />
            </LayoutItem>
        </Layout>
    )
}

CreateStudentForm.propTypes = {
    form: formShape.isRequired,
}

export default CreateStudentForm
