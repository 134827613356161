import React from 'react'

import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Main } from '../components/Main'
import { MedalForm } from '../components/MedalForm'
import { TrackVisibility } from '../components/TrackVisibility'

const MedalCreateView = () => (
    <Main>
        <Header>
            Create <br /> a Medal
        </Header>
        <TrackVisibility overrideClass="u-anim-scroll">
            <MedalForm />
        </TrackVisibility>
        <Footer />
    </Main>
)

export default MedalCreateView
