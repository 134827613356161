import { apiClient } from '../helpers/ApiClient'

const baseUrl = 'certificates'

/**
 * Fetch a list of all certificates for the active organization for the logged in user.
 *
 * @param  {Object} config
 * @return {Promise}
 */
export const FETCH_CERTIFICATE_LIST_REQUEST = 'FETCH_CERTIFICATE_LIST_REQUEST'
export const FETCH_CERTIFICATE_LIST_SUCCESS = 'FETCH_CERTIFICATE_LIST_SUCCESS'
export const FETCH_CERTIFICATE_LIST_FAILURE = 'FETCH_CERTIFICATE_LIST_FAILURE'
export const FETCH_CERTIFICATE_LIST_PENDING = 'FETCH_CERTIFICATE_LIST_PENDING'
export const fetchCertificates = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_CERTIFICATE_LIST_REQUEST,
            FETCH_CERTIFICATE_LIST_SUCCESS,
            FETCH_CERTIFICATE_LIST_FAILURE,
            FETCH_CERTIFICATE_LIST_PENDING,
        ],
    }
}

/**
 * Get a certificate.
 *
 * @param  {String} certificateId
 * @param  {Object} config
 * @return {Promise}`
 */
export const fetchCertificate = (certificateId, config = {}) => {
    return apiClient.get(`${baseUrl}/${certificateId}`, config)
}

/**
 * Create a certificate.
 *
 * @param  {Object} data
 * @param  {Object} config
 * @return {Promise}`
 */
export const createCertificate = (data, config = {}) => {
    return apiClient.post(baseUrl, data, config)
}

/**
 * Fetch all the data required to display a Certificate publicly.
 *
 * @param  {String} certificateId
 * @param  {Object} config
 * @return {Promise}
 */
export const fetchPublicCertificate = (certificateId, config = {}) => {
    return apiClient.get(`public/${baseUrl}/${certificateId}`, config)
}
