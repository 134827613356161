import React, { useState, useRef, useLayoutEffect } from 'react'

import { Transition } from 'react-transition-group'

const defaultStyle = (duration, state) => {
    return {
        transition: `opacity ${duration}ms ${easing[state]}, max-height ${duration}ms ${easing[state]}, min-height ${duration}ms ${easing[state]}`,
        overflow: 'hidden',
        opacity: 0,
    }
}

const easing = {
    entering: 'ease-out',
    entered: 'ease-out',
    exiting: 'ease-out',
    exited: 'ease-out',
}

const FadeList = props => {
    const [transitionStyles, setTransitionStyles] = useState(0)
    const ref = useRef(null)

    useLayoutEffect(() => {
        const height = ref.current ? ref.current.clientHeight : 0
        setTransitionStyles({
            entering: {
                opacity: 0,
                maxHeight: FadeList.lastHeight || height,
                minHeight: FadeList.lastHeight || height,
            },
            entered: { opacity: 1, maxHeight: height, minHeight: height },
            exiting: { opacity: 0, maxHeight: height, minHeight: height },
            exited: { opacity: 0, maxHeight: height, minHeight: height },
        })
        FadeList.lastHeight = height
    }, [])

    return (
        <Transition {...props} timeout={props.timeout || 250}>
            {state => (
                <div
                    className={props.className}
                    ref={ref}
                    style={{
                        ...defaultStyle(props.timeout || 250, state),
                        ...transitionStyles[state],
                    }}
                >
                    {props.children}
                </div>
            )}
        </Transition>
    )
}

// Feels like a hack
FadeList.lastHeight = null

export default FadeList
