import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import createApiClientMiddleware from './middlewares/createApiClientMiddleware'
import { apiClient } from './helpers/ApiClient'
import createRootReducer from './reducers'
import { isServer } from './utils/server'

let storeInstance = null
let historyInstance = null

export const createStore = (url = '/') => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url]
    })
    : createBrowserHistory()

    const enhancers = []
    const middleware = [ createApiClientMiddleware(apiClient), routerMiddleware(history), thunk ]

    if (process.env.NODE_ENV === 'development' && !isServer) {
        middleware.push(createLogger({ collapsed: true }))
        const devToolsExtension = window.devToolsExtension
        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension())
        }
    }

    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    )

    // Do we have preloaded state available? Great, save it.
    const initialState = !isServer ? window.__PRELOADED_STATE__ : {}
    // Delete it once we have it stored in a variable
    if (!isServer) {
        delete window.__PRELOADED_STATE__
    }

    // Create the store
    const store = reduxCreateStore(
        createRootReducer(history),
        initialState,
        composedEnhancers
    )

    storeInstance = store
    historyInstance = history
    apiClient.setStore(store)

    return {
        store,
        history,
    }
}

export const getStore = () => {
    if (storeInstance === null) {
        createStore()
    }
    return storeInstance
}

export const getHistory = () => {
    if (historyInstance === null) {
        createStore()
    }
    return historyInstance
}

/*
const history = isServer ? createMemoryHistory({
    initialEntries: [url]
}) : createBrowserHistory()

const middleware = [createApiClientMiddleware(apiClient), routerMiddleware(history), thunk]

if (process.env.NODE_ENV === `development`) {
    middleware.push(createLogger({ collapsed: true }))
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose()
const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(...middleware)))

apiClient.setStore(store)

export { history, middleware, store }
*/
