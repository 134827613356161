import React from 'react'

import PropTypes from 'prop-types'

const LayoutItem = ({ children, extraProps, overrideClass }) => {
    return (
        <div className={`layout_item ${overrideClass}`} {...extraProps}>
            {children}
        </div>
    )
}

LayoutItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    extraProps: PropTypes.object,
    overrideClass: PropTypes.string,
}

LayoutItem.defaultProps = {
    children: null,
    extraProps: {},
    overrideClass: '',
}

export default LayoutItem
